import React from 'react';
import '../../assets/styles/components/billing/billing-team-subscription.scss';
import logo from '../../assets/images/Kor-logo.svg';

const BillingTeamsSubscription = ({ teams, fetchTeams, activeName, handleGetInfo }) => {
    return (
        <div className="billing-teamsub-box">
            {teams && teams.map(team => (
                team.role === 'Owner' && (
                    <div key={team.id} className={(activeName === team.name) ? 'card-team-subscription-active' : 'card-team-subscription'} onClick={()=> handleGetInfo(team, team.name)}>
                        <div className="avatar-team ml-2">
                            <img src={logo} alt='logo'></img>
                        </div>
                        <p className="name-team">{team.name}</p>
                        <p className="type-sub">{team.subscription}</p>
                    </div>
                )
            ))}
        </div>
    )
}


export default BillingTeamsSubscription;
