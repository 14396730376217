import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { changeClass, changeInitial } from '../../actions/navbar.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopyright
} from '@fortawesome/free-regular-svg-icons';
import '../../assets/styles/components/sidebar/sidebar.scss';
import { fetchTeams } from '../../actions/team.actions';
import { Form } from 'react-bootstrap';
import logo from '../header/Kor-logo.svg';
import closeIcon from '../../assets/images/icons/close-icon.svg';
import pinSidebar from '../../assets/images/icons/pushpin.svg';
import { changeSelectedTeam } from '../../actions/team.actions';

const SideBar = ({ 
  active, 
  habitat, 
  fetchTeams, 
  changeClass, 
  classNavBar,
  team,
  teams,
  changeSelectedTeam,
  changeInitial,
  initialClass
}) => {
  const [labelNav, setLabelNav] = useState('hidden-label');
  const [sideNavSize, setNavSizeNav] = useState('');
  const [barsMenu, setBarsMenu] = useState('bars-menu');
  const [sideBarLine, setSideBarLine] = useState('sidebar-line');
  const [addClass, setAddClass] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => { fetchTeams() }, []);

  const handleMenu = () => {
    changeClass(active);
    setAddClass(!addClass);

  };

  const handleSelectTeam = (teamId) => {
    changeSelectedTeam(teamId)
  }

  return (
    <div className={"menu-container-kor " + classNavBar + ' ' + initialClass}>
    <div className={'side-bar-container' + ' ' + classNavBar} onClick={handleMenu}>
    </div>
    <div className={!active ? 'menu-kor-sidebar animate__animated animate__slideOutLeft' : 'menu-kor-sidebar animate__animated animate__slideInLeft'}>
        <div className="container-menu-sections">
        <div className="header-kor-logo">
          <div className="logo-line-close">
          <img src={closeIcon} className="closeIcon" alt='close-icon' onClick={handleMenu} />
          <div className="line"></div>
          <img src={logo} className="app-logo" alt="logo" />
          </div>
          <div className="select-teams">
            <p>Team</p>
            {team.name ? (
            <Form.Control
              className="metric-selectbox"
              as="select"
              defaultValue={'DEFAULT'}
              onChange={e => handleSelectTeam(e.target.value)}
            >
              <option value="DEFAULT" disabled>{team.name}</option>
              <option value={team.id}>Personal</option>
              {teams.map(team => (
                <option value={team.id} key={team.id}>{team.name}</option>
              ))}
            </Form.Control>
          ) : (
            <Form.Control
              className="metric-selectbox"
              as="select"
              defaultValue={'DEFAULT'}
              onChange={e => handleSelectTeam(e.target.value)}
            >
              <option value={team.id}>Personal</option>
              {teams.map(team => (
                <option value={team.id} key={team.id}>{team.name}</option>
              ))}
            </Form.Control>
          )}
          </div>
        </div>

        <div className="menu-kor-sections">
          <p className="menu-title">Menu</p>
          <div className="section-menu" onClick={handleMenu}>
          <NavLink exact to='/Overview'>
            <p>Overview</p>
            </NavLink>
          </div>
          <div className="section-menu"  onClick={handleMenu}>
          <NavLink exact to='/Habitats'>
            <p>Habitats</p>
            </NavLink>
          </div>
          <div className="section-menu"  onClick={handleMenu}>
          <NavLink exact to='/Repositories'>
            <p>Repositories</p>
            </NavLink>
          </div>
          <div className="section-menu"  onClick={handleMenu}>
            <NavLink exact to='/Templates'>
             <p>Templates</p>
            </NavLink>
          </div>
          <div className="section-menu"  onClick={handleMenu}>
          <NavLink exact to='/SettingsPage'>
            <p>Credentials</p>
            </NavLink>
          </div>
          <div className="section-menu"  onClick={handleMenu}>
          <NavLink exact to='/Teams'>
            <p>Teams</p>
            </NavLink>
          </div>
          <div className="section-menu"  onClick={handleMenu}>
          <NavLink exact to='/Billing'>
            <p>Billing</p>
            </NavLink>
          </div>
          <div className="section-menu"  onClick={handleMenu}>
          <NavLink exact to='/Account'>
            <p>Settings</p>
            </NavLink>
          </div>
          <div className="section-menu"  onClick={handleMenu}>
          <NavLink exact to='/Help'>
            <p>Help</p>
            </NavLink>
          </div>
        </div>
        </div>

        <div>
        <div className="pin-sidebar">
          <div className="icon-sidebar">
            <img src={pinSidebar} alt="push-pin" />
          </div>
          <p>Pin to Sidebar</p>
        </div>

        <div className="copyrights-text"> 
            <p><FontAwesomeIcon icon={faCopyright} /> 2021 KOR Inc. All rights reserved. </p>
            <p>KOR is registered trademark of FluxNetwork Inc. Terms, conditions, features, avaliability, pricing, fees, service and support options subject change without notice.</p>
        </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  currentUser: state.user.user,
  active: state.navbar.active,
  team: state.team.team,
  teams: state.team.teams,
  habitat: state.habitat.habitat,
  classNavBar: state.navbar.class,
  initialClass: state.navbar.initalClass
});
const mapDispatch = {
  changeClass,
  fetchTeams,
  changeSelectedTeam,
  changeInitial
};

export default connect(mapState, mapDispatch)(SideBar);
