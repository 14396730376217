import React, { useState } from 'react';
import '../../../assets/styles/components/billing/modals-billing.scss';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

const PlanTeamModal = (props) => {


  const [showCancel, setShowCancel] = useState(false);

  const plan4 = props.plan4;
  const plan5 = props.plan5;
  const nameInterval = props.nameInterval;

  const handleCloseModal = () => {
    if (props.modalComparePlanTeam === 'display-modal') {
      props.setModalComparePlanTeam('display-none');
    }
  };

  const handleShowCancel = () => {
    setShowCancel(!showCancel);
  }

  const handleCancelSubscription = () => {
    setShowCancel(!showCancel);
    props.handeleCancelSubscription();
  }

  return (
    <div className={props.modalComparePlanTeam + ' modal-billing-container'}>
      <div className="container-plans">
        <div className="title-toggle">
          <div className="title-plans">
            <p>
              <b>Streamline serverless development now</b>
            </p>
          </div>
          <div className="close-modal" onClick={handleCloseModal}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>

        <div className="plan-cards-container">
          {!showCancel ? (
            <div
              className={`plan-card-1 ${props.team.subscription === 'Project' && 'select'
                }`}
            >
              <div className="title-description-plan">
                <p>Basic</p>
                <p>For small teams trying out KOR for an unlimited amount of time</p>
              </div>
              <div className="price-plan">
                <p>$0 USD/mo</p>
                <p>Free Forever</p>
              </div>
              <div className="plan-features">
                <p>Top Features</p>
                <div className="feature-billing-item">
                  <FontAwesomeIcon icon={faCheck} className="color-green" />{' '}
                  <p>Unlimited teams</p>
                </div>

                <div className="feature-billing-item">
                  <FontAwesomeIcon icon={faCheck} className="color-green" />
                  <p>3 team members</p>
                </div>

                <div className="feature-billing-item">

                  <FontAwesomeIcon icon={faCheck} className="color-green" />
                  <p>2 habitats</p>
                </div>

                <div className="feature-billing-item">
                  <FontAwesomeIcon icon={faCheck} className="color-green" />
                  <p>3 functions</p>
                </div>
              </div>
              {props.team.subscription === 'Project' ? (
                <div className="button-up-select">
                  <p>Current Plan</p>
                </div>
              ) : (
                  <div className="button-up-downgrade" onClick={handleShowCancel}>
                    <p>{props.currentPlan3}</p>
                  </div>
                )}
            </div>
          ) : (
              <div className={`plan-card-1 ${props.team.subscription === 'Project' && 'select'
                }`}>
                <div className="title-description-plan">
                  <p>Are you sure to cancel subscription?</p>
                </div>
                <div className="plan-features text-center">
                  <Button
                    variant="outline-primary"
                    className="m-2"
                    onClick={handleCancelSubscription}
                  >Yes</Button>
                  <Button
                    variant="outline-secondary"
                    className="m-2"
                    onClick={handleShowCancel}
                  >No</Button>
                </div>
              </div>
            )}

          <div
            className={`plan-card-1 ${props.team.subscription === 'Pro' && 'select'
              }`}
          >
            <div className="title-description-plan">
              <p>Pro</p>
              <p>For companies that require advanced tools, dynamic team options, and greater support</p>
            </div>
            <div className="price-plan">
              <p>$9.99 USD/mo</p>
              <p>Per user billed annually <br></br> 12.99 USD billed monthly</p>
            </div>
            <div className="plan-features">
              <p>Top Features</p>
              <div className="feature-billing-item">
                <FontAwesomeIcon icon={faCheck} className="color-green" />{' '}
                <p>Unlimited team menbers</p>
              </div>
              <div className="feature-billing-item">
                <FontAwesomeIcon icon={faCheck} className="color-green" />{' '}
                <p>Unlimited habitats</p>
              </div>
              <div className="feature-billing-item">
                <FontAwesomeIcon icon={faCheck} className="color-green" />{' '}
                <p> Unlimited functions</p>
              </div>
            </div>
            {props.team.subscription === 'Pro' ? (
              <div className="button-up-select">
                <p>Current Plan</p>
              </div>
            ) : (
                <div className="button-up-downgrade">
                  <p
                    onClick={(event) =>
                      props.handleGoCheckout(props.plan5, nameInterval)
                    }
                  >
                    {props.currentPlan5}
                  </p>
                </div>
              )}
          </div>

          <div
            className={`plan-card-1 ${props.team.subscription === 'Enterprice' && 'select'
              }`}
          >
            <div className="title-description-plan">
              <p>Enterprise</p>
              <p>
                For enterprises in highly regulated industries or extra large
                businesses
              </p>
            </div>
            <div className="price-plan">
              <p>Let&apos;s Talk</p>
            </div>
            <div className="plan-features">
              <p>Top Features</p>

              <div className="feature-billing-item">

                <FontAwesomeIcon icon={faCheck} className="color-green" />{' '}
                <p> Custom integration solutions</p>
              </div>

              <div className="feature-billing-item">
                <FontAwesomeIcon icon={faCheck} className="color-green" />{' '}
                <p>Tailored support with customer success team</p>
              </div>

              <div className="feature-billing-item">
                <FontAwesomeIcon icon={faCheck} className="color-green" />{' '}
                <p>Enterprise-grade compliance and security</p>
              </div>

            </div>
            {props.team.subscription === 'Enterprice' ? (
              <div className="button-up-select">
                <p>Current Plan</p>
              </div>
            ) : (
                <div className="button-up-downgrade">
                  <p>Contact Us</p>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanTeamModal;
