import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

export const createAurora = data => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/aws/aws_aurora`, data, { headers: authToken });
}

export const getAuroras = habitat_id => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/aws/aws_aurora?habitat_id=${habitat_id}`, { headers: authToken });
}

export const deleteAurora = (habitat_id, id) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.delete(`${URL}/aws/aws_aurora/${id}?habitat_id=${habitat_id}`, { headers: authToken });
}

export const createRds = data => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/aws/aws_rds`, data, { headers: authToken });
}

export const getRds = habitat_id => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/aws/aws_rds?habitat_id=${habitat_id}`, { headers: authToken });
}

export const deleteRds = (habitat_id, id) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.delete(`${URL}/aws/aws_rds/${id}?habitat_id=${habitat_id}`, { headers: authToken });
}