export const templateToID = (idStr) => {
  return idStr.replace(/kor/g, '-');
}

export const idToTemplate = (idStr) => {
  return idStr.replace(/-/g, 'kor');
}

export const onlyUnique = (value, index, self) => { 
  return self.indexOf(value) === index;
}

export const findLineBreak = (str) => {
  return str.replace(/\W+(?=[A-Z][a-z])/g, '$&\n');
}

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
}