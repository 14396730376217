import React from 'react';
import Moment from 'moment';

const BillingHistoryList = (props) => {
    let sortedPayments = props.payments.reverse()
        
	return (
        <div className="page-box billing-page-box"> 
            {sortedPayments.map((payment) => (
                <div key={payment.id.toString()} className="row billing-history-row">
                    
                    <div className="billing-column-left billing-column-text-normal">
                        <p className="date-width">{Moment(payment.created_at).format('MMMM D, YYYY')}</p>
                    </div>
                    <div className="billing-column-left billing-column-text-info">
                        <p>{payment.description}</p>
                    </div>
                    <div className="billing-column-empty">

                    </div>
                    <div className="billing-column-right billing-column-text-normal">
                        <p>$.9.99</p>
                    </div>
                    <div className="billing-column-right billing-column-text-normal">
                        
                        { payment && ( <p className="pay-hist-acc"><i className="pay-hist-acc fas fa-check-circle"></i><i className="pay-hist-text-acc"> Paid</i></p> ) }
                            
                        { !payment && ( <p className="pay-hist-rej"><i className="pay-hist-rej fas fa-times-circle"></i><i className="pay-hist-text-rej">Not Paid</i></p> ) }
                    
                    </div>
                    <div className="billing-column-last billing-column-text-normal">
                        <div className="receipt-link">
                            <a href={payment.invoice_url} target="_blank" rel="noopener noreferrer" ><i className="fas fa-receipt"></i><u className="receipt-hist-text">Receipt </u></a>
                        </div>
                    </div>
                    <div className="border-bottom-line" />
                </div>
            ))}
        </div>

	);
}

export default BillingHistoryList;