import React from 'react';
import '../../assets/styles/components/settings/aws-permissions-settings.scss';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faQuestionCircle, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';

const AWSPermissions = (props) => {
    return (
        <div className='no-collapsed'>
            <div className='title-aws-1'>
                <p>AWS Credentials</p>
                <Button
                    variant='outline-primary'
                    onClick={props.handleAddAWSCredsURL}
                >
                    Authorize AWS
                </Button>
            </div>

            {props.loading && (
                <div className="spinner-container">
                    <Spinner animation="border" variant="primary" />
                    <p>Creating Credential..</p>
                </div>
            )}


            {props.error && (
                <div className='error-display text-danger'>
                    <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {props.error}</p>
                </div>
            )}

            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Region</th>
                        <th scope="col">Verified</th>
                        <th scope="col">Created At</th>
                    </tr>
                </thead>
                <tbody>
                    {props.awsCredsUser.userCredentials.aws_credentials.map((cred) => (
                        <tr key={cred.id}>
                            <td>{cred.name}</td>
                            <td>{cred.region}</td>
                            {cred.verified ? (
                                <td className='verified-check'><FontAwesomeIcon icon={faCheckCircle} /></td>
                            ) : (
                                <td className='no-verified-check'><FontAwesomeIcon icon={faExclamationCircle} /></td>
                            )}
                            <td>{cred.created_at}</td>

                        </tr>
                    ))}
                </tbody>
            </table>

            <p className="description-aws">We use Cross-account to integrate KOR with AWS</p>
        </div>
    )
}

export default AWSPermissions;