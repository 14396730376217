import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import FunctionBox from '../funcs/FuncBox';

const Functions = (props) => {
    return(
            <FunctionBox gitClones={props.gitClones} />
    );
};

const mapState = (state) => ({
});
const mapDispatch = { 
};
export default connect(mapState, mapDispatch)(Functions);