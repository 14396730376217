import React from 'react';
import { Button, Form } from 'react-bootstrap';

const HabitatSaveTestModal = ({
  setShow,
  testName,
  setTestName,
  handleSaveTest
}) => {

  return (
    <div className="create-team-form">

      <div className="w-100 pl-5 pr-5">
        <p className="modal-title">Add test name</p>
        <div className="pr-4 pl-4">
          <Form.Control
            type="text"
            value={testName}
            name="credsName"
            aria-label="Name"
            autoComplete="off"
            placeholder="Test Name"
            className="aws-creds-form"
            aria-describedby="new-name"
            onChange={e => setTestName(e.target.value)}
          />
        </div>
      </div>

      <div className="pl-5 pr-5 buttons-container">
        <Button
          size='sm'
          className="mr-3"
          variant="primary"
          onClick={e => setShow(false)}
        >Cancel</Button>
        <Button
          size='sm'
          disabled={!testName}
          className="ml-3"
          variant="primary"
          onClick={e => handleSaveTest()}
        >Save</Button>
      </div>

    </div>
  )
}

export default HabitatSaveTestModal;

