import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

// Create team
export const purchasePro= (token, interval) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));  
  const data = {
    stripe: token,
    plan: 'pro',
    interval: interval
  }
  return axios.post(`${URL}/subscriptions`, data, { headers: authToken })
}

export const purchaseStartup= (token, interval, team_email, seats) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));  
  const data = {
    stripe: token,
    plan: 'startup',
    interval: interval,
    team_email: team_email,
    seats: seats
  }
  return axios.post(`${URL}/subscriptions`, data, { headers: authToken })
}

// Get all payments for Pro subscription
export const getProSubscriptions = () => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));  
  return axios.get(`${URL}/pro_payments`, { headers: authToken })
}

// Downgrade from Pro Plan
export const downgradeProPlan = (stripe_id) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));  
	return axios.delete(`${URL}/subscriptions/${stripe_id}`, {headers: authToken})
}

// Get all payments for Startup subscription
export const getStartupSubscriptions = (team_id) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));  
  return axios.get(`${URL}/team_payments/${team_id}`, { headers: authToken })
}



