import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;


/*********************************
 * Git create repo
 *********************************/
export const gitCreateRepo = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.post(`${URL}/create_reopository`, data, { headers: authToken });  
}

/*********************************
 * Git clone
 *********************************/
export const gitClone = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.post(`${URL}/git`, data, { headers: authToken });  
}

/*********************************
 * Git clone list
 *********************************/
export const gitClonesList = async (teamId) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  if (teamId) {
    return await axios.get(`${URL}/git?team_id=${teamId}`, { headers: authToken });
  } else {
    return await axios.get(`${URL}/git`, { headers: authToken });
  }
}

/*********************************
 * Git clone Delete
 *********************************/
export const gitCloneDelete = async (id, directoryName) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.delete(`${URL}/git/${id}?directory_name=${directoryName}`, { headers:authToken })
}

/*********************************
 * Git clone Pull
 *********************************/
export const gitClonePull = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.put(`${URL}/pull`, data, { headers: authToken });
}

/*********************************
 * Git get Branches
 *********************************/
export const getBranches = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.post(`${URL}/branches`, data, { headers: authToken });
}

/*********************************
 * Git clone Checkout
 *********************************/
export const gitCloneCheckout = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.post(`${URL}/checkout`, data, { headers: authToken });
}

/*********************************
 * Git Remote
 *********************************/
export const gitRemote = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.put(`${URL}/git_remote`, data, { headers: authToken });
}
