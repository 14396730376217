import React, { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

const HabitatListTests = ({
  funcId,
  loading,
  funcTest,
  getTests,
  handleSetTest,
  loadingMessage
}) => {

  useEffect(() => {
    getTests(funcId);
  }, []);

  return (
    <>
      {loading && (
        <div className="spinner-container funcs-habitat-spinner">
          <Spinner animation="border" variant="primary" />
          <p>{loadingMessage}</p>
        </div>
      )}

      <div className="pt-2 pb-2 mt-4">
        {funcTest && funcTest.length > 0 && (
          <div>
            <p>Save payloads</p>
            {funcTest.map(test => (
              <p 
                key={test.name}
                className="p-2 rounded border"
                onClick={e => handleSetTest(test)}
              >
                {test.name}
              </p>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default HabitatListTests;