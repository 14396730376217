import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Form, Button } from 'react-bootstrap';

const HabitatAuthList = (props) => {

    return (
        <div className="list-habitat-auth">
            <div className="title-habitat-costs title-auth">
                <p>Authentication Pools</p>
                <div className="button-auth">
                    <Button
                        variant="primary"
                        size='sm'
                        onClick={props.handleShowNewPool}
                    >+ New Pool</Button>
                    <Button
                        variant="outline-danger"
                        size='sm'
                        disabled={!props.selectedCognito}
                        onClick={props.handleShowRemove}
                    >Remove</Button>
                </div>
            </div>

            {props.cognitoList.length === 0 ? (
                <div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Users</th>
                                <th scope="col">Created At</th>
                            </tr>
                        </thead>
                    </table>
                    <div className="empty-section-auth">
                        <p>No user pool yet.</p>
                    </div>
                </div>
            ) : (
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Name</th>
                                <th scope="col">Users</th>
                                <th scope="col">Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.cognitoList.map(cognito => (
                                <tr key={cognito.id}>
                                    <td className="check-radio">
                                        <div onChange={props.handleSelectedRadio}>
                                            <fieldset>
                                                <Form.Check
                                                    type="radio"
                                                    aria-label="radio 1"
                                                    name="s3Bucket"
                                                    value={cognito.id}
                                                    id={cognito.id}
                                                />
                                            </fieldset>
                                        </div>
                                    </td>
                                    <td>{cognito.name}</td>
                                    <td className="show-users-auth">
                                        <div onClick={() => props.handleSelectPool(cognito.name, cognito.user_pool_id, props.habitat.id)}>
                                            <b>Show users</b>  <FontAwesomeIcon icon={faEye} />
                                        </div>

                                    </td>
                                    <td>{cognito.created_at}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                )}


            <div className="title-habitat-costs title-users-list">
                <p>Users List</p>
            </div>

            {props.userList.length === 0 ? (
                <div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Username</th>
                                <th scope="col">Email</th>
                                <th scope="col">Status</th>
                                <th scope="col">Management</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>

                    <div className="empty-section-auth">
                        <p>No users added yet or no user pool selected.</p>
                    </div>
                </div>
            ) : (

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Username</th>
                                <th scope="col">Email</th>
                                <th scope="col">Status</th>
                                <th scope="col">Management</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.userList.map(user => (
                                <tr key={user.id}>
                                    <td scope="col">{user.username}</td>
                                    <td scope="col">{user.attributes[2].value}</td>
                                    {user.enabled ? (
                                        <td scope="col" className="text-success-1"><FontAwesomeIcon icon={faCheckCircle} /> Enabled</td>
                                    ):(
                                        <td scope="col" className="text-danger"><FontAwesomeIcon icon={faTimesCircle} /> Disabled</td>
                                    )}
                                    <td scope="col" className="show-users-auth management-user" onClick={() => props.handleSelectUser(user)}><FontAwesomeIcon icon={faPencilAlt} /></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                )}
        </div>
    )
}

export default HabitatAuthList;