import produce from 'immer';
import {
  CLONE_PULL_BEGIN,
  CLONE_PULL_SUCCESS,
  CLONE_PULL_ERROR,
  CLONE_CHECKOUT_BEGIN,
  CLONE_CHECKOUT_SUCCESS,
  CLONE_CHECKOUT_ERROR,
  GET_BRANCHES_BEGIN,
  GET_BRANCHES_SUCCESS,
  GET_BRANCHES_ERROR,
  CLONE_GIT_BEGIN,
  CLONE_GIT_SUCCESS,
  CLONE_GIT_ERROR,
  GET_CLONE_GIT_BEGIN,
  GET_CLONE_GIT_SUCCESS,
  GET_CLONE_GIT_ERROR,
  DELETE_CLONE_GIT_BEGIN,
  DELETE_CLONE_GIT_SUCCESS,
  DELETE_CLONE_GIT_ERROR,
  NEW_TEMPLATE_BEGIN,
  NEW_TEMPLATE_SUCCESS,
  NEW_TEMPLATE_ERROR,
  FILE_OPEN_BEGIN,
  FILE_OPEN_SUCCESS,
  FILE_OPEN_ERROR,
  ZIP_FILE_BEGIN,
  ZIP_FILE_SUCCESS,
  ZIP_FILE_ERROR,
  ADD_FILETREE_BEGIN,
  ADD_FILETREE_SUCCESS,
  ADD_FILETREE_ERROR,
  DELETE_FILETREE_BEGIN,
  DELETE_FILETREE_SUCCESS,
  DELETE_FILETREE_ERROR
} from '../actions/types';
import { dataTransfer } from '../api/aws/sdk';

const initialState = {
  gitClones: [],
  branches: [],
  fileTree: {},
  openFile: {},
  zip_path: null,
  loading_file: false,
  loading: false,
  loading_message: '',  
  error: null,
}

function remove(array, key, value) {
  const index = array.findIndex(obj => obj[key] === value);
  return index >= 0 ? [
      ...array.slice(0, index),
      ...array.slice(index + 1)
  ] : array;
}

function update(array, key, value) {
  var foundIndex = array.findIndex(obj => obj[key] === value.id);
  array[foundIndex] = value;
  return array;
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case NEW_TEMPLATE_BEGIN:
    case ADD_FILETREE_BEGIN:
    case DELETE_FILETREE_BEGIN:
    case ZIP_FILE_BEGIN:
    case CLONE_GIT_BEGIN:
    case CLONE_PULL_BEGIN:
    case GET_BRANCHES_BEGIN:
    case GET_CLONE_GIT_BEGIN:
    case CLONE_CHECKOUT_BEGIN:
    case DELETE_CLONE_GIT_BEGIN:
      draft.loading_message = action.loading_message;
      draft.loading = true;
      draft.error  = null;
      return;
    case NEW_TEMPLATE_SUCCESS:
      draft.loading = false;
      draft.fileTree = action.payload;
      draft.error = null;
      return;
    case ADD_FILETREE_SUCCESS:
      draft.loading = false;
      draft.fileTree = {};
      draft.error = null;
      return;
    case DELETE_FILETREE_SUCCESS:
      draft.loading = false;
      draft.fileTree = remove(draft.fileTree, 'id', action.payload);
      draft.error = null;
      return;
    case FILE_OPEN_SUCCESS:
      draft.loading_file = false;
      draft.openFile = action.payload;
      draft.error = null;
      return;
    case ZIP_FILE_SUCCESS:
      draft.loading = false;
      draft.zip_path = action.payload;
      draft.error = null;     
      return;
    case FILE_OPEN_ERROR:
      draft.loading_file = false;
      draft.error = action.error;
      return;
    case FILE_OPEN_BEGIN:
      draft.loading_file = true;
      draft.error = null;
      return;
    case FILE_OPEN_SUCCESS:
      draft.loading_file = false;
      draft.openFile = action.payload;
      draft.error = null;
      return;  
    case CLONE_PULL_SUCCESS:
    case CLONE_CHECKOUT_SUCCESS: 
      draft.loading = false;            
      draft.gitClones = action.payload;
      draft.error = null;
      return;   
    case GET_CLONE_GIT_SUCCESS:
      draft.loading = false;                  
      draft.gitClones = action.payload;
      draft.error = null;
      return;
    case CLONE_GIT_SUCCESS:
      draft.loading = false;            
      draft.gitClones = action.payload.git_clone;
      draft.fileTree = action.payload.file_system.childrens;
      draft.error = null;
      return;
    case DELETE_CLONE_GIT_SUCCESS:
      draft.loading = false;      
      draft.gitClones = action.payload;
      draft.error = null;
      return;
    case GET_BRANCHES_SUCCESS:
      draft.loading = false;
      draft.branches = action.payload;
      draft.error = null;
      return;
    case ADD_FILETREE_ERROR:
    case DELETE_FILETREE_ERROR:
    case ZIP_FILE_ERROR:
    case GET_BRANCHES_ERROR:
    case GET_CLONE_GIT_ERROR:
    case CLONE_CHECKOUT_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return;
    case CLONE_GIT_ERROR:
      draft.loading = false;
      draft.error = action.errorCloneGit
      return;
    case NEW_TEMPLATE_ERROR:
      draft.loading = false;
      draft.error = action.errorGetTree
      return;
    case DELETE_CLONE_GIT_ERROR:
      draft.loading = false;
      draft.error = action.errorRemoveClone
      return;
    case CLONE_PULL_ERROR:
      draft.loading = false;
      draft.error = action.errorPullClone
      return;
    default:
      return;
  }
}, initialState);

export default reducer;