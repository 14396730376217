import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InfoTemplate = ({
  image,
  templateName
}) => {
  return (
    <div className="info-ctn">
      {image && templateName && (
        <div className="p-5">
          <div className="template-title d-flex justify-content-center mt-5 pb-3">
            <div className="img-ctn mr-3">
              <Image src={image} fluid />
            </div>
            <h3 className="font-weight-bold">{templateName}</h3>
          </div>
        </div>
      )}
      <div className="subtitle-ctn text-center">
        <p className="font-weight-bold">
          Launch powerful web apps without <br />
          writing code.
        </p>
      </div>
      <div className="pl-5 pr-5 pb-5">
        <Row className="template-title pb-5 mb-2">
          <Col xs={12} md={1} className="mt-4 pt-2">
            <FontAwesomeIcon
              icon={faCheck}
            />
          </Col>
          <Col xs={12} md={11} className="mt-4">
            <p className="font-weight-bold">
              Build customer-facing web platforms and internal tools in hours instead of months. 
            </p>
          </Col>
          <Col xs={12} md={1} className="mt-4 pt-2">
            <FontAwesomeIcon
              icon={faCheck}
            />
          </Col>
          <Col xs={12} md={11} className="mt-4">
            <p className="font-weight-bold">
              Empower anyone on your team to make changes, not just developers.
            </p>
          </Col>
          <Col xs={12} md={1} className="mt-4 pt-2">
            <FontAwesomeIcon
              icon={faCheck}
            />
          </Col>
          <Col xs={12} md={11} className="mt-4">
            <p className="font-weight-bold">
              Gain traction and grow rapidly. Go from MVP to a scaling application.
            </p>
          </Col>
          <Col xs={12} md={1} className="mt-4 pt-2">
            <FontAwesomeIcon
              icon={faCheck}
            />
          </Col>
          <Col xs={12} md={11} className="mt-4">
            <p className="font-weight-bold">
              Demonstrate your idea before making an investment in technical resources.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default InfoTemplate;