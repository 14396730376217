import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Alert, Col, Row, Form, Popover, OverlayTrigger } from 'react-bootstrap';
import '../../assets/styles/components/AwsCredManage.scss';
import { getClones } from '../../actions/git-clone.actions';
import { newFunction, editFunction, listFunctions, deleteFunction } from '../../actions/funcs.actions';
import { nextStepTour } from '../../actions/app-tour.actions';
import { withRouter } from 'react-router-dom';
import routes from '../../constants/routes';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileCode,
  faInfoCircle,
  faCheckCircle,
  faExclamationCircle,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import '../../assets/styles/components/habitats/HabitatFuncs.scss'
import { runtimeList } from '../../models/runtime';


const HabitatFunc = withRouter(({
  onboardingClassFuncs,
  loadingFunction,
  loadingMessage,
  deleteFunction,
  listFunctions,
  statusAppTour,
  editFunction,
  nextStepTour,
  newFunction,
  currentUser,
  stepAppTour,
  getClones,
  gitClones,
  s3Buckets,
  awsRoles,
  stopTour,
  awsCreds,
  history,
  habitat,
  loading,
  funcs,
  team,
  error
}) => {

  const [show, setShow] = useState(false);
  const [role, setRole] = useState('');
  const [handler, setHandler] = useState('');
  const [runtime, setRuntime] = useState('');
  const [gitClone, setGitClone] = useState('');
  const [showRemove, setShowRemove] = useState(false);
  const [sendDeploy, setSendDeploy] = useState('/HabitatDeploymentsPage')
  const [showNewFunction, setshowNewFunction] = useState('new-function-setup')

  useEffect(() => {
    getClones(team)
    listFunctions(habitat.id);
  }, [getClones]);

  const handleNewFunc = () => {
    const clone = gitClones.find(clone => clone.id === gitClone);
    newFunction(
      team,
      role,
      runtime,
      handler,
      clone.git_hash,
      awsCreds,
      s3Buckets,
      habitat.id,
      clone.project_name
    );
    setShow(false);
    setHandler('');
    setRuntime('');
    if (stepAppTour === 11) {
      nextStepTour();
      history.push(`${routes.RELEASES}/:habitatId`)
    }
  };

  const handleRemove = (func) => {
    deleteFunction(func.id, habitat.id);
  }

  const handleShowNew = () => {
    setshowNewFunction('new-function-show')
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Memory Limits</Popover.Title>
      <Popover.Content>
        <li>The minimum and default memory value is 128 MB.</li>
        <li>The maximum allowed value 10,240 MB.</li>
      </Popover.Content>
    </Popover>
  );

  const MemoryLimitTooltip = () => (
    <OverlayTrigger trigger={['hover', 'focus']} placement="left" overlay={popover}>
      <p className="restrictionsText">
        Memory (MB) &nbsp; <FontAwesomeIcon className="restrictInfo" icon={faInfoCircle} />
      </p>
    </OverlayTrigger>
  );

  return (
    <div className={onboardingClassFuncs}>
      {team.id
        ? ((team.subscription === 'Project' && funcs.length >= 3)) && (
          <div className="m-4">
            <Alert variant="warning">
              Upgrade your plan to add more Functions
          </Alert>
          </div>
        )
        : (currentUser.subscription === 'Basic' &&
          funcs.length >= 3) && (
          <div className="m-4">
            <Alert variant="warning">
              Upgrade your plan to add more Functions
          </Alert>
          </div>
        )}
      <div className="habitat-funcs-management-container">
        <div className="title-setup-w-new">
          <div className="title-setup-management-funcs">
            <div className="box-feature-name-icon-funcs"><FontAwesomeIcon icon={faFileCode} /></div>
            <h5>Functions</h5>
          </div>
          {team.id ?
            (((team.subscription === 'Project' && funcs.length <= 2) ||
              (team.subscription !== 'Project')) && (
                <div className="new-function-btn-setup" onClick={handleShowNew}>
                  <p> + New Function</p>
                </div>
              )) : (((currentUser.subscription === 'Basic' && funcs.length <= 2) ||
                currentUser.subscription !== 'Basic')) && (
              <div className="new-function-btn-setup" onClick={handleShowNew}>
                <p> + New Function</p>
              </div>
            )}
        </div>

        {error && (
          <div className="error-display text-danger">
            <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
          </div>
        )}

        {loadingFunction && (
          <div className="spinner-container funcs-habitat-spinner">
            <Spinner animation="border" variant="primary" />
            <p>{loadingMessage}</p>
          </div>
        )}



        <div className="setup-container-funcs">
          {funcs && funcs.length > 0 && (
            funcs.map(func => (
              <div key={func.id}>
                <div className="funcs-setup-box" key={func.id}>

                  <div className="box-feature-name-funcs">
                    <div className="box-feature-name-icon"><FontAwesomeIcon icon={faFileCode} /></div>
                    <p>{func.function_name}</p>
                  </div>

                  <div className="text-feature-items">
                    <div className="box-feature-item">
                      <p><span><FontAwesomeIcon className="greenLight" icon={faCheckCircle} /></span> Active</p>
                    </div>

                    <div className="box-feature-item">
                      <p><b>Run Time: </b> {func.runtime}</p>
                    </div>                

                    <div className="box-feature-item">
                      <Button variant="outline-danger" size="sm" onClick={() => handleRemove(func)}>Remove</Button>
                    </div>
                  </div>
                  
                </div>   
                <div className="func_details">
                  <div className="func_edit_element">
                    <Row>
                      <Col sm={4} className="func-form-label">
                        <MemoryLimitTooltip />
                      </Col>
                      <Col>
                        <Form.Control
                          type="number"
                          aria-label="memory"
                          aria-describedby="memory"
                          value={handler}                      
                          onChange={e => setHandler(e.target.value)}
                          placeholder={func.memory}
                        />  
                      </Col>
                    </Row>                  
                  </div>
                </div>    
              </div>       
            ))
          )}
        </div>

        <div className={showNewFunction + ' new-function-container'}>
          <p>New Function</p>

          <div className="inputs-new-aws-credential">
            <Form.Control
              as="select"
              defaultValue={'DEFAULT'}
              onChange={e => setGitClone(e.target.value)}
            >
              <option disabled value="DEFAULT">--Select a Repository--</option>
              {gitClones.map(clone => (
                team.name ? (
                  clone.team_id && (
                    <option key={clone.id} value={clone.id}>{clone.project_name}</option>
                  )
                ) : (
                    !clone.team_id && (
                      <option key={clone.id} value={clone.id}>{clone.project_name}</option>
                    )
                  )
              ))}
            </Form.Control>
            <Form.Control
              as="select"
              defaultValue={'DEFAULT'}
              onChange={e => setRole(e.target.value)}
            >
              <option disabled value="DEFAULT">--Select a Role--</option>
              {awsRoles.map(role => (
                <option key={role.id} value={role.arn}>{role.role_name}</option>
              ))}
            </Form.Control>
            <Form.Control
              type="text"
              aria-label="handler"
              aria-describedby="handler"
              value={handler}
              onChange={e => setHandler(e.target.value)}
              placeholder="Handler"
            />
            <Form.Control as="select" defaultValue={'DEFAULT'} onChange={e => setRuntime(e.target.value)}>
              <option disabled value="DEFAULT">--Select a Runtime--</option>
              {runtimeList().map((run) => (
                <option key={run.Identifier} value={run.Identifier}>{run.Name}</option>
              ))}
            </Form.Control>
          </div>

          <div className="button-container-aws">
            <Button variant="primary" size="sm" className="newCredBtn-1" disabled={!role || !gitClone || !runtime || !handler} onClick={() => handleNewFunc()}>
              + Add Function
            </Button>
          </div>
          <span className="label-info">*A S3 Bucket will be created</span>
        </div>


      </div>

    </ div>
  );

})

const mapState = (state, props) => ({
  team: state.team.team,
  funcs: state.func.funcs,
  currentUser: state.user.user,
  loading: state.gitClone.loading,
  s3Buckets: state.awsS3.s3Buckets,
  awsRoles: state.awsRoles.awsRoles,
  gitClones: state.gitClone.gitClones,
  loadingFunction: state.func.loading,
  loadingMessage: state.func.loading_message,
  stepAppTour: state.appTour.stepAppTour,
  awsCreds: state.awsCreds.awsCredentials,
  statusAppTour: state.appTour.stateAppTour,
  onboardingClassFuncs: state.appTour.onboardingClassFuncs,
  error: state.func.error
})

const mapDispatch = {
  deleteFunction,
  listFunctions,
  editFunction,
  newFunction,
  getClones,
  nextStepTour
}

export default connect(mapState, mapDispatch)(HabitatFunc);