import React from 'react';
import HabitatBuildHistoryItem from './HabitatBuildHistoryItem';
import '../../assets/styles/components/habitats/HabitatCICD.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';

const HabitatBuildHistory = ({
  builds,
  loading,
  habitat,
  buildsExecut,
  listBuildLogs,
  listExecutions,
  listBuildStatus,
  deleteBuildCICD,
  buildLogs,
  errorBuildHistory,
  loadingMessage
}) => {
  return (
    <div className="container-builds-hi">
      <p className="title-build">Build History</p>

      {loading && (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
          <p>{loadingMessage}</p>
        </div>
      )}


      {errorBuildHistory && (
        <div className="error-display text-danger">
          <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {errorBuildHistory}</p>
        </div>
      )}


      {builds.length > 0 ? (
        <div className="build-history-items-container">
          {builds.map(build => (
            <HabitatBuildHistoryItem
              build={build}
              key={build.id}
              habitat={habitat}
              loading={loading}
              buildsExecut={buildsExecut}
              listBuildLogs={listBuildLogs}
              listExecutions={listExecutions}
              listBuildStatus={listBuildStatus}
              deleteBuildCICD={deleteBuildCICD}
              buildLogs={buildLogs}
            />
          ))}
        </div>
      ) : (
          <div className="empty-div">No builds yet.</div>
        )}

    </div>
  );
};

export default HabitatBuildHistory;
