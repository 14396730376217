import {
  GET_STATUS_BEGIN,
  GET_STATUS_SUCCESS,
  GET_STATUS_ERROR,
} from './types';
import { getStatus } from '../api/serverStatus';

export const getServer = () => {
  return (dispatch) => {
    dispatch({ type: GET_STATUS_BEGIN });
    getStatus()
      .then((response) => {
        dispatch({
          type: GET_STATUS_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => dispatch({ type: GET_STATUS_ERROR, error }));
  };
};
