
import React from 'react';

const rootStyle = {
    height: '100%'
}

const PublicLayout = ({ children }) => (                       
    <div style={rootStyle}>    
        {children}    
    </div>
);

export default PublicLayout;