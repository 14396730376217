import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from '../avatar/Avatar';
import { connect } from 'react-redux';
import routes from '../../constants/routes';
import { logout } from '../../actions/user.actions';
import { changeClass,  changeInitial } from '../../actions/navbar.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button'
import {
  faServer,
  faCog,
  faSignOutAlt,
  faLayerGroup,
  faBars,
  faSearch
} from '@fortawesome/free-solid-svg-icons';
import '../../assets/styles/components/navbar.scss';
import logo from './Kor-logo.svg';
import bell from '../../assets/images/bell-icon.svg';
import power from '../../assets/images/powerbutton1.svg'
import settings from '../../assets/images/settings-icon.svg';
import lifebuoy from '../../assets/images/lifebuoy-icon.svg';
import barsIcon from '../../assets/images/burger-icon.svg';
import { changeSelectedTeam } from '../../actions/team.actions';

const Navbar = ({
  currentUser,
  logout,
  changeClass,
  active,
  team,
  pageTitle,
  teams,
  changeSelectedTeam,
  changeInitial
}) => {
  const [labelNav, setLabelNav] = useState('hidden-label');
  const [sideNavSize, setNavSizeNav] = useState('sidenav');
  const [barsMenu, setBarsMenu] = useState('bars-menu');
  const [addClass, setAddClass] = useState(false);
  const teamEmpty = null;

  useEffect(() => {
  }, []);

  const hanldeRestoreNav = () => {
    changeInitial();
    changeClass(active);
    setAddClass(!addClass);
  }

  const handleSelectTeam = (teamId) => {
    changeSelectedTeam(teamId)
  }

  return (
    <div className="nav-header-container">
      <div className="header-kor-logo">
        <img src={barsIcon} className="bars-icon-navbar" onClick={hanldeRestoreNav}/>
        <div className="line"></div>
        <img src={logo} className="app-logo" alt="logo" />
        <div className="spacer"></div>
        <div className="select-teams">
          <p>Team</p>
          {team.name ? (
            <Form.Control
              className="metric-selectbox"
              as="select"
              defaultValue={'DEFAULT'}
              onChange={e => handleSelectTeam(e.target.value)}
            >
              <option value="DEFAULT" disabled>{team.name}</option>
              <option value={team.id}>Personal</option>
              {teams.map(team => (
                <option value={team.id} key={team.id}>{team.name}</option>
              ))}
            </Form.Control>
          ) : (
            <Form.Control
              className="metric-selectbox"
              as="select"
              defaultValue={'DEFAULT'}
              onChange={e => handleSelectTeam(e.target.value)}
            >
              <option value={team.id}>Personal</option>
              {teams.map(team => (
                <option value={team.id} key={team.id}>{team.name}</option>
              ))}
            </Form.Control>
          )}

        </div>
      </div>

      <div className="search-input-kor">
        <FontAwesomeIcon icon={faSearch} />
        <Form.Control
          aria-label="Name"
          aria-describedby="new-name"
          className="search-navbar"
          autoComplete="off"
          // onChange={e => props.setNameCert(e.target.value)}
          type="text"
          name="credsName"
          placeholder="Search"
        />
      </div>

      <div className="avatar-kor-settings">
        <div className="avatar-name">
          <div className="photo-avatar">
            <Avatar userName={currentUser.name} avatarClass="avatarPicTop" />
          </div>
          <p>{currentUser.name}</p>
        </div>

        <div className="icons-settings">
          <div>
          <NavLink exact to='/Account'>
            <img src={settings} alt="settings" />
            </NavLink>
          </div>
          <div>
          <NavLink exact to='/Help'>
            <img src={lifebuoy} alt="help" />
          </NavLink>
          </div>
          <div className="logout-img">
            <img src={power} alt="notifications" onClick={() => logout()} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state, props) => ({
  currentUser: state.user.user,
  active: state.navbar.active,
  team: state.team.team,
  pageTitle: props.pageTitle,
  teams: state.team.teams
});

const mapDispatch = { 
  logout, 
  changeClass, 
  changeSelectedTeam, 
  changeInitial 
};
export default connect(mapState, mapDispatch)(Navbar);
