import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../constants/routes';
import '../../assets/styles/components/habitats/HabitatTopMenu.scss';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
  faCog,
  faTachometerAlt,
  faRocket,
  faChartBar,
  faProjectDiagram,
  faTerminal,
  faInfinity,
  faDollarSign,
  faSitemap,
  faUserFriends,
  faDatabase
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HabitatTopMenu = (props) => {
  const handleNextStep = () => {
    props.changeOnboardingClassHabitat('onboardingClassHabitat');
    props.changeOnboardingClassAWS('onboardingClassAWS');
    props.nextStepTour();
  }

  return (
    <>
      <div className="sub-menu-side-r ctn-large">
        <NavLink className="habitat-top-menu" activeClassName='is-active' to={`${routes['HABITAT MONITOR']}/${props.habitat.id}`}>
          <FontAwesomeIcon className="top-menu-icons" icon={faChartBar} />
          <span className="top-menu-title">Monitor</span>
        </NavLink>
        <NavLink className="habitat-top-menu" activeClassName='is-active' to={`${routes.HABITATCOSTS}/${props.habitat.id}`}>
          <FontAwesomeIcon className="top-menu-icons" icon={faDollarSign} />
          <span className="top-menu-title">Costs</span>
        </NavLink>
        <NavLink className="habitat-top-menu" activeClassName='is-active' to={`${routes.HABITATAUTH}/${props.habitat.id}`}>
          <FontAwesomeIcon className="top-menu-icons" icon={faUserFriends} />
          <span className="top-menu-title">Authentication</span>
        </NavLink>
        <NavLink className="habitat-top-menu" activeClassName='is-active' to={`${routes.RELEASES}/${props.habitat.id}`}>
          <FontAwesomeIcon className="top-menu-icons" icon={faRocket} />
          <span className="top-menu-title">Releases</span>
        </NavLink>
        <NavLink className="habitat-top-menu" activeClassName='is-active' to={`${routes.HABITATDB}/${props.habitat.id}`}>
          <FontAwesomeIcon className="top-menu-icons" icon={faDatabase} />
          <span className="top-menu-title">DB</span>
        </NavLink>
        <NavLink className="habitat-top-menu" activeClassName='is-active' to={`${routes['HABITATCICD']}/${props.habitat.id}`}>
          <FontAwesomeIcon className="top-menu-icons" icon={faInfinity} />
          <span className="top-menu-title">CI/CD</span>
        </NavLink>
        <NavLink className="habitat-top-menu" activeClassName='is-active' to={`${routes['HABITAT STEP FUNCTIONS']}/${props.habitat.id}`}>

          <FontAwesomeIcon className="top-menu-icons" icon={faProjectDiagram} />
          <span className="top-menu-title">Step Functions</span>
        </NavLink>
        <NavLink className="habitat-top-menu" activeClassName='is-active' to={`${routes['HABITAT LOGS']}/${props.habitat.id}`}>
          <FontAwesomeIcon className="top-menu-icons" icon={faTerminal} />
          <span className="top-menu-title">Logs</span>
        </NavLink>
        <NavLink className="habitat-top-menu" activeClassName='is-active' to={`${routes['HABITAT API GATEWAY']}/${props.habitat.id}`}>
          <FontAwesomeIcon className="top-menu-icons" icon={faSitemap} />
          <span className="top-menu-title">API Gateway</span>
        </NavLink>


        {props.stepAppTour === 5 ? (
          <NavLink className="habitat-top-menu onboardingClass" activeClassName='is-active' to={`${routes['HABITAT SETTINGS']}/${props.habitat.id}`} onClick={handleNextStep}>
            <FontAwesomeIcon className="top-menu-icons" icon={faCog} />
            <span className="top-menu-title">Settings</span>
          </NavLink>
        ) : (
            <NavLink className="habitat-top-menu" activeClassName='is-active' to={`${routes['HABITAT SETTINGS']}/${props.habitat.id}`}>
              <FontAwesomeIcon className="top-menu-icons" icon={faCog} />
              <span className="top-menu-title">Settings</span>
            </NavLink>
          )}
      </div>

      <div className="sub-menu-side-r ctn-small">
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <strong>Monitor</strong>.
            </Tooltip>
          }
        >
          <NavLink className="habitat-top-menu mr-3" activeClassName='is-active' to={`${routes['HABITAT MONITOR']}/${props.habitat.id}`}>
            <FontAwesomeIcon icon={faChartBar} />
          </NavLink>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <strong>Costs</strong>.
            </Tooltip>
          }
        >
          <NavLink className="habitat-top-menu mr-3" activeClassName='is-active' to={`${routes.HABITATCOSTS}/${props.habitat.id}`}>
            <FontAwesomeIcon icon={faDollarSign} />
          </NavLink>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <strong>Authentication</strong>.
            </Tooltip>
          }
        >
          <NavLink className="habitat-top-menu mr-3" activeClassName='is-active' to={`${routes.HABITATAUTH}/${props.habitat.id}`}>
            <FontAwesomeIcon icon={faUserFriends} />
          </NavLink>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <strong>Releases</strong>.
            </Tooltip>
          }
        >
          <NavLink className="habitat-top-menu mr-3" activeClassName='is-active' to={`${routes.RELEASES}/${props.habitat.id}`}>
            <FontAwesomeIcon icon={faRocket} />
          </NavLink>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <strong>DB</strong>.
            </Tooltip>
          }
        >
          <NavLink className="habitat-top-menu mr-3" activeClassName='is-active' to={`${routes.HABITATDB}/${props.habitat.id}`}>
            <FontAwesomeIcon icon={faDatabase} />
          </NavLink>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <strong>CI/CD</strong>.
            </Tooltip>
          }
        >
          <NavLink className="habitat-top-menu mr-3" activeClassName='is-active' to={`${routes['HABITATCICD']}/${props.habitat.id}`}>
            <FontAwesomeIcon icon={faInfinity} />
          </NavLink>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <strong>Step Functions</strong>.
            </Tooltip>
          }
        >
          <NavLink className="habitat-top-menu mr-3" activeClassName='is-active' to={`${routes['HABITAT STEP FUNCTIONS']}/${props.habitat.id}`}>

            <FontAwesomeIcon icon={faProjectDiagram} />
          </NavLink>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <strong>Logs</strong>.
            </Tooltip>
          }
        >
          <NavLink className="habitat-top-menu mr-3" activeClassName='is-active' to={`${routes['HABITAT LOGS']}/${props.habitat.id}`}>
            <FontAwesomeIcon icon={faTerminal} />
          </NavLink>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <strong>API Gateway</strong>.
            </Tooltip>
          }
        >
          <NavLink className="habitat-top-menu mr-3" activeClassName='is-active' to={`${routes['HABITAT API GATEWAY']}/${props.habitat.id}`}>
            <FontAwesomeIcon icon={faSitemap} />
          </NavLink>
        </OverlayTrigger>


        {props.stepAppTour === 5 ? (
          <OverlayTrigger
            ment="bottom"
            overlay={
              <Tooltip>
                <strong>Settings</strong>.
              </Tooltip>
            }
          >
            <NavLink className="habitat-top-menu mr-3 onboardingClass" activeClassName='is-active' to={`${routes['HABITAT SETTINGS']}/${props.habitat.id}`} onClick={handleNextStep}>
              <FontAwesomeIcon icon={faCog} />
            </NavLink>
          </OverlayTrigger>
        ) : (
            <OverlayTrigger
              ment="bottom"
              overlay={
                <Tooltip>
                  <strong>Settings</strong>.
                </Tooltip>
              }
            >
              <NavLink className="habitat-top-menu mr-3" activeClassName='is-active' to={`${routes['HABITAT SETTINGS']}/${props.habitat.id}`}>
                <FontAwesomeIcon icon={faCog} />
              </NavLink>
            </OverlayTrigger>
          )}
      </div>
    </>
  );
}

const mapState = (state, props) => ({
})

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(HabitatTopMenu);
