import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Magnet from '../assets/images/magnet.svg';
import CloneItem from '../components/git/CloneItem'; 
import { Row, Col, Form, Spinner } from 'react-bootstrap';
import '../assets/styles/containers/RepositoriesPage.scss';
import { listGitCreds } from '../actions/git-creds.actions';
import GitCloneModal from '../components/Modals/GitCloneModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import { 
  cloneGit,  
  remoteGit, 
  pullClone,
  getClones,
  removeClone,
  cloneBranches, 
  cloneCheckout, 
} from '../actions/git-clone.actions';

const RepositoriesPage = ({
  team,
  error,
  loading,
  branches,
  cloneGit,  
  gitCreds,
  remoteGit, 
  pullClone,
  gitClones,
  getClones,
  removeClone,
  listGitCreds,
  cloneBranches, 
  cloneCheckout,
  loadingMessage,
}) => {

  const [ show, setShow ]         = useState(false);
  const [ repoUrl, setRepoUrl ]   = useState('');
  const [ gitCred, setGitCred ]   = useState('');
  const [ repoName, setRepoName ] = useState('');

  useEffect(() => {
    listGitCreds();
    getClones(team);
  }, [team]);

  const handleClone = () => {
    cloneGit(repoUrl, repoName, gitCred, team.id);
    setShow(false);
    setRepoUrl('');
    setRepoName('');
    setGitCred('');
  }
  
  return(
    <>
      <div className="init-ctn">
        <Row>
          <Col md={6}>
            <h2><b>Repositories</b></h2>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <button className="btn kor-btn btn-lg btn-new-repository" onClick={() => setShow(!show)}>
              <img src={Magnet} className="mr-2" />
              <b>CLONE REPOSITORY</b>
            </button>
          </Col>
        </Row>
      </div>
      {loading && (
        <div className="spinner-container repository-spinner ml-5">
          <Spinner animation="border" variant="primary" />
          <p>{loadingMessage}</p>
        </div>
      )}
      {error && (
        <div className="error-display text-danger ml-5">
          <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
        </div>
      )}

      {gitClones.length < 1 ? (
        <div className="center">
          <h3 className="text-uppercase font-weight-bold mb-4">
            Add a repository <br/> to get started
          </h3>
          <button className="btn kor-btn btn-lg" onClick={() => setShow(!show)}>
            <img src={Magnet} className="mr-2" />
            CLONE REPOSITORY
          </button>
        </div>
      ) : (
        gitClones.map(clone => (
          <CloneItem 
            clone={clone}
            key={clone.id}
            branches={branches}
            pullClone={pullClone}
            removeClone={removeClone}
            cloneCheckout={cloneCheckout}
            cloneBranches={cloneBranches}
          />
        ))
      )}

      <GitCloneModal 
        show={show}
        setShow={setShow}
        repoUrl={repoUrl}
        repoName={repoName}
        gitCreds={gitCreds}
        setGitCred={setGitCred}
        setRepoUrl={setRepoUrl}
        setRepoName={setRepoName}
        handleClone={handleClone}
      />
    </>
  );
}

const mapState = (state) => ({
  team: state.team.team,
  error: state.gitClone.error,
  loading: state.gitClone.loading,
  branches: state.gitClone.branches,
  gitClones: state.gitClone.gitClones,
  loadingMessage: state.gitClone.loading_message,
  gitCreds: state.gitCreds.gitCredentials.git_credentials
});

const mapDispatch = {
  cloneGit,  
  remoteGit, 
  pullClone,
  getClones,
  removeClone,
  listGitCreds,
  cloneBranches, 
  cloneCheckout,
}

export default connect(
  mapState,
  mapDispatch
)(RepositoriesPage);