import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { faExclamationCircle, faDollarSign, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const HabitatCostsActivation = (props) => {
    return (
        <div className="cost-activation-container">
            <div className="title-cost-activation">
                <div className="icon-costs-activation">
                    <FontAwesomeIcon icon={faDollarSign} />
                </div>
                <p>Cost Explorer for KOR</p>
            </div>

            <div className="description-activation">
                <p>First, you have to enable your Cost Explorer in AWS.</p>
                <a
                  href="https://docs.aws.amazon.com/awsaccountbilling/latest/aboutv2/ce-enable.html"
                  rel="noopener noreferrer"
                  className="help"
                  target="_blank"
                >
                <p>Click here to know how</p>
                </a>
            </div>

            <Button
                variant="primary"
                size="sm"
                className="btn-search-deployed"
                onClick={() => props.handleCostActivation()}
            >
              Activate for KOR  
            </Button>

          
            <div className="question-mark-documentation-habitat">
            <p>Why I should enable costs for my habitat?</p>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip className="red-tooltip">
                    <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                  </Tooltip>
                }

              >
                <a
                  href="https://docs.aws.amazon.com/awsaccountbilling/latest/aboutv2/ce-enable.html"
                  rel="noopener noreferrer"
                  className="help"
                  target="_blank"
                >

                  <FontAwesomeIcon icon={faQuestionCircle} />
                </a>
              </OverlayTrigger>
            </div>
        </div>

    )
}

export default HabitatCostsActivation;