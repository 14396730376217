/****************************************************************************************
 * Deployment Model
 *
 * Description:
 *
 * Deployments are objects that keep track of a deplyoment action to a serverless provider.
 * These objects store relevant information such as date, log groups, log streams, etc.
 *
 * Schema:
 *
 * deployment = {
 *  team_id: team_id,           string
 *  status: 'successful'        string
 *  habitat_id: "gjbhbk86969",  string
 *  functions: [                array
 *    "9868969868990": {        obj
 *      runtime: "python3.8"    string
 *    }
 *  ]
 * }
 *
 * ************************************************************************************/

export const deploymentModel = (habitat_id, team_id = null) => {
  return (
    {      
      habitat_id: habitat_id,
      team_id: team_id,
      status: '',
      functions: []
    }
  )
}
