import {
    NEXTSTEP_BEGIN,
    NEXTSTEP_SUCCESS,
    STOP_TOUR_BEGIN,
    STOP_TOUR_SUCCESS,
    CHANGE_CLASS_HABITAT_BEGIN,
    CHANGE_CLASS_HABITAT_SUCCESS,
    CHANGE_CLASS_ROLES_BEGIN,
    CHANGE_CLASS_ROLES_SUCCESS,
    CHANGE_CLASS_AWS_BEGIN,
    CHANGE_CLASS_AWS_SUCCESS,
    CHANGE_CLASS_FUNCTIONS_BEGIN,
    CHANGE_CLASS_FUNCTIONS_SUCCESS,
    SELECT_TYPE_APP_TOUR_START,
    SELECT_TYPE_APP_TOUR_SUCCESS,
    SELECT_TYPE_APP_TOUR_ERROR,
  } from './types';
  
  export const selectType = (type) => {
    return (dispatch) => {
      dispatch({
        type: SELECT_TYPE_APP_TOUR_START,
      });
      dispatch({
        type: SELECT_TYPE_APP_TOUR_SUCCESS,
        payload: type,
      });
    };
  };
  
  export const nextStepTour = () => {
    return (dispatch) => {
      dispatch({ type: NEXTSTEP_BEGIN });
      dispatch({
        type: NEXTSTEP_SUCCESS,
        payload: 1,
      });
    };
  };
  
  export const stopTour = () => {
    return (dispatch) => {
      dispatch({ type: STOP_TOUR_BEGIN });
      dispatch({
        type: STOP_TOUR_SUCCESS,
        payload: 0,
      });
    };
  };
  
  export const changeOnboardingClassHabitat = (data) => {
    return (dispatch) => {
      dispatch({ type: CHANGE_CLASS_HABITAT_BEGIN });
      dispatch({
        type: CHANGE_CLASS_HABITAT_SUCCESS,
        payload: data,
      });
    };
  };
  
  export const changeOnboardingClassRoles = (data) => {
    return (dispatch) => {
      dispatch({ type: CHANGE_CLASS_ROLES_BEGIN });
      dispatch({
        type: CHANGE_CLASS_ROLES_SUCCESS,
        payload: data,
      });
    };
  };
  
  export const changeOnboardingClassAWS = (data) => {
    return (dispatch) => {
      dispatch({ type: CHANGE_CLASS_AWS_BEGIN });
      dispatch({
        type: CHANGE_CLASS_AWS_SUCCESS,
        payload: data,
      });
    };
  };
  
  export const changeOnboardingClassFunctions = (data) => {
    return (dispatch) => {
      dispatch({ type: CHANGE_CLASS_FUNCTIONS_BEGIN });
      dispatch({
        type: CHANGE_CLASS_FUNCTIONS_SUCCESS,
        payload: data,
      });
    };
  };
  