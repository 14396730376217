import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

// Create template
export const createTemplate = (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/templates`, data, { headers: authToken });  
}

// Get template
export const getTemplates = (habitat_id) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/templates/${habitat_id}`, { headers: authToken });  
}

// Delete template
export const deleteTemplates = (id) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.delete(`${URL}/templates/${id}`, { headers: authToken });  
}

// Update git hash
export const update_hash = (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/aws/git_hash_update`, data, { headers: authToken });  
}

// Rollback template
export const rollBack = (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  if (data.rol_id && !data.git_site && !data.git_lambda) {
    return axios.delete(`${URL}/template_rollback?habitat_id=${data.habitat_id}&rol_id=${data.rol_id}`, { headers: authToken });  
  } else if (!data.rol_id && data.git_site && !data.git_lambda) {
    return axios.delete(`${URL}/template_rollback?habitat_id=${data.habitat_id}&git_site=${data.git_site}`, { headers: authToken });  
  } else if (data.rol_id && data.git_site && !data.git_lambda) {
    return axios.delete(`${URL}/template_rollback?habitat_id=${data.habitat_id}&rol_id=${data.rol_id}&git_site=${data.git_site}`, { headers: authToken });  
  } else if (data.git_site && data.git_lambda) {
    return axios.delete(`${URL}/template_rollback?habitat_id=${data.habitat_id}&rol_id=${data.rol_id}&git_site=${data.git_site}&git_lambda=${data.git_lambda}`, { headers: authToken });  
  } else {
    return axios.delete(`${URL}/template_rollback?habitat_id=${data.habitat_id}`, { headers: authToken });  
  }
}

// Disable or enable cloudfront
export const manageCloudfront = (habitat_id, site_id, distribution_status) => {
  const data = {
    only_disable: true,
    enable: distribution_status,
    habitat_id: habitat_id,
    default_ttl: '86400'
  }
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/aws/update_distribution/${site_id}`, data , { headers: authToken }); 
}

// Get Index Invalidations
export const getIndexInvalidations = (habitat_id, site_id) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/aws/index_invalidations/${site_id}?habitat_id=${habitat_id}`, { headers: authToken });  
}

// Show Invalidations
export const getInvalidations = (habitat_id, invalidation, site_id) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/aws/show_invalidation/${site_id}?habitat_id=${habitat_id}&invalidation=${invalidation}`, { headers: authToken });  
}

export const distributionValidation = (habitat_id, site_id, invalidation) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  const data = {
    'habitat_id': habitat_id,
    'invalidation': invalidation
  }
  return axios.post(`${URL}/aws/distribution_invalidations/${site_id}`, data , { headers: authToken });  
}
