import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const AwsCredTemplate = ({
  team,
  gitCred,
  loading,
  gitCreds,
  awsCreds,
  setCount,
  showCreds,
  setGitCred,
  requestKey,
  AWSRegions,
  showLoading,
  setShowCreds,
  templateName,
  awsCredsUser,
  validateCreds,
  errorTemplates,
  HandleHabitadWithURL,
  handleHabitatAndCreds
}) => {
  return (
    <>
      <Row className="pl-5 pr-5 pt-5">
        <Col xs={12}>
          <div className="container">
            <span className="text-muted">
              Please connect to AWS. Enter and verify the AWS credentials that you will be using for your web application.
            </span>
          </div>
        </Col>
        <Col xs={12}>
          {awsCreds ? (
            <h5 className="font-weight-bold mt-5 mb-3">AWS Credentials</h5>
          ):(
            templateName !== 'STRAPI' && (
              <h5 className="font-weight-bold mt-5 mb-3">Git Credentials</h5>
            )
          )}
          
        </Col>
      </Row>
      <Row>
        {!showLoading ? (
          <>
          {templateName !== 'STRAPI' && (
            <Col xs={12}>
              <div className="container d-flex pl-5 pr-5">
                <Form.Control
                  as="select"
                  defaultValue={'DEFAULT'}
                  className="select-cred-habitat"
                  onChange={e => setGitCred(e.target.value)}
                >
                    <option value="DEFAULT">Select Credential</option>
                    {gitCreds.map(cred => (
                        <option key={cred.id} value={cred.id}>{cred.name}</option>
                    ))}
                </Form.Control>
              </div>
            </Col>
          )}
          <Col xs={12}>
            <h5 className="font-weight-bold mt-5 mb-3 ml-5">AWS Credentials</h5>
          </Col>
          <Col xs={12}>
            <div className="container d-flex pl-5 pr-5">
              <Form.Control
                  as="select"
                  defaultValue={'DEFAULT'}
                  className="select-cred-habitat"
                  disabled={!gitCred && templateName !== 'STRAPI'}
                  onChange={e => handleHabitatAndCreds(e.target.value)}
              >
                  <option value="DEFAULT">Select Credential</option>
                  {awsCredsUser.aws_credentials.map(cred => (
                    <option key={cred.id} value={JSON.stringify(cred)}>{cred.name}</option>
                  ))}
              </Form.Control>
            </div>
          </Col>
          </>
        ) : (
          loading ? (
            <Col xs={12}>
              <div className="d-flex justify-content-center mt-5 mb-5">
                <div className="loader"></div>
              </div>
            </Col>
          ) : (
            awsCreds ? (
              <Col xs={12}>
                <Row className="justify-content-center">
                  <Col xs={12} md={3} className="text-center">
                    <p className="font-weight-bold mb-0 mt-3">Profile</p>
                    {awsCreds && (
                      <p>{awsCreds.name}</p>
                    )}
                  </Col>
                  <Col xs={12} md={3} className="text-center">
                    <p className="font-weight-bold mb-0 mt-3">Region</p>
                    {awsCreds && (
                      <p>{awsCreds.region}</p>
                    )}
                  </Col>
                  <Col xs={12} md={3} className="text-center">
                    <p className="font-weight-bold mb-0 mt-3">Verify</p>
                    {awsCreds && (
                      awsCreds.verified ? (
                        <FontAwesomeIcon
                          className="greenLight"
                          icon={faCheckCircle}
                        />
                      ) : 
                      (
                        <FontAwesomeIcon
                          className="redLight"
                          icon={faTimesCircle}
                        />
                      )
                    )}
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col xs={12}>
                <div className="error-display text-danger">
                  <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {errorTemplates}</p>
                </div>
              </Col>
            )
          )
        )}
      </Row>
      <Row>
        <div className="next-button-templates">
          <Button
            className="btn-template-outline mt-3 mb-5"
            onClick={() => setCount(2)}
            disabled={!awsCreds}
          >Next</Button>
        </div>
      </Row>
    </>
  );
}

export default AwsCredTemplate;