import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import '../../assets/styles/modals/ModalKorSmallRight.scss'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import closeIcon from '../../assets/images/icons/close-icon.svg';

const GitCloneModal = ({
  show, 
  setShow,
  repoUrl,
  repoName,
  gitCreds,
  setGitCred,
  setRepoUrl,
  setRepoName,
  handleClone,
}) => {
  return (
    <>
      {show && (
        <div className="modal-kor-small-right">
          <div className="box-modal-options-container-small-right">

            <div className="close-title">
            <img src={closeIcon} onClick={() => setShow(!show)} />
            <h3>Clone a new repository</h3>
            </div>

            <p className="mt-5">
              Add a new repository from an internal tool or from the internet, start by adding your credentials.
            </p>
            <Row>
              <Col xs={2} className="p-0 mt-3 mr-2">
                <p className="input-label">
                  Repository name
                </p>
              </Col>
              <Col xs={9} className="p-0 mt-3">
                <Form.Control
                  type="text"
                  value={repoName}
                  aria-label="Title"
                  placeholder="Repository name"
                  aria-describedby="new-title"
                  onChange={e => setRepoName(e.target.value)}
                />
              </Col>
              <Col xs={2} className="p-0 mt-3 mr-2">
                <p className="input-label">
                  Repository link
                </p>
              </Col>
              <Col xs={9} className="p-0 mt-3">
                <Form.Control
                  type="text"
                  value={repoUrl}
                  aria-label="Title"
                  aria-describedby="new-title"
                  placeholder="Repository link"
                  onChange={e => setRepoUrl(e.target.value)}
                />
              </Col>
              <Col xs={12} className="p-0 mt-4 mb-4">
                <p>*KOR is using HTTPS for clone.<br/>You can clone a repository without git credentials if the repository is public.</p>
              </Col>
              <Col xs={2} className="p-0 mr-2">
                <p className="input-label">
                  GIT Credentials
                </p>
              </Col>
              <Col xs={9} className="p-0 mb-5">
                <Form.Control
                  as="select"
                  defaultValue={'DEFAULT'}
                  onChange={e => setGitCred(e.target.value)}
                >
                  <option disabled value="DEFAULT">
                    Select Credentials
                  </option>
                  {gitCreds && gitCreds.map(cred => (
                    <option
                      key={cred.id}
                      value={cred.id}>
                      {cred.name}
                    </option>
                  ))}
                </Form.Control>  
              </Col>
              <Col xs={12}>
                <div className="d-flex justify-content-end mr-4">
                  <button 
                    onClick={() => handleClone()}
                    disabled={!repoUrl || !repoName}
                    className="btn kor-btn-sm btn-sm" 
                  >
                    CLONE NOW
                  </button>  
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
}

export default GitCloneModal;