/****************************************************************************************
 * Habitat Model
 *
 * Description:
 *
 * A habitat is a logical encapsulation of a serverless project, it also serves as
 * the UI for the cloud provider's API and can also be thought of as a Serverless
 * Orchestration Center
 *
 * Schema:
 *
 * habitat = {
 *  name: "Untitled",
 *  team_id: team_id,
 *  funcs: [
 *    {
 *      "func_id": "func1-id", // function code id
 *      "title": "funcA"
 *    }
 *  ],
 *  awsCredsID: "69868997",
 *  awsResources: {
 *    s3Buckets: [
 *      {
 *        name: "proj-local-bucket",
 *        region: "us-east-1",
 *        website: "true",
 *        endpoint: "url"
 *      }
 *    },
 *    lambdas: [
 *      {}
 *    ]
 *  ]
 * }
 *
 * ************************************************************************************/

export const habitatModel = (name_habitat, team_id) => {
    return (
        {
            name: name_habitat,
            team_id: team_id      
        }
    )
}
