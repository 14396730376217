import { 
  GET_INSTANCES_BEGIN, GET_INSTANCES_SUCCESS, GET_INSTANCES_ERROR,
  DELETE_INSTANCE_BEGIN,  DELETE_INSTANCE_SUCCESS,  DELETE_INSTANCE_ERROR,
  GET_INSTANCE_STATUS_BEGIN, GET_INSTANCE_STATUS_SUCCESS, GET_INSTANCE_STATUS_ERROR,
  CHANGE_INSTANCE_STATUS_BEGIN, CHANGE_INSTANCE_STATUS_SUCCESS, CHANGE_INSTANCE_STATUS_ERROR
} from './types';

import {
  getEC2s,
  deleteEC2,
  getEC2Status,
  instanceStatus
} from '../api/aws/ec2';

export const fetchEC2s = habitat_id => {
  return dispatch => {
    dispatch({
      type: GET_INSTANCES_BEGIN
    });
    getEC2s(habitat_id)
    .then(response => {
      dispatch({
        type: GET_INSTANCES_SUCCESS,
        payload: response.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_INSTANCES_ERROR,
        error: error.response.data.error
      });
    });
  }
}

export const deleteInstance = (id, habitat_id) => {
  return dispatch => {
    dispatch({
      type: DELETE_INSTANCE_BEGIN
    });

    const data = {
      id,
      habitat_id
    }

    deleteEC2(data)
    .then(response => {
      dispatch({
        type: DELETE_INSTANCE_SUCCESS,
        payload: response.data
      });
    })
    .catch(error => {
      dispatch({
        type: DELETE_INSTANCE_ERROR,
        error: error.response.data.error
      });
    });
  }
}

export const instanceChangeStatus = (id, status_action, habitat_id) => {
  return dispatch => {
    dispatch({
      type: CHANGE_INSTANCE_STATUS_BEGIN
    });

    const data = {
      habitat_id,
      status_action,
      status_read: false
    }

    instanceStatus(id, data)
    .then(response => {
      const statusData = {
        habitat_id,
        status_read: true
      }

      getEC2Status(id, statusData)
      .then(response => {
        dispatch({
          type: CHANGE_INSTANCE_STATUS_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: CHANGE_INSTANCE_STATUS_ERROR,
          error: error.response.data.error
        });
      });
    })
    .catch(error => {
      dispatch({
        type: CHANGE_INSTANCE_STATUS_ERROR,
        error: error.response.data.error
      });
    });
  }
}

export const getInstaceStatus = (id, habitat_id) => {
  return dispatch => {
    dispatch({
      type: GET_INSTANCE_STATUS_BEGIN
    });

    const data = {
      habitat_id,
      status_read: true
    }

    getEC2Status(id, data)
    .then(response => {
      dispatch({
        type: GET_INSTANCE_STATUS_SUCCESS,
        payload: response.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_INSTANCE_STATUS_ERROR,
        error: error.response.data.error
      });
    });
  }
}