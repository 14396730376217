import {
  CREATE_AWSROLE_BEGIN,
  CREATE_AWSROLE_SUCCESS,
  CREATE_AWSROLE_ERROR,
  DELETE_AWSROLE_BEGIN,
  DELETE_AWSROLE_SUCCESS,
  DELETE_AWSROLE_ERROR,
  GET_AWSROLE_BEGIN,
  GET_AWSROLE_SUCCESS,
  GET_AWSROLE_ERROR,
} from './types';
import { createAWSRole, deleteAWSRole, getAWSRoles } from '../api/awsRoles';

export const newAWSRole = (roleName, habitatId) => {
  return (dispatch) => {
    dispatch({ type: CREATE_AWSROLE_BEGIN });
    let awsPolicyDocument = {
      Version: '2012-10-17',
      Statement: [
        {
          Effect: 'Allow',
          Action: [
            'logs:CreateLogGroup',
            'logs:CreateLogStream',
            'logs:DescribeLogGroups',
            'logs:DescribeLogStreams',
            'logs:PutLogEvents',
            'logs:GetLogEvents',
            'logs:FilterLogEvents',
          ],
          Resource: '*',
        },
        {
          Effect: 'Allow',
          Action: ['execute-api:Invoke', 'execute-api:ManageConnections'],
          Resource: 'arn:aws:execute-api:*:*:/*/*arn:aws:execute-api:*:*:*/*/*',
        },
        {
          Effect: 'Allow',
          Action: ['apigateway:*'],
          Resource: 'arn:aws:apigateway:*::/*',
        },
        {
          Effect: 'Allow',
          Action: 'states:*',
          Resource: '*',
        },
        {
          Effect: 'Allow',
          Action: 'logs:CreateLogGroup',
          Resource: 'arn:aws:logs:us-east-1:973651203340:*',
        },
        {
          Effect: 'Allow',
          Action: ['logs:CreateLogStream', 'logs:PutLogEvents'],
          Resource: [
            'arn:aws:logs:us-east-1:973651203340:log-group:/aws/lambda/*',
            'arn:aws:logs:us-east-1:973651203340:log-group:/aws/codebuild/*',
          ],
        },
        {
          Effect: 'Allow',
          Action: ['ecr:*', 'cloudtrail:LookupEvents'],
          Resource: '*',
        },
        {
          Sid: 'CloudWatchEventsFullAccess',
          Effect: 'Allow',
          Action: 'events:*',
          Resource: '*',
        },
        {
          Action: ['iam:PassRole'],
          Resource: '*',
          Effect: 'Allow',
          Condition: {
            StringEqualsIfExists: {
              'iam:PassedToService': [
                'cloudformation.amazonaws.com',
                'elasticbeanstalk.amazonaws.com',
                'ec2.amazonaws.com',
                'ecs-tasks.amazonaws.com',
              ],
            },
          },
        },
        {
          Action: [
            'codecommit:CancelUploadArchive',
            'codecommit:GetBranch',
            'codecommit:GetCommit',
            'codecommit:GetUploadArchiveStatus',
            'codecommit:UploadArchive',
          ],
          Resource: '*',
          Effect: 'Allow',
        },
        {
          Action: [
            'codedeploy:CreateDeployment',
            'codedeploy:GetApplication',
            'codedeploy:GetApplicationRevision',
            'codedeploy:GetDeployment',
            'codedeploy:GetDeploymentConfig',
            'codedeploy:RegisterApplicationRevision',
          ],
          Resource: '*',
          Effect: 'Allow',
        },
        {
          Action: ['codestar-connections:UseConnection'],
          Resource: '*',
          Effect: 'Allow',
        },
        {
          Action: [
            'elasticbeanstalk:*',
            'ec2:*',
            'elasticloadbalancing:*',
            'autoscaling:*',
            'cloudwatch:*',
            's3:*',
            'sns:*',
            'cloudformation:*',
            'rds:*',
            'sqs:*',
            'ecs:*',
          ],
          Resource: '*',
          Effect: 'Allow',
        },
        {
          Action: ['lambda:InvokeFunction', 'lambda:ListFunctions'],
          Resource: '*',
          Effect: 'Allow',
        },
        {
          Action: [
            'opsworks:CreateDeployment',
            'opsworks:DescribeApps',
            'opsworks:DescribeCommands',
            'opsworks:DescribeDeployments',
            'opsworks:DescribeInstances',
            'opsworks:DescribeStacks',
            'opsworks:UpdateApp',
            'opsworks:UpdateStack',
          ],
          Resource: '*',
          Effect: 'Allow',
        },
        {
          Action: [
            'cloudformation:CreateStack',
            'cloudformation:DeleteStack',
            'cloudformation:DescribeStacks',
            'cloudformation:UpdateStack',
            'cloudformation:CreateChangeSet',
            'cloudformation:DeleteChangeSet',
            'cloudformation:DescribeChangeSet',
            'cloudformation:ExecuteChangeSet',
            'cloudformation:SetStackPolicy',
            'cloudformation:ValidateTemplate',
          ],
          Resource: '*',
          Effect: 'Allow',
        },
        {
          Action: [
            'codebuild:BatchGetBuilds',
            'codebuild:StartBuild',
            'codebuild:BatchGetBuildBatches',
            'codebuild:StartBuildBatch',
          ],
          Resource: '*',
          Effect: 'Allow',
        },
        {
          Effect: 'Allow',
          Action: [
            'devicefarm:ListProjects',
            'devicefarm:ListDevicePools',
            'devicefarm:GetRun',
            'devicefarm:GetUpload',
            'devicefarm:CreateUpload',
            'devicefarm:ScheduleRun',
          ],
          Resource: '*',
        },
        {
          Effect: 'Allow',
          Action: [
            'servicecatalog:ListProvisioningArtifacts',
            'servicecatalog:CreateProvisioningArtifact',
            'servicecatalog:DescribeProvisioningArtifact',
            'servicecatalog:DeleteProvisioningArtifact',
            'servicecatalog:UpdateProduct',
          ],
          Resource: '*',
        },
        {
          Effect: 'Allow',
          Action: ['cloudformation:ValidateTemplate'],
          Resource: '*',
        },
        {
          Effect: 'Allow',
          Action: ['ecr:DescribeImages'],
          Resource: '*',
        },
        {
          Effect: 'Allow',
          Action: [
            'states:DescribeExecution',
            'states:DescribeStateMachine',
            'states:StartExecution',
          ],
          Resource: '*',
        },
        {
          Effect: 'Allow',
          Action: [
            'appconfig:StartDeployment',
            'appconfig:StopDeployment',
            'appconfig:GetDeployment',
          ],
          Resource: '*',
        },
        {
          Sid: 'IAMPassRoleForCloudWatchEvents',
          Effect: 'Allow',
          Action: 'iam:PassRole',
          Resource: 'arn:aws:iam::*:role/AWS_Events_Invoke_Targets',
        },
        {
          Effect: 'Allow',
          Action: [
            'xray:PutTraceSegments',
            'xray:PutTelemetryRecords',
            'xray:GetSamplingRules',
            'xray:GetSamplingTargets',
          ],
          Resource: ['*'],
        },
        {
          Effect: 'Allow',
          Action: [
            'cloudformation:DescribeChangeSet',
            'cloudformation:DescribeStackResources',
            'cloudformation:DescribeStacks',
            'cloudformation:GetTemplate',
            'cloudformation:ListStackResources',
            'cloudwatch:*',
            'cognito-identity:ListIdentityPools',
            'cognito-sync:GetCognitoEvents',
            'cognito-sync:SetCognitoEvents',
            'dynamodb:*',
            'ec2:DescribeSecurityGroups',
            'ec2:DescribeSubnets',
            'ec2:DescribeVpcs',
            'events:*',
            'iam:GetPolicy',
            'iam:GetPolicyVersion',
            'iam:GetRole',
            'iam:GetRolePolicy',
            'iam:ListAttachedRolePolicies',
            'iam:ListRolePolicies',
            'iam:ListRoles',
            'iam:PassRole',
            'iot:AttachPrincipalPolicy',
            'iot:AttachThingPrincipal',
            'iot:CreateKeysAndCertificate',
            'iot:CreatePolicy',
            'iot:CreateThing',
            'iot:CreateTopicRule',
            'iot:DescribeEndpoint',
            'iot:GetTopicRule',
            'iot:ListPolicies',
            'iot:ListThings',
            'iot:ListTopicRules',
            'iot:ReplaceTopicRule',
            'kinesis:DescribeStream',
            'kinesis:ListStreams',
            'kinesis:PutRecord',
            'kms:ListAliases',
            'codebuild:*',
            'lambda:*',
            'logs:*',
            's3:*',
            'sns:ListSubscriptions',
            'sns:ListSubscriptionsByTopic',
            'sns:ListTopics',
            'sns:Publish',
            'sns:Subscribe',
            'sns:Unsubscribe',
            'sqs:ListQueues',
            'sqs:SendMessage',
            'tag:GetResources',
            'xray:PutTelemetryRecords',
            'xray:PutTraceSegments',
          ],
          Resource: '*',
        },
      ],
    };

    let awsRolePolicy = {
      Version: '2012-10-17',
      Statement: {
        Effect: 'Allow',
        Principal: {
          Service: [
            'lambda.amazonaws.com',
            'apigateway.amazonaws.com',
            'codebuild.amazonaws.com',
          ],
        },
        Action: 'sts:AssumeRole',
      },
    };

    const jsonPolicyDocument = JSON.stringify(awsPolicyDocument);
    const awsPolicyDocumentReplace = jsonPolicyDocument
      .replace(/\\n/g, '\\n')
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, '\\&')
      .replace(/\\r/g, '\\r')
      .replace(/\\t/g, '\\t')
      .replace(/\\b/g, '\\b')
      .replace(/\\f/g, '\\f');

    const jsonAwsRolePolicy = JSON.stringify(awsRolePolicy);
    const awsRolePolicyReplace = jsonAwsRolePolicy
      .replace(/\\n/g, '\\n')
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, '\\&')
      .replace(/\\r/g, '\\r')
      .replace(/\\t/g, '\\t')
      .replace(/\\b/g, '\\b')
      .replace(/\\f/g, '\\f');

    const data = {
      role_name: roleName.replace(/\s/g, '-'),
      role_policy: awsRolePolicyReplace,
      habitat_id: habitatId,
      policy_document: awsPolicyDocumentReplace,
    };
    createAWSRole(data)
      .then((response) => {
        dispatch({
          type: CREATE_AWSROLE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => dispatch({ type: CREATE_AWSROLE_ERROR, error }));
  };
};

export const removeAWSRole = (roleId, habitatId, roleName, policyArn) => {
  return (dispatch) => {
    dispatch({ type: DELETE_AWSROLE_BEGIN });
    const data = {
      habitat_id: habitatId,
      role_name: roleName,
      policy_arn: policyArn,
    };
    deleteAWSRole(roleId, data)
      .then((response) => {
        dispatch({
          type: DELETE_AWSROLE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => dispatch({ type: DELETE_AWSROLE_ERROR, error }));
  };
};

export const fetchAWSRole = (habitatId) => {
  return (dispatch) => {
    dispatch({
      type: GET_AWSROLE_BEGIN,
      loading_message: 'Loading AWS Role...',
    });
    getAWSRoles(habitatId)
      .then((response) => {
        dispatch({
          type: GET_AWSROLE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_AWSROLE_ERROR,
          errorGetRoles: 'Get roles fail',
          error,
        })
      );
  };
};
