import React from 'react';

const StateMachineViewer = ({show, states, machine, definition}) => {
  return (
    <>
      <div className="p-3 bg-white">
        <p className="title-step-funcs">State Machine JSON</p>
        <div className="d-flex justify-content-center bg-light p-5 machine-state">
          {!show ? (
            definition && (
              <pre>
                { JSON.stringify(JSON.parse(definition), null, 2) }
              </pre>
            )
          ):(
            <pre>
              { JSON.stringify(machine, null, 2) }
            </pre>
          )}
        </div>
      </div>
    </>
  )
}

export default StateMachineViewer;