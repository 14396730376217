import produce from 'immer';

import {
  NEW_DEPLOYMENT_BEGIN, NEW_DEPLOYMENT_SUCCESS, NEW_DEPLOYMENT_ERROR,
  ALL_DEPLOYMENTS_BEGIN, ALL_DEPLOYMENTS_SUCCESS, ALL_DEPLOYMENTS_ERROR,
  REMOVE_DEPLOYMENT_BEGIN, REMOVE_DEPLOYMENT_SUCCESS, REMOVE_DEPLOYMENT_ERROR,
} from '../actions/types';

const initialState = {
  deployments: [],
  deployment: {},
  loading: false,
  error: null
};

function remove(array, key, value) {
  const index = array.findIndex(obj => obj[key] === value);
  return index >= 0 ? [
      ...array.slice(0, index),
      ...array.slice(index + 1)
  ] : array;
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case NEW_DEPLOYMENT_BEGIN:
    case REMOVE_DEPLOYMENT_BEGIN:
    case ALL_DEPLOYMENTS_BEGIN:
      draft.loading = true;
      draft.error = null;
      return;
    case REMOVE_DEPLOYMENT_SUCCESS:
      draft.loading = false;
      draft.deployments = remove(draft.deployments, '_id', action.payload.id)
      draft.error = null;
      return;
    case NEW_DEPLOYMENT_SUCCESS:
      draft.loading = false;
      draft.deployment = action.payload;
      draft.deployments.unshift(action.payload);
      return;
    case ALL_DEPLOYMENTS_SUCCESS:
      draft.loading = false;
      draft.deployments = action.payload;
      draft.error = null;
      return;
    case NEW_DEPLOYMENT_ERROR:
    case REMOVE_DEPLOYMENT_ERROR:
    case ALL_DEPLOYMENTS_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;
