import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

// Scope team to user
export const getTeams = () => {  
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));  
  return axios.get(`${URL}/teams`, { headers: authToken })
};

// Verification delete team
export const deleteVerification = (id) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/team_verification/?teid=${id}`, { headers: authToken })
}

// Get one team
export const getTeam = (id) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));  
  return axios.get(`${URL}/teams/${id.toString()}`, { headers: authToken })
}

// Create team
export const newTeam = (teamName) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));  
  const data = {
    name: teamName
  }
  return axios.post(`${URL}/teams`, data, { headers: authToken })
}

// Delete team
export const removeTeam = (teamId) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.delete(`${URL}/teams/${teamId}`, { headers: authToken })
};

// Get members
export const getMembers = (teamId) => {  
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));  
  return axios.get(`${URL}/members/${teamId}`, { headers: authToken })
};

// Remove member if owner
// Blocked

// Add team member
export const sendInvite = (memberEmail, teamId) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));  
  const data = {
    invitee_email: memberEmail,
    team_id: teamId
  }
  return axios.post(`${URL}/invitations`, data, { headers: authToken });
}

// Leave team
export const removeMember = (teamId, userId) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  const data = {
    teid: teamId,
    usid: userId
  }
  return axios.post(`${URL}/remove_member`, data, { headers: authToken });
}
// Blocked

//Get Team Subscription
export const getTeamSubscription = (id) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));  
  return axios.get(`${URL}/team_subscriptions/${id}`, { headers: authToken })
}

// Put Team Change status
export const changeStatus = (id) => {
  const data = {
    id
  }
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.put(`${URL}/change_status`, data, { headers: authToken });
}