import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import routes from '../constants/routes';
import Background from '../assets/images/NotFoundBackground.png';
import '../assets/styles/components/notfound.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';


const InternalServerErrorPage = withRouter(({ history }) => {
  const GoBackToOverview = (e) => {
    e.preventDefault();
    history.push(routes.OVERVIEW);
  };

return(
    <div>
      <img
        className="background"
        src={Background}
        alt="Not Found Page background"
      />
      <div className="error-message">
        <h1 id="error-title1">INTERNAL SERVER ERROR</h1>
        <h2 id="error-title2">500</h2>
        <p id="error-text">
          <FontAwesomeIcon icon={faExclamationTriangle} id="notFoundIcon" />
         An error has occurred and we are working to fix the problem.
          <br />
         We should be up and working shortly.
        </p>
        <Button className="goback-btn" onClick={GoBackToOverview}>
          Go Back
        </Button>
      </div>
    </div>
);
});

const mapState = (props, state) => ({
  history: props.history,
});
const mapDispatch = {};
export default connect(mapState, mapDispatch)(InternalServerErrorPage);

