import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const HabitatDocumentationLink = (props) => {
    return (
        <div className="d-flex justify-content-end mr-3 mb-4 question-mark-documentation">
            {props.habitatSetUp && props.habitatSetUp === 'habitat' && (
                <OverlayTrigger
                    placement="left"
                    overlay={
                        <Tooltip className="red-tooltip">
                            <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                        </Tooltip>
                    }

                >
                    <a
                        href="https://getkor.freshdesk.com/support/solutions/articles/47001164310-aws-credentials-verification"
                        rel="noopener noreferrer"
                        className="help"
                        target="_blank"
                    >

                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </a>
                </OverlayTrigger>
            )}

            {props.habitatSetUp && props.habitatSetUp === 'roles' && (
                <OverlayTrigger
                    placement="left"
                    overlay={
                        <Tooltip className="red-tooltip">
                            <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                        </Tooltip>
                    }

                >
                    <a
                        href="https://getkor.freshdesk.com/support/solutions/articles/47001164311-habitat-roles"
                        rel="noopener noreferrer"
                        className="help"
                        target="_blank"
                    >

                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </a>
                </OverlayTrigger>
            )}

            {props.habitatSetUp && props.habitatSetUp === 'funcs' && (
                <OverlayTrigger
                    placement="left"
                    overlay={
                        <Tooltip className="red-tooltip">
                            <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                        </Tooltip>
                    }

                >
                    <a
                        href="https://getkor.freshdesk.com/support/solutions/articles/47001164312-functions"
                        rel="noopener noreferrer"
                        className="help"
                        target="_blank"
                    >

                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </a>
                </OverlayTrigger>
            )}

            {props.habitatSetUp && props.habitatSetUp === 'templates' && (
                <OverlayTrigger
                    placement="left"
                    overlay={
                        <Tooltip className="red-tooltip">
                            <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                        </Tooltip>
                    }

                >
                    <a
                        href="https://getkor.freshdesk.com/support/solutions/articles/47001164339-templates"
                        rel="noopener noreferrer"
                        className="help"
                        target="_blank"
                    >

                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </a>
                </OverlayTrigger>
            )}

            {props.habitatSetUp && props.habitatSetUp === 's3' && (
                <OverlayTrigger
                    placement="left"
                    overlay={
                        <Tooltip className="red-tooltip">
                            <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                        </Tooltip>
                    }

                >
                    <a
                        href="https://getkor.freshdesk.com/support/solutions/articles/47001164308-habitat-settings"
                        rel="noopener noreferrer"
                        className="help"
                        target="_blank"
                    >

                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </a>
                </OverlayTrigger>
            )}

            {props.habitatSetUp && props.habitatSetUp === 'dynamo' && (
                <OverlayTrigger
                    placement="left"
                    overlay={
                        <Tooltip className="red-tooltip">
                            <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                        </Tooltip>
                    }

                >
                    <a
                        href="https://getkor.freshdesk.com/support/solutions/articles/47001164308-habitat-settings"
                        rel="noopener noreferrer"
                        className="help"
                        target="_blank"
                    >

                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </a>
                </OverlayTrigger>
            )}

            {props.habitatSetUp && props.habitatSetUp === 'cognito' && (
                <OverlayTrigger
                    placement="left"
                    overlay={
                        <Tooltip className="red-tooltip">
                            <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                        </Tooltip>
                    }

                >
                    <a
                        href="https://getkor.freshdesk.com/support/solutions/articles/47001164308-habitat-settings"
                        rel="noopener noreferrer"
                        className="help"
                        target="_blank"
                    >

                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </a>
                </OverlayTrigger>
            )}

            {props.habitatSetUp && props.habitatSetUp === 'api' && (
                <OverlayTrigger
                    placement="left"
                    overlay={
                        <Tooltip className="red-tooltip">
                            <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                        </Tooltip>
                    }

                >
                    <a
                        href="https://getkor.freshdesk.com/support/solutions/articles/47001164308-habitat-settings"
                        rel="noopener noreferrer"
                        className="help"
                        target="_blank"
                    >

                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </a>
                </OverlayTrigger>
            )}

            {!props.habitatSetUp && (
                <OverlayTrigger
                    placement="left"
                    overlay={
                        <Tooltip className="red-tooltip">
                            <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                        </Tooltip>
                    }

                >
                    <a
                        href="https://getkor.freshdesk.com/support/solutions/articles/47001164308-habitat-settings"
                        rel="noopener noreferrer"
                        className="help"
                        target="_blank"
                    >

                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </a>
                </OverlayTrigger>
            )}




        </div>
    )
}

export default HabitatDocumentationLink;