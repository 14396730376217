import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../assets/styles/components/team-members.scss';
import Loading from './Loading';
import { Accordion , Card } from 'react-bootstrap';
import { inviteMember, fetchMembers } from '../actions/team.actions';
import MemberList from './members/MemberList';



const Members = ({
  fetchMembers, 
  inviteMember,
  team_invites,
  team_members,
  teamId,
  loading  
}) => {    

  localStorage.getItem('theTeam');
  teamId = localStorage.getItem('theTeamId');

  const [memberEmail, setMemberEmail] = useState('');
    useEffect(() => {
      const fetchMembersSecure = () => {
        fetchMembers(teamId)
      }
      fetchMembersSecure()  
  }, [fetchMembers, teamId]);


  return (
    <div>
      <div className=" container-kds">
        <div className="page-nav-main">
          <p className="page-title">Members</p>                      
        </div>
        <div className="col-md-12 page-content">
          <p className="team-page-subtitle">TEAM MEMBERS</p>

          <Accordion defaultActiveKey="0">
            <Card className="teamcard">
              <Accordion.Toggle className="team-card-header" as={Card.Header} eventKey="1">
                <div className="team-card-header-title"><i className="fas fa-user-plus"></i>Invite Member</div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <form onSubmit={e => e.preventDefault()} className="team-page-form">                 
                    <div className="input-field-email">                                       
                      <input 
                        name="teamEmail"
                        type="email"
                        value={memberEmail}
                        className="team-input-field" 
                        placeholder="Team email"
                        onChange={e => setMemberEmail(e.target.value)} />
                    </div>

                    <button
                      type="submit"                      
                      className="btn btn-primary btn-team-submit"      
                      onClick={() => inviteMember(teamId, memberEmail)} >  
                      Send
                    </button>
                  </form>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          
          
          { team_members && ( <MemberList members={team_members} /> ) }

          { loading && ( <Loading /> ) }

        </div>
      </div>
    </div>     
  );
}

const mapDispatch = { 
  inviteMember,
  fetchMembers
};

const mapState = (state, props) => ({
	currentUser: state.user.user,
  teamId: props.match.params.teamId,
  team_members: state.team.team_members,
  team_invites: state.team.team_invites,
  loading: state.team.loading,  
});

export default connect(
  mapState,
  mapDispatch
)(Members);