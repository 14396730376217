import produce from 'immer';
import {
  ADD_GIT_INFO_BEGIN, ADD_GIT_INFO_SUCCESS,
  ADD_DOMIAN_INFO_BEGIN, ADD_DOMIAN_INFO_SUCCESS,
  GET_TEMPLATE_INFO_BEGIN, GET_TEMPLATE_INFO_SUCCESS,
  DEPLOY_STATIC_BEGIN, DEPLOY_STATIC_SUCCESS, DEPLOY_STATIC_ERROR,
  CREATE_STATIC_BEGIN, CREATE_STATIC_SUCCESS, CREATE_STATIC_ERROR,
  BUILD_AND_UPDATE_BEGIN, BUILD_AND_UPDATE_SUCCESS, BUILD_AND_UPDATE_ERROR,
  CHANGE_SITE_STATUS_BEGIN, CHANGE_SITE_STATUS_SUCCESS, CHANGE_SITE_STATUS_ERROR,
  GET_STATIC_TEMPLATE_BEGIN, GET_STATIC_TEMPLATE_SUCCESS, GET_STATIC_TEMPLATE_ERROR,
  CREATE_STRAPI_TEMPLATE_BEGIN, CREATE_STRAPI_TEMPLATE_SUCCESS, CREATE_STRAPI_TEMPLATE_ERROR,
  CREATE_STATIC_TEMPLATE_BEGIN, CREATE_STATIC_TEMPLATE_SUCCESS, CREATE_STATIC_TEMPLATE_ERROR,
  REMOVE_STATIC_TEMPLATE_BEGIN, REMOVE_STATIC_TEMPLATE_SUCCESS, REMOVE_STATIC_TEMPLATE_ERROR,
  CREATE_WEB_APP_TEMPLATE_BEGIN, CREATE_WEB_APP_TEMPLATE_SUCCESS, CREATE_WEB_APP_TEMPLATE_ERROR,
  CREATE_STRAPI_WITHOUT_DOMAIN_BEGIN, CREATE_STRAPI_WITHOUT_DOMAIN_SUCCESS, CREATE_STRAPI_WITHOUT_DOMAIN_ERROR,
  ADD_HABITAT_AND_CREDS_TEMPLATE_BEGIN, ADD_HABITAT_AND_CREDS_TEMPLATE_SUCCCESS, ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR,
  MANAGE_CLOUDFRONT_BEGIN, MANAGE_CLOUDFRONT_SUCCESS, MANAGE_CLOUDFRONT_ERROR,
  GET_INVALIDATIONS_BEGIN, GET_INVALIDATIONS_SUCCESS, GET_INVALIDATIONS_ERROR,
  CREATE_INVALIDATIONS_BEGIN, CREATE_INVALIDATIONS_SUCCESS, CREATE_INVALIDATIONS_ERROR,
  GET_INDEX_INVALIDATION_BEGIN, GET_INDEX_INVALIDATION_SUCCESS, GET_INDEX_INVALIDATION_ERROR,
  CREATE_STATIC_WITHOUTDOMAIN_BEGIN, CREATE_STATIC_WITHOUTDOMAIN_SUCCESS, CREATE_STATIC_WITHOUTDOMAIN_ERROR,
  CREATE_WEB_APP_WITHOUTDOMAIN_BEGIN, CREATE_WEB_APP_WITHOUTDOMAIN_SUCCESS, CREATE_WEB_APP_WITHOUTDOMAIN_ERROR,
  CREATE_EC2_STRAPI_WITHDOMAIN_BEGIN, CREATE_EC2_STRAPI_WITHDOMAIN_SUCCESS, CREATE_EC2_STRAPI_WITHDOMAIN_ERROR,
  CREATE_EC2_STRAPI_WITHOUTDOMAIN_BEGIN, CREATE_EC2_STRAPI_WITHOUTDOMAIN_SUCCESS, CREATE_EC2_STRAPI_WITHOUTDOMAIN_ERROR
} from '../actions/types';
import { dataTransfer } from '../api/aws/sdk';

const initialState = {
  templateName: null,
  loading: false,
  loading_message: '',
  error: null,
  infoTemplate: {
    type: 'A',
    uri: null,
    name: null,
    prefix: null,
    dns_name: null,
    git_creds: null,
    domain_name: null,
    project_name: null
  },
  role: null,
  name: null,
  deploy: null,
  domains: null,
  habitat: null,
  awsCred: null,
  templates: null,
  cloudFrontStatus: null,
  invalidations: {},
  invalidation: {},
  dbEndpoint: '',
  devEndpoint: '',
  prodEndpoint: '',
  frontendEndpoint: null,
  adminEndpoint: null,
  endpointStatic: '',
  endpointWebApp: '',
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_GIT_INFO_BEGIN:
    case CREATE_STATIC_BEGIN:
    case DEPLOY_STATIC_BEGIN:
    case ADD_DOMIAN_INFO_BEGIN:
    case BUILD_AND_UPDATE_BEGIN:
    case MANAGE_CLOUDFRONT_BEGIN:
    case GET_INVALIDATIONS_BEGIN:
    case GET_TEMPLATE_INFO_BEGIN:
    case CHANGE_SITE_STATUS_BEGIN:
    case GET_STATIC_TEMPLATE_BEGIN:
    case CREATE_INVALIDATIONS_BEGIN:
    case CREATE_STRAPI_TEMPLATE_BEGIN:
    case CREATE_STATIC_TEMPLATE_BEGIN:
    case REMOVE_STATIC_TEMPLATE_BEGIN:
    case CREATE_WEB_APP_TEMPLATE_BEGIN:
    case  GET_INDEX_INVALIDATION_BEGIN:
    case CREATE_STATIC_WITHOUTDOMAIN_BEGIN:
    case CREATE_EC2_STRAPI_WITHDOMAIN_BEGIN:
    case CREATE_STRAPI_WITHOUT_DOMAIN_BEGIN:
    case CREATE_WEB_APP_WITHOUTDOMAIN_BEGIN:
    case ADD_HABITAT_AND_CREDS_TEMPLATE_BEGIN:
    case CREATE_EC2_STRAPI_WITHOUTDOMAIN_BEGIN:
      draft.loading = true;
      draft.error = null;
      draft.loading_message = action.loading_message;
      return;
    case GET_TEMPLATE_INFO_SUCCESS:
      draft.loading = false;
      draft.templateName = action.payload;
      return;
    case ADD_DOMIAN_INFO_SUCCESS:
      draft.loading = false;
      draft.infoTemplate.name = action.payload.name;
      draft.infoTemplate.prefix = action.payload.prefix;
      draft.infoTemplate.domain_name = action.payload.domain_name;
      return;
    case ADD_GIT_INFO_SUCCESS:
      draft.loading = false;
      draft.infoTemplate.uri = action.payload.uri;
      draft.infoTemplate.git_creds = action.payload.git_creds;
      draft.infoTemplate.project_name = action.payload.project_name;
      return;
    case MANAGE_CLOUDFRONT_SUCCESS:
      draft.loading = false;
      draft.templates = action.payload.historical
      return;
    case CREATE_EC2_STRAPI_WITHDOMAIN_SUCCESS:
    case CREATE_EC2_STRAPI_WITHOUTDOMAIN_SUCCESS:
      draft.loading = false;
      draft.devEndpoint = action.payload.devEndpoint;
      draft.prodEndpoint = action.payload.prodEndpoint;
      draft.dbEndpoint = action.payload.dbEndpoint;
      return;
    case CREATE_STRAPI_WITHOUT_DOMAIN_SUCCESS:
      draft.loading = false;
      draft.frontendEndpoint = action.payload.frontendEndpoint;
      draft.adminEndpoint = action.payload.adminEndpoint;
      return;
    case CREATE_STATIC_SUCCESS:
    case REMOVE_STATIC_TEMPLATE_SUCCESS:
    case CREATE_STATIC_TEMPLATE_SUCCESS:
    case CREATE_STRAPI_TEMPLATE_SUCCESS:
      draft.loading = false;
      draft.templates = action.payload;
      return;
    case CREATE_STATIC_WITHOUTDOMAIN_SUCCESS:
        draft.loading = false;
        draft.templates = action.payload;
        draft.endpointStatic = action.payload.site_details.domain_name;
      return;
    case CREATE_WEB_APP_TEMPLATE_SUCCESS:
      draft.loading = false;
      draft.templates = action.payload.templates;
      draft.endpointWebApp = action.payload;
    case CREATE_WEB_APP_WITHOUTDOMAIN_SUCCESS:
      draft.loading = false;
      draft.templates = action.payload.templates;
      draft.endpointWebApp = action.payload.endpoint;
      return;
    case CHANGE_SITE_STATUS_SUCCESS:
    case GET_STATIC_TEMPLATE_SUCCESS:
      draft.loading = false;
      draft.templates = action.payload;
      return;
    case DEPLOY_STATIC_SUCCESS:
      draft.loading = false;
      draft.deploy = action.payload;
      return;
    case BUILD_AND_UPDATE_SUCCESS:
      draft.loading = false;
      draft.templates = action.payload;
      return;
    case GET_INVALIDATIONS_SUCCESS: 
      draft.loading = false;
      draft.invalidations = action.payload;
      return;
    case ADD_HABITAT_AND_CREDS_TEMPLATE_SUCCCESS:
      draft.loading = false;
      draft.role = action.payload.role;
      draft.name = action.payload.name;
      draft.domains = action.payload.domains;
      draft.habitat = action.payload.habitat;
      draft.awsCred = action.payload.awsCred;
      return;
    case  CREATE_INVALIDATIONS_SUCCESS: 
        draft.loading = false;
        draft.invalidations = action.payload;
        draft.invalidation = action.payload;
      return;
    case GET_INDEX_INVALIDATION_SUCCESS:
      draft.loading = false;
      draft.invalidation = action.payload;
      return;
    case DEPLOY_STATIC_ERROR:
    case BUILD_AND_UPDATE_ERROR:
    case MANAGE_CLOUDFRONT_ERROR:
    case GET_INVALIDATIONS_ERROR:
    case CHANGE_SITE_STATUS_ERROR:
    case GET_STATIC_TEMPLATE_ERROR:
    case CREATE_INVALIDATIONS_ERROR: 
    case CREATE_STRAPI_TEMPLATE_ERROR:
    case CREATE_STATIC_TEMPLATE_ERROR:
    case CREATE_WEB_APP_TEMPLATE_ERROR:
    case CREATE_EC2_STRAPI_WITHDOMAIN_ERROR:
    case CREATE_WEB_APP_WITHOUTDOMAIN_ERROR:
    case CREATE_STRAPI_WITHOUT_DOMAIN_ERROR:
    case ADD_HABITAT_AND_CREDS_TEMPLATE_ERROR:
    case CREATE_EC2_STRAPI_WITHOUTDOMAIN_ERROR:
      draft.loading = false;
      draft.error = action.error;
      draft.role = null;
      draft.name = null;
      draft.domains = null;
      draft.habitat = null;
      draft.awsCred = null;
      return;
    case CREATE_STATIC_ERROR:
    case CREATE_STATIC_WITHOUTDOMAIN_ERROR:
      draft.loading = false;
      draft.error = action.errorCreateStatic;
      return;
    case REMOVE_STATIC_TEMPLATE_ERROR:
      draft.loading = false;
      draft.error = action.errorRemoveTemplate;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;