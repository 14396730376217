import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';

const HeaderTemplate = () => {
  return (
    <Row>
      <Col xs={12}>
        <h1 className="text-center font-weight-bold">
          <Badge className="badge-template mr-3 pt-2 pb-2">
            <FontAwesomeIcon
              icon={faLayerGroup}
            />
          </Badge>
          KOR Templates
        </h1>
      </Col>
      <Col xs={12} className="mt-4 mb-4 text-center">
        <span className="font-weight-bold">
          There are no hard limits on the number of users, volume of traffic, or <br/> 
          data storage.
        </span>
      </Col>
    </Row>
  );
}

export default HeaderTemplate;