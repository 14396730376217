import produce from 'immer';

import {
    SETTINGS_AWSCREDS_TOGGLE,
} from '../actions/types';

const initialState = {
    featureSelected: ''
};

const reducer = produce((draft, action) => {
    switch (action.type) {                
        case SETTINGS_AWSCREDS_TOGGLE:            
            draft.awsCredSelected = action.payload;
            return;
        default:
            return;
    }
}, initialState);

export default reducer;
