import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const AccountCancelModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.handleToggle}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.habitats.length > 0 ? (
          <p>To cancel this account you first need to delete all your habitats</p>
        ) : (
          <p>Are you sure to delete this account?</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" disabled={props.habitats.length > 0} onClick={props.handleRemoveUser}>
          Yes
        </Button>
        <Button variant="primary" onClick={props.handleToggle}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccountCancelModal;