import {
  CREATE_S3_BEGIN,
  CREATE_S3_SUCCESS,
  CREATE_S3_ERROR,
  DELETE_S3_BEGIN,
  DELETE_S3_SUCCESS,
  DELETE_S3_ERROR,
  GET_ALL_S3_BEGIN,
  GET_ALL_S3_SUCCESS,
  GET_ALL_S3_ERROR,
  CLEAR_S3_BEGIN,
  CLEAR_S3_SUCCESS,
  CLEAR_S3_ERROR,
  GET_S3_FILES_BEGIN,
  GET_S3_FILES_SUCCESS,
  GET_S3_FILES_ERROR,
  DISCONNECT_HAB_S3_BEGIN,
  DISCONNECT_HAB_S3_SUCCESS,
  DISCONNECT_HAB_S3_ERROR,
} from './types';
import {
  addBucket,
  getBuckets,
  clearBucket,
  removeBucket,
  disconnectS3s,
  getBucketFile,
} from '../api/aws/sdk';

export const createS3Bucket = (name, habitat_id, region) => {
  return (dispatch) => {
    dispatch({
      type: CREATE_S3_BEGIN,
      loading_message: 'Creating S3 bucket...',
    });
    addBucket(name, habitat_id, region)
      .then(() => {
        getBuckets(habitat_id)
          .then((response) => {
            dispatch({
              type: CREATE_S3_SUCCESS,
              payload: response.data,
            });
          })
          .catch((error) => {
            dispatch({
              type: CREATE_S3_ERROR,
              error_message: error,
              error,
            });
          });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_S3_ERROR,
          error_message: error.response.data.error,
          error,
        });
      });
  };
};

export const listAWSS3 = (habitat_id) => {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_S3_BEGIN,
      loading_message: 'Loading S3 buckets...',
    });
    getBuckets(habitat_id)
      .then((response) => {
        dispatch({
          type: GET_ALL_S3_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_S3_ERROR,
          error_message: 'Get S3 Buckets fail',
          error,
        });
      });
  };
};

export const deleteAWSS3 = (habitat_id, bucket) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_S3_BEGIN,
      loading_message: 'Deleting S3 buckets...',
    });
    removeBucket(habitat_id, bucket)
      .then((response) => {
        dispatch({
          type: DELETE_S3_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        const error = err.response.data.error;
        dispatch({
          type: DELETE_S3_ERROR,
          error_message: error,
          error,
        });
      });
  };
};

export const getS3Info = (bucket, habitat_id) => {
  return (dispatch) => {
    dispatch({
      type: GET_S3_FILES_BEGIN,
      loading_message: 'Loading S3 bucket files...',
    });
    getBucketFile(bucket, habitat_id)
      .then((response) => {
        dispatch({
          type: GET_S3_FILES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_S3_FILES_ERROR,
          error_message: 'Get S3 files fail',
          error,
        });
      });
  };
};

export const clearS3 = (bucket, habitat_id) => {
  const data = {
    bucket,
    habitat_id,
  };
  return (dispatch) => {
    dispatch({
      type: CLEAR_S3_BEGIN,
      loading_message: 'Loading clear S3 bucket...',
    });
    clearBucket(data)
      .then((reponse) => {
        dispatch({
          type: CLEAR_S3_SUCCESS,
          payload: reponse.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CLEAR_S3_ERROR,
          error_message: 'Error clearing bucket',
          error,
        });
      });
  };
};

export const disconnectS3Bucket = (habitat, s3Name) => {
  return (dispatch) => {
    dispatch({
      type: DISCONNECT_HAB_S3_BEGIN,
      loading_message: 'Disconnecting S3 Bucket...',
    });
    disconnectS3s(habitat, s3Name)
      .then((response) => {
        dispatch({
          type: DISCONNECT_HAB_S3_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DISCONNECT_HAB_S3_ERROR,
          error_message: error.response.data.error,
          error,
        });
      });
  };
};
