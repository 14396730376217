import React from 'react';
import Chart from 'react-apexcharts';

const GraphsErrorsRequest = props => {
    return(
        <div>
            <div className="graphic-container">
                <div className="graphic">
                    <Chart 
                        options={props.awsOverview.options}
                        series={props.awsOverview.series}
                        type="area"
                        width="100%"
                        height="230"
                    />
                </div>
            </div>
        </div>
    );
}

export default GraphsErrorsRequest;