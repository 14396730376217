import React, { useState } from 'react';
import HabitatAPIMethodsList from './HabitatAPIMethodsList';
import HabitatAPINewMethod from './HabitatAPINewMethod';

const HabitatAPIMethods = ({ 
  funcs,
  apiId, 
  methods, 
  habitat, 
  awsRoles, 
  awsCreds, 
  endpoint, 
  selectedAPI, 
  createRoute, 
  methodError,
  deleteMethod
}) => {
  const [ show, setShow ] = useState(false);

  const handleShow = () => {
    setShow(!show);
  }

  return (
    <div className="habitat-funcs-management-container mt-5">
      <div className="title-setup-w-new">
        <div className="title-setup-management-funcs">
          <h5>Endpoints</h5>
        </div>
        <div className="new-function-btn-setup" onClick={handleShow}>
          <p> + New Endpoint</p>
        </div>
      </div>
      
      <div className="list-roles">
        <HabitatAPIMethodsList
          apiId={apiId}
          habitat={habitat} 
          methods={methods}
          awsCreds={awsCreds}
          deleteMethod={deleteMethod}
        />
      </div>

      {show && (
        <HabitatAPINewMethod 
          funcs={funcs}
          habitat={habitat}
          awsRoles={awsRoles}
          selectedAPI={selectedAPI}
          createRoute={createRoute} 
          methodError={methodError}
        />
      )}
    </div>
  );
}

export default HabitatAPIMethods;