import React from 'react';
import '../../assets/styles/components/StepFunctionList.scss';
import { Row, Col, ListGroup } from 'react-bootstrap';
import habitatIcon from '../../assets/images/icons/HabitatLight.svg';

const StepFuncList = ({stateMachines, handleShowDefinition}) => {
  return (
    <>
      { stateMachines ? (
        <ListGroup>
          {stateMachines.map((stateMachine, index) =>(
            <div className="habitat-list-item-wrapper" key={stateMachine.id}>
              <div className="box-step-func" onClick={() => handleShowDefinition(stateMachine.definition)}>
                <div>
                  <div>
                    <div className="icon-step-func">
                      <img className="habitat-icon-box" src={habitatIcon} alt="collection-icon" />
                    </div>
                  </div>
                  <div  className="info-step-func">
                    <p><b>Name:</b> {stateMachine.name}</p>
                    <p><b>Updated at: </b> {stateMachine.updated_at}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </ListGroup>
      ): (
        <ListGroup>
          <p className="empty-habitat-list">Create step functions!</p>
        </ListGroup>
      )}
    </>
  );
};

export default StepFuncList;