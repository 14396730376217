import React from 'react';
import { connect } from 'react-redux';
import '../assets/styles/components/onboarding/onboarding.scss'
import { Row, Col, Badge, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button'
import headImg from '../../src/assets/images/onboarding-head.png';
import { NavLink } from 'react-router-dom';
import 'animate.css/animate.css'
import Spinner from 'react-bootstrap/Spinner';
import { Discovery } from 'aws-sdk';

const AppTour = (props) => {

    const statusAppTour = false;
    const stepAppTour = props.stepAppTour
    const changeStatusOnboarding = props.changeStatusOnboarding

    const handleNextStepTour = () => {
        props.nextStepTour();
    }

    const handleStopAppTour = () => {
        props.changeStatusOnboarding(false)
        props.stopTour();
    }

    const handleNextStepRoles = () => {
        props.changeOnboardingClassRoles('onboardingClassRoles')
        props.changeOnboardingClassHabitat('')
        props.changeOnboardingClassAWS('')
        props.nextStepTour();
    }

    const handleNextFunction = () => {
        props.changeOnboardingClassFunctions('onboardingClassFuncs')
        props.changeOnboardingClassRoles('')
        props.nextStepTour();
    }

    return (
        <>
            {statusAppTour && (
                <div className="onboarding-container">
                    <div className="card-onboarding-container">
                        {stepAppTour === 0 && (
                            <div className="card-01-onboarding animate__animated animate__bounceInDown">
                                <Row>
                                    <Col xs={12}>
                                        <Image src={headImg} fluid />
                                    </Col>
                                    <Col xs={12} >
                                        <div className="pr-3 pl-3 pt-0 mt-2">
                                            <h3>Welcome to KOR!</h3>
                                            <p>
                                                KOR is a serverless development platform where you and your team can build, manage, test, and deploy code in one developer-friendly place.</p>
                                            <p>
                                                Let&apos;s take a quick tour. If you&apos;d like to take this tour again you&apos;ll find an activation option within the Settings screen.</p>
                                        </div>
                                    </Col>

                                    <Col xs={12} className="pt-0 mt-2 d-flex">
                                        <NavLink exact to="/Repositories">
                                            <Button variant="primary" onClick={handleNextStepTour}>
                                                Start Tour!
                                        </Button>
                                        </NavLink>
                                        <Button variant="outline-primary" onClick={handleStopAppTour}>
                                            Skip onboarding
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {stepAppTour === 1 && (
                            <div className="card-02-onboarding animate__animated animate__fadeInLeftBig">
                                <Row>
                                    <Col>
                                        <Row className="ml-1 mb-3">
                                            <h3>
                                                <Badge pill variant="primary" className="mr-2">
                                                    1
                                                        </Badge>
                                            </h3>
                                            <h6 className="mt-2">ADD A NEW REPOSITORY</h6>
                                        </Row>
                                        <p>
                                            Let&apos;s start by adding a new Repository, you need to click on <i>&quot;+ Add New&quot;.</i>
                                        </p>
                                        <div className="d-flex justify-content-start">
                                            <Button variant="link" className="ml-0 pl-0" onClick={handleStopAppTour}>
                                                Skip Onboarding
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {stepAppTour === 2 && (
                            <div className="card-03-onboarding animate__animated animate__fadeInLeftBig">
                                <Row>
                                    <Col>
                                        <Row className="ml-1 mb-3">
                                            <h3>
                                                <Badge pill variant="primary" className="mr-2">
                                                    2
                                            </Badge>
                                            </h3>
                                            <h6 className="mt-2">CLONE A REPOSITORY</h6>
                                        </Row>
                                        <p>
                                            Click on <i>&quot;Clone Repository&quot;</i> to open the menu. <br />
                                        If you dont have a repository handy, you can clone one from KOR.
                                        Copy the following link: <b>https://gitlab.com/kor-comunity/kor-lambda.git</b>
                                            <br />
                                            <br /> Add a new name to your repository,
                                        paste the link and, then you can leave the credentials empty for the public repositories.
                                        </p>
                                        <div className="d-flex">
                                            <Button variant="link" className="ml-0 pl-0" onClick={handleStopAppTour}>
                                                Skip Onboarding
                                        </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {stepAppTour === 3 && (
                            <div className="card-04-onboarding animate__animated animate__fadeInLeftBig">
                                <Row>
                                    <Col>
                                        <Row className="ml-1 mb-3">
                                            <h3>
                                                <Badge pill variant="primary" className="mr-2">
                                                    3
                                            </Badge>
                                            </h3>
                                            <h6 className="mt-2">CREATE HABITAT</h6>
                                        </Row>
                                        <p>
                                            Click on the <i>&quot;New Habitat&quot;</i> button to create a new one.
                                        </p>
                                        <div className="d-flex">
                                            <Button variant="link" className="ml-0 pl-0" onClick={handleStopAppTour}>
                                                Skip Onboarding
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {stepAppTour === 4 && (
                            <div className="card-05-onboarding animate__animated animate__fadeInLeftBig">
                                <Row>
                                    <Col>
                                        <Row className="ml-1 mb-3">
                                            <h3>
                                                <Badge pill variant="primary" className="mr-2">
                                                    4
                                            </Badge>
                                            </h3>
                                            <h6 className="mt-2">SELECT HABITAT</h6>
                                        </Row>
                                        <p>
                                            Select or click in the habitat to open the habitat configuration.
                                        </p>
                                        <div className="d-flex">
                                            <Button variant="link" className="ml-0 pl-0" onClick={handleStopAppTour}>
                                                Skip Onboarding
                                        </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {stepAppTour === 5 && (
                            <div className="card-06-onboarding animate__animated animate__fadeInLeftBig">
                                <Row>
                                    <Col>
                                        <Row className="ml-1 mb-3">
                                            <h3>
                                                <Badge pill variant="primary" className="mr-2">
                                                    5
                                        </Badge>
                                            </h3>
                                            <h6 className="mt-2">HABITAT SETTINGS</h6>
                                        </Row>
                                        <p>Click on <i>&quot;Settings&quot;</i> to setup the Habitat</p>
                                        <div className="d-flex">
                                            <Button variant="link" className="ml-0 pl-0" onClick={handleStopAppTour}>
                                                Skip Onboarding
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {stepAppTour === 6 && (
                            <div className="card-07-onboarding animate__animated animate__fadeInLeftBig">
                                <Row>
                                    <Col>
                                        <Row className="ml-1 mb-3">
                                            <h3>
                                                <Badge pill variant="primary" className="mr-2">
                                                    6
                                            </Badge>
                                            </h3>
                                            <h6 className="mt-2">OPEN AWS CREDENTIALS</h6>
                                        </Row>
                                        <p>
                                            Click on <i>&quot;AWS Credentials&quot;</i> to open the credential setup.
                                        </p>
                                        <div className="d-flex">
                                            <Button variant="link" className="ml-0 pl-0" onClick={handleStopAppTour}>
                                                Skip Onboarding
                                        </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {stepAppTour === 7 && (
                            <div className="card-08-onboarding animate__animated animate__fadeInLeftBig">
                                <Row>
                                    <Col>
                                        <Row className="ml-1 mb-3">
                                            <h3>
                                                <Badge pill variant="primary" className="mr-2">
                                                    7
                                            </Badge>
                                            </h3>
                                            <h6 className="mt-2">AWS CREDENTIAL</h6>
                                        </Row>
                                        <p>The credentials you are using for this Habitat can be seen here.</p>
                                        <div className="d-flex">
                                            <div className="next-onboarding">
                                                <Button variant="link" className="" onClick={handleStopAppTour}>
                                                    Skip onboarding
                                                </Button>
                                                <Button variant="link" className="button-2" onClick={handleNextStepRoles}>
                                                    Next
                                                 </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {stepAppTour === 8 && (
                            <div className="card-10-onboarding animate__animated animate__fadeInLeftBig">
                                <Row>
                                    <Col>
                                        <Row className="ml-1 mb-3">
                                            <h3>
                                                <Badge pill variant="primary" className="mr-2">
                                                    8
                                            </Badge>
                                            </h3>
                                            <h6 className="mt-2">OPEN ROLES</h6>
                                        </Row>
                                        <p>
                                            Click on &quot;Habitat Roles&quot; you will be able to check the role.
                                        </p>
                                        <div className="d-flex">
                                            <Button variant="link" className="ml-0 pl-0" onClick={handleStopAppTour}>
                                                Skip Onboarding
                                        </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {stepAppTour === 9 && (
                            <div className="card-11-onboarding animate__animated animate__fadeInLeftBig">
                                <Row>
                                    <Col>
                                        <Row className="ml-1 mb-3">
                                            <h3>
                                                <Badge pill variant="primary" className="mr-2">
                                                    9
                                           </Badge>
                                            </h3>
                                            <h6 className="mt-2">ROLE</h6>
                                        </Row>
                                        <p>
                                            KOR creates a role after creating your Habitat. This is the role you see here.
                                        </p>
                                        <div className="d-flex">
                                            <div className="next-onboarding">
                                                <Button variant="link" className="" onClick={handleStopAppTour}>
                                                    Skip onboarding
                                                </Button>
                                                <Button variant="link" className="button-2" onClick={handleNextFunction}>
                                                    Next
                                                 </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {stepAppTour === 10 && (
                            <div className="card-12-onboarding animate__animated animate__fadeInLeftBig">
                                <Row>
                                    <Col>
                                        <Row className="ml-1 mb-3">
                                            <h3>
                                                <Badge pill variant="primary" className="mr-2">
                                                    10
                                            </Badge>
                                            </h3>
                                            <h6 className="mt-2">OPEN FUNCTIONS</h6>
                                        </Row>
                                        <p>
                                            Click on <i>&quot;Functions&quot;</i> to check your functions or create a new one.
                                        </p>
                                        <div className="d-flex">
                                            <Button variant="link" className="ml-0 pl-0" onClick={handleStopAppTour}>
                                                Skip Onboarding
                                        </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {stepAppTour === 11 && (
                            <div className="card-13-onboarding animate__animated animate__fadeInLeftBig">
                                <Row>
                                    <Col>
                                        <Row className="ml-1 mb-3">
                                            <h3>
                                                <Badge pill variant="primary" className="mr-2">
                                                    11
                                            </Badge>
                                            </h3>
                                            <h6 className="mt-2">ADD A FUNCTION</h6>
                                        </Row>
                                        <p>
                                            To create a Function, click on the <i>&quot;+ Function button&quot;</i> and then select the repository with the role, then enter the function&apos;s handler and select the runtime.
                                        </p>
                                        <div className="d-flex">
                                            <Button variant="link" className="ml-0 pl-0" onClick={handleStopAppTour}>
                                                Skip Onboarding
                                        </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {stepAppTour === 12 && (
                            <div className="card-14-onboarding animate__animated animate__fadeInLeftBig">
                                <Row>
                                    <Col>
                                        <Row className="ml-1 mb-3">
                                            <h3>
                                                <Badge pill variant="primary" className="mr-2">
                                                    12
                                            </Badge>
                                            </h3>
                                            <h6 className="mt-2">DEPLOY</h6>
                                        </Row>
                                        {props.loadingFunction && (
                                            <div className="spinner-container">
                                                <Spinner animation="border" variant="primary" />
                                                <p>Creating function...</p>
                                            </div>
                                        )}
                                        <p>
                                            Once the Function is completed, (it could take a moment), you can deploy it by clicking on your function for open it and click on <i>&quot;Deploy&quot;.</i>
                                        </p>
                                        <div className="d-flex">
                                            <Button variant="link" className="ml-0 pl-0" onClick={handleStopAppTour}>
                                                Skip Onboarding
                                        </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {stepAppTour === 13 && (
                            <div className="card-15-onboarding animate__animated animate__fadeInLeftBig">
                                <Row>
                                    <Col>
                                        <Row className="ml-1 mb-3">
                                            <h3>
                                                <Badge pill variant="primary" className="mr-2">
                                                    13
                                            </Badge>
                                            </h3>
                                            <h6 className="mt-2">FINISH</h6>
                                        </Row>
                                        <p>
                                            <b>Great!</b> You have completed Onboarding. You are ready to use KOR.
                                            <br />
                                            If you need more resources
                                            <a href="https://getkor.freshdesk.com/support/solutions" rel="noreferrer" target="_blank">
                                                click here.</a>
                                        </p>
                                        <div className="d-flex">
                                            <Button variant="link" className="ml-0 pl-0" onClick={handleStopAppTour}>
                                                Finish Onboarding
                                        </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

const mapState = (state, props) => ({
})

const mapDispatch = {

}

export default connect(
    mapState,
    mapDispatch
)(AppTour);