import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getProPayments, getPlanStartup } from '../../actions/payment.actions';
import BillingHistoryList from './BillingHistoryList';
import Loading from '../Loading';

const BillingHistory = ({props, getProPayments, getPlanStartup, proPayments, startupPayments, loading, team }) => {
    let billingHistorySize = 0
    let proBillingHistorySize = 0
    let teamId = team.id
    if(!team.id){
        teamId = localStorage.getItem('theTeamId');
    }
    useEffect(() => {
        getProPayments()
        if(teamId){
            getPlanStartup(teamId)
        }

    }, [getProPayments, getPlanStartup, team, teamId]);
      
    let style = {
        width: '55em',
        border: '2px solid #d5d5d6',
        color: 'rgb(187, 187, 187)',
        borderRadius: '10px',
        padding: '1em',
		textAlign: 'center',
		margin: '2em 0 0 2em',
    }

    if(props.billingType === 'team'){
        if(startupPayments){
            billingHistorySize = startupPayments.length;
            if(billingHistorySize > 0){
                style = {display: 'none'}    
            }
        }
    }else if(props.billingType === 'single'){
        if(proPayments){
            proBillingHistorySize = proPayments.length;
            if(proBillingHistorySize > 0){
                style = {display: 'none'}
            }
        }
    }    
    
    const showBilling = (bType) => {
        if(props.billingType === 'team'){
            return <BillingHistoryList  key={startupPayments} payments={startupPayments}/>
        }else {
            return <BillingHistoryList  key={proPayments} payments={proPayments}/>
        }
    }
    const showNoBilling = (bType) => {
        if(props.billingType === 'team'){
            return <div style={style}>No Payments Made</div>
        }else {
            return <div style={style}>No Payments Made</div>
        }
    }
    const spinnerStyle={
        textAlign: 'center'
     } 
    return (
        <div className="row billing-row">
            {showBilling(props.billingType)}
            {showNoBilling(props.billingType)}
			
			{ loading && ( 
				<div style={spinnerStyle}>
					<Loading /> 
				</div>
			) }
      	</div>
    );
}

const mapState = (state, props) => ({
    loading:  state.payment.loading,
    startupPayments: state.payment.proPayments,
    proPayments: state.payment.proPayment,
    team: state.team.team,
    props: props
});

const mapDispatch = { 
    getProPayments,
    getPlanStartup
};

export default connect( mapState, mapDispatch )(BillingHistory);
