import React, { useState, useEffect } from 'react';
import HabitatTopMenu from '../components/Habitats/HabitatTopMenu';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import '../assets/styles/components/HabitatAuth.scss';
import Spinner from 'react-bootstrap/Spinner';
import HabitatAuthList from '../components/Habitats/HabitatAuthList';
import HabitatAuthManagement from '../components/Habitats/HabitatAuthManagement';
import {
    listCognito,
    getUsers,
    disableUsers,
    createUserPool,
    resetPassword,
    deleteCognito,
    enableUsers
} from '../actions/aws-cognito.actions';
import HabitatAuthDeletePool from '../components/Habitats/modals/HabitatAuthDeletePool'
import HabitatAuthDeleteUser from '../components/Habitats/modals/HabitatAuthDeleteUser'
import HabitatAuthDisableUser from '../components/Habitats/modals/HabitatAuthDisableUser'
import { retrieveHabitat } from '../actions/habitat.actions';

const HabitatAuthPage = ({
    params,
    habitat,
    listCognito,
    cognitoList,
    getUsers,
    userList,
    disableUsers,
    createUserPool,
    resetPassword,
    deleteCognito,
    loading,
    loadingMessage,
    retrieveHabitat,
    messageDisable,
    resetMessage,
    enableUsers
}) => {
    useEffect(() => {
        if(habitat.id){
            listCognito(habitat.id);
        } else {
            retrieveHabitat(params.habitatId);
            listCognito(params.habitatId);
        }
    }, []);

    const [selectedCognito, setSelectedCognito] = useState('')
    const [selectedUser, setSelectedUser] = useState('')
    const [selectedCognitoName, setSelectedCognitoName] = useState('')
    const [showNewPool, setShowNewPool] = useState('display-none-pool')
    const [newCognitoName, setNewCognitoName] = useState('')
    const [showResetPass, setShowResetPass] = useState('display-none-reset')
    const [resetPass, setResetPass] = useState('')
    const [showRemoveModal, setShowRemoveModal] = useState('display-none-modal-remove')
    const [showDisableUserModal, setShowDisableModal] = useState('display-none-disable-user-modal')

    const handleSelectUser = (user) => {
        setSelectedUser(user)
    }

    const handleSelectedRadio = (e) => {
        setSelectedCognito(e.target.value);
    };

    const handleSelectPool = (cognitoName, userPoolId, habitatId) => {
        getUsers(userPoolId, habitatId)
        setSelectedCognitoName(cognitoName)
        setSelectedCognito(userPoolId);
    }

    const handleDisableUser = (userPoolId, habitatId, username) => {
        disableUsers(userPoolId, habitatId, username)
        setShowDisableModal('display-none-disable-user-modal')
    }

    const handleEnableUser = (userPoolId, habitatId, username) => {
        enableUsers(userPoolId, habitatId, username)
    }

    const handleShowDisableModal = () => {
        setShowDisableModal('')
    }

    const handleShowNewPool = () => {
        setShowNewPool('')
    }

    const handleNewPool = () => {
        createUserPool(newCognitoName, habitat.id)
        setShowNewPool('display-none-pool')
    }

    const handleShowReset = () => {
        setShowResetPass('')
    }

    const handleShowRemove = () => {
        setShowRemoveModal('')
    }

    const handleResetPassword = () => {
        resetPassword(selectedCognito, habitat.id, selectedUser.username, resetPass)
        setShowResetPass('display-none-reset')
    }

    const handleDeleteCognito = () => {
        deleteCognito(selectedCognito, habitat.id)
        setShowRemoveModal('display-none-modal-remove')
    }

    return (
        <>
            <HabitatAuthDeletePool
                showRemoveModal={showRemoveModal}
                setShowRemoveModal={setShowRemoveModal}
                handleDeleteCognito={handleDeleteCognito}
            />

            <HabitatAuthDisableUser
                showDisableUserModal={showDisableUserModal}
                selectedCognito={selectedCognito}
                selectedUser={selectedUser}
                habitat={habitat}
                setShowDisableModal={setShowDisableModal}
                handleDisableUser={handleDisableUser}
            />



            <div className="habitat-sub-menu">
                <div className="sub-menu-side-l">
                    <FontAwesomeIcon icon={faGlobe} />
                    <p>{habitat.name}</p>
                </div>
                <HabitatTopMenu
                    habitat={habitat}
                />
            </div>


            <div className="habitat-auth-container">
                <div className="habitat-auth-list-container">
                    {loading && (
                        <div className="spinner-container auth-habitat-spinner">
                            <Spinner animation="border" variant="primary" />
                            <p>{loadingMessage}</p>
                        </div>
                    )}


                    <HabitatAuthList
                        cognitoList={cognitoList}
                        handleSelectedRadio={handleSelectedRadio}
                        selectedCognito={selectedCognito}
                        handleSelectPool={handleSelectPool}
                        habitat={habitat}
                        userList={userList}
                        handleSelectUser={handleSelectUser}
                        handleShowNewPool={handleShowNewPool}
                        handleShowRemove={handleShowRemove}
                    />
                </div>

                <div className="habitat-auth-management">
                    <HabitatAuthManagement
                        selectedUser={selectedUser}
                        selectedCognito={selectedCognito}
                        selectedCognitoName={selectedCognitoName}
                        handleDisableUser={handleDisableUser}
                        habitat={habitat}
                        showNewPool={showNewPool}
                        setNewCognitoName={setNewCognitoName}
                        handleNewPool={handleNewPool}
                        setResetPass={setResetPass}
                        showResetPass={showResetPass}
                        handleShowReset={handleShowReset}
                        handleResetPassword={handleResetPassword}
                        handleShowDisableModal={handleShowDisableModal}
                        messageDisable={messageDisable}
                        resetMessage={resetMessage}
                        handleEnableUser={handleEnableUser}
                    />
                </div>
            </div>

        </>
    )
}

const mapState = (state, props) => ({
    params: props.match.params,
    habitat: state.habitat.habitat,
    cognitoList: state.cognito.cognitoList,
    userList: state.cognito.userList,
    loading: state.cognito.loading,
    loadingMessage: state.cognito.loading_message,
    messageDisable: state.cognito.messageDisable,
    resetMessage: state.cognito.reset
})

const mapDispatch = {
    listCognito,
    getUsers,
    disableUsers,
    createUserPool,
    resetPassword,
    deleteCognito,
    retrieveHabitat,
    enableUsers
}

export default connect(
    mapState,
    mapDispatch)(HabitatAuthPage);