import React, { useState } from 'react'; 
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import InvitationBox from './InvitationBox';
import '../../assets/styles/components/notifications/invitation-box.scss';

const InvitationsList = ({ 
    fetchInvitations, 
    invitation, 
    invitations,
    rejectInvite,
    acceptInvite,
    fetchTeams }) => {

    return(
        <div className="invitations-list">
            <InvitationBox 
                invitation={invitation}
                invitations={invitations}
                fetchInvitations={fetchInvitations}
                rejectInvite={rejectInvite}
                acceptInvite={acceptInvite}
                fetchTeams={fetchTeams}
            />
        </div>
    );
}

export default InvitationBox;