import produce from 'immer';
import { GET_DOMAINS_BEGIN, GET_DOMAINS_SUCCESS, GET_DOMAINS_ERROR } from '../actions/types';

const initalState = {
  domains: [],
  loading: false,
  error: null
}

const reducer = produce((draft, action) => {
  switch(action.type) {
    case GET_DOMAINS_BEGIN:
      draft.loading = true;
      draft.error = null;
      return;
    case GET_DOMAINS_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.domains = action.payload;
      return;
    case GET_DOMAINS_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return;
    default:
      return;
  }
}, initalState);

export default reducer;