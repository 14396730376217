import React from 'react';
import { Form, Spinner, Button } from 'react-bootstrap';
import '../../assets/styles/components/deployLogModal.scss';

const DeployLogModal = props => {
  return (
        <div>
            <Form.Group>
              <Form.Label>Select Invocation Type</Form.Label>
              <Form.Control
                as="select"
                defaultValue={'DEFAULT'}
                onChange={e => props.setType(e.target.value)}
              >
                <option disabled value="DEFAULT">--Select a Type--</option>
                <option value="Event">Event</option>
                <option value="RequestResponse">RequestResponse</option>
                <option value="DryRun">DryRun</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Payload</Form.Label>
              <Form.Control
                rows="3"
                as="textarea"
                value={props.payload}
                onChange={e => props.setPayload(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Response</Form.Label>
            </Form.Group>
            {props.loading ? (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            ):(
              <div>
                <div className="cotainer mt-4 mb-5">
                  <code>{props.invoke}</code>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-between">
              <Button
                variant="primary"
                disabled={!props.type}
                onClick={props.handleInvoke}
                size="sm" 
              >Invoke</Button>
              <Button
                variant="primary"
                disabled={!props.payload}
                onClick={props.handleToggle}
                size="sm" 
              >Save</Button>
            </div>
        </div>
  );
};

export default DeployLogModal;
