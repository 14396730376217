import React from 'react';
import { connect } from 'react-redux';
import HabitatBox from '../Habitats/HabitatBox';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Habitats = ({ habitats }) => {
    return (
        <div>
            <HabitatBox habitats={habitats} />
        </div>
    );
};

const mapState = (state) => ({
    habitats: state.habitat.habitats
});
const mapDispatch = {
};
export default connect(mapState, mapDispatch)(Habitats);