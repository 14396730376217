import React from 'react';
import routes from '../constants/routes.json';
import { connect } from 'react-redux';
import AppTeamLayout from './AppTeamLayout';
import { Route, Redirect } from 'react-router-dom';

// App Team Layout Route
const AppTeamLayoutRoute = ({
    component: Component,
    currentUser,
    ...rest
}) => {        
    return (
        <Route {...rest} render={matchProps => (    
            currentUser ?                
            <AppTeamLayout>
                <Component {...matchProps} />
            </AppTeamLayout>
            : <Redirect to={{ pathname: routes.SIGNIN }} />
        )} />
    )
};

const mapState = (state, props) => {    
    return {
        currentUser: state.user.user,
        location: props.location        
    };
};
export default connect(mapState)(AppTeamLayoutRoute);
  
