import React from 'react';
import { connect } from 'react-redux';

const HabitatDeployLogItem = ({
    handleShowAllLog,
    log,
    showAllLog,
    handleGetLog,
    logFunc
}) => {
    return (
        <div className="log-deploy-item" onClick={() => handleGetLog(log.log_stream_name)}>
            <div>
                <p>{log.log_stream_name}</p>
            </div>

            <div className={showAllLog + ' log-func-all'}>
                {logFunc.map((log) =>
                    <pre key={log.id}>
                       {log.message}
                    </pre>
                )}

            </div>
        </div>
    )
}

const mapState = (state, props) => ({
    log: props.log,
    handleShowAllLog: props.handleShowAllLog,
    showAllLog: props.showAllLog,
    handleGetLog: props.handleGetLog,
    logFunc: state.awsSdk.log
})

const mapDispatch = {

}

export default connect(mapState, mapDispatch)(HabitatDeployLogItem);