import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import InvitationsList from '../notifications/InvitationsList.js'
import { fetchInvitations, acceptInvite, rejectInvite } from '../../actions/invitation.actions.js'
import { fetchTeams } from '../../actions/team.actions.js'

const AlertsNotifications = ({
    fetchInvitations,
    invitation,
    invitations,
    rejectInvite,
    acceptInvite,
    fetchTeams
}) => {

    useEffect(() => {
        fetchInvitations()
    }, [fetchInvitations]);


    return (
        <div className="alerts-notifications-container">
            <h4 className="habitat-main-content main-content-left"><b>Notifications</b></h4>

            <div className="alerts-box-container">
                {invitations.length > 0 ? (
                    <div className="notifications-container">
                        <InvitationsList
                            invitations={invitations}
                            invitation={invitation}
                            fetchInvitations={fetchInvitations}
                            rejectInvite={rejectInvite}
                            acceptInvite={acceptInvite}
                            fetchTeams={fetchTeams}
                        />
                    </div>
                ) : (
                        <div className="blank-section-notifications">No new notifications yet.</div>
                    )}
            </div>
        </div>
    );
};

const mapState = (state, props) => ({
    invitation: state.invitation.invitation,
    invitations: state.invitation.invitations
});

const mapDispatch = {
    fetchInvitations,
    rejectInvite,
    acceptInvite,
    fetchTeams
}

export default connect(
    mapState,
    mapDispatch
)(AlertsNotifications);