import {
  NEW_DEPLOYMENT_BEGIN,
  NEW_DEPLOYMENT_SUCCESS,
  NEW_DEPLOYMENT_ERROR,
  ALL_DEPLOYMENTS_BEGIN,
  ALL_DEPLOYMENTS_SUCCESS,
  ALL_DEPLOYMENTS_ERROR,
  REMOVE_DEPLOYMENT_BEGIN,
  REMOVE_DEPLOYMENT_SUCCESS,
  REMOVE_DEPLOYMENT_ERROR,
} from './types';
import { deploymentModel } from '../models/deployment';
import DeploymentDB from '../db/deployment';

export const newDeployment = (habitatID, status = 'successful') => {
  const deployment = deploymentModel(habitatID);
  return (dispatch) => {
    dispatch({ type: NEW_DEPLOYMENT_BEGIN });
    // db.createDeployment(deployment, status)
    // .then(response => {
    //   db.getDeployment(response.id).then(deployResp => {
    //     dispatch({
    //       type: NEW_DEPLOYMENT_SUCCESS,
    //       payload: deployResp
    //     })
    //   })
    //   .catch(error => {
    //     dispatch({ type: NEW_DEPLOYMENT_ERROR, error });
    //   })
    // })
    // .catch(error => {
    //   dispatch({ type: NEW_DEPLOYMENT_ERROR, error });
    // })
  };
};

export const deleteDeployment = (deploymentID, rev) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_DEPLOYMENT_BEGIN });
    // db.removeDeployment(deploymentID, rev)
    // .then(response => {
    //   dispatch({
    //     type: REMOVE_DEPLOYMENT_SUCCESS,
    //     payload: response
    //   })
    // })
    // .catch(error => {
    //   dispatch({ type: REMOVE_DEPLOYMENT_ERROR, error });
    // });
  };
};

export const allDeployments = () => {
  return (dispatch) => {
    dispatch({ type: ALL_DEPLOYMENTS_BEGIN });
    // db.getAllDeployment()
    // .then(response => {
    //   dispatch({
    //     type: ALL_DEPLOYMENTS_SUCCESS,
    //     payload: response
    //   });
    // })
    // .catch(error => {
    //   dispatch({ type: ALL_DEPLOYMENTS_ERROR, error });
    // });
  };
};
