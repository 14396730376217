import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import CheckoutForm from '../billing/CheckoutForm';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { connect } from 'react-redux';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../assets/styles/components/billing/billing-checkout.scss';
import {
  getCustomer,
  paymentConfirm,
  AddSubscription,
  getCustomerCard,
  addCheckoutCard
} from '../../actions/billing.actions';
import logo from '../../assets/images/Kor-dark-logo.svg';

const CheckOutPage = ({
  getCustomerCard,
  AddSubscription,
  addCheckoutCard,
  checkout,
  loading,
  message,
  error,
  card
}) => {

  useEffect(() => {
    if (checkout) {
      getCustomerCard(checkout.team);
    }
  }, []);

  const [interval, setInterval] = useState('Monthly')
  const [toggleActive, setToggleActive] = useState(false);
  const [priceInterval, setPriceInterval] = useState('mo')
  const [priceMult, setPriceMult] = useState(1)
  const [intervalCheckout, setIntervalCheckout] = useState('monthly')

  const handleAnually = () => {
    if (toggleActive) {
      setToggleActive(false)
      setInterval('Monthly')
      setPriceInterval('mo')
      setPriceMult(1)
      setIntervalCheckout('monthly')
    } else {
      setToggleActive(true)
      setInterval('Annually')
      setPriceInterval('year')
      setPriceMult(12)
      setIntervalCheckout('yearly')
    }

  }

  return (
    <div className="main-content-billing-checkout">
      <div md={6} className="leftSettingsCol border-right">
        <div className="checkout-container-c">
          <div className="plan-container-c">
            {checkout && (
              <div className="plan-container">
                <p>{checkout.product.nameplan}</p>
                <p>{checkout.product.description}</p>

                {interval === 'Monthly' ? (

                  <p>${checkout.product.price} USD/mo</p>
                ) : (
                    <p>$9.99 USD/mo</p>
                  )}


                <p>Top Features</p>
                {checkout.product.features.map((feature) => (
                  <p key={feature.id}>
                    <FontAwesomeIcon icon={faCheck} className="color-green" />{' '}
                    {feature.feature_content}
                  </p>
                ))}

                {checkout.team.name && (
                  <p className="subtotal">Users: {checkout.product.quantity}</p>
                )}
                {interval === 'Monthly' ? (
                  <>
                    <p className="subtotal">Subtotal: ${(checkout.product.price * priceMult) * checkout.product.quantity} USD</p>
                    <p className="total-checkout">Total: ${(checkout.product.price * priceMult) * checkout.product.quantity} USD</p>
                  </>
                ) : (
                  <>
                    <p className="subtotal">Subtotal: ${(9.99 * priceMult) * checkout.product.quantity} USD</p>
                    <p className="total-checkout">Total: ${(9.99 * priceMult) * checkout.product.quantity} USD</p>
                  </>
                )}
              </div>
            )}
            <div className="switching-billing-cycle">
              <div>
                <p>Billing cycle</p>
              </div>

              <div className="switching-button">
                <p>{interval}</p>
                <BootstrapSwitchButton
                  checked={false}
                  onlabel=''
                  offlabel=''
                  onChange={handleAnually}
                />
              </div>
            </div>
          </div>
          <div className="footer-checkout">
            <img src={logo} className="app-logo" alt="logo" />
            <p>© 2020 KOR Inc. All rights reserved. KOR is a registered trademark of FluxNetwork Inc. Terms, conditions, features, availability, pricing, fees, service and support options subject change without notice.</p>
          </div>
        </div>

      </div>
      <div className="rightSettingsCol">
        <div className="container-form-checkout">
          <Card className="p-66">
            <p className="title-pay">
              <b>Select your payment method</b>
            </p>
            <CheckoutForm
              AddSubscription={AddSubscription}
              addCheckoutCard={addCheckoutCard}
              getCustomer={getCustomer}
              checkout={checkout}
              loading={loading}
              message={message}
              card={card}
              error={error}
              intervalCheckout={intervalCheckout}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  stripeCustomer: state.billing.stripeCustomer,
  nameplan: state.checkout.checkout.nameplan,
  interval: state.checkout.checkout.interval_d,
  checkout: state.billing.checkout,
  message: state.billing.message,
  payment: state.billing.payment,
  loading: state.billing.loading,
  error: state.billing.error,
  card: state.billing.card,
  history: state.history,
  user: state.user.user,
});

const mapDispatch = {
  getCustomer,
  paymentConfirm,
  AddSubscription,
  getCustomerCard,
  addCheckoutCard
};

export default connect(mapState, mapDispatch)(CheckOutPage);
