import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const HabitatDynamos = (props) => {
    return (
        <>
            <div className="new-function-btn-setup" onClick={props.handleShowDyanoManagement}>
                <p> + New DB</p>
            </div>

            {props.dynamos.length === 0 ? (
                <div className="blank-section">No Dynamos added yet.</div>
            ) : (
                    <div className="container-dynamos-box">
                        {props.dynamos.map(db => (
                            <div className="funcs-setup-box" key={db.id}>
                                <div className="box-feature-name-funcs">
                                    <div className="box-feature-name-icon"><FontAwesomeIcon icon={faDatabase} /></div>
                                    <p>{db.table_name}</p>
                                </div>

                                <div className="box-feature-item">
                                    <p><b>Create at: </b> {db.created_at}</p>
                                </div>

                                <div className="box-feature-item">
                                    <Button variant="outline-danger" size="sm" onClick={() => props.handleRemoveDynamo(db)}>Remove</Button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
        </>
    )
}

export default HabitatDynamos;