import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { listAWSCreds } from '../../actions/aws-creds.actions';
import { newDeployment, allDeployments } from '../../actions/deployment.actions';
import HabitatTemplateItem from './HabitatTemplateItem';
import { Alert, ListGroup } from 'react-bootstrap';
import { nextStepTour, stopTour } from '../../actions/app-tour.actions';
import { changeStatusOnboarding } from '../../actions/user.actions'
import AppTour from '../../components/AppTour';
import Spinner from 'react-bootstrap/Spinner';
import HabitatDeployAll from '../../components/Habitats/HabitatDeployAll';
import { deployAll } from '../../actions/funcs.actions';
import { getStaticSites } from '../../actions/templates.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

const HabitatTemplatesList = ({
  step,
  funcs,
  habitat,
  active,
  stepAppTour,
  statusAppTour,
  nextStepTour,
  stopTour,
  changeStatusOnboarding,
  loadingSites,
  loadingMessage,
  gitClones,
  onboardingClassFuncs,
  templates,
  getStaticSites,
  errorSites
}) => {

  const [depClass, setDepclass] = useState('unDeploy-ctn');
  const [noCredsAlertShow, setNoCredsAlertShow] = useState(true);


  useEffect(() => {
    handleActive();
  }, []);

  const handleActive = () => {
    if (active && step === 10) {
      setDepclass('deploy-ctn');
    }
  }

  return (
    <div className="functions-deploy-container templates-releases-container">

      {errorSites && (
        <div className="error-display text-danger">
          <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {errorSites}</p>
        </div>
      )}

      {templates.length > 0 ? (
        <div className="all-deployment-items">
          <div>
          </div>

          {loadingSites && (
            <div className="spinner-container">
              <Spinner animation="border" variant="primary" />
              <p>{loadingMessage}</p>
            </div>
          )}

          {templates && (
            <ListGroup>
              {templates.map((template, index) => (
                <HabitatTemplateItem
                  key={template.id}
                  template={template}
                  index={index}
                />
              ))}
            </ListGroup>
          )}
        </div>
      ) : (
          <div className="blank-section">No sites added yet.</div>
        )}


    </div>
  )
}

const mapState = (state, props) => ({
  habitat: state.habitat.habitat,
  active: state.user.user.active,
  funcs: state.func.funcs,
  stepAppTour: state.appTour.stepAppTour,
  statusAppTour: state.user.user.onboarding,
  loadingFunction: state.templates.loading,
  gitClones: state.gitClone.gitClones,
  onboardingClassFuncs: state.appTour.onboardingClassFuncs,
  templates: state.templates.templates,
  errorSites: state.templates.error,
  loadingSites: state.templates.loading,
  loadingMessage: state.templates.loading_message
})

const mapDispatch = {
  allDeployments,
  listAWSCreds,
  newDeployment,
  nextStepTour,
  stopTour,
  changeStatusOnboarding,
  getStaticSites
}

export default connect(
  mapState,
  mapDispatch
)(HabitatTemplatesList);