import React from 'react';

const SignUpMessage = () => {
  return (
    <>
      <div className="sign-title">
        <p className="sigtext">Thank you for your interest in KOR</p>
      </div>
      <div>
        <div className="info-ctn-message">
          <p className="m-0">
            Unfortunately, our beta user group is currently full.
          </p>
          <p className="tto">
            We will notify you when we are accepting more users. 
          </p>
        </div>
      </div>
    </>
  );
};

export default SignUpMessage;
