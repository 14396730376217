import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import Spinner from 'react-bootstrap/Spinner';
import { Form } from 'react-bootstrap';

const AwsChart = ({
  funcs,
  hours,
  awsData,
  loading,
  habitat,
  endTime,
  setHours,
  startTime,
  getAwsData,
  selectType,
  labelChart,
  handleChart,
  setStartTime,
  setLabelChart,
  selectLastHour
}) => {

  useEffect(() => {
    handleChart();
  }, []);

  return (
    <div className="overview-requests-errors">
      <div className="overview-white-box">
        <div className="metric-chart">
          <Form.Control
            className="metric-selectbox"
            as="select"
            defaultValue={labelChart}
            onChange={e => selectType(e.target.value)}
          >
            <option value="Invocations">Invocations</option>
            <option value="Errors">Errors</option>
          </Form.Control>
          <Form.Control
            className="metric-selectbox"
            as="select"
            defaultValue={hours}
            onChange={e => selectLastHour(e.target.value)}
          >
            <option value="3">Last 3 hours</option>
            <option value="12">Last 12 hours</option>
            <option value="24">Last day</option>
          </Form.Control>
        </div>
        <div className="overview-grey-box">
          {loading ? (
            <Spinner />
          ): (
            <Chart
              options={awsData.options}
              series={awsData.series}
              type="area"
              width="100%"
              height="230"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapState = (state, props) => ({
  selectLastHour: props.selectLastHour,
  setLabelChart: props.setLabelChart,
  setStartTime: props.setStartTime,
  handleChart: props.handleChart,
  selectType: props.selectType,
  labelChart: props.labelChart,
  getAwsData: props.getAwsData,
  startTime: props.startTime,
  setHours: props.setHours,
  nameFunc: props.nameFunc,
  endTime: props.endTime,
  awsData: state.awsSdk.awsMetrics,
  loading: state.awsSdk.loading,
  habitat: props.habitat,
  hours: props.hours,
  funcs: props.funcs
});


export default connect(
  mapState
)(AwsChart);
