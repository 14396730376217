import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faGitlab, faBitbucket } from '@fortawesome/free-brands-svg-icons'

const NewGitCredsModal = (props) => {

    return (
        <div className={props.showGitModal + ' modal-kor-container container-git-modal'}>
            <div className="box-modal-options-container">
                <p className="modal-instructions-1"><b>New Git Credentials</b></p>
                <Form.Control
                    aria-label="Name"
                    aria-describedby="new-name"
                    className="aws-creds-form"
                    autoComplete="off"
                    onChange={(e) => props.setName(e.target.value)}
                    type="text"
                    name="credsName"
                    placeholder="Credential Name"
                />
                <div className="buttons-container-modal-git">
                    {props.service === 'git' && (
                        <Button
                            variant="primary"
                            size='sm'
                            className="cancel-button"
                            onClick={() => props.handleGitOauth()}
                            disabled={!props.name}
                        >Authorize Github &nbsp; <FontAwesomeIcon icon={faGithub} /></Button>
                    )}
                    
                    {props.service === 'gitlab' && (
                        <Button
                            variant="primary"
                            size='sm'
                            className="cancel-button"
                            onClick={() => props.handleGitOauth()}
                            disabled={!props.name}
                        >Authorize Gitlab &nbsp; <FontAwesomeIcon icon={faGitlab} /></Button>
                    )}

                    {props.service === 'bitbucket' && (
                        <Button
                            variant="primary"
                            size='sm'
                            className="cancel-button"
                            onClick={() => props.handleGitOauth()}
                            disabled={!props.name}
                        >Authorize Bitbucket &nbsp; <FontAwesomeIcon icon={faBitbucket} /></Button>
                    )}

                    <Button
                        variant="primary"
                        size='sm'
                        className="cancel-button"
                        onClick={props.handleCloseGitModal}
                    >Cancel</Button>
                </div>
            </div>
        </div>
    )
}

export default NewGitCredsModal;