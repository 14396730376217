import produce from 'immer';

import {
  SET_AWSCRED_BEGIN,
  SET_AWSCRED_SUCCESS,
  SET_AWSCRED_ERROR,
  GET_AWS_METRICS_BEGIN,
  GET_AWS_METRICS_SUCCESS,
  GET_AWS_METRICS_ERROR,
  GET_AWS_LOGS_BEGIN,
  GET_AWS_LOGS_SUCCESS,
  GET_AWS_LOGS_ERROR,
  GET_AWS_LOG_BEGIN,
  GET_AWS_LOG_SUCCESS,
  GET_AWS_LOG_ERROR,
  GET_AWS_OVERVIEW_BEGIN,
  GET_AWS_OVERVIEW_SUCCESS,
  GET_AWS_OVERVIEW_ERROR,
  GET_AWS_ALL_LOGS_BEGIN,
  GET_ALL_AWS_LOGS_ERROR,
  GET_AWS_ALL_LOGS_SUCCESS,
  GET_IAM_USER_BEGIN,
  GET_IAM_USER_SUCCESS,
  GET_IAM_USER_ERROR,
  GET_AWS_DURATION_BEGIN, 
  GET_AWS_DURATION_SUCCESS, 
  GET_AWS_DURATION_ERROR,
  GET_AWS_MEMORY_BEGIN, 
  GET_AWS_MEMORY_SUCCESS, 
  GET_AWS_MEMORY_ERROR,
  GET_AWS_ALL_FUNC_LOGS_BEGIN, 
  GET_AWS_ALL_FUNC_LOGS_SUCCESS, 
  GET_AWS_ALL_FUNC_LOGS_ERROR,
} from '../actions/types';

const initialState = {  
  iamUser: {},
  awsMetrics: {
    series: [],
    options: {}
  },
  awsOverview: {
    series: [],
    options: {}    
  },
  awsDuration: {
    series: [],
    options: {},
    timestamps: []
  },
  awsMemory: {
    memorySize: [],
    memoryUsed: []
  },
  loading: false,
  logLoading: false,
  loading_message: '',
  error: null,
  logs: {
    credentials: [],
    function: [],
    logStreams: []
  },
  log: [],
  allLogs: {
    logs: []
  },
  logFunc: []
};

function remove(array, key, value) {
    const index = array.findIndex(obj => obj[key] === value);
    return index >= 0 ? [
        ...array.slice(0, index),
        ...array.slice(index + 1)
    ] : array;
}

const reducer = produce((draft, action) => {
  switch (action.type) {    
    case GET_IAM_USER_BEGIN:    
    case SET_AWSCRED_BEGIN:
    case GET_AWS_LOGS_BEGIN:
    case GET_AWS_METRICS_BEGIN:
    case GET_AWS_OVERVIEW_BEGIN:
    case GET_AWS_DURATION_BEGIN:
    case GET_AWS_MEMORY_BEGIN:
    case GET_AWS_ALL_FUNC_LOGS_BEGIN:
      draft.loading = true;
      draft.error = null;
      draft.loading_message = action.loading_message;
      return;
    case GET_AWS_LOG_BEGIN:
      draft.logLoading = true;
      draft.error = null;
      return;
    case GET_IAM_USER_SUCCESS:
      draft.iamUser = action.payload;
      return;    
    case GET_AWS_METRICS_SUCCESS:
      draft.loading = false;
      draft.awsMetrics = action.payload;
      draft.error = null;
      return;
    case SET_AWSCRED_SUCCESS:
      draft.loading = false;
      draft.error = null;
      return;
    case GET_AWS_LOG_SUCCESS:
      draft.logLoading = false;
      draft.log = action.payload;
      draft.error = null;
      return;
    case GET_AWS_LOGS_SUCCESS:
      draft.loading = false;
      draft.logs = action.payload;
      draft.error = null;
      return;
    case GET_AWS_OVERVIEW_SUCCESS:
      draft.loading = false;
      draft.awsOverview = action.payload;
      draft.error = null;
      return;
    case GET_AWS_DURATION_SUCCESS:
      draft.loading = false;
      draft.awsDuration = action.payload;
      draft.error =  null;
      return;
    case GET_AWS_MEMORY_SUCCESS:
      draft.loading = false;
      draft.awsMemory = action.payload;
      draft.error =  null;
      return;
    case GET_AWS_ALL_LOGS_SUCCESS:
      draft.logLoading = false;
      draft.allLogs = action.payload;
      draft.error = null;
      return;
    case GET_AWS_ALL_FUNC_LOGS_SUCCESS:
      draft.logLoading = false;
      draft.logFunc = action.payload;
      return;
    case SET_AWSCRED_ERROR:
    case GET_IAM_USER_ERROR:
    case GET_AWS_LOGS_ERROR:
    case GET_AWS_METRICS_ERROR:
    case GET_AWS_OVERVIEW_ERROR:
    case GET_AWS_DURATION_ERROR:
    case GET_AWS_MEMORY_ERROR:
    case GET_ALL_AWS_LOGS_ERROR: 
    case GET_AWS_ALL_FUNC_LOGS_ERROR:

      draft.logLoading = false;
      draft.error = action.error;
      return;
    default:
  }
}, initialState);

export default reducer;
