import React from 'react';
import { InputGroup, FormControl, Button, Row, Col } from 'react-bootstrap';
import '../../assets/styles/components/ResetPassword.scss'

const ChangePassword = ({
  password, 
  setPassword, 
  handleReset,
  confirmPassword,
  setConfirmPassword
}) => {
  return (
    <>
      <div className='d-flex justify-content-center mt-5'>
        <h5 className='text-center'>Reset Password</h5>
      </div>
      <Row className='mb-5'>
        <Col className='p-5 ml-5 d-flex justify-content-center' sm={12}>
          <div className='ctn-form'>
            <h6 className='font-weight-bold'>Write your new password account</h6>
            <div className='borderLeft'></div>
            <InputGroup size='sm' className='mb-3 mt-4'>
              <FormControl 
                aria-label='Small' 
                aria-describedby='inputGroup-sizing-sm' 
                placeholder='New password'
                type='password'
                value={password}
                onChange={event => setPassword(event.target.value)}
              />
            </InputGroup>
            <InputGroup size='sm' className='mb-3 mt-4'>
              <FormControl 
                aria-label='Small' 
                aria-describedby='inputGroup-sizing-sm' 
                placeholder='Confirm password'
                type='password'
                value={confirmPassword}
                onChange={event => setConfirmPassword(event.target.value)}
              />
            </InputGroup>
          </div>
        </Col>
        <Col sm={12}>
          <div className='mt-4 ml-5 d-flex justify-content-center pl-5 pr-5'>
            <Button 
              variant="primary" 
              size='sm' 
              onClick={handleReset} 
              disabled={!password || !confirmPassword || password !== confirmPassword}
            >Send</Button>
          </div>
          <div className='mt-4 ml-5 d-flex justify-content-center pl-5 pr-5'>
            {password && confirmPassword && password !== confirmPassword && (
              <div className="alert alert-danger" role="alert">
                Password does not match please verify.
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ChangePassword;