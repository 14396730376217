import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'


const HabitatBox = ({ habitats }) => {
    return (
        <tbody>
            {habitats.length > 0 ? (
                <>
                    {habitats.map(habitat => (
                        <tr key={habitat.id}>
                            <td className="first-col">{habitat.name}</td>
                            <td>Web App</td>
                            <td>12,056</td>
                            <td>12</td>
                            <td>8</td>
                            <td>5</td>
                            <td>$465.00</td>
                        </tr>
                    ))}
                </>
            ) : (
                <div className="blank-section">No habitats added yet.</div>
            )}
        </tbody>
    );
}

const mapState = (state) => ({
    habitats: state.habitat.habitats
});
const mapDispatch = {
};
export default connect(mapState, mapDispatch)(HabitatBox);
