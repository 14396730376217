import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFileCode,
    faFill
} from '@fortawesome/free-solid-svg-icons';

const HabitatS3 = (props) => {
    return (
        <div>
            {props.habitatSetUp === 's3' ? (
                <div className={props.habitatSetUp + '-selected box-feature'} onClick={() => props.handleSelectFeature('s3')}>
                    <div className="box-feature-name">
                        <div className="box-feature-name-icon-s3"><FontAwesomeIcon icon={faFill} /></div>
                        <p>S3 Buckets</p>
                    </div>

                    <div className="box-feature-plus-1">
                        <p>S3 Buckets</p>
                        <p>{props.awsS3.length}</p>
                    </div>

                    <div className="box-feature-plus">
                        <p>Last Update</p>
                        <p>2020-08-14T16:41:59.400Z</p>
                    </div>
                </div>
            ) : (
                    <div className="box-feature" onClick={() => props.handleSelectFeature('s3')}>
                        <div className="box-feature-name">
                            <div className="box-feature-name-icon-s3"><FontAwesomeIcon icon={faFill} /></div>
                            <p>S3 Buckets</p>
                        </div>

                        <div className="box-feature-plus-1">
                            <p>S3 Buckets</p>
                            <p>{props.awsS3.length}</p>
                        </div>

                        <div className="box-feature-plus">
                            <p>Last Update</p>
                            <p>2020-08-14T16:41:59.400Z</p>
                        </div>
                    </div>
                )}
        </div>
    )
}

export default HabitatS3;