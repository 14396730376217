import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { Form, Button } from 'react-bootstrap';
import HabitatFrontChart from './HabitatFrontChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const HabitatCloudFrontMetrics = ({
  error,
  habitat,
  templates,
  frontMetrics,
  getFrontMetrics
}) => {
  const [ name, setName ]           = useState('');
  const [ endTime, setEndTime ]     = useState(Moment().format());
  const [ startTime, setStartTime ] = useState(Moment().subtract(1, 'months').format());
  
  useEffect(() => {
    if (templates.length > 0) {
      setName(templates[0].cloudfront_distribution_id);
      getFrontMetrics(habitat.id, startTime, endTime, templates[0].cloudfront_distribution_id);
    }
  }, []);

  const handleGetMetrics = () => {
    getFrontMetrics(habitat.id, startTime, endTime, name);
  }
  

  return (
    <>
      <div className="logs-menu">
        <Form.Control
          className="logs-selectbox"
          as="select"
          value={name}
          onChange={e => setName(e.target.value)}
        >
          <option value="all-funcs">Sites</option>
          {templates.map(site => (
            <option key={site.id} value={site.cloudfront_distribution_id}>{site.name}</option>
          ))}
        </Form.Control>

        <Form.Control
          type='date'
          className="logs-selectbox"
          onChange={s => setStartTime(Moment(s.target.value).format())}
        />

        <Form.Control
          type='date'
          className="logs-selectbox"
          onChange={e => setEndTime(Moment(e.target.value).format())}
        />

        <Button className="logs-selectbox" disabled={!startTime || !endTime || !name} onClick={handleGetMetrics}>Search</Button>
      </div>

      {error && (
        <div className="text-danger">
          <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
        </div>
      )}
      
      {frontMetrics && (
        <HabitatFrontChart 
          frontMetrics={frontMetrics}
        />
      )}
    </>
  );
}

export default HabitatCloudFrontMetrics;