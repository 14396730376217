import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import {
    fetchInvitations,
    acceptInvite,
    rejectInvite
} from '../actions/invitation.actions';
import { NavLink, withRouter } from 'react-router-dom';
import '../assets/styles/pages/overview.scss';
import '../assets/styles/components/overview-habitats.scss';
import '../assets/styles/components/overview-functions.scss';
import '../assets/styles/components/overview-request-errors.scss';
import '../assets/styles/components/overview-alerts-notifications.scss';
import routes from '../constants/routes';
import Habitats from '../components/overview/Habitats.js';
import Functions from '../components/overview/Funcs.js';
import RequestErrors from '../components/overview/RequestErrors.js';
import AlertsNotifications from '../components/overview/AlertsNotifications.js';
import { getAwsOverview } from '../actions/aws-sdk.actions';
import { fetchAllFuncs } from '../actions/funcs.actions';
import { Row, Col } from 'react-bootstrap';
import AppTour from '../components/AppTour';
import { nextStepTour, stopTour } from '../actions/app-tour.actions';
import { changeStatusOnboarding } from '../actions/user.actions';
import { listHabitats, newHabitatWithCreds } from '../actions/habitat.actions';
import { listFunctions } from '../actions/funcs.actions';
import { getClones, cloneGit } from '../actions/git-clone.actions'
import rowRight from '../assets/images/icons/overview/arrow-right.svg';
import reload from '../assets/images/icons/overview/load.svg'
import { Modal, Button, Form } from 'react-bootstrap';
import plus from '../assets/images/icons/overview/plus.svg';
import CreateOverviewModal from '../components/Modals/CreateOverviewModal';
import { getUserAWSCreds } from '../actions/aws-creds.actions.js';
import { listGitCreds } from '../actions/git-creds.actions';
import Magnet from '../assets/images/magnet.svg';

const Overview = withRouter(({
    loading,
    awsOverview,
    classNavbar,
    getAwsOverview,
    fetchInvitations,
    team,
    listHabitats,
    habitats,
    listFunctions,
    awsOverviewOptions,
    fetchAllFuncs,
    allFuncs,
    gitClones,
    getClones,
    getUserAWSCreds,
    awsCreds,
    newHabitatWithCreds,
    listGitCreds,
    gitCreds,
    history,
    cloneGit,
    countFuncs
}) => {
    useEffect(() => {
        fetchAllFuncs();
        fetchInvitations();
        listHabitats(team);
        listFunctions(team)
        getAwsOverview(metricName, period, startTime, endTime);
        getClones(team);
        getUserAWSCreds();
        listGitCreds();
    }, [team]);

    const [hours, setHours] = useState(3);
    const [show, setShow] = useState(false);
    const [repoUrl, setRepoUrl] = useState('');
    const [gitCred, setGitCred] = useState('');
    const [repoName, setRepoName] = useState('');
    const [selectedItem, setSelectedItem] = useState('overview');
    const [awsCred, setAwsCred] = useState();
    const [classReload, setClassReload] = useState('');
    const [endTime, setEndTime] = useState(Moment().format())
    let startTime = Moment()
        .subtract(hours, 'hours')
        .format();
    const [period] = useState(300);
    const [metricName, setMetricName] = useState('errors');
    const data = {
        uri: 'https://github.com/Clemenshemmerling/reactapp.git',
        project_name: 'reactapp'
    }

    const handlePrintDate = (date) => {
        setEndTime(date)
        getAwsOverview(metricName, period, startTime, endTime);
    }

    const handleCreateHabitatWithCreds = () => {
        newHabitatWithCreds(awsCred, team.id)
        history.push(`${routes.HABITATS}`)
    }

    const handleClone = () => {
        cloneGit(repoUrl, repoName, gitCred, team.id);
        setShow(false);
        setRepoUrl('');
        setRepoName('');
        setGitCred('');
        history.push(`${routes.REPOSITORIES}`)
    }

    const handleReloadRepos = () => {
        setClassReload('animate__animated animate__rotateIn');
        getClones(team)
        if(classReload === ''){
            setClassReload('animate__animated animate__rotateIn');
        }else{
            setClassReload('');
        }
    }

    return (
        <div className='main-content-app'>
            <CreateOverviewModal
                show={show}
                setShow={setShow}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                awsCreds={awsCreds}
                setAwsCred={setAwsCred}
                handleCreateHabitatWithCreds={handleCreateHabitatWithCreds}
                repoUrl={repoUrl}
                repoName={repoName}
                setGitCred={setGitCred}
                setRepoUrl={setRepoUrl}
                setRepoName={setRepoName}
                handleClone={handleClone}
                gitCreds={gitCreds}
            />
            <div className='init-ctn title-section-ui'>
                <h2><b>Overview</b></h2>
                <div className="calendar-create">
                    <p>Date</p>
                    <Form.Control
                        type='date'
                        className="logs-selectbox"
                        onChange={e => handlePrintDate(Moment(e.target.value).format())}
                    />
                    <Button onClick={() => setShow(true)}>
                        <img src={plus} /> Create
                    </Button>
                </div>
            </div>
            <div className='overview-container'>
                {habitats.length > 0 ? (
                    <div className='overview-data'>
                        <div className='title-subsection-overview'>
                            <div className='arrow-image-container'>
                                <p>All habitats</p>
                                <div className='arrow-image'>
                                    <NavLink exact to='/Habitats'>
                                        <img src={rowRight} />
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className='overview-data-section'>
                            <div className='metric-data-container'>
                                <div>
                                    <p>{habitats.length}</p>
                                </div>
                                <p>Habitats</p>
                            </div>
                            <div className='metric-data-container'>
                                <div>
                                    <p>0</p>
                                </div>
                                <p>Requests</p>
                            </div>
                            <div className='metric-data-container'>
                                <div>
                                    <p>{awsCreds.length}</p>
                                </div>
                                <p>AWS Creds</p>
                            </div>
                            <div className='metric-data-container'>
                                <div>
                                    <p>{countFuncs}</p>
                                </div>
                                <p>Functions</p>
                            </div>
                            <div className='metric-data-container'>
                                <div>
                                    <p>{gitClones.length}</p>
                                </div>
                                <p>Repositories</p>
                            </div>
                        </div>

                        <div className="graphs-overview">
                            <RequestErrors
                                awsOverview={awsOverview}
                                awsOverviewOptions={awsOverviewOptions}
                            />
                            <div className="table-graph">
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="first-col">Habitat Name</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Cred Name</th>
                                            <th scope="col">Created At</th>
                                            <th scope="col">Last Update</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <>
                                            {habitats.map(habitat => (
                                                <tr key={habitat.id}>
                                                    <td className="first-col">{habitat.name}</td>
                                                    <td>Habitat</td>
                                                    <td>{habitat.cred_name}</td>
                                                    <td>{Moment(habitat.created_at).format('ll')}</td>
                                                    <td>{Moment(habitat.updated_at).format('ll')}</td>
                                                </tr>
                                            ))}
                                        </>
                                    </tbody>

                                </table>
                            </div>
                        </div>

                    </div>
                ) : (
                    <div className='overview-data'>
                        <div className='title-subsection-overview'>
                            <div className='arrow-image-container'>
                                <p>All habitats</p>
                                <div className='arrow-image'>
                                    <NavLink exact to='/Habitats'>
                                        <img src={rowRight} />
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="blank-section-repos">
                            <p>Create a Habitat to get started</p>
                            <Button>
                                <NavLink exact to='/Habitats'>
                                    <img src={plus} className="mr-2" />
                                        Create habitat
                                    </NavLink>
                            </Button>
                        </div>
                    </div>
                )}



                <div className='overview-repostories-section'>
                    <div className='title-subsection-overview'>
                        <div className='arrow-image-container'>
                            <p>Repositories</p>
                            <div className='arrow-image'>
                                <NavLink exact to='/Repositories'>
                                    <img src={rowRight} />
                                </NavLink>
                            </div>
                        </div>

                        <div className="reload-icon" onClick={() => handleReloadRepos()}>
                            <img src={reload} className={classReload} />
                        </div>
                    </div>

                    <div className="functions-container-overview">
                        {(gitClones.length > 0) ? (
                            <Functions
                                gitClones={gitClones}
                            />
                        ) : (
                            <div className="blank-section-repos">
                                <p>Add a repository to get started</p>
                                <Button>
                                    <NavLink exact to='/Repositories'>
                                        <img src={Magnet} className="mr-2" />
                                        Clone Repository
                                    </NavLink>
                                </Button>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    )
})

const mapState = (state) => ({
    currentUser: state.user.user,
    loading: state.awsSdk.loading,
    classNavbar: state.navbar.class,
    awsOverview: state.awsSdk.awsOverview,
    stepAppTour: state.appTour.stepAppTour,
    statusAppTour: state.user.user.onboarding,
    team: state.team.team,
    habitats: state.habitat.habitats,
    awsOverviewOptions: state.awsSdk.awsOverview.options,
    allFuncs: state.func.allFuncs,
    countFuncs: state.func.count,
    gitClones: state.gitClone.gitClones,
    awsCreds: state.awsCreds.userCredentials.aws_credentials,
    gitCreds: state.gitCreds.gitCredentials.git_credentials
});

const mapDispatch = {
    fetchInvitations,
    getAwsOverview,
    acceptInvite,
    rejectInvite,
    nextStepTour,
    stopTour,
    changeStatusOnboarding,
    listHabitats,
    listFunctions,
    fetchAllFuncs,
    getClones,
    getUserAWSCreds,
    newHabitatWithCreds,
    listGitCreds,
    cloneGit
};

export default connect(
    mapState,
    mapDispatch
)(Overview);