import {
  GET_STEP_FUNCTION_BEGIN,
  GET_STEP_FUNCTION_SUCCESS,
  GET_STEP_FUNCTION_ERROR,
  CREATE_STEP_FUNCTION_BEGIN,
  CREATE_STEP_FUNCTION_SUCCESS,
  CREATE_STEP_FUNCTION_ERROR,
  UPDATE_STEP_FUNCTION_BEGIN,
  UPDATE_STEP_FUNCTION_SUCCESS,
  UPDATE_STEP_FUNCTION_ERROR,
  DELETE_STEP_FUNCTION_BEGIN,
  DELETE_STEP_FUNCTION_SUCCESS,
  DELETE_STEP_FUNCTION_ERROR,
} from './types';
import {
  getAllStepFunc,
  createStepFunc,
  updateStepFunc,
  deleteStepFunc,
} from '../api/stepFunc';

export const getStepFunc = (habitat) => {
  return (dispatch) => {
    dispatch({
      type: GET_STEP_FUNCTION_BEGIN,
      loading_message: 'Loading step functions...',
    });
    getAllStepFunc(habitat)
      .then((response) => {
        dispatch({
          type: GET_STEP_FUNCTION_SUCCESS,
          payload: { stateMachines: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_STEP_FUNCTION_ERROR,
          errorGetStepFunc: 'Get step functions fail',
          error,
        });
      });
  };
};

export const stepFuncAdd = (name, definition, role_arn, habitat_id) => {
  const myJSONString = JSON.stringify(definition);
  let scape = myJSONString
    .replace(/\\n/g, '\\n')
    .replace(/\\'/g, "\\'")
    .replace(/\\"/g, '\\"')
    .replace(/\\&/g, '\\&')
    .replace(/\\r/g, '\\r')
    .replace(/\\t/g, '\\t')
    .replace(/\\b/g, '\\b')
    .replace(/\\f/g, '\\f');
  const data = {
    name: name.replace(/[^A-Z0-9]/gi, '-'),
    definition: scape,
    role_arn,
    habitat_id,
  };
  return (dispatch) => {
    dispatch({
      type: CREATE_STEP_FUNCTION_BEGIN,
      loading_message: 'Creating new step function...',
    });
    createStepFunc(data)
      .then((response) => {
        dispatch({
          type: CREATE_STEP_FUNCTION_SUCCESS,
          payload: { stateMachines: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_STEP_FUNCTION_ERROR,
          errorAddStepFunc: 'Create step function fail',
          error,
        });
      });
  };
};

export const editStepFunc = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_STEP_FUNCTION_BEGIN,
      loading_message: 'Updating step function...',
    });
    updateStepFunc(data)
      .then((response) => {
        dispatch({
          type: UPDATE_STEP_FUNCTION_SUCCESS,
          payload: { stateMachines: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_STEP_FUNCTION_ERROR,
          errorEditStepFunc: 'Update step function fail',
          error,
        });
      });
  };
};

export const removeStepFunc = (data) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_STEP_FUNCTION_BEGIN,
      loading_message: 'Deleting step function...',
    });
    deleteStepFunc(data)
      .then((response) => {
        dispatch({
          type: DELETE_STEP_FUNCTION_SUCCESS,
          payload: { stateMachines: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_STEP_FUNCTION_ERROR,
          errorRemoveStep: 'Delete step function fail',
          error,
        });
      });
  };
};
