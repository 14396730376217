import React from 'react';
import '../../assets/styles/components/UserInfo.scss';
import AvatarBig from '../avatar/AvatarBig';
import { Container } from 'react-bootstrap';

const UserInfo = (props) => {
  return (
    <div>
      <Container>
        <div className='fig'>
          <div className='d-flex justify-content-center border mt-5 mb-5 shadow-sm rounded'>
            <div className='p-2 marginFig text-center'>
              <AvatarBig className='border' userName={props.name} avatarClass="avatarBigPicTop" />
              <div className='name-user-space'>
                <span>{props.name}</span>
              </div>
              <div className='name-user-space'>
                <span>{props.currentUser.email}</span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default UserInfo;
