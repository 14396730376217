import React, { useState } from 'react';
import {useStripe, useElements, CardNumberElement} from '@stripe/react-stripe-js';
import { Row, Col } from 'react-bootstrap';
import CardSection from './CardSection';
import { addCustomerCard }from '../../actions/billing.actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import routes from '../../constants/routes';

const CardSetupForm = withRouter(({user, team, setShow, history, setToast, addCustomerCard}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [name, setName]       = useState('');
  const [address, setAddress] = useState('');

  const handleSubmit = async event => {
    event.preventDefault();
    const card = elements.getElement(CardNumberElement);
    addCustomerCard(stripe, card, team, name, address);
    setShow(false);
    setToast(true);
    setName('');
    setAddress('');
  };

  const handleClear = () => {
    history.push(routes.BILLING)
  };

  return (
    <Row>
      <Col xs={12}>
        <CardSection 
          setName={setName}
          setAddress={setAddress}
        />
      </Col>
      <Col xs={12}>
        <div className="d-flex justify-content-center">
          <button className="m-2" onClick={handleClear}>Cancel</button>
          <button className="m-2" disabled={!stripe || !elements} onClick={(event) => handleSubmit(event)}>Add Card</button>
        </div>
      </Col>
    </Row>
  );
});


const mapState = (state, props) => ({
  user: state.user.user,
  history: state.history
});

const mapDispatch = {
  addCustomerCard
}

export default connect(
  mapState,
  mapDispatch
)(CardSetupForm);