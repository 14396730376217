import React, { useState } from 'react';
import GraphErrorsRequest from '../graphs/graphsErrorsRequest.js';

const RequestErrors = (props) => {

    const [graphsErrorsRequest, setGraphErrorsRequest] = useState(
        {
            graphName: ''
        }
    );


    return (
        <div className="request-errors-container">
            <GraphErrorsRequest
                graphsErrorsRequest={graphsErrorsRequest}
                setGraphErrorsRequest={setGraphErrorsRequest}
                awsOverview={props.awsOverview}
            />

        </div >
    );
};

export default RequestErrors;