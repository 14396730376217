import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

export const createApiGateway = (apiName, habitatId) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    const data = {
        name: apiName,
        description: null,
        template: false,
        version: '1',
        habitat_id: habitatId
    }
    return axios.post(`${URL}/aws/aws_api_gateway`, data , { headers: authToken })
}


export const authorizeCognito = (habitatId, cognitoArn, apiId) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    const data = {
        habitat_id: habitatId,
        cognito_arn: cognitoArn
    }
    return axios.post(`${URL}/aws/authorize_api_gateway/${apiId}`, data , { headers: authToken })
}

export const createResource = (apiId, habitatId, pathPart) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    const data = {
        id: apiId,
        habitat_id: habitatId,
        path_part: pathPart
    }
    return axios.post(`${URL}/aws/create_resource/`, data , { headers: authToken })
}

export const createMethod = (apiId, data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/api_gateway_create_method/${apiId}`, data , { headers: authToken })
}

export const deployApi = (apiId, habitatId, stageName, stageDescription) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    const data = {
        id: apiId,
        habitat_id: habitatId,
        stage_name: stageName,
        stage_description: stageDescription
    }
    return axios.post(`${URL}/aws/depoy_api_gateway/`, data , { headers: authToken });
}

export const listApi = (habitatId) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.get(`${URL}/aws/aws_api_gateway/?habitat_id=${habitatId}` , { headers: authToken });
}

export const listMethods = (id, habitatId) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.get(`${URL}/aws/api_gateway_index_methods/${id}?habitat_id=${habitatId}` , { headers: authToken });
}

export const listResources = (id, habitatId) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.get(`${URL}/aws/api_gateway_index_resources/${id}?habitat_id=${habitatId}` , { headers: authToken });
}

export const removeApi = (habitatId, apiId) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.delete(`${URL}/aws/aws_api_gateway/${apiId}?habitat_id=${habitatId}` , { headers: authToken });
}

export const createRespose = (methodId, data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/method_responses/${methodId}`, data, { headers: authToken });
}

export const testApiGateway = (apiId, data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/test_api/${apiId}`, data, { headers: authToken });
}

export const removeMethod = (id, habitat_id) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.delete(`${URL}/aws/api_gateway_delete_method/${id}?habitat_id=${habitat_id}` , { headers: authToken });
}

export const getcloudFrontMetrics = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/aws_metrics/cloudfront`, data, { headers: authToken });  
}

export const getAPIGatewayMetrics = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/aws_metrics/aws_api_gateway`, data, { headers: authToken });  
}