import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { Controlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/base16-light.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import {
  deleteDeployment,
  allDeployments,
} from '../../actions/deployment.actions';
import { cloudFrontStatus } from '../../actions/templates.actions';
import { getAwsLogs, getAwsLog } from '../../actions/aws-sdk.actions';
import {
  updateFunction,
  funcInvoke,
} from '../../actions/funcs.actions';
import { withRouter } from 'react-router-dom';
import { nextStepTour, stopTour } from '../../actions/app-tour.actions';
import routes from '../../constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode, faEye, faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  siteStatus,
  updateStatic,
  deployStatic,
  buildAndUpdate,
  buildAndDeployStatic,
  fetchInvalidations,
  newInvalidation,
  getValidation
} from '../../actions/templates.actions';
import { fetchAWSRole } from '../../actions/aws-roles.actions';
require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');

const HabitatTemplateItem = withRouter(
  ({
    log,
    func,
    team,
    index,
    habitat,
    history,
    awsRoles,
    template,
    gitClones,
    siteStatus,
    funcInvoke,
    stepAppTour,
    deployStatic,
    updateStatic,
    fetchAWSRole,
    nextStepTour,
    buildAndUpdate,
    updateFunction,
    buildAndDeployStatic,
    templates,
    errorSites,
    cloudFrontStatus,
    fetchInvalidations,
    invalidationsArray,
    newInvalidation,
    getValidation,
    invalidationItem
  }) => {
    const [code, setCode] = useState(
      'version: 0.2\r\n\r\n#env:\r\n  #variables:\r\n     # key: \"value\"\r\n     # key: \"value\"\r\n  #parameter-store:\r\n     # key: \"value\"\r\n     # key: \"value\"\r\n  #secrets-manager:\r\n     # key: secret-id:json-key:version-stage:version-id\r\n     # key: secret-id:json-key:version-stage:version-id\r\n  #exported-variables:\r\n     # - variable\r\n     # - variable\r\n  #git-credential-helper: yes\r\n#batch:\r\n  #fast-fail: true\r\n  #build-list:\r\n  #build-matrix:\r\n  #build-graph:\r\nphases:\r\n  #install:\r\n    #If you use the Ubuntu standard image 2.0 or later, you must specify runtime-versions.\r\n    #If you specify runtime-versions and use an image other than Ubuntu standard image 2.0, the build fails.\r\n    #runtime-versions:\r\n      # name: version\r\n      # name: version\r\n    #commands:\r\n      # - command\r\n      # - command\r\n  #pre_build:\r\n    #commands:\r\n      # - command\r\n      # - command\r\n  build:\r\n    commands:\r\n      # - command\r\n      # - command\r\n  #post_build:\r\n    #commands:\r\n      # - command\r\n      # - command\r\n#reports:\r\n  #report-name-or-arn:\r\n    #files:\r\n      # - location\r\n      # - location\r\n    #base-directory: location\r\n    #discard-paths: yes\r\n    #file-format: JunitXml | CucumberJson\r\n#artifacts:\r\n  #files:\r\n    # - location\r\n    # - location\r\n  #name: $(date +%Y-%m-%d)\r\n  #discard-paths: yes\r\n  #base-directory: location\r\n#cache:\r\n  #paths:\r\n    # - paths'
    );

    const initialCode = 'version: 0.2\r\n\r\n#env:\r\n  #variables:\r\n     # key: \"value\"\r\n     # key: \"value\"\r\n  #parameter-store:\r\n     # key: \"value\"\r\n     # key: \"value\"\r\n  #secrets-manager:\r\n     # key: secret-id:json-key:version-stage:version-id\r\n     # key: secret-id:json-key:version-stage:version-id\r\n  #exported-variables:\r\n     # - variable\r\n     # - variable\r\n  #git-credential-helper: yes\r\n#batch:\r\n  #fast-fail: true\r\n  #build-list:\r\n  #build-matrix:\r\n  #build-graph:\r\nphases:\r\n  #install:\r\n    #If you use the Ubuntu standard image 2.0 or later, you must specify runtime-versions.\r\n    #If you specify runtime-versions and use an image other than Ubuntu standard image 2.0, the build fails.\r\n    #runtime-versions:\r\n      # name: version\r\n      # name: version\r\n    #commands:\r\n      # - command\r\n      # - command\r\n  #pre_build:\r\n    #commands:\r\n      # - command\r\n      # - command\r\n  build:\r\n    commands:\r\n      # - command\r\n      # - command\r\n  #post_build:\r\n    #commands:\r\n      # - command\r\n      # - command\r\n#reports:\r\n  #report-name-or-arn:\r\n    #files:\r\n      # - location\r\n      # - location\r\n    #base-directory: location\r\n    #discard-paths: yes\r\n    #file-format: JunitXml | CucumberJson\r\n#artifacts:\r\n  #files:\r\n    # - location\r\n    # - location\r\n  #name: $(date +%Y-%m-%d)\r\n  #discard-paths: yes\r\n  #base-directory: location\r\n#cache:\r\n  #paths:\r\n    # - paths'

    const html = '{code}'
    const [show, setShow] = useState(false);
    const [type, setType] = useState('');
    const [build, setBuild] = useState(false);
    const [payload, setPayload] = useState('');
    const [showInfo, setShowInfo] = useState('info-func-deploy');
    const [showCode, setShowCode] = useState('opacity-display');
    const [ownBuild, setOwnBuild] = useState(false);
    const [checked, setChecked] = useState(false);
    const [invalidations, setInvalidations] = useState('');
    const [showInvalidations, setShowInvalidations] = useState('display-none');
    const [siteId, setSiteId] = useState(null);

    const handleDeploy = (template) => {
      deployStatic(template, team.id);
      if (stepAppTour === 14) {
        nextStepTour();
        history.push(routes.OVERVIEW);
      }
    };

    const handleFetchInvalidations = (template_id) => {
      fetchInvalidations(habitat.id, template.id)
      if (showInvalidations === 'display-none') {
        setShowInvalidations('')
        setSiteId(template_id)
      } else {
        setShowInvalidations('display-none')
      }
    }

    const handleNewInvalidation = () => {
      newInvalidation(habitat.id, template.id, invalidations)
      setInvalidations('')
    }

    const handleShowInvalidation = (invalidation_id) => {
      getValidation(habitat.id, invalidation_id, template.id)
    }

    const handleCloudFrontStatus = (distribution_status) => {
      cloudFrontStatus(habitat.id, template.id, distribution_status);
    }

    const handleDeploySite = () => {
      ownBuild ?
        buildAndDeployStatic(awsRoles, template, null, habitat.id, team.id)
        :
        buildAndDeployStatic(awsRoles, template, code, habitat.id, team.id)
    };

    const handleShowInfoFunc = (template) => {
      if (showInfo === 'info-func-deploy') {
        setShowInfo('info-func-deploy-show');
        setShowCode('opacity-display-show');
      } else {
        setShowInfo('info-func-deploy');
        setShowCode('opacity-display');
      }

      if (template.buildspec_source) {
        setCode(template.buildspec_source);
      }

    };

    useEffect(() => {
      fetchAWSRole(habitat.id);
    }, []);

    return (
      <div>
        <div className="box-func-deploy box-feature" key={template.id}>
          <div className="box-feature-name" onClick={() => handleShowInfoFunc(template)}>
            <div className="box-feature-name-container">
              <div className="box-feature-name-icon-templates">
                <FontAwesomeIcon icon={faFileCode} />
              </div>
              <div className="func-name-date-deploy">
                <p>
                  <span>
                    <b>{template.name}</b>
                  </span>
                </p>
                <p>
                  <span className="date-color">{ }</span>
                </p>
              </div>
            </div>
            <div className="runtime-deploy">
              {gitClones.map(clone => (
                clone.project_name === template.name &&
                clone.git_hash === template.git_hash &&
                template.cicd_enable && (
                  <Button
                    key={clone.id}
                    variant="outline-primary"
                    size="sm"
                    onClick={handleDeploySite}
                  >
                    Deploy
                  </Button>
                )
              ))}

              {gitClones.map(clone => (
                clone.project_name === template.name &&
                clone.git_hash === template.git_hash &&
                !template.cicd_enable && (
                  <Button
                    key={clone.id}
                    variant="outline-primary"
                    size="sm"
                    onClick={() => handleDeploy(template)}
                  >
                    Deploy
                  </Button>
                )
              ))}

              {gitClones.map(clone => (
                clone.project_name === template.name &&
                clone.git_hash !== template.git_hash &&
                template.cicd_enable && template.build_id && (
                  <Button
                    key={clone.id}
                    variant="outline-warning"
                    size="sm"
                    onClick={() => buildAndUpdate(template, habitat.id, clone.git_hash, team.id)}
                  >
                    Update
                  </Button>
                )
              ))}


              {gitClones.map(clone => (
                clone.project_name === template.name &&
                clone.git_hash !== template.git_hash &&
                template.cicd_enable && !template.build_id && (
                  <Button
                    key={clone.id}
                    variant="outline-warning"
                    size="sm"
                    onClick={handleDeploySite}
                  >
                    Update
                  </Button>
                )
              ))}

              {gitClones.map(clone => (
                clone.project_name === template.name &&
                clone.git_hash !== template.git_hash &&
                !template.cicd_enable && (
                  <Button
                    key={clone.id}
                    variant="outline-warning"
                    size="sm"
                    onClick={() => updateStatic(template, team, clone.git_hash)}
                  >
                    Update
                  </Button>
                )
              ))}
            </div>
          </div>

          <div>
            <table className={showInfo + ' table'}>
              <thead>
                <tr>
                  <th scope="col">Domain</th>
                  <th scope="col">Additional Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>
                      <b>Name:</b> {template.domain_name}
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>Category:</b> {template.category}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className={showInfo + ' enable-ci-container'}>
              <div className="check-enable-build-container margin-left">
                <Form.Check
                  checked={template.cicd_enable}
                  type="checkbox"
                  className="check-enable-build-1"
                  onChange={() => siteStatus(template.id, habitat.id, !template.cicd_enable)}
                />
                <p>Enable CI</p>
              </div>

            </div>


            {template.cicd_enable && (
              <div>
                <div className={showInfo + ' buildspec-file'}>
                  <div>buildspec.yml</div>
                  <div className="check-enable-build-container">
                    <Form.Check
                      type="checkbox"
                      checked={ownBuild}
                      className="check-enable-build"
                      onChange={() => setOwnBuild(!ownBuild)}
                    />
                    <p>Use project&apos;s buildspec file</p>
                  </div>
                </div>

                {!ownBuild && templates.length > 0 && (
                  <div className={showCode}>
                    <CodeMirror
                      value={code}
                      options={{
                        mode: 'javascript',
                        theme: 'base16-light',
                        lineNumbers: true,
                        readOnly: false
                      }}
                      onBeforeChange={(editor, data, value) => {
                        setCode(value)
                      }}
                    />
                  </div>
                )}
              </div>
            )}

            {!template.distribution_status ? (
              <div className={showInfo + ' enable-ci-container'}>
                <div className="check-enable-build-container margin-left">
                  <Form.Check
                    checked={template.distribution_status}
                    type="checkbox"
                    className="check-enable-build-1"
                    onChange={() => handleCloudFrontStatus(true)}
                  />
                  <p>Enable Cloudfront</p>
                </div>
              </div>
            ) : (
              <div className={showInfo + ' enable-ci-container'}>
                <div className="check-enable-build-container margin-left">
                  <Form.Check
                    checked={template.distribution_status}
                    type="checkbox"
                    className="check-enable-build-1"
                    onChange={() => handleCloudFrontStatus(false)}
                  />
                  <p>Cloudfront Enabled</p>
                </div>
              </div>
            )}
          </div>
          <div className={showInfo + ' invalidations-container'}>
              <Button
                variant='outline-primary'
                className='button-validations'
                onClick={() => handleFetchInvalidations(template.id)}
              >
                Show Invalidations
              </Button>

            <div className={showInvalidations + ' invalidation-container'}>
              {invalidationsArray.templateId === template.id && (
                <table className='table table-invalidations'>
                  <thead>
                    <tr>
                      <th scope="col">Invalidation</th>
                      <th scope="col">Created at</th>
                      <th scope="col">State</th>
                      <th scope="col">Show</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invalidationsArray.items.map(invalidation => (
                      <tr key={invalidation.id}>
                        <td>
                          <p>{invalidation.id}</p>
                        </td>
                        <td>
                          <p>{invalidation.create_time}</p>
                        </td>
                        <td>
                          {invalidation.status === 'Completed' ? (
                            <p className='status-completed'>Completed &nbsp;&nbsp;<FontAwesomeIcon icon={faCheckCircle} /> </p>
                          ) : (
                            <p className='status-inprogress'>In Progress &nbsp;&nbsp;<FontAwesomeIcon icon={faSpinner} /> </p>
                          )}
                        </td>
                        <td>
                          <p
                            className='show-invalidation'
                            onClick={() => handleShowInvalidation(invalidation.id)}>
                            Show <FontAwesomeIcon icon={faEye} />
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              <br></br>
              {invalidationsArray.templateId === template.id && (
                <>
                  {invalidationItem && (
                    <>
                      <p><b>Invalidation:  {invalidationItem.id}</b></p>


                      <div className='invalidation-selected-container'>
                        <pre>
                          {invalidationItem.invalidation_batch.paths.items.map(item => (
                            <p key={item}>{item}</p>
                          ))}
                        </pre>
                      </div>
                    </>
                  )}
                </>
              )}



              <br></br>
              {invalidationsArray.templateId === template.id && (
                <>
                  <p><b>New Invalidation</b></p>
                  <textarea
                    onChange={e => setInvalidations(e.target.value)}
                    className="text-area-invalidations">
                  </textarea>
                  <div className="save-invalidations-button">
                    <Button
                      variant="outline-primary"
                      className="button-validations"
                      value={invalidations}
                      onClick={() => handleNewInvalidation()}
                    >
                      Save Invalidation
                    </Button>
                  </div>

                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const mapState = (state, props) => ({
  index: props.index,
  log: state.awsSdk.log,
  team: state.team.team,
  history: props.history,
  logs: state.awsSdk.logs,
  template: props.template,
  templates: state.templates.templates,
  invoke: state.func.invoke,
  loading: state.func.loading,
  habitat: state.habitat.habitat,
  awsRoles: state.awsRoles.awsRoles,
  gitClones: state.gitClone.gitClones,
  stepAppTour: state.appTour.stepAppTour,
  statusAppTour: state.appTour.stateAppTour,
  errorSites: state.templates.error,
  invalidationsArray: state.templates.invalidations,
  invalidationItem: state.templates.invalidation.invalidations
});

const mapDispatch = {
  stopTour,
  getAwsLog,
  funcInvoke,
  getAwsLogs,
  siteStatus,
  deployStatic,
  fetchAWSRole,
  nextStepTour,
  updateStatic,
  updateFunction,
  allDeployments,
  buildAndUpdate,
  deleteDeployment,
  buildAndDeployStatic,
  cloudFrontStatus,
  fetchInvalidations,
  newInvalidation,
  getValidation
};

export default connect(mapState, mapDispatch)(HabitatTemplateItem);
