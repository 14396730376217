import produce from 'immer';

import {
    GET_APPTOURSTATE_BEGIN,
    GET_APPTOURSTATE_SUCCESS,
    GET_APPTOURSTATE_ERROR,
    NEXTSTEP_BEGIN,
    NEXTSTEP_SUCCESS,
    NEXTSTEP_ERROR,
    STOP_TOUR_BEGIN,
    STOP_TOUR_SUCCESS,
    STOP_TOUR_ERROR,
    CHANGE_CLASS_HABITAT_BEGIN,
    CHANGE_CLASS_HABITAT_SUCCESS,
    CHANGE_CLASS_HABITAT_ERROR,
    CHANGE_CLASS_ROLES_BEGIN,
    CHANGE_CLASS_ROLES_SUCCESS,
    CHANGE_CLASS_ROLES_ERROR,
    CHANGE_CLASS_AWS_BEGIN,
    CHANGE_CLASS_AWS_SUCCESS,
    CHANGE_CLASS_AWS_ERROR,
    CHANGE_CLASS_FUNCTIONS_BEGIN,
    CHANGE_CLASS_FUNCTIONS_SUCCESS,
    CHANGE_CLASS_FUNCTIONS_ERROR
} from '../actions/types';

const initialState = {
    stateAppTour: false,
    stepAppTour: 0,
    loading: false,
    onboardingClassHabitat: '',
    onboardingClassRoles: '',
    onboardingClassAWS: '',
    onboardingClassFuncs: '',
    onboardingType: '',
    error: null
}

const reducer = produce((draft, action) => {
    switch (action.type) {
        case GET_APPTOURSTATE_BEGIN:
        case NEXTSTEP_BEGIN:
        case STOP_TOUR_BEGIN:
        case CHANGE_CLASS_HABITAT_BEGIN:
        case CHANGE_CLASS_ROLES_BEGIN:
        case CHANGE_CLASS_AWS_BEGIN:
        case CHANGE_CLASS_FUNCTIONS_BEGIN:
            draft.loading = true;
            draft.error = null;
            return;
        case GET_APPTOURSTATE_SUCCESS:
            draft.stepAppTour = action.payload;
            return;
        case NEXTSTEP_SUCCESS:
            draft.loading = false;
            draft.stepAppTour += action.payload;
            draft.error = null;
            return;
        case STOP_TOUR_SUCCESS:
            draft.loading = false;
            draft.stateAppTour = false;
            draft.error = null;
            draft.stepAppTour = 0;
            return;
        case CHANGE_CLASS_HABITAT_SUCCESS:
            draft.loading = false;
            draft.onboardingClassHabitat = action.payload;
            draft.error = null;
            return;
        case CHANGE_CLASS_ROLES_SUCCESS:
            draft.loading = false;
            draft.onboardingClassRoles = action.payload;
            draft.error = null;
            return;
        case CHANGE_CLASS_AWS_SUCCESS:
            draft.loading = false;
            draft.onboardingClassAWS = action.payload;
            draft.error = null;
            return;
        case CHANGE_CLASS_FUNCTIONS_SUCCESS:
            draft.loading = false;
            draft.onboardingClassFuncs = action.payload;
            draft.error = null;
            return;
        case GET_APPTOURSTATE_ERROR:
        case NEXTSTEP_ERROR:
        case STOP_TOUR_ERROR:
        case CHANGE_CLASS_HABITAT_ERROR:
        case CHANGE_CLASS_AWS_ERROR:
        case CHANGE_CLASS_ROLES_ERROR:
        case  CHANGE_CLASS_FUNCTIONS_ERROR:
            draft.error = action.error;
            return;
        default:
            return;
    }
}, initialState);

export default reducer;