import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../constants/routes';
import Message from '../components/Message';
import logo from '../assets/images/Kor-dark-logo.svg';
import '../assets/styles/pages/login_page.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faGoogle } from '@fortawesome/free-brands-svg-icons';

const SigninPage = ({
  error,
  loading,
  location,
  currentUser,
  loginWithOauth,
}) => {
  useEffect(() => {
    if (location.search.length > 1) {
      loginWithOauth(location.search);
    }
  }, [location]);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(false);

  if (currentUser) {
    if (currentUser.confirmed) {
      return <Redirect to={routes.ONBOARDING} noThrow />;
    }
  }

  return (
    <div className="log-big-box">
      <div className="log-container log-container-left">
        <div className="logo">
          <img className="kor-size" src={logo} alt="logo" />
        </div>
        <div className="sign-title">
          <p className="sigtext">SIGN IN</p>
        </div>
        {show ? (
          <Message />
        ) : (
          <div className="sign-form">
            <form onSubmit={(e) => e.preventDefault()} className="login">
              <div className="sign-instructions">
                <p className="tto">Simplify your serverless projects.</p>
              </div>
              <div className="nothing">
                {error && (
                  <div className="error-display text-danger">
                    <p>
                      <FontAwesomeIcon icon={faExclamationCircle} /> {error}
                    </p>
                  </div>
                )}
                <input
                  className="sign-input spacing-top"
                  type="text"
                  value={username}
                  id="username"
                  placeholder="Email address"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="nothing">
                <input
                  type="password"
                  value={password}
                  id="password"
                  className="sign-input spacing-top"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="spacing-top">
                <div className="custom-control custom-checkbox">
                  <div className="more-spacing">
                    <input
                      type="checkbox"
                      className="custom-control-input remember-spacing"
                      id="defaultChecked2"
                    />
                  </div>
                  <div className="remember-label">
                    <label
                      className="custom-control-label"
                      htmlFor="defaultChecked2"
                    >
                      Remember this access
                    </label>
                  </div>
                  <button
                    type="submit"
                    disabled={loading}
                    className="button-signin"
                    onClick={() => setShow(true)}
                  >
                    <div className="sign-button-text"> Sign in with email </div>
                  </button>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    disabled={loading}
                    className="button-signin-google m-2"
                    onClick={() => setShow(true)}
                  >
                    <div className="sign-button-text">
                      {' '}
                      Sign in with{' '}
                      <FontAwesomeIcon className="ml-1" icon={faGoogle} />
                    </div>
                  </button>
                  <button
                    type="submit"
                    disabled={loading}
                    className="button-signin-github m-2"
                    onClick={() => setShow(true)}
                  >
                    <div className="sign-button-text">
                      {' '}
                      Sign in with{' '}
                      <FontAwesomeIcon className="ml-1" icon={faGithub} />
                    </div>
                  </button>
                </div>
              </div>
              <div className="create-account spacing-top create-account-cont">
                <p>Don&apos;t have an account yet?&nbsp;</p>
                <p>
                  <Link to={routes.SIGNUP} className="linked-to">
                    Sign Up
                  </Link>
                </p>
              </div>

            </form>
          </div>
        )}
      </div>
      <div className="log-container log-container-right">
        <div className="log-image" />
      </div>
    </div>
  );
};

const mapState = (state, props) => ({
  error: state.user.error,
  location: props.location,
  loading: state.user.loading,
  currentUser: state.user.user,
});

const mapDispatch = {
};

export default connect(mapState, mapDispatch)(SigninPage);

