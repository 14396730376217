import { SETTINGS_AWSCREDS_TOGGLE, SETTINGS_GITCREDS_TOGGLE } from './types';

export const awsCredsToggle = (feature) => {
  return (dispatch) => {
    dispatch({
      type: SETTINGS_AWSCREDS_TOGGLE,
      payload: feature,
    });
  };
};
