/****************************************************************************************
 * AWS Credentials Model
 *
 * Description:
 *
 * AWS credentials store profile information from the user's AWS accounts.
 * Key name are mapped to the format the server expects, i.e. api_key
 * Schema:
 *
 * awsCred = {
 *  name: "Profile Name",
 *  habitat_id: "FEDWFASGDDGgsdgsa",
 *  team_id: team_id,
 *  api_key: "ABKSFJSKSJSKDJDJD",
 *  api_secret: "password",
 *  region: "account region",
 *  verified: true
 * }
 *
 * ************************************************************************************/

export const awsCredModel = (name, apiKey, apiSecret, region, habitat_id, key) => {
  return (
    {
      name:         name,
      habitat_id:   habitat_id,
      api_key:      apiKey,
      api_secret:   apiSecret,
      region:       region,
      verified:     false,
      request:      1,
      frontkey:     key
    }
  )
}
