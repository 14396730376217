import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const NewGITCredModal = (props) => {
  return (
    <div className={props.showGitModalOthers + ' modal-kor-container container-git-others'}>
      <div className="box-modal-options-container">
        <p className="modal-instructions-1"><b>New Git Credentials</b></p>
        <Form.Control
          aria-label="Name"
          aria-describedby="new-name"
          className="aws-creds-form"
          onChange={e => props.setName(e.target.value)}
          type="text"
          name="credsName"
          placeholder="Name"
        />
        <Form.Control
          aria-label="Username"
          aria-describedby="api-key"
          className="aws-creds-form"
          autoComplete="off"
          onChange={e => props.setUserName(e.target.value)}
          type="text"
          name="username"
          placeholder="Username"
        />
        <Form.Control
          aria-label="Usersecret"
          aria-describedby="user-secret"
          className="aws-creds-form"
          autoComplete="new-password"
          onChange={e => props.setUserSecret(e.target.value)}
          type="password"
          name="usersecret"
          placeholder="Access Token or Password"
        />
        <div className="buttons-container">
          <Button
            variant="primary"
            size='sm'
            className="cancel-button"
            onClick={props.handleNewGITCred}
          >Save</Button>
          <Button
            variant="primary"
            size='sm'
            className="cancel-button"
            onClick={props.handleCloseGitModalOthers}
          >Cancel</Button>
        </div>
      </div>

    </div>
  )
}

export default NewGITCredModal;
