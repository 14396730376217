import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Table, Badge, Button, Spinner } from 'react-bootstrap';
import {
  faServer,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';

const HabitatInstances = ({
  error,
  status,
  habitat,
  loading,
  instances,
  deleteInstance,
  getInstaceStatus,
  instanceChangeStatus
}) => {

  const [ instance, setInstance ] = useState(null);

  const selectInstance = (instance) => {
    setInstance(instance);
    getInstaceStatus(instance.id, habitat.id);
  }

  return (
    <>
      <div className="habitat-funcs-management-container">
        <div className="title-setup-w-new">
          <div className="title-setup-management-funcs">
            <div className="box-feature-name-icon-funcs"><FontAwesomeIcon icon={faServer} /></div>
            <h5>EC2 Instances</h5>
          </div>
        </div>

        {error && (
          <div className="error-display text-danger">
            <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
          </div>
        )}

        {loading && (
          <div className="spinner-container funcs-habitat-spinner">
            <Spinner animation="border" variant="primary" />
          </div>
        )}

        <div className="list-s3-creds">
          <div className="management-s3-container">
            <p>
              {status && status.status === 'running' && (
                <Badge variant="success">{status.status.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</Badge>
              )}
              {status && status.status === 'Reboot request sent.' && (
                <Badge variant="primary">{status.status.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</Badge>
              )}
              {status && status.status === 'start' && (
                <Badge variant="primary">{status.status.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</Badge>
              )}
              {status && status.status === 'reboot' && (
                <Badge variant="warning">{status.status.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</Badge>
              )}
              {status && status.status === 'pending' && (
                <Badge variant="warning">{status.status.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</Badge>
              )}
              {status && status.status === 'stopped' && (
                <Badge variant="danger">{status.status.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</Badge>
              )}
              {status && status.status === 'stopping' && (
                <Badge variant="danger">{status.status.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</Badge>
              )}
            </p>
            <div className="options-aws">
              <Button variant="outline-success" size="sm" disabled={!instance} onClick={() => instanceChangeStatus(instance.id, 'start', habitat.id)}>Start</Button>
              <Button variant="outline-primary" size="sm" disabled={!instance} onClick={() => instanceChangeStatus(instance.id, 'reboot', habitat.id)}>Reboot</Button>
              <Button variant="outline-warning" size="sm" disabled={!instance} onClick={() => instanceChangeStatus(instance.id, 'stop', habitat.id)}>Stop</Button>
              <Button variant="outline-danger" size="sm" disabled={!instance} onClick={() => deleteInstance(instance.id, habitat.id)}>Remove</Button>
            </div>
          </div>
          <Table className="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Name</th>
                <th scope="col">DB Prod Password</th>
                <th scope="col">DB Dev Password</th>
                <th scope="col">Create at</th>
              </tr>
            </thead>
            <tbody className="tr-ctn">
              {instances && instances.length > 0 && (
                instances.map(ec2 => 
                  <tr key={ec2.id}>
                    <td className="check-radio">
                      <div onChange={() => selectInstance(ec2)}>
                        <fieldset>
                          <Form.Check
                            type="radio"
                            aria-label="radio 1"
                            name="ec2Intance"
                            id={ec2.id}
                            value={ec2}
                          />
                        </fieldset>
                      </div>
                    </td>
                    <td>{ec2.name}</td>
                    <td>{ec2.db_password}</td>
                    <td>{ec2.db_dev_password}</td>
                    <td>{ec2.created_at}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default HabitatInstances;