import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  faFolderOpen,
  faLaptopCode
} from '@fortawesome/free-solid-svg-icons';
import { Popover, Overlay, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../assets/styles/pages/Funcs.scss';
import GitCloneModal from '../components/git/clone/GitCloneModal';
import '../assets/styles/components/funcs.scss';
import { listGitCreds } from '../actions/git-creds.actions'
import { cloneGit, removeClone, getClones } from '../actions/git-clone.actions'
import FuncsHighlightCode from '../components/funcs/FuncsHighlightCode'
import '../assets/styles/components/funcs/funcs-highlight-code.scss'
import '../assets/styles/components/funcs/func-tree-file.scss'
import { nextStepTour, stopTour } from '../actions/app-tour.actions';
import AppTour from '../components/AppTour';
import { changeStatusOnboarding } from '../actions/user.actions';
import Spinner from 'react-bootstrap/Spinner';
import { remoteGit, cloneBranches, cloneCheckout, pullClone, getFileTree, getFile, removeFileTree } from '../actions/git-clone.actions';
import GitModal from './Modals/GitModal';
import ToastComponent from '../components/toast/ToastComponent'
import { withRouter } from 'react-router-dom';
import routes from '../constants/routes.json';
import Tree from './funcs/folderTree/tree';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { faQuestionCircle, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

const Funcs = withRouter(({
  team,
  loading,
  history,
  getFile,
  branches,
  gitCreds,
  cloneGit,
  fileTree,
  stopTour,
  remoteGit,
  pullClone,
  getClones,
  gitClones,
  currentUser,
  removeClone,
  stepAppTour,
  getFileTree,
  nextStepTour,
  listGitCreds,
  loadingClone,
  cloneCheckout,
  cloneBranches,
  statusAppTour,
  removeFileTree,
  currentOpenFile,
  changeStatusOnboarding,
  error,
  loadingMessage
}) => {

  const target = useRef(null);
  const [clone, setClone] = useState(null);
  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [gitShow, setGitShow] = useState(false);
  const [gitCredsClone, setGitCredsClone] = useState('');
  const [showTree, setShowTree] = useState('show-tree-none');
  const [showFile, setShowFile] = useState(false);
  const [onboardingClass, setOnboardingClass] = useState('onboardingClass');
  const [sendHabitat, setSendHabitat] = useState('/Habitats');
  var inputRepository = React.createRef();
  var inputRepositoryName = React.createRef();

  useEffect(() => {
    listGitCreds();
    getFileTree(team.id);
    getClones(team);
  }, [team]);

  const handleOpenGit = clone => {
    setClone(clone);
    setGitShow(true);
    cloneBranches(clone);
  }

  const handleGetInputs = () => {
    const repository = inputRepository.current.value
    const name = inputRepositoryName.current.value
    handleCloneGit(repository, name);
}

  const handlePull = () => {
    pullClone(clone);
    setGitShow(false);
  }

  const handleCheckout = event => {
    clone.git_branch = event.target.value
    cloneCheckout(clone);
    setGitShow(false);
  }

  const handleOverlay = () => {
    setShow(!show);
    if (stepAppTour === 1) {
      nextStepTour();
    }
  };

  const handleShowClone = () => {
    setShowModal(!showModal);
    handleOverlay();
  };

  const handleCloneGit = (repository, name) => {
    cloneGit(repository, name, gitCredsClone, team.id);
    setShowModal(false);
    setShowToast(true);
    setShowTree('show-tree');
    if (stepAppTour === 2) {
      nextStepTour();
    }
  }

  const handleGetFile = (repo) => {
    if (repo) {
      getFile(repo.url);
      setShowFile(true);
    }
  };

  const handleDeleteRepo = (directoryName, ftID) => {
    if (directoryName) {
      const clone = gitClones.find(clone => directoryName === clone.project_name);
      removeClone(clone.id, clone.directory_name);
      setShowToast(true);
      removeFileTree(ftID);
    }

  }

  const handleCloseToast = () => {
    setShowToast(false)
    history.push(routes.FUNCTIONS)
  }

  return (
    <div className="functions-container">
      <AppTour
        stopTour={stopTour}
        stepAppTour={stepAppTour}
        nextStepTour={nextStepTour}
        statusAppTour={statusAppTour}
        onboardingClass={onboardingClass}
        setOnboardingClass={setOnboardingClass}
        changeStatusOnboarding={changeStatusOnboarding}
      />
      <ToastComponent
        showToast={showToast}
        handleCloseToast={handleCloseToast}
        message="Repository cloned! Wait..."
      />
      <GitModal
        clone={clone}
        show={gitShow}
        branches={branches}
        gitCreds={gitCreds}
        setShow={setGitShow}
        remoteGit={remoteGit}
        handlePull={handlePull}
        loadingClone={loadingClone}
        handleCheckout={handleCheckout}
      />
      <GitCloneModal
        gitCreds={gitCreds}
        showModal={showModal}
        sendHabitat={sendHabitat}
        stepAppTour={stepAppTour}
        setShowModal={setShowModal}
        gitCredsClone={gitCredsClone}
        handleCloneGit={handleCloneGit}
        setGitCredsClone={setGitCredsClone}
        handleGetInputs={handleGetInputs}
        inputRepository={inputRepository}
        inputRepositoryName={inputRepositoryName}
      />
      <div className="functions-container-left">
        <div className="functions-list-title-container">
          {team.id
            ? ((team.subscription === 'Project' && gitClones.length >= 3)) && (
              <div className="m-4">
                <Alert variant="warning">
                  Upgrade your plan to add more Repos
                </Alert>
              </div>
            )
            : (currentUser.subscription === 'Basic' &&
              gitClones.length >= 3) && (
              <div className="m-4">
                <Alert variant="warning">
                  Upgrade your plan to add more Repos
                </Alert>
              </div>
            )}
          <br />
          <div className="flex-funcs-title">
            <p>Repository List</p>
            <div className="question-mark-documentation-habitat">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip className="red-tooltip">
                    <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                  </Tooltip>
                }

              >
                <a
                  href="https://getkor.freshdesk.com/support/solutions/articles/47001163957-clone-a-repository"
                  rel="noopener noreferrer"
                  className="help"
                  target="_blank"
                >

                  <FontAwesomeIcon icon={faQuestionCircle} />
                </a>
              </OverlayTrigger>
            </div>
          </div><br />
          <div className="add-new-repository">
            {team.id
              ? ((team.subscription === 'Project' && gitClones.length <= 2)
                || (team.subscription !== 'Project')) && (
                <button className={onboardingClass + ' functions-add btn btn-outline-primary'} id="newFunction" onClick={handleOverlay} ref={target}>
                  + Add new
                </button>
              )
              : ((currentUser.subscription === 'Basic' && gitClones.length <= 2)
                || (currentUser.subscription !== 'Basic')) && (
                <button className={onboardingClass + ' functions-add btn btn-outline-primary'} id="newFunction" onClick={handleOverlay} ref={target}>
                  + Add new
                </button>
              )}
          </div>
          <Overlay target={target.current} show={show} placement="right">
            <Popover id="popover-basic">
              <Popover.Content>
                <div className="new-function" onClick={handleShowClone}>
                  <FontAwesomeIcon
                    className="new-icon-function"
                    icon={faLaptopCode}
                  />
                  <span>Clone a Repository</span>
                </div>
              </Popover.Content>
            </Popover>
          </Overlay>

          {error && (
            <div className="error-display text-danger">
              <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
            </div>
          )}


        </div>

        {loading && (
          <div className="spinner-container repository-spinner">
            <Spinner animation="border" variant="primary" />
            <p>{loadingMessage}</p>
          </div>
        )}


        <div className="functions-list-container">

          {fileTree && fileTree.length > 0 ? (
            <Tree
              fileTree={fileTree}
              gitClones={gitClones}
              handleOpenGit={handleOpenGit}
              handleGetFile={handleGetFile}
              handleDeleteRepo={handleDeleteRepo}
            />
          ) : (
              <div className="blank-section">No repositories added yet.</div>
            )}

        </div>
      </div>



      {!showFile ? (
        <div className="functions-container-right">
          <div className="emptyDirSelect">
            <FontAwesomeIcon className="open-dir-icon" icon={faFolderOpen} />
            <p>No item selected</p>
          </div>
        </div>
      ) : (
          <div className="functions-container-right-code">
            {loading ? (
              <div>Loading...</div>
            ) : (
                <FuncsHighlightCode
                  currentOpenFile={currentOpenFile}
                />
              )}
          </div>
        )}
    </div>
  );
});

const mapState = (state, props) => ({
  team: state.team.team,
  currentUser: state.user.user,
  branches: state.gitClone.branches,
  loading: state.gitClone.loading,
  gitClones: state.gitClone.gitClones,
  fileTree: state.gitClone.fileTree,
  loadingClone: state.gitClone.loading,
  stepAppTour: state.appTour.stepAppTour,
  statusAppTour: state.user.user.onboarding,
  currentOpenFile: state.gitClone.openFile.doc,
  gitCreds: state.gitCreds.gitCredentials.git_credentials,
  error: state.gitClone.error,
  loadingMessage: state.gitClone.loading_message
})

const mapDispatch = {
  getFile,
  cloneGit,
  stopTour,
  remoteGit,
  getClones,
  pullClone,
  getFileTree,
  removeClone,
  listGitCreds,
  nextStepTour,
  cloneCheckout,
  cloneBranches,
  removeFileTree,
  changeStatusOnboarding,
}

export default connect(
  mapState,
  mapDispatch
)(Funcs)