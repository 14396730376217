import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

export const createEC2 = data => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/aws/aws_instance`, data, { headers: authToken });
}

export const getEC2s = habitat_id => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/aws/aws_instance?habitat_id=${habitat_id}`, { headers: authToken });
}

export const getEC2 = data => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/aws/aws_instance/${data.id}?habitat_id=${data.habitat_id}`, { headers: authToken });
}

export const deleteEC2 = data => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.delete(`${URL}/aws/aws_instance/${data.id}?habitat_id=${data.habitat_id}`, { headers: authToken });
}

export const DBPasswordGenerator = (id, data) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.put(`${URL}/aws/db_password_generation/${id}`, data, { headers: authToken });
}

export const getEC2Status = (id, data) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/aws/instance_status/${id}`, data, { headers: authToken });
}

export const addElasticIP = (id, data) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.put(`${URL}/aws/aws_instance/elastic_ip/${id}`, data, { headers: authToken });
}

export const instanceBuild = (data) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/aws/instance_build`, data, { headers: authToken });
}

export const instanceStatus = (id, data) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/aws/instance_status/${id}`, data, { headers: authToken });
}