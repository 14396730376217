import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AppImage from '../assets/images/icons/pc.png';
import ApiImage from '../assets/images/icons/custom.png';
import { getClones } from '../actions/git-clone.actions';
import { addDomainInfo } from '../actions/templates.actions';
import '../assets/styles/containers/NewTemplateFormPage.scss';
import StaticImage from '../assets/images/icons/browser.png';
import StrapiImage from '../assets/images/icons/strapi.svg';
import { 
  deployTemplate,
  deployNewWebApp,
  addHabitadWithURL,
  addHabitadAndCreds,
  EC2StrapiWithDomain, 
  addHabitadWithCreds, 
  deployTemplateWebApp, 
  deployTemplateStrapi, 
  deployWebAppWithCreds,
  EC2StrapiWithoutDomain,
  deployNewTemplateStrapi,
  deployTemplateWithoutDomain, 
  deployWebAppWithCredsWithoutDomain,
  deployNewTemplateStrapiWithoutDomain
} from '../actions/templates.actions';
import InfoTemplate from '../components/templates/InfoTemplate';
import FormNewTemplate from '../components/templates/FormNewTemplate';
import { selectHabitat } from '../actions/habitat.actions';
import { listGitCreds } from '../actions/git-creds.actions';
import { requestKey, listAWSCreds, validateCreds, getUserAWSCreds, addAwsCredWithURL } from '../actions/aws-creds.actions';

const NewTemplateFormPage = ({ 
  team,
  name,
  domains,
  history, 
  loading,
  habitat,
  awsCreds,
  gitCreds,
  awsRoles,
  gitClones, 
  getClones, 
  requestKey,
  awsCredURL,
  dbEndpoint,
  devEndpoint,
  listAWSCreds,
  awsCredsUser,
  loadingCreds,
  templateName,
  listGitCreds,
  prodEndpoint, 
  selectHabitat,
  addDomainInfo,
  validateCreds,
  endpointWebApp,
  errorTemplates,
  deployTemplate,
  endpointStatic,
  getUserAWSCreds,
  deployNewWebApp,
  addHabitadWithURL,
  addAwsCredWithURL,
  addHabitadAndCreds,
  EC2StrapiWithDomain, 
  addHabitadWithCreds,
  strapiEndpointAdmin,
  deployTemplateStrapi,
  deployTemplateWebApp,
  deployWebAppWithCreds,
  EC2StrapiWithoutDomain,
  strapiEndpointFrontend,
  deployNewTemplateStrapi,
  deployTemplateWithoutDomain,
  deployWebAppWithCredsWithoutDomain,
  deployNewTemplateStrapiWithoutDomain,
}) => {
  useEffect(() => {
    getClones(team);
    getUserAWSCreds();
    listGitCreds();
    switch (templateName) {
      case 'WEB APP':
        setImage(AppImage);
        return; 
      case 'STATIC SITE':
        setImage(StaticImage);
        return;
      case 'API':
        setImage(ApiImage);
        return;
      case 'STRAPI':
        setImage(StrapiImage);
        return;
      default:
        return;
    }
  }, [templateName]);

  const [image, setImage] = useState(null);

  return(
    <div className="new-template-container"> 
      <div className="info-secction">
        <InfoTemplate 
          image={image}
          templateName={templateName}
        />
      </div>
      <div className="steps-template-container">
        <FormNewTemplate 
          team={team}
          staticName={name}
          history={history}
          loading={loading}
          domains={domains}
          habitat={habitat}
          awsCreds={awsCreds}
          gitCreds={gitCreds}
          awsRoles={awsRoles}
          gitClones={gitClones}
          requestKey={requestKey}
          awsCredURL={awsCredURL}
          dbEndpoint={dbEndpoint}
          devEndpoint={devEndpoint}
          prodEndpoint={prodEndpoint}
          loadingCreds={loadingCreds}
          awsCredsUser={awsCredsUser}
          templateName={templateName}
          selectHabitat={selectHabitat}
          addDomainInfo={addDomainInfo}
          validateCreds={validateCreds}
          errorTemplates={errorTemplates}
          deployTemplate={deployTemplate}
          endpointWebApp={endpointWebApp}
          endpointStatic={endpointStatic}
          deployNewWebApp={deployNewWebApp}
          addHabitadWithURL={addHabitadWithURL}
          addAwsCredWithURL={addAwsCredWithURL}
          addHabitadAndCreds={addHabitadAndCreds}
          EC2StrapiWithDomain={EC2StrapiWithDomain}
          strapiEndpointAdmin={strapiEndpointAdmin}
          addHabitadWithCreds={addHabitadWithCreds}
          deployTemplateStrapi={deployTemplateStrapi}
          deployTemplateWebApp={deployTemplateWebApp}
          deployWebAppWithCreds={deployWebAppWithCreds}
          EC2StrapiWithoutDomain={EC2StrapiWithoutDomain}
          strapiEndpointFrontend={strapiEndpointFrontend}
          deployNewTemplateStrapi={deployNewTemplateStrapi}
          deployTemplateWithoutDomain={deployTemplateWithoutDomain}
          deployWebAppWithCredsWithoutDomain={deployWebAppWithCredsWithoutDomain}
          deployNewTemplateStrapiWithoutDomain={deployNewTemplateStrapiWithoutDomain}
        />
      </div>
    </div>
  );
}

const mapState = (state, props) => ({
  team: state.team.team,
  history: props.history,
  name: state.templates.name,
  domains: state.templates.domains,
  loading: state.templates.loading,
  habitat: state.templates.habitat,
  awsCreds: state.templates.awsCred,
  awsRoles: state.awsRoles.awsRoles,
  gitClones: state.gitClone.gitClones,
  loadingCreds: state.awsCreds.loading,
  errorTemplates: state.templates.error,
  dbEndpoint: state.templates.dbEndpoint,
  devEndpoint: state.templates.devEndpoint,
  awsCredURL: state.awsCreds.awsCredential,
  prodEndpoint: state.templates.prodEndpoint,
  templateName: state.templates.templateName,
  awsCredsUser: state.awsCreds.userCredentials,
  endpointStatic: state.templates.endpointStatic,
  endpointWebApp: state.templates.endpointWebApp,
  strapiEndpointAdmin: state.templates.adminEndpoint,
  gitCreds: state.gitCreds.gitCredentials.git_credentials,
  strapiEndpointFrontend: state.templates.frontendEndpoint
});

const mapDispatch = {
  getClones,
  requestKey,
  listAWSCreds,
  listGitCreds,
  selectHabitat,
  addDomainInfo,
  validateCreds,
  deployTemplate,
  deployNewWebApp,
  getUserAWSCreds,
  addHabitadWithURL,
  addAwsCredWithURL,
  addHabitadAndCreds,
  EC2StrapiWithDomain,
  addHabitadWithCreds,
  deployTemplateStrapi, 
  deployTemplateWebApp,
  deployWebAppWithCreds,
  EC2StrapiWithoutDomain,
  deployNewTemplateStrapi,
  deployTemplateWithoutDomain,
  deployWebAppWithCredsWithoutDomain,
  deployNewTemplateStrapiWithoutDomain
};

export default connect(
  mapState,
  mapDispatch
)(NewTemplateFormPage);