import React from 'react';
import { ListGroup } from 'react-bootstrap';
import MemberCard from './MemberCard';

const MemberList = (props) => {
	return (
		<div className="row">			
			<div className="team-page-box">				
				<ListGroup>				
					{props.members.map((member) => (
						<MemberCard key={member.id.toString()} value={member} />
				  	))}
				</ListGroup>				
			</div>
		</div>
	);
}

export default MemberList;