import React from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from '../avatar/Avatar'
import { connect } from 'react-redux';
import { logout } from '../../actions/user.actions';
import '../../assets/styles/components/team-navbar.scss';
import logo from './Kor-dark-logo.svg';

const Navbar = ({currentUser, logout, team, teamName}) => { 

  return (
    <div>
      <div>
        <div className="sidenav col-md ">
          
            <Avatar userName={teamName} avatarClass="avatarTeamPicTop-Left"/>

            <div className=" teamProfileName">
              {team.name}
            </div>

            <div className="teams-texto-nav">TEAM SETTINGS</div>

              <NavLink exact to={`/TeamProfile/${team.id}/${team.name}`} className="teams-nav-side-text" activeClassName="teams-nav-side-text-active" ><i className="fas fa-user iconos"></i>Details</NavLink>              
              <NavLink exact to={`/TeamBilling/${team.id}/${team.name}`} className="teams-nav-side-text" activeClassName="teams-nav-side-text-active" ><i className="fas fa-dollar-sign iconos"></i>Billing</NavLink>
              <NavLink exact to={`/TeamMembers/${team.id}/${team.name}`} className="teams-nav-side-text" activeClassName="teams-nav-side-text-active" ><i className="fas fa-hotel iconos"></i>Members</NavLink>
              
            <div className="teams-texto-nav">MY PROFILE</div>            

            <NavLink exact to='/Overview' className="teams-nav-side-text-two" activeClassName="teams-nav-side-text-active" >
              <Avatar userName={currentUser.name} avatarClass="avatarPicTop-leftMenu" />
                <div className=" leftMenu-Name">
                  {currentUser.name}
                </div>
            </NavLink>
                                      
        </div>
      </div>

      <div className="flux-navbar fixed-nav nav-wraper nav"> 
        
        <NavLink to='/' className="brand-logo"> 
          <img src={logo} className="App-logo" alt="logo" /> 
        </NavLink>

            <div className="line"></div>
            <div className="titleflux-text"> Team Settings </div>
            <div className="spacer" />
              
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {currentUser.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>                    
                  <Dropdown.Item onClick={() => logout()}>Sign Out</Dropdown.Item>                        
                </Dropdown.Menu>
              </Dropdown>

              <Avatar userName={currentUser.name} avatarClass="avatarPicTop" />
      </div>
    </div>      
  )
};

const mapState = (state, props) => ({
  currentUser: state.user.user,  
  team: state.team.team,
  teamName: props.teamName
});

const mapDispatch = { 
  logout, 
};

export default connect(
  mapState, 
  mapDispatch
)(Navbar);