import React from 'react';
import user from '../../assets/images/icons/UserCircle.svg';
import { Modal, Button, Form } from 'react-bootstrap';

const TeamInvitations = ({ invitations, acceptInvite, rejectInvite }) => {
    return (
        <div className="team-invitations-box">
            <div className="teams-box-title-mark">
                <p><b>Team Invitations</b></p>
            </div>
            <div className="invitations-container">
            {invitations.map(inv => (
                <div key={inv.invite_id} className="invitation-container-team">
                    <div className="avatar-invite">
                    <img src={user} />
                    </div>

                    <div className="details-invite">
                          <p>{inv.team}</p>  
                          <p>Invited by: {inv.inviter}</p>
                    </div>

                    <div className="buttons-invite">
                        <Button onClick={() => rejectInvite(inv.invite_id)}>
                            Decline 
                        </Button>
                        <Button onClick={() => acceptInvite(inv.invite_id)}>
                            Accept
                        </Button>
                    </div>
                </div>
            ))}
            </div>
        </div>
    )
}

export default TeamInvitations;