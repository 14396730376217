import React from 'react';
import { Modal } from 'react-bootstrap';
import CreditCard from '../CreditCard';
const AddCardModal = ({show, team, setShow, setToast}) => {
  return(
    <Modal
      size="lg"
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center">
          Add Card
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreditCard 
          team={team}
          setShow={setShow}
          setToast={setToast}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AddCardModal;