import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const EditGITCredModal = (props) => {

  return (
    <Modal
      show={props.showDelete}
      onHide={() => props.setShowDelete(false)}
      aria-labelledby="section-set-modal-title"
    >
    <Modal.Header closeButton>
        <Modal.Title id="section-set-modal-title">
          Update GIT Credentials
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="button-update-name-function">          
          <Button 
            // disabled={!props.usersecret || !props.username || !props.credsName} 
            variant="primary" 
            onClick={props.handleDeleteGITCred}>
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default EditGITCredModal;
