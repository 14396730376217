import React from 'react';
import { Toast, Row, Col, Alert } from 'react-bootstrap';

const ToastPayment = (props) => {
  return (
    <Row>
      {!props.loading && (
        <Col xs={12}>
          <Toast onClose={() => props.handleClear()} show={props.show} delay={3000} autohide>
            <Toast.Body>
              {props.payment && (
                <Alert variant="success" className="bg-success text-white w-100 capitalize">
                  {props.payment}
                </Alert>
              )}
              {props.error && (
                <Alert variant="danger">
                  {props.error}
                </Alert>
              )}
            </Toast.Body>
          </Toast>
        </Col>
      )}
    </Row>
  );
};

export default ToastPayment;