import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faConciergeBell
} from '@fortawesome/free-solid-svg-icons';

const HabitatRoles = (props) => {

    const handleNextStep = () => {
        if(props.stepAppTour === 8){
            props.nextStepTour();
        }
    }

    return (
        <div className={props.onboardingClassRoles} onClick={handleNextStep}>
        {props.habitatSetUp === 'roles' ? (
            <div className={props.habitatSetUp + '-selected box-feature'} onClick={() => props.handleSelectFeature('habitat')}>
            <div className="box-feature-name">
                <div className="box-feature-name-icon"><FontAwesomeIcon icon={faConciergeBell} /></div>
                <p>Habitat Roles</p>
            </div>

            <div className="box-feature-plus-1">
                <p>No. Roles</p>
                <p>{props.awsRoles.length}</p>
            </div>

            <div className="box-feature-plus">
                <p>Last Update</p>
                <p>2020-08-14T16:41:59.400Z</p>
            </div>
        </div>
        ):(
            <div className="box-feature" onClick={() => props.handleSelectFeature('roles')}>
            <div className="box-feature-name">
                <div className="box-feature-name-icon"><FontAwesomeIcon icon={faConciergeBell} /></div>
                <p>Habitat Roles</p>
            </div>

            <div className="box-feature-plus-1">
                <p>No. Roles</p>
                <p>{props.awsRoles.length}</p>
            </div>

            <div className="box-feature-plus">
                <p>Last Update</p>
                <p>2020-08-14T16:41:59.400Z</p>
            </div>
        </div> 
        )}

        </div>
    )
}

export default HabitatRoles;