import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAws } from '@fortawesome/free-brands-svg-icons';
import { faCodeBranch, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { faGithub, faGitlab, faBitbucket } from '@fortawesome/free-brands-svg-icons'


const OnboardingStepCreds = (props) => {

    useEffect(function () {
        if(props.alertMessage === true){
            if(props.awsCredAlert){
                alert('AWS Credential added successfully!');
                props.setAlertMessage(false);
            }
        }
    })


    return (
        <div className='welcome-page-container'>
            <p className='title-welcome'>Welcome to KOR!</p>
            <p className='description-kor'>KOR is a serverless development platform where you and you can build, manage, test, deploy code all in one developer-friendly place.</p>
            <div className='creds-setup-onboarding'>
                <p className="label-instructions">We need to set up a couple of things first<br /> so that we can automatically deploy and manage projects for you.</p>

                <div className='creds-container'>
                    <div className='cred-container'>
                        <p className='aws-cred-icon'> <FontAwesomeIcon icon={faAws} /> &nbsp; AWS Authorization</p>
                        <p>You will have to connect an AWS account. It is a simple 1 click integration to an account and everything else can be left to KOR. KOR will automatically set up all best practices and security for your project’s deployments and management.</p>
                        {props.loadingAws && (
                            <div className="spinner-container funcs-habitat-spinner">
                                <Spinner animation="border" variant="primary" />
                                <p>&nbsp;&nbsp;&nbsp;Creating Credential...</p>
                            </div>
                        )}

                        {props.errorAwsCreds && (
                            <div className="error-display text-danger">
                                <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: Something went wrong, try again!</p>
                            </div>
                        )}


                        {props.awsCredDone && (
                            <div>
                                {props.awsCredDone.length > 0 ? (
                                    <div className='button-authorize-done'>
                                        <Button
                                            variant='outline-primary'
                                            disabled>
                                            Authorized
                                </Button>
                                        <FontAwesomeIcon icon={faCheckCircle} />
                                    </div>
                                ) : (
                                    <div className='button-authorize-done'>
                                        <Button
                                            variant='outline-primary'
                                            onClick={e => props.handleAddAWSCredsURL()}>
                                            Authorize
                                </Button>
                                    </div>
                                )} 

                            </div>
                        )}


                    </div>
                    <div className='cred-container'>
                        <p className='git-cred-icon'> <FontAwesomeIcon icon={faCodeBranch} />&nbsp; GIT Authorization</p>
                        <p>You will have to link a git account here. It is a simple 1 click integration so KOR can automate git push’s, pulls, and version control. </p>
                        {props.loadingGit && (
                            <div className="spinner-container funcs-habitat-spinner">
                                <Spinner animation="border" variant="primary" />
                                <p>&nbsp;&nbsp;&nbsp;Creating Credential...</p>
                            </div>
                        )}

                        {props.errorGitCreds && (
                            <div className="error-display text-danger">
                                <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: Something went wrong, try again!</p>
                            </div>
                        )}

                        {props.gitCredsDone === null && (
                            <div>
                                <Form.Control
                                    aria-label="Name"
                                    aria-describedby="new-name"
                                    className="aws-creds-form"
                                    autoComplete="off"
                                    onChange={e => props.setName(e.target.value)}
                                    type="text"
                                    name="credsName"
                                    placeholder="Cred Name"
                                />
                                <Form.Control
                                    className="metric-selectbox"
                                    as="select"
                                    defaultValue={'DEFAULT'}
                                    onChange={e => props.setService(e.target.value)}
                                >
                                    <option disabled value="DEFAULT">-- Select Service --</option>
                                    <option value='git'>GitHub</option>
                                    <option value='gitlab'>GitLab</option>
                                    <option value='bitbucket'>Bitbucket</option>
                                </Form.Control>
                            </div>
                        )}


                        {props.gitCredsDone === null ? (
                            <div className='button-authorize-done'>
                                <Button
                                    variant='outline-primary'
                                    onClick={e => props.handleGitOauth()}
                                    disabled={!props.name && !props.service}
                                >
                                    Authorize
                                </Button>
                            </div>
                        ) : (
                            <div className='button-authorize-done'>
                                <Button
                                    variant='outline-primary'
                                    disabled
                                >
                                    Authorized
                                </Button>
                                <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                        )}

                    </div>
                </div>
            </div>

            {props.awsCredDone && (
                <>
                    {props.gitCredsDone === 'successs' && props.awsCredDone.length > 0 && (
                        <>
                            <p>You are all set now, let&apos;s go.</p>
                            <Button
                                onClick={props.handleNextStep}
                            >
                                Next
                            </Button>
                        </>
                    )}
                </>
            )}

        </div>


    )
}

export default OnboardingStepCreds;