import React from 'react';

const BillingPlanFeatureList = (props) => {
    return (      
        <ul className="list-group list-group-flush featureList">        
            {props.featureList.map((feature) => (
                <li className="list-group-item" key={feature}>{feature}</li>
            ))}
        </ul>
      );
}

export default BillingPlanFeatureList;