import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

/*********************************
 * Config Credentials
 *********************************/

export const setCredentials = (credentials) => {
//   AWS.config.update(credentials);
//   return {
//     apiKey: AWS.config.credentials.accessKeyId,
//     secretKey: AWS.config.credentials.secretAccessKey,
//     region: AWS.config.region
//   }
};

/********************************
 * Verify Creds
 ********************************/
export const verifyCreds = async (credID) => {      
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));      
    return axios.get(`${URL}/aws_cred_verify/${credID}`, { headers: authToken });  
}

/********************************
 * IAM API
 ********************************/
export const getIAMUser = async (credentials, user) => {
//   await AWS.config.update(credentials);
//   const iam = new AWS.IAM({apiVersion: '2010-05-08'});    
//   const response = await iam.getUser(user).promise();
//   return response.User;
}

/********************************
 * Cloud Watch API
 ********************************/
export const getMetrics = async (habitat_id, start_time, end_time, period, metric_name, func_name, stat, unit, id) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    const data = {
        habitat_id,
        start_time,
        end_time,
        period,
        metric_name,
        func_name,
        stat,
        unit,
        id
    }
    return axios.post(`${URL}/aws/aws_metrics`, data, { headers: authToken });  
}


/*********************************
 * S3 Buckets API
 *********************************/
export const getBuckets = async (habitat_id) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));      
    return axios.get(`${URL}/aws/aws_s3s/${habitat_id}`, { headers: authToken });  
};

export const getBucketFile = async (bucket, habitat_id) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));      
    return axios.get(`${URL}/aws/count_bucket_files?habitat_id=${habitat_id}&bucket=${bucket}`, { headers: authToken });  
};

export const removeBucket = async (habitat_id, bucket) => {
    let data = {
        bucket
    }
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));      
    return axios.post(`${URL}/aws/aws_s3s/remove/${habitat_id}`, data, { headers: authToken });  
};

export const addBucket = async (bucket, habitat_id, region) => {
    const data = {
        bucket,
        region,
        habitat_id
    }
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/aws_s3s`, data, { headers: authToken });      
}

export const addStaticBucket = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/aws_s3s`, data, { headers: authToken });      
}

export const uploadFile = async (file, bucket,  habitat_id, file_name) => {
    const data = {
        file,
        bucket,
        habitat_id,
        file_name 
    }
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/upload`, data, { headers: authToken });  
}

export const uploadFolder = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/upload_folder`, data, { headers: authToken });
}

export const uploadClone = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/upload_repo`, data, { headers: authToken }, { timeout: 60 * 4 * 1000 });
}

export const addEnv = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/upload_env`, data, { headers: authToken });
}

export const addBuildspec = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/upload_buildspec`, data, { headers: authToken });
}

export const clearBucket = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.delete(`${URL}/aws/clear_bucket?habitat_id=${data.habitat_id}&bucket=${data.bucket}`, { headers: authToken });
}

export const disconnectS3s = (habId, bucketName) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));         
    let data = {
      bucket: bucketName
    }
   
    return axios.post(`${URL}/aws/aws_s3s/deselect/${habId}`, data, { headers: authToken})
}

export const s3ToEbs = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/s3_to_ebs`, data, { headers: authToken });
}

/********************************
 * Cloud Watch Logs
 ********************************/

export const getListLogs = async (habitat_id, func_name, limit) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    const data = {
        habitat_id,
        func_name,
        limit
    }
    return axios.post(`${URL}/aws/aws_logs`, data, { headers: authToken });  
}

export const getLog = async (habitat_id, func_name, log_stream_name) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    const data = {
        habitat_id,
        func_name,
        log_stream_name
    }
    return axios.post(`${URL}/aws/aws_log`, data, { headers: authToken });  
}

export const getBuildStatus = (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/deploy_status`, data, { headers: authToken });  
} 

/********************************
 * Dynamo DB
 ********************************/

export const getDB = async (habitat_id) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.get(`${URL}/aws/aws_dynamo/?habitat_id=${habitat_id}`, { headers: authToken });
}

export const createDB = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/aws_dynamo/`, data, { headers: authToken });
}

export const removeDB = async (id) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.delete(`${URL}/aws/aws_dynamo/${id}`, { headers: authToken });
}

/********************************
 * Route53
 ********************************/

export const getDomain = async (habitat_id) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.get(`${URL}/aws/aws_static_sites/?habitat_id=${habitat_id}`, { headers: authToken });  
}

export const addDomian = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/aws_static_sites`, data, { headers: authToken });  
}

/********************************
 * Codebuild 
 ********************************/

export const createProject = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/create_project`, data, { headers: authToken });  
}

export const createBuild = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/code_build`, data, { headers: authToken });  
}

export const dataTransfer = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/data_transfer`, data, { headers: authToken });  
}

export const buildLogs = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/aws_build_logs`, data, { headers: authToken });  
}

export const getBuilds = async (habitat_id) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.get(`${URL}/aws/aws_pipeline/?habitat_id=${habitat_id}`, { headers: authToken });  
}

export const getBuildExecution = async (id, habitat_id) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.get(`${URL}/aws/show_executions/${id}/?habitat_id=${habitat_id}`, { headers: authToken });  
}

export const changeSiteStatus = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/site_status`, data, { headers: authToken });  
}

export const deleteBuild = async (build_id, habitat_id) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.delete(`${URL}/aws/aws_pipeline/${build_id}?habitat_id=${habitat_id}`, { headers: authToken });
}

/********************************
 * CloudFront
 ********************************/

export const requestDomain = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/certificate_request`, data, { headers: authToken });  
}

export const contentDistribution = async (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws/content_distribution`, data, { headers: authToken });  
}
