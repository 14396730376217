import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetPassword } from '../actions/user.actions';
import ResetPassword from '../components/account/ResetPassword';
import routes from '../constants/routes';

const ResetPasswordPage = withRouter(({resetPassword, history}) => {

  const [email, setEmail] = useState('');
  
  const handleReset = () => {
    resetPassword(email);
    history.push(routes.SIGNIN);
  }

  return (        
    <ResetPassword 
      email={email}
      setEmail={setEmail}
      handleReset={handleReset}
    />    
  );
});

const mapState = (state, props) => ({
  history: props.history
});

const mapDispatch = {
  resetPassword
};

export default connect(
  mapState,
  mapDispatch
)(ResetPasswordPage);
