import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { listAWSCreds } from '../../actions/aws-creds.actions';
import { newDeployment, allDeployments } from '../../actions/deployment.actions';
import HabitatDeployItem from './HabitatDeployItem';
import { Alert, ListGroup } from 'react-bootstrap';
import { nextStepTour, stopTour } from '../../actions/app-tour.actions';
import { changeStatusOnboarding } from '../../actions/user.actions'
import AppTour from '../../components/AppTour';
import Spinner from 'react-bootstrap/Spinner';
import HabitatDeployAll from '../../components/Habitats/HabitatDeployAll';
import { deployAll } from '../../actions/funcs.actions';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { faQuestionCircle, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HabitatDeployList = ({
  step,
  funcs,
  habitat,
  active,
  stepAppTour,
  statusAppTour,
  nextStepTour,
  stopTour,
  changeStatusOnboarding,
  loadingFunction,
  gitClones,
  onboardingClassFuncs,
  errorFuncs,
  loadingMessage
}) => {

  const [depClass, setDepclass] = useState('unDeploy-ctn');
  const [noCredsAlertShow, setNoCredsAlertShow] = useState(true);


  useEffect(() => {
    handleActive();
  }, []);

  const handleActive = () => {
    if (active && step === 10) {
      setDepclass('deploy-ctn');
    }
  }

  return (
    <div className="functions-deploy-container">
      <AppTour
        stepAppTour={stepAppTour}
        statusAppTour={statusAppTour}
        nextStepTour={nextStepTour}
        stopTour={stopTour}
        changeStatusOnboarding={changeStatusOnboarding}
        loadingFunction={loadingFunction}
      />

      {errorFuncs && (
        <div className="error-display text-danger">
          <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {errorFuncs}</p>
        </div>
      )}

      {!habitat.cred_id && (
        <Alert show={noCredsAlertShow} variant="warning" className="kor-alerts" onClose={() => setNoCredsAlertShow(false)} dismissible>
          <p>No credentials set.</p>
        </Alert>
      )}
      {loadingFunction && (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
          <p>{loadingMessage}</p>
        </div>
      )}
      {funcs.length > 0 ? (
        <div className={onboardingClassFuncs + ' all-deployment-items'}>
          <ListGroup>
            {funcs.map((func, index) => (
              <HabitatDeployItem
                func={func}
                key={func.id}
                index={index}
                gitClones={gitClones}
              />
            ))}
          </ListGroup>
        </div>
      ):(
        <div className="blank-section">No functions added yet.</div>
      )}

    </div>
  )
}

const mapState = (state) => ({
  habitat: state.habitat.habitat,
  active: state.user.user.active,
  funcs: state.func.funcs,
  stepAppTour: state.appTour.stepAppTour,
  statusAppTour: state.user.user.onboarding,
  loadingFunction: state.func.loading,
  loadingMessage: state.func.loading_message,
  gitClones: state.gitClone.gitClones,
  onboardingClassFuncs: state.appTour.onboardingClassFuncs,
  errorFuncs: state.func.error
})

const mapDispatch = {
  allDeployments,
  listAWSCreds,
  newDeployment,
  nextStepTour,
  stopTour,
  changeStatusOnboarding
}

export default connect(
  mapState,
  mapDispatch
)(HabitatDeployList);