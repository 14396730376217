import React from 'react';
import '../../assets/styles/modals/Modal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button'

const DeleteRepositoryModal = (props) => {
    const handleCloseModal = () => {
        props.setShowDeleteRepository('display-none-kor');
        props.setActiveClassPop(null)
    }

    return (
        <div className={props.showDeleteRepository + ' modal-kor-container modal-delete-repository'} >
            <div className="box-modal-options-container">
                <p><FontAwesomeIcon icon={faExclamationTriangle} className="delete-icon" /> Delete Repository</p>

                <p>Are you sure you want to delete this repository?</p>
                <div className="buttons-modal-kor">
                    <Button variant="primary" size="sm" onClick={() => props.handleDeleteRepository(props.name, props.id)}>
                        Delete
                </Button>
                    <Button variant="primary" size="sm" onClick={handleCloseModal}>
                        Cancel
                </Button>
                </div>

            </div>
        </div>
    )
}

export default DeleteRepositoryModal;