import {
    PAYMENT_BEGIN,
    PAYMENT_SUCCESS,
    PAYMENT_ERROR,
    GET_USER_PAYMENT_BEGIN,
    GET_USER_PAYMENT_SUCCESS,
    GET_USER_PAYMENT_ERROR,
    DOWNGRADE_BEGIN,
    DOWNGRADE_SUCCESS,
    DOWNGRADE_ERROR,
    UPDATE_SUBSCRIPTION_PLAN_STARTUP,
    GET_STARTUP_PAYMENT_BEGIN,
    GET_STARTUP_PAYMENT_SUCCESS,
  } from './types';
  import {
    purchasePro,
    getProSubscriptions,
    downgradeProPlan,
    purchaseStartup,
    getStartupSubscriptions,
  } from '../api/payments';
  
  // Purchase Pro Plan
  export const subscribePro = (tk, interval) => {
    return (dispatch) => {
      dispatch({ type: PAYMENT_BEGIN });
      purchasePro(tk, interval)
        .then((response) => {
          dispatch({
            type: PAYMENT_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({ type: PAYMENT_ERROR, error });
        });
    };
  };
  // Purchase Startup Plan
  export const subscribeStartup = (tk, interval, team_email, seats) => {
    return (dispatch) => {
      dispatch({ type: PAYMENT_BEGIN });
      purchaseStartup(tk, interval, team_email, seats)
        .then((response) => {
          dispatch({
            type: PAYMENT_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({ type: PAYMENT_ERROR, error });
        });
    };
  };
  
  // Get all pro payments
  export const getProPayments = () => {
    return (dispatch) => {
      dispatch({ type: GET_USER_PAYMENT_BEGIN });
      getProSubscriptions()
        .then((response) => {
          dispatch({
            type: GET_USER_PAYMENT_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_USER_PAYMENT_ERROR, error });
        });
    };
  };
  
  // Downgrade from Pro Plan
  export const downgradePro = (stripeId) => {
    return (dispatch) => {
      dispatch({ type: DOWNGRADE_BEGIN });
      downgradeProPlan(stripeId)
        .then((response) => {
          dispatch({
            type: DOWNGRADE_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({ type: DOWNGRADE_ERROR, error });
        });
    };
  };
  
  //Startup Plan
  export const updatePlanStartup = (plan) => {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    user.subscription.startup = plan;
    localStorage.setItem('currentUser', JSON.stringify(user));
    return (dispatch) => {
      dispatch({
        type: UPDATE_SUBSCRIPTION_PLAN_STARTUP,
        payload: plan,
      });
    };
  };
  // GET Startup
  export const getPlanStartup = (team_id) => {
    return (dispatch) => {
      dispatch({ type: GET_STARTUP_PAYMENT_BEGIN });
      getStartupSubscriptions(team_id)
        .then((response) => {
          dispatch({
            type: GET_STARTUP_PAYMENT_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_USER_PAYMENT_ERROR, error });
        });
    };
  };
  