import React from 'react';
import { Spinner } from 'react-bootstrap';

/***************
 * 
 * Loading
 * 
 * Usage:
 * Import as <Loading size="sm" myClass="someClass" />
 * 
 * Params:
 * - Sizes: sm, md, lg
 * - Custom class add the the current spinner class
 * 
 ***************/

const Loading = (props) => {
    return (
      <Spinner size={props.size} animation="border" className={props.myClass} variant="primary" role="status">
          <span className="sr-only">Loading...</span>
      </Spinner>
    )
};

export default Loading;