import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { faCheck, faTimes, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import routes from '../../constants/routes';

const InvitationBox =  withRouter(
    ({
     invitations, 
     invitation, 
     fetchInvitations, 
     rejectInvite, 
     acceptInvite,
     history,
     fetchTeams
    }) => {

    const handleDeleteInvite = (inviteId) => {
        rejectInvite(inviteId);
    }

    const handleAcceptInvite = (inviteId) => {
        acceptInvite(inviteId);
        fetchTeams()
        history.push(`${routes.TEAMS}`)
    }

    return (
        <div className="invitations-list">
            {invitations && invitations.map(invitations => (
                <div className="invitation-container" key={invitations.invite_id}>
                    <div className="name-team-invitation">
                        <p className="icon-team"><FontAwesomeIcon icon={faUsers} /> </p>
                        <p>Team Invitation: <b> {invitations.team}</b></p>
                    </div>

                    <div className="buttons-invitation-v2">
                        <Button variant="primary" size="sm"  onClick={() => handleAcceptInvite(invitations.invite_id)}>
                            <b>Accept</b>
                        </Button>
                        <Button variant="outline-danger" size="sm" onClick={() => handleDeleteInvite(invitations.invite_id)}>
                            <b>Decline</b>
                        </Button>
                    </div>
                </div>
            ))}
        </div>
    );
})

export default InvitationBox;