import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import '../../assets/styles/modals/ModalKorSmallRight.scss'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import closeIcon from '../../assets/images/icons/close-icon.svg';
import logo from '../../assets/images/Kor-logo.svg';
import user from '../../assets/images/icons/UserCircle.svg';
import Moment from 'moment';

const TeamDetailsModal = ({
    show,
    setShow,
    repoUrl,
    repoName,
    setRepoName,
    teamDetails,
    teamDetailsTeam,
    teamDetailsMembers,
    setMemberEmail,
    handleAddMember,
    handleUpgradeTeam,
    teamDetailsRole,
    handleLeaveTeam
}) => {
    return (
        <>
            {show && (
                <div className="modal-kor-small-right">
                    <div className="box-modal-options-container-small-right teams-modal">
                        <div className="close-title">
                            <img src={closeIcon} onClick={() => setShow(!show)} />
                            <div className="avatar-team">
                                <img src={user} alt='logo'></img>
                            </div>
                            <h3>{teamDetailsTeam.name}</h3>
                        </div>

                        <div className="team-name-update">
                            <div className="avatar-team">
                                <img src={user} alt='logo'></img>
                            </div>
                            <div className="update-input-team">
                                <p>Team Name</p>
                                <Form.Control
                                    type="text"
                                    value={teamDetailsTeam.name}
                                    aria-label="Title"
                                    placeholder={teamDetailsTeam.name}
                                    aria-describedby="new-title"
                                    disabled
                                />
                            </div>
                        </div>

                        <div className="membership-team-container">
                            <div className="membership-text">
                                <p>Membership: <span>{teamDetailsTeam.subscription}</span></p>
                                <p>Current team has <span>{teamDetailsMembers.members.length}</span> members.</p>
                            </div>

                            <div>
                                <Button onClick={() => handleUpgradeTeam()}>
                                    Upgrade
                                </Button>
                            </div>
                        </div>

                        <div className="new-member-add">
                            <p className="title-subsection-team">Add New Member</p>
                            <div className="input-new-member">
                                <p>Email </p>

                                {teamDetailsRole === "Owner" ? (
                                    <Form.Control
                                        type="text"
                                        value={repoName}
                                        aria-label="Title"
                                        placeholder="Member Email"
                                        aria-describedby="new-title"
                                        onChange={e => setMemberEmail(e.target.value)}
                                    />
                                ) : (

                                    <Form.Control
                                        type="text"
                                        value={repoName}
                                        aria-label="Title"
                                        placeholder="Member Email"
                                        aria-describedby="new-title"
                                        disabled
                                    />
                                )}


                                {teamDetailsRole === "Owner" ? (
                                    <Button onClick={() => handleAddMember()}>
                                        Send Invite
                                    </Button>
                                ) : (
                                    <Button disabled>
                                        Send Invite
                                    </Button>
                                )}

                            </div>
                            <p>Adding a new member to the team will increase monthly cost.</p>
                        </div>

                        <p className="title-subsection-team">Members</p>
                        <div className="members-team-table">
                            <table className="table">
                                <thead>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Join Date</th>
                                </thead>
                                <tbody>
                                    {teamDetailsMembers.members.map(member => (
                                        <tr key={member.id}>
                                            <td>{member.name}</td>
                                            <td>{member.email}</td>
                                            <td>{member.role}</td>
                                            <td>{Moment(member.joined_at).format('ll')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="leave-team-button">
                            {teamDetailsRole === "Member" && (
                                <Button onClick={() => handleLeaveTeam(teamDetailsTeam, teamDetailsMembers.members)}>
                                    Leave team
                                </Button>
                            )}

                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default TeamDetailsModal;