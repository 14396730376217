import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import '../assets/styles/pages/Habitats.scss';
import { getTests, saveTest } from '../actions/funcs.actions';
import { listAWSS3 } from '../actions/aws-s3.actions';
import { listFunctions } from '../actions/funcs.actions';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { listAWSCreds } from '../actions/aws-creds.actions';
import { fetchAWSRole } from '../actions/aws-roles.actions';
import { listCognito } from '../actions/aws-cognito.actions';
import { retrieveHabitat } from '../actions/habitat.actions';
import { getApiList } from '../actions/aws-apigateway.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HabitatTopMenu from '../components/Habitats/HabitatTopMenu';
import HabitatDynamoAPI from '../components/Habitats/HabitatDynamoAPI';
import HabitatAPIGateway from '../components/Habitats/HabitatAPIGateway';
import { 
  apiTest, 
  createAPI, 
  createRoute, 
  deleteMethod, 
  getApiMetrics, 
  createCrudApi, 
  createCognitoAPI,
  createCognitoCrudApi 
} from '../actions/aws-apigateway.actions';

const HabitatAPIGatewayPage = ({
  team,
  funcs,
  error,
  params,
  habitat,
  apiTest,
  testApi,
  methods,
  loading,
  awsCreds,
  awsRoles,
  cognitos,
  getTests,
  funcTest,
  saveTest,
  createAPI,
  s3Buckets,
  listAWSS3,
  getApiList,
  apiMetrics,
  funcLoading,
  createRoute,
  methodError,
  apiGateways,
  listCognito,
  listAWSCreds,
  deleteMethod,
  fetchAWSRole,
  getApiMetrics,
  createCrudApi,
  listFunctions,
  loadingMessage,
  retrieveHabitat,
  createCognitoAPI,
  loadingFuncMessage,
  createCognitoCrudApi
}) => {

  useEffect(() => {
    if (habitat.id) {
      listAWSS3(habitat.id);
      getApiList(habitat.id);
      listCognito(habitat.id);
      listAWSCreds(habitat.id);
      fetchAWSRole(habitat.id);
      listFunctions(habitat.id);
    } else {
      listAWSS3(params.habitatId);
      getApiList(params.habitatId);
      listCognito(params.habitatId);
      listAWSCreds(params.habitatId);
      fetchAWSRole(params.habitatId);
      listFunctions(params.habitatId);
      retrieveHabitat(params.habitatId);
    }
  }, []);

  const [apiId, setApiId]             = useState('');
  const [selectedAPI, setSelectedAPI] = useState('');

  return (
    <>
      <div className="habitat-sub-menu">
        
        <div className="sub-menu-side-l">
          <FontAwesomeIcon icon={faGlobe} />
          <p>{habitat.name}</p>
        </div>

        <HabitatTopMenu 
          habitat={habitat}
        />
      </div>

      <div className="habitat-container-settings">

        <div className="api-feature-container">

          <HabitatDynamoAPI 
            team={team}
            funcs={funcs}
            error={error}
            apiId={apiId}
            habitat={habitat}
            loading={loading}
            methods={methods}
            awsCreds={awsCreds}
            awsRoles={awsRoles}
            cognitos={cognitos}
            s3Buckets={s3Buckets}
            createAPI={createAPI}
            createRoute={createRoute}
            selectedAPI={selectedAPI}
            methodError={methodError}
            deleteMethod={deleteMethod}
            createCrudApi={createCrudApi}
            loadingMessage={loadingMessage}
            createCognitoAPI={createCognitoAPI}
            createCognitoCrudApi={createCognitoCrudApi}
          />

        </div>

        <div className="habitat-feature-container-api">
          
          <HabitatAPIGateway
            error={error}
            methods={methods}
            habitat={habitat}
            apiTest={apiTest}
            testApi={testApi}
            loading={loading}
            funcTest={funcTest}
            saveTest={saveTest}
            getTests={getTests}
            setApiId={setApiId}
            funcLoading={funcLoading}
            selectedAPI={selectedAPI}
            apiGateways={apiGateways}
            setSelectedAPI={setSelectedAPI}
            loadingFuncMessage={loadingFuncMessage}
          />

        </div>

      </div>

    </>
  );
}

const mapState = (state, props) => ({
  team: state.team.team,
  funcs: state.func.funcs,
  params: props.match.params,
  error: state.apiGateway.error,
  funcTest: state.func.funcTest,
  habitat: state.habitat.habitat,
  funcLoading: state.func.loading,
  dynamos: state.awsDynamo.dynamos,
  s3Buckets: state.awsS3.s3Buckets,
  testApi: state.apiGateway.testApi,
  awsRoles: state.awsRoles.awsRoles,
  loading: state.apiGateway.loading,
  methods: state.apiGateway.methods,
  cognitos: state.cognito.cognitoList,
  awsCreds: state.awsCreds.awsCredentials,
  apiMetrics: state.apiGateway.apiMetrics,
  methodError: state.apiGateway.methodError,
  apiGateways: state.apiGateway.apiGateways,
  loadingFuncMessage: state.func.loading_message,
  loadingMessage: state.apiGateway.loading_message
});

const mapDispatch = {
  apiTest,
  getTests,
  saveTest,
  listAWSS3,
  createAPI,
  getApiList,
  listCognito,
  createRoute,
  fetchAWSRole,
  deleteMethod,
  listAWSCreds,
  getApiMetrics,
  listFunctions,
  createCrudApi,
  retrieveHabitat,
  createCognitoAPI,
  createCognitoCrudApi
}

export default connect(
  mapState,
  mapDispatch  
)(HabitatAPIGatewayPage);