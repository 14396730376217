import produce from 'immer';
import {
  SET_AWS_DYNAMO_BEGIN,
  SET_AWS_DYNAMO_SUCCESS,
  SET_AWS_DYNAMO_ERROR,
  GET_AWS_DYNAMO_BEGIN,
  GET_AWS_DYNAMO_SUCCESS,
  GET_AWS_DYNAMO_ERROR,
  REMOVE_AWS_DYNAMO_BEGIN,
  REMOVE_AWS_DYNAMO_SUCCESS,
  REMOVE_AWS_DYNAMO_ERROR
} from '../actions/types';

const initialState = {
  dynamos: [],
  loading: false,
  loading_message: '',
  error: null
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case SET_AWS_DYNAMO_BEGIN:
    case GET_AWS_DYNAMO_BEGIN:
    case REMOVE_AWS_DYNAMO_BEGIN:
      draft.loading = true;
      draft.error = null;
      draft.loading_message = action.loading_message;
      return;
    case SET_AWS_DYNAMO_SUCCESS:
    case GET_AWS_DYNAMO_SUCCESS:
    case REMOVE_AWS_DYNAMO_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.dynamos = action.payload;
      return;
    case REMOVE_AWS_DYNAMO_ERROR:
      draft.loading = false;
      draft.error = action.errorRemoveDynamo;
      return;
    case GET_AWS_DYNAMO_ERROR:
      draft.loading = false;
      draft.error = action.errorGetDynamo;
      return;
    case SET_AWS_DYNAMO_ERROR:
      draft.loading = false;
      draft.error = action.errorCreateDynamo;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;