import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import {
  fetchTeam,
  leaveTeam,
  createTeam,
  fetchTeams,
  deleteTeam,
  deleteError,
  inviteMember,
  unselectTeam,
  fetchMembers,
  inviteAndCharge,
  changeSelectedTeam,
  deleteTeamVerification,
  getDetailsTeam,
  fetchTeamsWithMembers
} from '../actions/team.actions';
import '../assets/styles/pages/teams-page.scss';
import TeamList from './teams/TeamList';
import TeamInvitations from './teams/TeamInvitations';
import { Modal, Button, Form } from 'react-bootstrap';
import TeamManagement from './teams/TeamManagement';
import TeamMembers from './teams/TeamMembers';
import plus from '../assets/images/icons/overview/plus.svg';
import ModalCreateTeam from './teams/modals/CreateTeamModal'
import ModalAddMember from './teams/modals/AddMemberModal';
import ModalDeleteTeam from './teams/modals/DeleteTeamModal';
import routes from '../constants/routes.json';
import rowRight from '../assets/images/icons/overview/arrow-right.svg';
import TeamDetailsModal from '../components/Modals/TeamDetailsModal';
import CreateTeamModal from '../components/Modals/CreateTeamModal';
import DeleteTeamModal from '../components/Modals/DeleteTeamModal';
import { fetchInvitations, rejectInvite, acceptInvite } from '../actions/invitation.actions';

const Teams = withRouter(({
  team,
  teams,
  idTeam,
  members,
  loading,
  history,
  fetchTeam,
  leaveTeam,
  errorTeam,
  deleteTeam,
  fetchTeams,
  createTeam,
  currentUser,
  deleteError,
  inviteMember,
  unselectTeam,
  fetchMembers,
  teamDetails,
  inviteAndCharge,
  changeSelectedTeam,
  deleteTeamVerification,
  deleteTeamVerificationState,
  getDetailsTeam,
  error,
  loadingMessage,
  teamDetailsMembers,
  teamDetailsTeam,
  teamDetailsRole,
  fetchInvitations,
  invitations,
  acceptInvite,
  rejectInvite,
  fetchTeamsWithMembers,
  teamsWithMembers
}) => {

  const [teamName, setTeamName] = useState('');
  const [memberEmail, setMemberEmail] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showError, setShowError] = useState('error-teams');
  const [modalCreateTeam, setmodalCreateTeam] = useState('display-none');
  const [modalDeleteTeam, setModalDeleteTeam] = useState(false);
  const [modalAddMember, setModalAddMember] = useState('display-none');
  const [showDisabledCard, setShowDisabledCard] = useState('display-modal');
  const [show, setShow] = useState(false);
  const [showNewTeam, setShowNewTeam] = useState(false);


  useEffect(() => {
    fetchTeams();
    fetchInvitations();
    fetchTeamsWithMembers();
    if(teams.length > 0){
      getDetailsTeam(teams[0].id)
    }
    
  }, [])

  useEffect(() => {
    if (team.id) {
      handleButtons(team.id);
    }
    if (errorTeam) {
      deleteError();
    }
  }, [team]);


  const handleCreateTeam = (e) => {
    createTeam(teamName);
    fetchTeamsWithMembers();
    setShowNewTeam(false);
  }


  const handleAddMember = () => {
    inviteMember(memberEmail, team.id);
    setShow(false);
  }

  const handleAddMemberAndCharge = () => {
    inviteMember(memberEmail, team.id);
    setModalAddMember('display-none');
  }

  const handleDeleteTeam = (teamId) => {
    deleteTeam(teamId);
    fetchTeamsWithMembers();
    setModalDeleteTeam(false);
  }

  const handleModalCreateTeam = () => {
    if (modalCreateTeam === 'display-none') {
      setmodalCreateTeam('display-modal');
    } else {
      setmodalCreateTeam('display-none');
    }
  }

  const handleLeaveTeam = (teamSelected, membersTeam) => {
    leaveTeam(teamSelected, currentUser, membersTeam);
    setShow(false);
  }

  const handleModalDeleteTeam = (teamId) => {
    deleteTeamVerification(teamId);
    setModalDeleteTeam(true);
  }

  const handleModalAddMember = () => {
    if (modalAddMember === 'display-none') {
      setModalAddMember('display-modal');
    } else {
      setModalAddMember('display-none');
    }
  }

  const handleButtons = (teamId) => {
    teams.forEach(team => {
      if (team.id === teamId) {
        if (team.active) {
          setButtonDisabled(true);
          setShowDisabledCard('display-none');
        } else {
          setButtonDisabled(false);
          setShowDisabledCard('display-modal');
        }
      }
    })
  }

  const handleCloseError = () => {
    setShowError('error-teams-none')
    if (errorTeam) {
      deleteError();
    }
  }

  const handleUpgradeTeam = () => {
    history.push(routes.BILLING);
  }

  const handleGetDetailsTeam = (teamId) => {
    getDetailsTeam(teamId)
  }

  return (
    <div>
      <div className="teams-container">
        <TeamDetailsModal
          show={show}
          setShow={setShow}
          teamDetailsMembers={teamDetailsMembers}
          teamDetailsTeam={teamDetailsTeam}
          handleAddMember={handleAddMember}
          setMemberEmail={setMemberEmail}
          handleUpgradeTeam={handleUpgradeTeam}
          teamDetailsRole={teamDetailsRole}
          handleLeaveTeam={handleLeaveTeam}
        />

        <CreateTeamModal
          showNewTeam={showNewTeam}
          setShowNewTeam={setShowNewTeam}
          handleCreateTeam={handleCreateTeam}
          setTeamName={setTeamName}
        />

        <DeleteTeamModal
          modalDeleteTeam={modalDeleteTeam}
          setModalDeleteTeam={setModalDeleteTeam}
          deleteTeamVerificationState={deleteTeamVerificationState}
          teamDetailsTeam={teamDetailsTeam}
          handleDeleteTeam={handleDeleteTeam}
        />


        <div className='init-ctn title-section-ui'>
          <h2><b>Teams</b></h2>
          <div>
            <Button onClick={() => setShowNewTeam(true)}>
              <img src={plus} /> Create new team
                    </Button>
          </div>
        </div>
        <div className="teams-list-management-container">
          <div className="team-list-container">

            {invitations.length > 0 && (
              <TeamInvitations
                invitations={invitations}
                acceptInvite={acceptInvite}
                rejectInvite={rejectInvite}
              />
            )}

            <TeamList
              team={team}
              teams={teams}
              teamsWithMembers={teamsWithMembers}
              errorTeam={errorTeam}
              showError={showError}
              fetchTeam={fetchTeam}
              unselectTeam={unselectTeam}
              fetchMembers={fetchMembers}
              handleButtons={handleButtons}
              buttonDisabled={buttonDisabled}
              handleCloseError={handleCloseError}
              setButtonDisabled={setButtonDisabled}
              changeSelectedTeam={changeSelectedTeam}
              error={error}
              loading={loading}
              loadingMessage={loadingMessage}
              setShow={setShow}
              handleGetDetailsTeam={handleGetDetailsTeam}
              handleModalDeleteTeam={handleModalDeleteTeam}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

const mapState = (state, props) => ({
  team: state.team.team,
  history: state.history,
  teams: state.team.teams,
  teamsWithMembers: state.team.teams_withmembers.teamsArray,
  idTeam: state.team.team.id,
  loading: state.team.loading,
  currentUser: state.user.user,
  members: state.team.team_members,
  errorTeam: state.team.errorTeams,
  deleteTeamVerificationState: state.team.team_verification,
  error: state.team.error,
  teamDetailsTeam: state.team.team_details.teamDetails,
  teamDetailsMembers: state.team.team_details.members,
  teamDetailsRole: state.team.team_details.roleTeam,
  loadingMessage: state.team.loading_message,
  invitations: state.invitation.invitations
});

const mapDispatch = {
  fetchTeam,
  leaveTeam,
  fetchTeams,
  createTeam,
  deleteTeam,
  deleteError,
  inviteMember,
  unselectTeam,
  fetchMembers,
  inviteAndCharge,
  changeSelectedTeam,
  deleteTeamVerification,
  getDetailsTeam,
  fetchInvitations,
  rejectInvite,
  acceptInvite,
  fetchTeamsWithMembers
};

export default connect(
  mapState,
  mapDispatch
)(Teams);