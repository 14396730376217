import React, { useEffect, useState } from 'react';

const HabitatMonitoringMenu = ({
    selectedFeature,
    setSelectedFeature
}) => {

    return (
        <>
            {selectedFeature === 'resources' && (
                <div className="habitat-monitoring-menu-container">
                    <p className="active-monitoring" onClick={
                        () => setSelectedFeature('resources')}>
                        Resources</p>
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('monitor')}>
                        Monitor</p>
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('apigateway')}>
                        Api Gateway</p>
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('cloudfront')}>
                        Cloudfront</p>
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('resources-report')}>
                        Resources Report</p>
                </div>
            )}

            {selectedFeature === 'monitor' && (
                <div className="habitat-monitoring-menu-container">
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('resources')}>
                        Resources</p>
                    <p className="active-monitoring" onClick={
                        () => setSelectedFeature('monitor')}>
                        Monitor</p>
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('apigateway')}>
                        Api Gateway</p>
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('cloudfront')}>
                        Cloudfront</p>
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('resources-report')}>
                        Resources Report</p>
                </div>
            )}

            {selectedFeature === 'apigateway' && (
                <div className="habitat-monitoring-menu-container">
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('resources')}>
                        Resources</p>
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('monitor')}>
                        Monitor</p>
                    <p className="active-monitoring" onClick={
                        () => setSelectedFeature('apigateway')}>
                        Api Gateway</p>
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('cloudfront')}>
                        Cloudfront</p>
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('resources-report')}>
                        Resources Report</p>
                </div>
            )}

            {selectedFeature === 'cloudfront' && (
                <div className="habitat-monitoring-menu-container">
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('resources')}>
                        Resources</p>
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('monitor')}>
                        Monitor</p>
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('apigateway')}>
                        Api Gateway</p>
                    <p className="active-monitoring" onClick={
                        () => setSelectedFeature('cloudfront')}>
                        Cloudfront</p>
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('resources-report')}>
                        Resources Report</p>
                </div>
            )}

            {selectedFeature === 'resources-report' && (
                <div className="habitat-monitoring-menu-container">
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('resources')}>
                        Resources</p>
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('monitor')}>
                        Monitor</p>
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('apigateway')}>
                        Api Gateway</p>
                    <p className="no-active-monitoring" onClick={
                        () => setSelectedFeature('cloudfront')}>
                        Cloudfront</p>
                    <p className="active-monitoring" onClick={
                        () => setSelectedFeature('resources-report')}>
                        Resources Report</p>
                </div>
            )}

        </>
    )
}

export default HabitatMonitoringMenu;