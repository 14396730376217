import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

/*********************************
 * Get file system tree
 *********************************/
export const getFileSystem = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  if (!data) {    
    return await axios.get(`${URL}/file_system/`, { headers: authToken });
  } else {
    return await axios.get(`${URL}/file_system/?team_id=${data}`, { headers: authToken });
  }
}

/*********************************
 * Open file
 *********************************/
export const openFile = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.post(`${URL}/file_system`, data, { headers: authToken });  
}

/*********************************
 * Zip git repositorie
 *********************************/
export const zipFile = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.post(`${URL}/zip`, data, { headers: authToken });  
}

export const zipRemove = async (path) => {
  const data = {
    path
  }
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.post(`${URL}/remove_zip`, data, { headers: authToken });  
}

/*********************************
 * Upload env repositorie
 *********************************/
export const envRepo = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.post(`${URL}/upload_env_repo`, data, { headers: authToken });  
}