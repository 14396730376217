import  React from 'react';
import { Row, Col, ListGroup, Form, Button } from 'react-bootstrap';

const AddStepfunc = ({
  states, 
  funcs, 
  setStates, 
  handleSelectFuntion, 
  handleSelectTask, 
  handleSelectProcess, 
  handleAddStepFunc
}) => {
  return (
    <>
      {states.map((state, index) => (
        <ListGroup key={index} className="mt-3 mb-0">
          <div className="habitat-list-item-wrapper">
            <div className="habitat-list-machine">
              <Row>
                <Col xs={12} lg={4}>
                  <Form.Group>
                    <Form.Label>Select Function</Form.Label>
                    <Form.Control 
                      as="select" 
                      custom
                      onChange={(e) => handleSelectFuntion(e, index)} 
                    >
                      <option>Select Function</option>
                      {funcs.map((func, index) => (
                        func.function_arn && (
                          <option key={func.id} value={func.function_arn}>{func.function_name}</option>
                        )
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} lg={4}>
                  <Form.Group>
                    <Form.Label>Select Task</Form.Label>
                    <Form.Control 
                      as="select" 
                      custom
                      onChange={(e) => handleSelectTask(e, index)} 
                    >
                      <option>Select Task</option>
                      <option>Pass</option>
                      <option>Task</option>
                      <option>Choise</option>
                      <option>Wait</option>
                      <option>Fail</option>
                      <option>Parallel</option>
                      <option>Map</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} lg={4}>
                  <Form.Group>
                    <Form.Label>Select Process</Form.Label>
                    <Form.Control 
                      as="select" 
                      custom
                      onChange={(e) => handleSelectProcess(e, index)}  
                    >
                      <option>Select Process</option>
                      <option>Next</option>
                      <option>End</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </div>
        </ListGroup>
      ))}
      <div className="d-flex justify-content-end mt-3">
        <Button onClick={handleAddStepFunc}>Create State Machine</Button>
      </div>
    </>
  )
}

export default AddStepfunc;