import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFileCode
} from '@fortawesome/free-solid-svg-icons';

const HabitatFuncs = (props) => {

    const handleNextStep = () => {
        if(props.stepAppTour === 10){
            props.nextStepTour();
        }
    }
    return (
        <div className={props.onboardingClassFuncs} onClick={handleNextStep}>
            {props.habitatSetUp === 'funcs' ? (
                <div className={props.habitatSetUp + '-selected box-feature'} onClick={() => props.handleSelectFeature('habitat')}>
                    <div className="box-feature-name">
                        <div className="box-feature-name-icon-funcs"><FontAwesomeIcon icon={faFileCode} /></div>
                        <p>Functions</p>
                    </div>

                    <div className="box-feature-plus-1">
                        <p>No. Functions</p>
                        <p>{props.funcs.length}</p>
                    </div>

                    <div className="box-feature-plus">
                        <p>Last Update</p>
                        <p>2020-08-14T16:41:59.400Z</p>
                    </div>
                </div>
            ) : (
                    <div className="box-feature" onClick={() => props.handleSelectFeature('funcs')}>
                        <div className="box-feature-name">
                            <div className="box-feature-name-icon-funcs"><FontAwesomeIcon icon={faFileCode} /></div>
                            <p>Functions</p>
                        </div>

                        <div className="box-feature-plus-1">
                            <p>No. Functions</p>
                            <p>{props.funcs.length}</p>
                        </div>

                        <div className="box-feature-plus">
                            <p>Last Update</p>
                            <p>2020-08-14T16:41:59.400Z</p>
                        </div>
                    </div>
                )}

        </div>
    )
}

export default HabitatFuncs;