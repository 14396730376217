import React, {useState} from 'react';
import { connect } from 'react-redux';
import CardSetupForm from '../components/billing/CreditCard';
import ToastPayment from '../components/toast/ToastPayment';
import { withRouter } from 'react-router-dom';
import routes from '../constants/routes';

const CreditCardPage = withRouter(({
  team,
  error,
  history,
  loading,
  message
}) => {
  const [show, setShow]   = useState(false);
  const [toast, setToast] = useState(false);

  const handleClear = () => {
    setShow(false);
    history.push(routes.BILLING)
  };

  return (
    <>
      <div className="container">
        <div className="h-75 d-inline-block w-100 mb-3">
          <ToastPayment
            show={toast}
            error={error}
            payment={message}
            loading={loading}
            handleClear={handleClear}
          />
        </div>
        <h5 className="mt-5 mb-5">Add Card</h5>
        <CardSetupForm 
          team={team}
          setShow={setShow}
          setToast={setToast}
        />
      </div>
    </>
  );
});

const mapState = (state) => ({
  history: state.history,
  team: state.billing.info,
  error: state.billing.error,
  loading: state.billing.loading,
  message: state.billing.message,
});

export default connect(mapState)(CreditCardPage);
