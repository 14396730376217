import React from 'react';

let style = {
    position: 'absolute',
    top: '5em',
    left: '15em',
    color: 'black',
}

const ConfirmationPendingPage = () => (    
    <div style={style}>
        <h1 id="con-pend-title">Confirmation Pending</h1>    
        <p>Please check your email to confirm your account.</p>        
    </div>
)

export default ConfirmationPendingPage;