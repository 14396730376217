import React from 'react';
import { Row, Col } from 'react-bootstrap';

const CountTemplate = ({ count, setCount, templateName }) => {
  return (
    <Row>
      <Col xs={12}>
        <div className="w-100 d-flex justify-content-center">
          <div className={count === 1 ? ('number-ctn-active') : ('number-ctn')} onClick={() => setCount(1)}>
            <h6 className="mb-0 font-weight-bold">
              1
            </h6>
          </div>
          <div className="border-number-ctn mr-2 ml-2">
            <div className="line-count"></div>
          </div>
          <div className={count === 2 ? ('number-ctn-active') : ('number-ctn')} onClick={() => setCount(2)}>
            <h6 className="mb-0 font-weight-bold">
              2
            </h6>
          </div>
          <div className="border-number-ctn mr-2 ml-2">
            <div className="line-count"></div>
          </div>
          <div className={count === 3 ? ('number-ctn-active') : ('number-ctn')} onClick={() => setCount(3)}>
            <h6 className="mb-0 font-weight-bold">
              3
            </h6>
          </div>
        </div>
      </Col>
    </Row >
  );
}

export default CountTemplate;