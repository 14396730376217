import {
  GET_DOMAINS_BEGIN,
  GET_DOMAINS_SUCCESS,
  GET_DOMAINS_ERROR,
} from './types';
import { getDomain } from '../api/aws/sdk';

export const getAwsDomains = (habitat_id) => {
  return (dispatch) => {
    dispatch({ type: GET_DOMAINS_BEGIN });
    getDomain(habitat_id)
      .then((response) => {
        dispatch({
          type: GET_DOMAINS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => dispatch({ type: GET_DOMAINS_ERROR, error }));
  };
};
