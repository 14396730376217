import produce from 'immer';

import {
    CREATE_CHECKOUT_BEGIN, 
    CREATE_CHECKOUT_SUCCESS, 
    CREATE_CHECKOUT_ERROR 
} from '../actions/types';

const initialState = {
    checkout: {    
        nameplan: '',
        interval_d: '',
        description: '',
        features: [],
        price: '',
        loading: false,
        error: null
    }
}

const reducer = produce((draft, action) => {
    switch(action.type){
        case CREATE_CHECKOUT_BEGIN:
            draft.loading = true;
            draft.error = null;
            return;
        case CREATE_CHECKOUT_SUCCESS:
          draft.checkout = action.payload
          draft.loading = false;
          return;
        case CREATE_CHECKOUT_ERROR:
            draft.error = action.error;
            return;
        default:
            return;
    }
}, initialState)

export default reducer;