import React, { useState, useEffect, useDebugValue } from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { Controlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/base16-light.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import {
  deleteDeployment,
  allDeployments,
} from '../../actions/deployment.actions';
import { getAwsLogs, getAwsLog } from '../../actions/aws-sdk.actions';
import {
  lambdaBuildStatus,
  deployAfterBuild,
  updateAndBuild,
  updateFunction,
  funcInvoke,
  buildDeploy,
  deploy,
} from '../../actions/funcs.actions';
import { fetchAWSRole } from '../../actions/aws-roles.actions';
import { withRouter } from 'react-router-dom';
import { nextStepTour, stopTour } from '../../actions/app-tour.actions';
import routes from '../../constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode } from '@fortawesome/free-solid-svg-icons';
import HabitatDeployEnvVars from './HabitatDeployEnvVars';

const HabitatDeployItem = withRouter(
  ({
    log,
    func,
    team,
    index,
    deploy,
    habitat,
    history,
    awsRoles,
    gitClones,
    funcInvoke,
    buildDeploy,
    stepAppTour,
    fetchAWSRole,
    nextStepTour,
    updateFunction,
    updateAndBuild,
    deployAfterBuild,
    lambdaBuildStatus,
    error
  }) => {
    const [code, setCode]               = useState('version: 0.2\r\n\r\n#env:\r\n  #variables:\r\n     # key: \"value\"\r\n     # key: \"value\"\r\n  #parameter-store:\r\n     # key: \"value\"\r\n     # key: \"value\"\r\n  #secrets-manager:\r\n     # key: secret-id:json-key:version-stage:version-id\r\n     # key: secret-id:json-key:version-stage:version-id\r\n  #exported-variables:\r\n     # - variable\r\n     # - variable\r\n  #git-credential-helper: yes\r\n#batch:\r\n  #fast-fail: true\r\n  #build-list:\r\n  #build-matrix:\r\n  #build-graph:\r\nphases:\r\n  #install:\r\n    #If you use the Ubuntu standard image 2.0 or later, you must specify runtime-versions.\r\n    #If you specify runtime-versions and use an image other than Ubuntu standard image 2.0, the build fails.\r\n    #runtime-versions:\r\n      # name: version\r\n      # name: version\r\n    #commands:\r\n      # - command\r\n      # - command\r\n  #pre_build:\r\n    #commands:\r\n      # - command\r\n      # - command\r\n  build:\r\n    commands:\r\n      # - command\r\n      # - command\r\n  #post_build:\r\n    #commands:\r\n      # - command\r\n      # - command\r\n#reports:\r\n  #report-name-or-arn:\r\n    #files:\r\n      # - location\r\n      # - location\r\n    #base-directory: location\r\n    #discard-paths: yes\r\n    #file-format: JunitXml | CucumberJson\r\n#artifacts:\r\n  #files:\r\n    # - location\r\n    # - location\r\n  #name: $(date +%Y-%m-%d)\r\n  #discard-paths: yes\r\n  #base-directory: location\r\n#cache:\r\n  #paths:\r\n    # - paths'); 
    const [show, setShow]               = useState(false);
    const [type, setType]               = useState('');
    const [build, setBuild]             = useState(false);
    const [fields, setFields]           = useState([{ key: '', value: ''}]);
    const [varShow, setVarShow]         = useState(false);
    const [payload, setPayload]         = useState('');
    const [showInfo, setShowInfo]       = useState('info-func-deploy');
    const [showCode, setShowCode]       = useState('opacity-display');
    const [ownBuild, setOwnBuild]       = useState(false);
    const [codeBuild, setCodeBuild]     = useState(true);
    const initialCode = 'version: 0.2\r\n\r\n#env:\r\n  #variables:\r\n     # key: \"value\"\r\n     # key: \"value\"\r\n  #parameter-store:\r\n     # key: \"value\"\r\n     # key: \"value\"\r\n  #secrets-manager:\r\n     # key: secret-id:json-key:version-stage:version-id\r\n     # key: secret-id:json-key:version-stage:version-id\r\n  #exported-variables:\r\n     # - variable\r\n     # - variable\r\n  #git-credential-helper: yes\r\n#batch:\r\n  #fast-fail: true\r\n  #build-list:\r\n  #build-matrix:\r\n  #build-graph:\r\nphases:\r\n  #install:\r\n    #If you use the Ubuntu standard image 2.0 or later, you must specify runtime-versions.\r\n    #If you specify runtime-versions and use an image other than Ubuntu standard image 2.0, the build fails.\r\n    #runtime-versions:\r\n      # name: version\r\n      # name: version\r\n    #commands:\r\n      # - command\r\n      # - command\r\n  #pre_build:\r\n    #commands:\r\n      # - command\r\n      # - command\r\n  build:\r\n    commands:\r\n      # - command\r\n      # - command\r\n  #post_build:\r\n    #commands:\r\n      # - command\r\n      # - command\r\n#reports:\r\n  #report-name-or-arn:\r\n    #files:\r\n      # - location\r\n      # - location\r\n    #base-directory: location\r\n    #discard-paths: yes\r\n    #file-format: JunitXml | CucumberJson\r\n#artifacts:\r\n  #files:\r\n    # - location\r\n    # - location\r\n  #name: $(date +%Y-%m-%d)\r\n  #discard-paths: yes\r\n  #base-directory: location\r\n#cache:\r\n  #paths:\r\n    # - paths'
    
    useEffect(() => {
      fetchAWSRole(habitat.id);
    }, []);

    const handleDeploy = (func) => {
      func.cicd_enable ?
      deployAfterBuild(func.id, fields) :
      deploy(func, fields)
      if (stepAppTour === 12) {
        nextStepTour();
        history.push(`${routes.OVERVIEW}/:habitatId`)
      }
    };

    const handleBuild = (func) => {
      ownBuild ?
      buildDeploy(awsRoles, func, null)
      :
      buildDeploy(awsRoles, func, code)
    };

    const handleShowInfoFunc = (func) => {
      if (showInfo === 'info-func-deploy') {
        setShowInfo('info-func-deploy-show');
        setShowCode('opacity-display-show');
      } else {
        setShowInfo('info-func-deploy');
        setShowCode('opacity-display');
      }

      if (func.buildspec_source) {
        setCode(func.buildspec_source);
      }

    };

    return (
      <div>
        <div className="box-func-deploy box-feature" key={func.id}>
          <div className="box-feature-name" onClick={() => handleShowInfoFunc(func)}>
            <div className="box-feature-name-container">
              <div className="box-feature-name-icon">
                <FontAwesomeIcon icon={faFileCode} />
              </div>
              <div className="func-name-date-deploy">
                <p>
                  <span>{func.function_name}</span>
                </p>
                <p>
                  <span className="date-color">{func.created_at}</span>
                </p>
              </div>
            </div>
            <div className="runtime-deploy">
              {!func.function_arn && (
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => handleDeploy(func)}
                  className="btn-search-deployed"
                >
                  Deploy
                </Button>
              )}


              {gitClones.map(clone => (
                clone.project_name === func.function_name && 
                clone.git_hash !== func.git_hash && 
                func.function_arn && !func.cicd_enable && (
                  <Button
                    key={func.id}
                    variant="outline-warning"
                    size="sm"
                    onClick={() => updateFunction(func, team, clone.git_hash)}
                    className="btn-search-deployed"
                  >
                    Update
                  </Button>
                )
              ))}

              {gitClones.map(clone => (
                clone.project_name === func.function_name && 
                clone.git_hash !== func.git_hash && 
                func.function_arn && func.cicd_enable && (
                  <Button
                    key={func.id}
                    variant="outline-warning"
                    size="sm"
                    onClick={() => updateAndBuild(func, clone.git_hash, team)}
                    className="btn-search-deployed"
                  >
                    Update
                  </Button>
                )
              ))}
            </div>
          </div>

          <div>
            <table className={showInfo + ' table'}>
              <thead>
                <tr>
                  <th scope="col">Repository</th>
                  <th scope="col">Additional Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>
                      <b>Name:</b> {func.function_name}
                    </p>
                    <p>
                      <b>Region:</b> Region
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>Handler:</b> {func.handler}
                    </p>
                    <p>
                      <b>Run Time:</b> {func.runtime}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className={showInfo + ' enable-ci-container'}>
              <div className="check-enable-build-container margin-left">
                <Form.Check
                  checked={func.cicd_enable}
                  type="checkbox"
                  className="check-enable-build-1"
                  onChange={() => lambdaBuildStatus(func.id, !func.cicd_enable, habitat.id)}
                />
                <p>Enable CI</p>
              </div>
              <div>
                {func.cicd_enable && (
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => handleBuild(func)}
                  >
                    Build
                  </Button>
                )}
              </div>
            </div>
            <div className={showInfo + ' enable-ci-container'}>
              <div className="check-enable-build-container margin-left">
                <Form.Check
                  checked={varShow}
                  type="checkbox"
                  className="check-enable-build-1"
                  onChange={() => setVarShow(!varShow)}
                />
                <p>Add Enviroment Variables</p>
              </div>
            </div>

            {func.cicd_enable && (
              <div>
                <div className={showInfo + ' buildspec-file'}>
                  <div>buildspec.yml</div>
                  <div className="check-enable-build-container">
                    <Form.Check
                      type="checkbox"
                      checked={ownBuild}
                      className="check-enable-build"
                      onChange={() => setOwnBuild(!ownBuild)}
                    />
                    <p>Use project&apos;s buildspec file</p>
                  </div>
                </div>

                {!ownBuild && (
                  <div className={showCode}>
                  <CodeMirror
                      value={code}
                      options={{
                        mode: 'javascript',
                        theme: 'base16-light',
                        lineNumbers: true,
                        readOnly: false
                      }}
                      onBeforeChange={(editor, data, value) => {
                        setCode(value)
                      }}
                  />
                </div>
                )}
              </div>
            )}

            {varShow && (
              <HabitatDeployEnvVars 
                fields={fields}
                setFields={setFields}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);

const mapState = (state, props) => ({
  func: props.func,
  index: props.index,
  log: state.awsSdk.log,
  team: state.team.team,
  history: props.history,
  logs: state.awsSdk.logs,
  invoke: state.func.invoke,
  loading: state.func.loading,
  habitat: state.habitat.habitat,
  awsRoles: state.awsRoles.awsRoles,
  gitClones: state.gitClone.gitClones,
  stepAppTour: state.appTour.stepAppTour,
  statusAppTour: state.appTour.stateAppTour,
  error: state.func.error
});

const mapDispatch = {
  deploy,
  stopTour,
  getAwsLog,
  funcInvoke,
  getAwsLogs,
  buildDeploy,
  nextStepTour,
  fetchAWSRole,
  updateFunction,
  updateAndBuild,
  allDeployments,
  deleteDeployment,
  deployAfterBuild,
  lambdaBuildStatus
};

export default connect(mapState, mapDispatch)(HabitatDeployItem);
