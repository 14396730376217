import React from 'react';
import { NavLink } from 'react-router-dom';
import routes from '../../constants/routes';

const OnboardingStepSelect = (props) => {
    return(
        <div className="welcome-page-container">
            <p className="title-welcome">Welcome to KOR!</p>
            <p className="description-kor">KOR is a serverless development platform where you and your team can build, manage, test, and deploy code in one developer-friendly place.</p>

            <div className="container-options">
                <NavLink className="option-container" to={`${routes['TEMPLATES']}`}>
                        <div className="image-option">
                            <img src={props.iconTemplates} />
                        </div>
                        <div className="info-option">
                            <p>KOR TEMPLATES</p>
                            <p>   Automatically deploy:
                            Static sites,
                            Web Apps,
                        Strapi CMS</p>
                        </div>
                </NavLink>

                <NavLink className="option-container" to={`${routes['HABITATS']}`}>
                    <div className="image-option kor-option">
                        <img src={props.headImg} />
                    </div>

                    <div className="info-option">
                        <p>KOR</p>
                        <p>   Modify projects,
                        Monitor,
                        Settings,
                        Create Projects from Scratch</p>
                    </div>
                </NavLink>
            </div>

            <p className="link-documentation">Find more documentation about KOR here. Step by step guides to help you work more
efficiently. <a href="https://getkor.freshdesk.com/support/home" rel="noreferrer" target="_blank">See more...</a></p>
        </div>
    )
}

export default OnboardingStepSelect;