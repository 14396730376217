import React, { useState } from 'react';
import { connect } from 'react-redux';
import routes from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { faTrashAlt, faLayerGroup, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  deleteHabitat,
  editHabitat,
  selectHabitat,
  requestDeleteHabitat
} from '../../actions/habitat.actions';
import habitatIcon from '../../assets/images/icons/HabitatLight.svg';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { nextStepTour, stopTour } from '../../actions/app-tour.actions';
import HabitatDeleteModal from '../Modals/HabitatDeleteModal';
import RemoveHabitatModal from '../Modals/RemoveHabitatModal';

const HabitatItem = withRouter((
  {
    step,
    active,
    habitat,
    deleteHabitat,
    editHabitat,
    selectHabitat,
    requestDeleteHabitat,
    history,
    nextStep,
    stepAppTour,
    nextStepTour,
    stopTour,
    errorDeleteHabitatCode,
    numResources
  }) => {

  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [showRemoveHabitat, setShowRemoveHabitat] = useState('display-none-kor');

  const handleShow = () => {
    setShow(true);
    document.body.click();
  };

  const handleUpdateHabitat = (e) => {
    e.preventDefault();
    let newHab = { name: name };
    editHabitat(habitat, newHab);
    setShow(false);
  }

  const handleRemoveHabitat = (e) => {
    deleteHabitat(habitat.id);
    document.body.click()
    setShowDelete(false)
  }

  const handleSelect = () => {
    if (stepAppTour === 4) {
      selectHabitat(habitat);
      nextStepTour();
      history.push(`${routes.HABITAT}/${habitat.id}`);
    } else {
      selectHabitat(habitat);
      history.push(`${routes.HABITAT}/${habitat.id}`);
    }
  }

  const handleShowDelete = (habitat_id) => {
    requestDeleteHabitat(habitat_id)
    setShowRemoveHabitat('')
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        <div className="new-collection" onClick={handleShow}>
          <FontAwesomeIcon className="rename-icon-collection" icon={faPencilAlt} />
          <span>Rename</span>
        </div>
        <div className="new-function" onClick={() => handleShowDelete(habitat.id)}>
          <FontAwesomeIcon className="delete-icon-function" icon={faTrashAlt} />
          <span>Delete</span>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="habitat-list-item">
      <RemoveHabitatModal
        showRemoveHabitat={showRemoveHabitat}
        handleShowDelete={handleShowDelete}
        setShowRemoveHabitat={setShowRemoveHabitat}
        handleRemoveHabitat={handleRemoveHabitat}
        habitat={habitat}
        errorDeleteHabitatCode={errorDeleteHabitatCode}
        numResources={numResources}
      />

      <div className="habitat-model-wrapper">
        <HabitatDeleteModal
          setShowDelete={setShowDelete}
          showDelete={showDelete}
          handleRemoveHabitat={handleRemoveHabitat}
        />
        <Modal
          size="sm"
          show={show}
          onHide={() => setShow(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Rename Habitat</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Control
                  aria-label="Name"
                  aria-describedby="new-name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  type="text"
                  maxlength="30"
                  placeholder="Habitat New Name"
                />
              </Form.Group>
            </Form>
            <div className="button-update-name-function">
              <Button variant="primary" onClick={(e) => handleUpdateHabitat(e)}>
                Save
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <div className="habitat-item-container">
        <div className="habitat-list-name-functions" onClick={handleSelect}>
          <div className="habitat-list-title">
            <div xs="1">
              <div className="habitat-icon">
                <img className="habitat-icon-box" src={habitatIcon} alt="collection-icon" />
              </div>
            </div>

            <div xs="2" onClick={handleSelect}>
              <div className="habitat-title-date">
                <h6>{habitat.name}</h6>
                <p>{habitat.updated_at}</p>
              </div>
            </div>
          </div>

          <div className="resources-functions-container">

            <div className="habitats-list" xs="1" onClick={handleSelect}>
              <div className="habitat-sect-item">
                <p className="habitat-list-titles">Functions</p>
                <p className="habitat-quantity">0</p>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center" xs="1" onClick={handleSelect}>
              <div className="habitat-sect-item">
                <p className="habitat-list-titles">Resources</p>
                <p className="habitat-quantity">0</p>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center" xs="3" onClick={handleSelect}>
              {habitat.name === 'Static Template' && (
                <div>
                  <div className="nav-side-section-template">
                    <FontAwesomeIcon icon={faLayerGroup} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Col className="habitats-list" xs="2">
          <OverlayTrigger rootClose={true} trigger="click" placement="top" overlay={popover}>
            <div className="habitat-dots">
              <b>...</b>
            </div>
          </OverlayTrigger>
        </Col>
      </div>
    </div>
  );
})

const mapState = (state, props) => ({
  habitat: props.value,
  stepAppTour: state.appTour.stepAppTour,
  errorDeleteHabitatCode: state.habitat.remove_habitat_request.verification,
  numResources: state.habitat.remove_habitat_request.num_resources
})

const mapDispatch = {
  deleteHabitat,
  editHabitat,
  selectHabitat,
  requestDeleteHabitat,
  withRouter,
  nextStepTour,
  stopTour,
}

export default connect(
  mapState,
  mapDispatch
)(HabitatItem);
