import React from 'react';

const NotificationSwitch = (props) => {
	return (
		<div className={props.className}>
			<input type="checkbox" defaultChecked={props.boolStatus} id={props.toggle} />
			<label htmlFor={props.toggle}></label>
		</div>
	);  
}

export default NotificationSwitch;