import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import routes from '../constants/routes';
import Background from '../assets/images/NotFoundBackground.png';
import '../assets/styles/components/notfound.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

const OauthDonePage = withRouter(({ history }) => {
  const GoBackToOverview = (e) => {
    e.preventDefault();
    history.push(routes.CREDENTIALS);
  };

  return (
    <div>
      <img
        className="background"
        src={Background}
        alt="Not Found Page background"
      />
      <div className="error-message">
        <h1 id="error-title1">USER AUTHORIZED</h1>
        <div className="icon-auth-text">
        <FontAwesomeIcon icon={faUserPlus} />
        <p>
          Your creds has been added correctly.
          <br />
          You can now close this window and go back to KOR.
        </p>
        </div>
      </div>
    </div>
  );
});

const mapState = (props, state) => ({
  history: props.history,
});
const mapDispatch = {};
export default connect(mapState, mapDispatch)(OauthDonePage);
