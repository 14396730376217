import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { Form, Button } from 'react-bootstrap';
import HabitatAPIChart from './HabitatAPIChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const HabitatAPIGatewayMetrics = ({
  error,
  habitat,
  apiMetrics,
  apiGateways,
  getApiMetrics
}) => {
  const [ name, setName ]           = useState('');
  const [ endTime, setEndTime ]     = useState(Moment().format());
  const [ startTime, setStartTime ] = useState(Moment().subtract(1, 'months').format());
  
  useEffect(() => {
    if (apiGateways.length > 0) {
      setName(apiGateways[0].name);
      getApiMetrics(habitat.id, startTime, endTime, apiGateways[0].name);
    }
  }, []);

  const handleGetMetrics = () => {
    getApiMetrics(habitat.id, startTime, endTime, name);
  }
  
  return (
    <>
      <div className="logs-menu">
        <Form.Control
          className="logs-selectbox"
          as="select"
          value={name}
          onChange={e => setName(e.target.value)}
        >
          <option value="all-funcs">API Gateways</option>
          {apiGateways.map(api => (
            <option key={api.id} value={api.name}>{api.name}</option>
          ))}
        </Form.Control>

        <Form.Control
          type='date'
          className="logs-selectbox"
          onChange={s => setStartTime(Moment(s.target.value).format())}
        />

        <Form.Control
          type='date'
          className="logs-selectbox"
          onChange={e => setEndTime(Moment(e.target.value).format())}
        />

        <Button className="logs-selectbox" disabled={!startTime || !endTime || !name} onClick={handleGetMetrics}>Search</Button>
      </div>

      {error && (
        <div className="text-danger">
          <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
        </div>
      )}
      
      {apiMetrics && (
        <HabitatAPIChart 
          apiMetrics={apiMetrics}
        />
      )}
    </>
  );
}

export default HabitatAPIGatewayMetrics;
