import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../assets/styles/components/habitats/HabitatReleases.scss';
import HabitatTopMenu from '../components/Habitats/HabitatTopMenu';
import { listFunctions, deployAll, getTests } from '../actions/funcs.actions';
import HabitatDeployList from '../components/Habitats/HabitatDeployList';
import HabitatTemplatesList from '../components/Habitats/HabitatTemplatesList';
import HabitatDeployListHistory from '../components/Habitats/HabitatDeploymentHistory';
import HabitatRelasesTitle from '../components/Habitats/HabitatReleasesTitle';
import { getStaticSites } from '../actions/templates.actions';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { retrieveHabitat } from '../actions/habitat.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HabitatDeploymentPage = ({
  params,
  habitat,
  getTests,
  templates,
  listFunctions,
  getStaticSites,
  retrieveHabitat,
  loadingTemplate,
}) => {
  useEffect(() => {
    if (habitat.id) {
      listFunctions(habitat.id);
      getStaticSites(habitat.id);
    } else {
      retrieveHabitat(params.habitatId);
      listFunctions(params.habitatId);
      getStaticSites(params.habitatId);
    }
  }, []);

  const [featureSelected, setFeatureSelected] = useState('funcs')

  const handleChangeActive = (activeFeature) => {
    setFeatureSelected(activeFeature)
  }

  return (
    <div>

      <div className="habitat-sub-menu">
        <div className="sub-menu-side-l">
          <FontAwesomeIcon icon={faGlobe} />
          <p>{habitat.name}</p>
        </div>
        <HabitatTopMenu
          habitat={habitat}
        />
      </div>

      <div className="habitat-container-deployment">
        <div className="habitat-all-funcs-deployment">
          <HabitatRelasesTitle
            handleChangeActive={handleChangeActive}
            featureSelected={featureSelected}
          />

          {featureSelected === 'funcs' ? (
            <HabitatDeployList />
          ) : (
              <HabitatTemplatesList />
            )}


        </div>

        <div className="habitat-history-deployment">
          <HabitatDeployListHistory
            getTests={getTests}
            templates={templates}
            loadingTemplate={loadingTemplate}
          />
        </div>



      </div>
    </div>
  );
};

const mapState = (state, props) => ({
  funcs: state.func.funcs,
  params: props.match.params,
  habitat: state.habitat.habitat,
  gitClones: state.gitClone.gitClones,
  templates: state.templates.templates,
  loadingTemplate: state.templates.loading,

});

const mapDispatch = {
  getTests,
  deployAll,
  listFunctions,
  getStaticSites,
  retrieveHabitat
}

export default connect(
  mapState,
  mapDispatch
)(HabitatDeploymentPage);
