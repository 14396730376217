import React from 'react';
import { ListGroup } from 'react-bootstrap';

const MemberCard = ({value}) => {	
	const itemStyle = {
		width: '30em'
	}

	return (			
		<ListGroup.Item style={itemStyle} action>	
		<div className="container">	
			<div className="row">
				<div className="col col-lg-2">
					<i className="fas fa-users"></i>
				</div>
				<div className="col col-lg-4">
					<div className="team-Name">
						<span className="length-name">
							{value.name}
						</span>
					</div>
				</div>
				<div className="col col-lg-3">
					<div className="team-Role" >
						<span className="background-role">
							{value.role}
						</span>
					</div>
				</div>				
			</div>
		</div>
		</ListGroup.Item>		
	);  
}

export default MemberCard;