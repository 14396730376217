import React from 'react';
import '../../assets/styles/components/UserSettings.scss';
import {
  Container,
  InputGroup,
  FormControl,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import { faTimes, faQuestionCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const UserSettings = (props) => {
  return (
    <div className="user-setting-main">
      <Container className="user-setting-container">
        <div className="userSetting-box">
          <Row className="mb-5">
            <Col sm={12} lg={6}>
              <div className="title-account-container">
                <h6 className="user-setting-subtitle">Change Name</h6>
                <div className="question-mark-documentation-habitat">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip className="red-tooltip">
                        <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                      </Tooltip>
                    }

                  >
                    <a
                      href="https://getkor.freshdesk.com/support/solutions/articles/47001163961-account-change-user-name-password"
                      rel="noopener noreferrer"
                      className="help"
                      target="_blank"
                    >

                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </a>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="borderLeft"></div>
              <InputGroup size="sm" className="mb-3 mt-4">
                <FormControl
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder="Name"
                  type="text"
                  value={props.name || ''}
                  onChange={(event) => props.setName(event.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm={12} lg={6}>
              <div className="mt-4 d-flex justify-content-center pl-5 pr-5">
                <Button
                  variant="primary"
                  size="sm"
                  block
                  onClick={props.handleUpdateName}
                  disabled={!props.name}
                >
                  Update
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col sm={12} lg={6}>
              <div className="title-account-container">
                <h6 className="user-setting-subtitle">Change Account Password</h6>
                <div className="question-mark-documentation-habitat">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip className="red-tooltip">
                        <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                      </Tooltip>
                    }

                  >
                    <a
                      href="https://getkor.freshdesk.com/support/solutions/articles/47001163961-account-change-user-name-password"
                      rel="noopener noreferrer"
                      className="help"
                      target="_blank"
                    >

                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </a>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="borderLeft"></div>
              <InputGroup size="sm" className="mb-3 mt-4">
                <FormControl
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder="New Password"
                  type="password"
                  onChange={(event) => props.setNewPassword(event.target.value)}
                />
              </InputGroup>
              <InputGroup size="sm" className="mb-3 mt-4">
                <FormControl
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder="Confirm Password"
                  type="password"
                  onChange={(event) =>
                    props.setConfirmPassword(event.target.value)
                  }
                />
              </InputGroup>
            </Col>
            <Col sm={12} lg={6}>
              <div className="mt-4 d-flex justify-content-center pl-5 pr-5">
                <Button
                  variant="primary"
                  size="sm"
                  block
                  disabled={
                    props.newPassword !== props.confirmPassword ||
                    !props.newPassword ||
                    !props.confirmPassword
                  }
                  onClick={props.handleUpdatePassword}
                >
                  Update
                </Button>
              </div>

            </Col>

            {props.error && (
              <div className="error-display text-danger">
                <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {props.error}</p>
              </div>
            )}


          </Row>
        </div>
      </Container>
      <Container className="d-ctn delete-account-container">
        <Row>
          <Col sm={12} lg={6}>
            <h6 className="user-setting-subtitle">Delete Account</h6>
            <div className="borderLeft"></div>
            <p className="d-text">
              Click{' '}
              <span className="text-danger" onClick={props.handleToggle}>
                here
                </span>{' '}
                to delete your account
              </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserSettings;
