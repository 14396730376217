import produce from 'immer';
import {
  GET_STATUS_BEGIN,
  GET_STATUS_SUCCESS,
  GET_STATUS_ERROR
} from '../actions/types';

const initialState = {
  loading: false,
  error: null
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case GET_STATUS_BEGIN:
      draft.loading = true;
      draft.error = null;
      return;
    case GET_STATUS_SUCCESS:
      draft.loading = false;
      draft.error = null;
      return;
    case GET_STATUS_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;