import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {Elements, StripeProvider} from 'react-stripe-elements';
import { Modal, Button } from 'react-bootstrap';
import CheckoutForm from './CheckoutForm';
import StartupCheckoutForm from './StartupCheckoutForm';
import BillingPlanFeatureList from './BillingPlanFeatureList';
import { downgradePro, getProPayments } from '../../actions/payment.actions';
import { getSubscriptionStartup } from '../../actions/team.actions';
import { updatePlan } from '../../actions/user.actions';
import moment from 'moment';

const BillingPlan = ({
  payment,
  subscription,
  planTitle,
  features,
  planPrice,
  priceCurrency,
  value,
  downgradePro,
  updatePlan,
  downgraded,
  subscriptionState,
  getSubscriptionStartup
}) => {
  
  const [mdShow, setMdShow] = useState(false);
  const [show, setShow] = useState(false);
  /*const [showTeam, setShowTeam] = useState(false);*/
  const [startupShow, setStartupShow] = useState(false);
  const [seats, setSeats] = useState(1);
  let teamId = localStorage.getItem('theTeamId');
  useEffect(() => {
    const getSubscriptionStartupSecure = () =>{
      if(teamId){
        getSubscriptionStartup(teamId)
      }
    }
    getSubscriptionStartupSecure()
    }, [teamId, getSubscriptionStartup]);

  const stripePK = process.env.REACT_APP_STRIPE_PK;

  const handleClose = () => {
    setMdShow(false)
  }
  const handleCloseDg = () => {
    setShow(false)
  }
  const handleCloseDgConfirm = () => {
    handleDowngrade()
    setShow(false)
  }
  const handleShow = () => {
    setShow(true)
  }
  const handleStartupShow = () => {
    setStartupShow(true)
  } 
  const handleCloseStartup = () => {
    setStartupShow(false)
  }
  //Close Team Downgrade
  /*
  const teamClose = () => {
    handleTeamDowngrade()
    showTeam(false)
  }
  const teamShow = () => {
    setShowTeam(true)
  }
  */
  const handleChange = ({error}) => {
    if (error) {
      //setErrorMessage(error.message);
    }
  }

  const handleDowngrade = () => {
    if(payment !== undefined){
      downgradePro(payment)
      return  
    }
    if(payment === undefined){      
      downgradePro(subscriptionState.stripe_id)
      return  
    }
    getProPayments()
  }
  if(downgraded.downSuccess){
    updatePlan('basic')
  }
  
  const renderSubscribedUntil = (paramDue) => {
    let todayIs = moment()

    if(moment(todayIs).isSameOrBefore(paramDue) && paramDue !== undefined && planTitle !== 'basic' && planTitle === 'pro') {
      return (
        <p>Active Until: {moment(subscriptionState.next_due_date).format('MMMM D, YYYY')}</p>
      )
    }
  }
    
  const title = planTitle.charAt(0).toUpperCase() + planTitle.slice(1)

  const planButtonState = (planParam, subscriptionParam) => {
   
    if(planParam === 'basic' && subscriptionParam === 'pro' ){
      return <button className="btn btn-primary btn-block" onClick={() => handleShow()} id={value} >Downgrade</button>
    }
    if(!subscriptionParam && planParam === 'Project'){
      return <button className="btn btn-primary btn-block" id={value} disabled>Current Plan</button> 
    }
    if(planParam === subscriptionParam){
      return <button className="btn btn-primary btn-block" id={value} disabled>Current Plan</button> 
    }else if(planParam !== subscriptionParam && planParam === 'pro'){
      //Pro
      return <button className="btn btn-primary btn-block" onClick={() => setMdShow(true)} id={value} >Subscribe</button>
    }else if(planParam !== subscriptionParam && planParam === 'starter'){
      //Startup
      return <button className="btn btn-primary btn-block" onClick={() => handleStartupShow()} id={value} >Subscribe</button>
      //return <button className="btn btn-primary btn-block" id={value} disabled>Current Plan</button>
    }
    return <button className="btn btn-primary btn-block" onClick={() => handleShow()} id={value} >Downgrade Team</button>
  }
  const renderSwitch = (param) => {

    switch(param) {
      case 'basic':
        return planButtonState(param, subscription);
      case 'pro':
        return planButtonState(param, subscription);
      case 'Project':
        return planButtonState(param, subscription);
      case 'Startup':
        return planButtonState('starter', subscription);
      case 'Enterprise':
        return <button className="btn btn-primary btn-block" onClick={() =>  window.location.href = 'mailto:info@getkor.io'} id={value} >Contact Us</button>
      default:
        return 'ERROR';
    }
  }

  return (
    <div className="col-3 billingPlanCard">
      <div className="plan-card">
      
        <div className="plan-card-header plan-name">
          <h2 className="planTitle">{title}</h2>
        </div>

        <div className="price-card-body">                        
          <h3 className="plan-card-title plan-price">{priceCurrency}{planPrice}</h3>  
        </div>

        <BillingPlanFeatureList featureList={features} />
        
        <div className="card-body">  
          {renderSwitch(planTitle)}
        </div>
          {renderSubscribedUntil(subscriptionState.next_due_date)}
        <Modal
          size="sm"
          show={mdShow}
          onHide={() => setMdShow(false)}
          aria-labelledby="example-modal-sizes-title-md" >

          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-md">
              Subscription
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <StripeProvider apiKey={stripePK}>
              <div className="example">                                                
                <Elements>
                  <CheckoutForm closeModal={handleClose}/>
                </Elements>
              </div>
            </StripeProvider>
          </Modal.Body>
        </Modal>

        <Modal
          show={show}
          onHide={handleCloseDg} 
          aria-labelledby="mdDowngrade" >
          <Modal.Header className="mdDowngrade" closeButton>
            <Modal.Title className="mdDowngradeTitle" >Pro Plan Cancelation</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mdDowngrade">Your subscription will be cancelled, you will keep pro features until {moment(subscriptionState.next_due_date).format('MMMM D, YYYY')}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDg}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleCloseDgConfirm}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show = {startupShow}
          onHide = {handleCloseStartup} 
          aria-labelledby = "mdDowngrade">
          <Modal.Header className="mdDowngrade" closeButton>
            <Modal.Title className="mdDowngradeTitle" >Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mdDowngrade">
            <input
              type="number"
              min="1"
              value={seats}
              onInput={e => setSeats(e.target.value)}
              onChange={handleChange}
            />
            <StripeProvider apiKey={stripePK}>
              <div className="example">                                                
                <Elements>
                  <StartupCheckoutForm seats={seats} closeModal={handleCloseStartup}/>
                </Elements>
              </div>
            </StripeProvider>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseStartup}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleCloseStartup}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </div>
  );
}
const mapState = (state, props) => ({
  priceCurrency: props.priceCurrency,
  subscription: props.subscription,
  planPrice: props.planPrice,
  planTitle: props.planTitle,
  features: props.features,
  payment: props.payment,  
  value: props.value,
  downgraded: state.payment,
  subscriptionState: state.user.user.subscription
});

const mapDispatch = { 
  downgradePro,
  updatePlan,
  getProPayments,
  getSubscriptionStartup
};
export default connect(mapState, mapDispatch)(BillingPlan);