import produce from 'immer';

import {
    CHANGE_NAVBAR_CLASS_BEGIN,
    CHANGE_NAVBAR_CLASS_SUCCESS,
    CHANGE_NAVBAR_CLASS_ERROR,
    CHANGE_INITIAL_NAVBAR_BEGIN,
    CHANGE_INITIAL_NAVBAR_SUCCESS
} from '../actions/types';

const initialState = {
    active: false,
    class: 'close-navbar',
    initalClass: 'display-menu-initial',
    loading: false,
    error: null
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case CHANGE_NAVBAR_CLASS_BEGIN:
        case CHANGE_INITIAL_NAVBAR_BEGIN:
            draft.loading = true;
            draft.error = null;
            return;
        case CHANGE_NAVBAR_CLASS_SUCCESS:
            draft.loading = false;
            draft.class = action.payload.class;
            draft.active = action.payload.active;
            draft.error = null;
            return;
        case CHANGE_INITIAL_NAVBAR_SUCCESS: 
            draft.loading = false;
            draft.initalClass = action.payload.initalClass;
            draft.error = null;
        case CHANGE_NAVBAR_CLASS_ERROR:
            draft.loading = false;
            draft.error = action.error;
            return;
        default:
            return;
    }
}, initialState);

export default reducer;
