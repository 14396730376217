import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchTeam, fetchTeams } from '../actions/team.actions';
import '../assets/styles/components/teamProfile.scss';
import Avatar from './avatar/Avatar'

const TeamProfile = ({fetchTeam,fetchTeams, team, teamId, teamName}) => {  
  
  useEffect(() => {    
    //fetchTeam(teamId)
    const fetchTeamSecure = () => {
      fetchTeam(teamId)
      fetchTeams()
    }    
    fetchTeamSecure()
  }, [teamId, fetchTeam, fetchTeams]);    
  
  if (teamId > 0) {
    localStorage.setItem('theTeam', teamName);
    localStorage.setItem('theTeamId', teamId);
  }
  teamName = localStorage.getItem('theTeam');
  teamId = localStorage.getItem('theTeamId');

  

  return (
    <div>
      <div className=" container-kds">
        <div className="page-nav-main">
          <p className="page-title">Team</p>
        </div>
        <div className="col-md-12 page-content">
          <p className="team-page-subtitle-teamprofile">TEAM DETAILS</p>          
          <div className="page-box-teamprofile profile-box-size">
              <div className="row">
                <div className="col-8">
                  <form className="page-form">
                    <div className="input-field">
                      <label htmlFor="name" className="text form-text">Team Name</label>
                      <input type="text" id="full-name" className="form-input profile-input-field" placeholder={team.name} />
                    </div>                        
                    <div className="input-field">
                      <label htmlFor="email" className="text form-text">Team Email</label>
                      <input type="email" id="Email" className="form-input profile-input-field" placeholder={team.email} disabled/>
                    </div>
                  </form>
                </div>
                <div className="col-1">
                  <p className="teamprofile-title">Avatar</p>
                  <div className="team-profile-box">                                                             
                      <Avatar userName={teamName} avatarClass="avatarTeamPicProfile" />                    
                  </div>          
                </div>
              </div>
            <br />      
          </div>  
        </div>
      </div>
    </div>            
  );
}

const mapState = (state, props) => ({
	currentUser: state.user.user,  
  team: state.team.team,  
  teamId: props.match.params.teamId,
  teamName: props.match.params.name
});

const mapDispatch = {
  fetchTeam,
  fetchTeams
}

export default connect(
  mapState,
  mapDispatch
)(TeamProfile);