import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import folder from '../../assets/images/icons/overview/folder.svg'
import Moment from 'moment';

const FunctionBox = (props) => {

    return (
        <div className="funcs-box-container">
            {props.gitClones.map(clone => (
                <div className="function-box" key={clone.id}>
                    <div className="icon-function-container">
                        <div className="function-icon">
                            <img src={folder} />
                        </div>
                        <div>
                            <p>{clone.project_name} / <span className="span-text-box">{clone.git_branch}</span></p>
                        </div>
                    </div>

                    <div className="aditional-info-repo">
                        <div className="aditional-info-repo-box1">
                            <p>Updated at</p>
                            <p>{Moment(clone.updated_at).format('lll')}</p>
                        </div>
                        <div className="aditional-info-repo-box">
                            <p>Author</p>
                            <div className="avatar-repo-author">
                                <div className="avatar-1">
                                    <p>A</p>
                                </div>
                                <div className="name-author">
                                    {clone.user_name === null ? (
                                        <p>Unknown</p>
                                    ):(
                                        <p>{clone.user_name}</p> 
                                    )}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

        </div>
    );
}

const mapState = (state) => ({
});
const mapDispatch = {
};
export default connect(mapState, mapDispatch)(FunctionBox);