import React from 'react';
import {Image, Card } from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const SaveCard = (props) => {
  return (
    <Card className="mt-2">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <Image src={require('../../assets/images/' + props.card.brand + '.svg')} />
          <span className="font-weight-normal text-muted">
            XXXX-{props.card.last4}
            {!props.disableEdit && (
              <FontAwesomeIcon 
                icon={faTimesCircle} 
                className="ml-2 text-danger" 
                onClick={props.handleRemoveModal} 
              />
            )}
          </span>
        </div>
      </Card.Body>
    </Card>
  );
}

export default SaveCard;