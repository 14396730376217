import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import '../../assets/styles/modals/ModalKorSmallRight.scss'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import closeIcon from '../../assets/images/icons/close-icon.svg';
import habitatIcon from '../../assets/images/icons/overview/habitaticon.svg';
import folder from '../../assets/images/icons/overview/folder.svg';
import staticIcon from '../../assets/images/icons/overview/staticsiteicon.svg';
import strapiIcon from '../../assets/images/icons/overview/strapi.svg';
import webAppIcon from '../../assets/images/icons/overview/webappicon.svg';
import plus from '../../assets/images/icons/overview/plus.svg';
import { NavLink } from 'react-router-dom';

const CreateOverviewModal = ({
  show,
  setShow,
  repoUrl,
  repoName,
  gitCreds,
  setGitCred,
  setRepoUrl,
  setRepoName,
  handleClone,
  selectedItem,
  setSelectedItem,
  awsCreds,
  setAwsCred,
  handleCreateHabitatWithCreds
}) => {

  const handleCloseOverviewModal = () => {
    setShow(!show)
    setSelectedItem('overview')
  }

  return (
    <>
      {show && selectedItem === "overview" && (
        <div className="modal-kor-small-right">
          <div className="box-modal-options-container-small-right">

            <div className="close-title">
              <img src={closeIcon} onClick={() =>  handleCloseOverviewModal()} />
              <h3>Create New Resource</h3>
            </div>

            <p className="mt-5">
              Add a new repository or a new habitat.
            </p>

            <div className="resources-container">
              <div className="resource-box">
                <div className="icon-resource">
                  <img src={habitatIcon} />
                </div>
                <div className="info-resource">
                  <p>Habitat</p>
                  <p>Launch from a template or custom habitat.</p>
                </div>
                <Button onClick={() => setSelectedItem('habitat')}>
                  Select
                </Button>
              </div>
              <div className="resource-box">
                <div className="icon-resource folder-icon-box">
                  <img src={folder} />
                </div>
                <div className="info-resource">
                  <p>Repository</p>
                  <p>Launch from a template or custom habitat.</p>
                </div>
                <Button onClick={() => setSelectedItem('repos')}>
                  Select
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {show && selectedItem === "habitat" && (
        <div className="modal-kor-small-right">
          <div className="box-modal-options-container-small-right">

            <div className="close-title">
              <img src={closeIcon} onClick={() =>  handleCloseOverviewModal()} />
              <h3>Create new Habitat</h3>
            </div>

            <p className="mt-5">
              Launch from a template, there are no hard limits on the number of users, volume of traffic, or data storage.
            </p>

            <div className="resources-container">
              <div className="resource-box">
                <div className="icon-resource folder-icon-box-2 icon-resource-static">
                  <img src={staticIcon} />
                </div>
                <div className="info-resource">
                  <p>Static Website</p>
                  <p>Launch a basic website for information purposes.</p>
                </div>
                <Button>
                  <NavLink exact to='/Templates'>
                    Select
                    </NavLink>
                </Button>
              </div>
              <div className="resource-box">
                <div className="icon-resource folder-icon-box-2 icon-resource-webapp">
                  <img src={webAppIcon} />
                </div>
                <div className="info-resource">
                  <p>Web App</p>
                  <p>Launch a complete web app from scratch.</p>
                </div>
                <Button>
                  <NavLink exact to='/Templates'>
                    Select
                    </NavLink>
                </Button>
              </div>
              <div className="resource-box">
                <div className="icon-resource folder-icon-box-1 icon-resource-strapi">
                  <img src={strapiIcon} />
                </div>
                <div className="info-resource">
                  <p>STRAPI</p>
                  <p>Launch a headless CMS all based in JS.</p>
                </div>
                <Button>
                  <NavLink exact to='/Templates'>
                    Select
                    </NavLink>
                </Button>
              </div>
            </div>

            <p className="mt-5">
              Or create an empty habitat using your AWS Credentials.
            </p>

            <div className="creds-container-modal">
              <p className="input-label">
                Credentials
                </p>
              <Form.Control
                as="select"
                defaultValue={'DEFAULT'}
                onChange={e => setAwsCred(e.target.value)}
              >
                <option disabled value="DEFAULT">
                  Select Credentials
                  </option>
                {awsCreds && awsCreds.map(cred => (
                  <option
                    key={cred.id}
                    value={cred.id}>
                    {cred.name}
                  </option>
                ))}
              </Form.Control>
              <Button onClick={() => handleCreateHabitatWithCreds()}>
                <img src={plus} /> Create
              </Button>
            </div>

            <div className="return-button-modal">
              <Button onClick={() => setSelectedItem('overview')}>
                Return
              </Button>
            </div>


          </div>
        </div>
      )}

      {show && selectedItem === "repos" && (
        <div className="modal-kor-small-right">
          <div className="box-modal-options-container-small-right">

            <div className="close-title">
              <img src={closeIcon} onClick={() =>  handleCloseOverviewModal()} />
              <h3>Clone a new repository</h3>
            </div>

            <p className="mt-5">
              Add a new repository from an internal tool or from the internet, start by adding your credentials.
          </p>
            <Row>
              <Col xs={2} className="p-0 mt-3 mr-2">
                <p className="input-label">
                  Repository name
              </p>
              </Col>
              <Col xs={9} className="p-0 mt-3">
                <Form.Control
                  type="text"
                  value={repoName}
                  aria-label="Title"
                  placeholder="Repository name"
                  aria-describedby="new-title"
                  onChange={e => setRepoName(e.target.value)}
                />
              </Col>
              <Col xs={2} className="p-0 mt-3 mr-2">
                <p className="input-label">
                  Repository link
              </p>
              </Col>
              <Col xs={9} className="p-0 mt-3">
                <Form.Control
                  type="text"
                  value={repoUrl}
                  aria-label="Title"
                  aria-describedby="new-title"
                  placeholder="Repository link"
                  onChange={e => setRepoUrl(e.target.value)}
                />
              </Col>
              <Col xs={12} className="p-0 mt-4 mb-4">
                <p>*KOR is using HTTPS for clone.<br />You can clone a repository without git credentials if the repository is public.</p>
              </Col>
              <Col xs={2} className="p-0 mr-2">
                <p className="input-label">
                  GIT Credentials
              </p>
              </Col>
              <Col xs={9} className="p-0 mb-5">
                <Form.Control
                  as="select"
                  defaultValue={'DEFAULT'}
                  onChange={e => setGitCred(e.target.value)}
                >
                  <option disabled value="DEFAULT">
                    Select Credentials
                </option>
                  {gitCreds && gitCreds.map(cred => (
                    <option
                      key={cred.id}
                      value={cred.id}>
                      {cred.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              <Col xs={12}>
                <div className="d-flex justify-content-end mr-4">
                  <button
                    onClick={() => handleClone()}
                    disabled={!repoUrl || !repoName}
                    className="btn kor-btn-sm btn-sm"
                  >
                    CLONE NOW
                </button>
                </div>
              </Col>
            </Row>
            <div className="return-button-modal">
              <Button onClick={() => setSelectedItem('overview')}>
                Return
              </Button>
            </div>
          </div>

          
        </div>
      )}


    </>
  );
}

export default CreateOverviewModal;