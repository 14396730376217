import {
  GET_TEST_BEGIN,
  GET_TEST_SUCCESS,
  GET_TEST_ERROR,
  STORE_TEST_BEGIN,
  STORE_TEST_SUCCESS,
  STORE_TEST_ERROR,
  UPDATA_CODE_BEGIN,
  UPDATA_CODE_SUCCESS,
  UPDATA_CODE_ERROR,
  CREATE_FUNCTION_BEGIN,
  CREATE_FUNCTION_SUCCESS,
  CREATE_FUNCTION_ERROR,
  GET_ALL_FUNCTION_BEGIN,
  GET_ALL_FUNCTION_SUCCESS,
  GET_ALL_FUNCTION_ERROR,
  UPDATE_FUNCTION_BEGIN,
  UPDATE_FUNCTION_SUCCESS,
  UPDATE_FUNCTION_ERROR,
  DELETE_FUNCTION_BEGIN,
  DELETE_FUNCTION_SUCCESS,
  DELETE_FUNCTION_ERROR,
  INVOKE_FUNCTION_BEGIN,
  INVOKE_FUNCTION_SUCCESS,
  INVOKE_FUNCTION_ERROR,
  DEPLOY_FUNCTION_BEGIN,
  DEPLOY_FUNCTION_SUCCESS,
  DEPLOY_FUNCTION_ERROR,
  GET_ALL_FUNCTIONS_BEGIN,
  GET_ALL_FUNCTIONS_SUCCESS,
  GET_ALL_FUNCTIONS_ERROR,
  UPDATE_ALL_FUCTIONS_BEGIN,
  UPDATE_ALL_FUCTIONS_SUCCESS,
  UPDATE_ALL_FUCTIONS_ERROR,
  DEPLOY_ALL_FUCTIONS_BEGIN,
  DEPLOY_ALL_FUCTIONS_SUCCESS,
  DEPLOY_ALL_FUCTIONS_ERROR,
  BUILD_FUNCTION_BEGIN,
  BUILD_FUNCTION_SUCCESS,
  BUILD_FUNCTION_ERROR,
  GET_DEPLOYMENTHISTORY_BEGIN,
  GET_DEPLOYMENTHISTORY_SUCCESS,
  GET_DEPLOYMENTHISTORY_ERROR,
  GET_FUNC_FILTER_BEGIN,
  GET_FUNC_FILTER_SUCCESS,
  GET_FUNC_FILTER_ERROR,
  CHANGE_STATE_FUNC_BEGIN,
  CHANGE_STATE_FUNC_SUCCESS,
  SET_BUILD_STATUS_BEGIN,
  SET_BUILD_STATUS_SUCCESS,
  SET_BUILD_STATUS_ERROR,
} from './types';
import {
  setFunc,
  storeTest,
  indexTest,
  fetchFuncs,
  updateFunc,
  removeFunc,
  invokeFunc,
  deployFunc,
  updateCode,
  lambdaStatus,
  updateHashLambda,
  deploymentHistory,
} from '../api/funcs';
import {
  addBucket,
  uploadFile,
  createBuild,
  uploadClone,
  createProject,
} from '../api/aws/sdk';
import { zipFile, zipRemove } from '../api/fileSystem';
import { getAllHabitats } from '../api/habitats';

export const fetchAllFuncs = () => {
  const data = [];
  return (dispatch) => {
    dispatch({
      type: GET_ALL_FUNCTIONS_BEGIN,
      loading_message: 'Loading functions...',
    });
    getAllHabitats()
      .then((response) => {
        response.data.habitats.forEach((habitat) => {
          fetchFuncs(habitat.id).then((funcs) => {
            funcs.data.forEach((func) => {
              data.push({
                name: func.function_name,
                runtime: func.runtime,
              });
            });
          });
        });
        dispatch({
          type: GET_ALL_FUNCTIONS_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_FUNCTIONS_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const changeStateDeploymentHistory = () => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_STATE_FUNC_BEGIN,
      loading_message: 'Loading Deployment history...',
    });
    dispatch({
      type: CHANGE_STATE_FUNC_SUCCESS,
      payload: false,
    });
  };
};

export const fetchFilterFunc = (habitat_id, func_id) => {
  const funcSelected = [];
  return (dispatch) => {
    dispatch({
      type: GET_FUNC_FILTER_BEGIN,
      loading_message: 'Loading function filter...',
    });
    fetchFuncs(habitat_id)
      .then((response) => {
        response.data.forEach((func) => {
          if (func.id === func_id) {
            funcSelected.push({
              function_name: func.function_name,
              runtime: func.runtime,
              created_at: func.created_at,
              updated_at: func.updated_at,
            });
          }
        });
        dispatch({
          type: GET_FUNC_FILTER_SUCCESS,
          payload: funcSelected,
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_FUNC_FILTER_ERROR,
          error: error.response.data.error,
        })
      );
  };
};

export const fetchDeploymentHistory = (
  habitat_id,
  start_date,
  end_date,
  page
) => {
  return (dispatch) => {
    dispatch({
      type: GET_DEPLOYMENTHISTORY_BEGIN,
      loading_message: 'Loading deployment history...',
    });
    deploymentHistory(habitat_id, start_date, end_date, page)
      .then((response) => {
        dispatch({
          type: GET_DEPLOYMENTHISTORY_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_DEPLOYMENTHISTORY_ERROR,
          error: error.response.data.error,
        })
      );
  };
};

export const funcInvoke = (func, habitat_id, invocationType, payload) => {
  return (dispatch) => {
    dispatch({
      type: INVOKE_FUNCTION_BEGIN,
      loading_message: 'Loading invoke function...',
    });

    invokeFunc(func.id, func.function_name, habitat_id, invocationType, payload)
      .then((response) => {
        dispatch({
          type: INVOKE_FUNCTION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: INVOKE_FUNCTION_ERROR,
          error: error.response.data.error,
        })
      );
  };
};

export const deploy = (func, fields) => {
  return (dispatch) => {
    dispatch({
      type: DEPLOY_FUNCTION_BEGIN,
      loading_message: 'Deploying function...',
    });
    let env = null;
    if (fields[0].key.length > 0) {
      env = JSON.stringify(
        fields.reduce((acc, cur) => ({ ...acc, [cur.key]: cur.value }), {})
      )
        .replace(/\\n/g, '\\n')
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, '\\&')
        .replace(/\\r/g, '\\r')
        .replace(/\\t/g, '\\t')
        .replace(/\\b/g, '\\b')
        .replace(/\\f/g, '\\f');
    }

    const zipInfo = {
      file_name: func.function_name,
      habitat_id: func.habitat_id,
    };
    zipFile(zipInfo)
      .then((response) => {
        const file = response.data.path;
        const file_name = `${func.function_name}.zip`;
        uploadFile(file, func.code_s3_bucket, func.habitat_id, file_name)
          .then((response) => {
            zipRemove(file)
              .then((response) => {
                deployFunc(func.id, env)
                  .then((response) => {
                    dispatch({
                      type: DEPLOY_FUNCTION_SUCCESS,
                      payload: response.data,
                    });
                  })
                  .catch((error) =>
                    dispatch({
                      type: DEPLOY_FUNCTION_ERROR,
                      errorDeployFunction: 'Deploy function fail',
                      error: error.response.data.error,
                    })
                  );
              })
              .catch((error) =>
                dispatch({
                  type: CREATE_FUNCTION_ERROR,
                  errorDeployFunction: 'Deploy function fail',
                  error: error.response.data.error,
                })
              );
          })
          .catch((error) =>
            dispatch({
              type: CREATE_FUNCTION_ERROR,
              errorDeployFunction: 'Deploy function fail',
              error: error.response.data.error,
            })
          );
      })
      .catch((error) =>
        dispatch({
          type: CREATE_FUNCTION_ERROR,
          errorDeployFunction: 'Deploy function fail',
          error: error.response.data.error,
        })
      );
  };
};

export const deployAfterBuild = (id, fields) => {
  return (dispatch) => {
    dispatch({ type: CREATE_FUNCTION_BEGIN });
    let env = null;
    if (fields[0].key.length > 0) {
      env = JSON.stringify(
        fields.reduce((acc, cur) => ({ ...acc, [cur.key]: cur.value }), {})
      )
        .replace(/\\n/g, '\\n')
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, '\\&')
        .replace(/\\r/g, '\\r')
        .replace(/\\t/g, '\\t')
        .replace(/\\b/g, '\\b')
        .replace(/\\f/g, '\\f');
    }

    deployFunc(id, env)
      .then((response) => {
        dispatch({
          type: DEPLOY_FUNCTION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: DEPLOY_FUNCTION_ERROR,
          errorDeployFunction: 'Deploy function fail',
          error: error.response.data.error,
        })
      );
  };
};

export const buildDeploy = (awsRoles, func, buildspec) => {
  return (dispatch) => {
    let data;
    if (!buildspec) {
      data = {
        habitat_id: func.habitat_id,
        project_name: `${func.function_name}-${Date.now()}`,
        artifac_name: `${func.function_name}.zip`,
        buildspec: 'buildspec.yml',
        source_location: func.function_name,
        artifac_location: func.code_s3_bucket,
        service_role: awsRoles[awsRoles.length - 1].arn,
        zip: 'ZIP',
        lambda_id: func.id,
      };
    } else {
      data = {
        habitat_id: func.habitat_id,
        project_name: `${func.function_name}-${Date.now()}`,
        artifac_name: `${func.function_name}.zip`,
        buildspec: buildspec,
        source_location: func.function_name,
        artifac_location: func.code_s3_bucket,
        service_role: awsRoles[awsRoles.length - 1].arn,
        zip: 'ZIP',
        lambda_id: func.id,
      };
    }
    dispatch({
      type: BUILD_FUNCTION_BEGIN,
      loading_message: 'Building function...',
    });
    createProject(data)
      .then((response) => {
        const buildInfo = {
          habitat_id: func.habitat_id,
          build_id: response.data.params.id,
        };
        createBuild(buildInfo)
          .then((reponse) => {
            dispatch({
              type: BUILD_FUNCTION_SUCCESS,
              payload: response.data,
            });
          })
          .catch((error) =>
            dispatch({
              type: BUILD_FUNCTION_ERROR,
              error: error.response.data.error,
            })
          );
      })
      .catch((error) =>
        dispatch({
          type: BUILD_FUNCTION_ERROR,
          errorBuildDeploy: 'Build fail',
          error: error.response.data.error,
        })
      );
  };
};

export const deployAll = (funcs) => {
  return (dispatch) => {
    funcs.forEach((func) => {
      if (!func.function_arn) {
        dispatch({
          type: DEPLOY_ALL_FUCTIONS_BEGIN,
          loading_message: 'Deploying functions...',
        });
        deployFunc(func.id)
          .then((response) => {
            dispatch({
              type: DEPLOY_ALL_FUCTIONS_SUCCESS,
              payload: response.data,
            });
          })
          .catch((error) =>
            dispatch({
              type: DEPLOY_ALL_FUCTIONS_ERROR,
              error: error.response.data.error,
            })
          );
      }
    });
  };
};

export const updateAll = (funcs, team) => {
  return (dispatch) => {
    funcs.forEach((func) => {
      const zipInfo = {
        file_name: func.function_name,
        habitat_id: func.habitat_id,
      };
      dispatch({
        type: UPDATE_ALL_FUCTIONS_BEGIN,
        loading_message: 'Updating functions...',
      });
      zipFile(zipInfo)
        .then((response) => {
          const file = response.data.path;
          const file_name = `${func.function_name}.zip`;
          uploadFile(file, func.code_s3_bucket, func.habitat_id, file_name)
            .then((response) => {
              func.code_s3_bucket = response.data.bucket_name;
              func.code_s3_key = response.data.key;
              zipRemove(file)
                .then((response) => {
                  updateCode(func)
                    .then((response) => {
                      dispatch({
                        type: UPDATE_ALL_FUCTIONS_SUCCESS,
                        payload: response.data,
                      });
                    })
                    .catch((error) =>
                      dispatch({
                        type: UPDATE_ALL_FUCTIONS_ERROR,
                        error: error.response.data.error,
                      })
                    );
                })
                .catch((error) =>
                  dispatch({
                    type: UPDATE_ALL_FUCTIONS_ERROR,
                    error: error.response.data.error,
                  })
                );
            })
            .catch((error) =>
              dispatch({
                type: UPDATE_ALL_FUCTIONS_ERROR,
                error: error.response.data.error,
              })
            );
        })
        .catch((error) =>
          dispatch({
            type: UPDATE_ALL_FUCTIONS_ERROR,
            error: error.response.data.error,
          })
        );
    });
  };
};

export const updateFunction = (func, team, git_hash) => {
  const zipInfo = {
    file_name: func.function_name,
    habitat_id: func.habitat_id,
  };

  return (dispatch) => {
    dispatch({
      type: UPDATA_CODE_BEGIN,
      loading_message: 'Updating function...',
    });
    zipFile(zipInfo)
      .then((response) => {
        const file = response.data.path;
        const file_name = `${func.function_name}.zip`;
        uploadFile(file, func.code_s3_bucket, func.habitat_id, file_name)
          .then((response) => {
            func.code_s3_bucket = response.data.bucket_name;
            func.code_s3_key = response.data.key;
            zipRemove(file)
              .then((response) => {
                updateCode(func)
                  .then((response) => {
                    const hashInfo = {
                      lambda_id: func.id,
                      git_hash,
                    };
                    updateHashLambda(hashInfo)
                      .then((response) => {
                        dispatch({
                          type: UPDATA_CODE_SUCCESS,
                          payload: response.data,
                        });
                      })
                      .catch((error) =>
                        dispatch({
                          type: UPDATA_CODE_ERROR,
                          error: error.response.data.error,
                        })
                      );
                  })
                  .catch((error) =>
                    dispatch({
                      type: UPDATA_CODE_ERROR,
                      error: error.response.data.error,
                    })
                  );
              })
              .catch((error) =>
                dispatch({
                  type: UPDATA_CODE_ERROR,
                  error: error.response.data.error,
                })
              );
          })
          .catch((error) =>
            dispatch({
              type: UPDATA_CODE_ERROR,
              error: error.response.data.error,
            })
          );
      })
      .catch((error) =>
        dispatch({
          type: UPDATA_CODE_ERROR,
          error: error.response.data.error,
        })
      );
  };
};

export const updateAndBuild = (func, git_hash, team) => {
  return (dispatch) => {
    const s3Data = {
      file_name: func.function_name,
      habitat_id: func.habitat_id,
      team_id: team.id,
    };

    dispatch({
      type: UPDATA_CODE_BEGIN,
      loading_message: 'Updating build...',
    });
    uploadClone(s3Data)
      .then((response) => {
        const buildInfo = {
          habitat_id: func.habitat_id,
          build_id: func.build_id,
        };
        createBuild(buildInfo)
          .then((response) => {
            const hashInfo = {
              lambda_id: func.id,
              git_hash,
            };
            updateHashLambda(hashInfo)
              .then((response) => {
                const payload = response.data;
                updateCode(func)
                  .then((response) => {
                    dispatch({
                      type: UPDATA_CODE_SUCCESS,
                      payload,
                    });
                  })
                  .catch((error) =>
                    dispatch({
                      type: UPDATA_CODE_ERROR,
                      error: error.response.data.error,
                    })
                  );
              })
              .catch((error) =>
                dispatch({
                  type: UPDATA_CODE_ERROR,
                  error: error.response.data.error,
                })
              );
          })
          .catch((error) =>
            dispatch({
              type: UPDATA_CODE_ERROR,
              error: error.response.data.error,
            })
          );
      })
      .catch((error) =>
        dispatch({
          type: UPDATA_CODE_ERROR,
          error: error.response.data.error,
        })
      );
  };
};

export const newFunction = (
  team,
  role,
  runtime,
  handler,
  git_hash,
  awsCreds,
  s3Buckets,
  habitat_id,
  function_name
) => {
  let data = {
    function_name,
    runtime,
    handler,
    role,
    habitat_id,
    git_hash,
    memory_size: 1024,
    timeout: 24,
    code_s3_bucket: null,
    code_s3_key: null,
  };
  const s3Data = {
    file_name: function_name,
    team_id: team.id,
    habitat_id,
  };
  return (dispatch) => {
    dispatch({
      type: CREATE_FUNCTION_BEGIN,
      loading_message: 'Creating function...',
    });
    const bucketName = `kor-lambda-${habitat_id}`;
    data.code_s3_bucket = bucketName;
    data.code_s3_key = `${function_name}.zip`;
    let bucket;
    if (s3Buckets) {
      bucket = s3Buckets.find((bucket) => bucket.bucket === bucketName);
    }
    if (!bucket) {
      uploadClone(s3Data)
        .then((response) => {
          addBucket(bucketName, habitat_id, awsCreds.region)
            .then((response) => {
              setFunc(data)
                .then((response) => {
                  dispatch({
                    type: CREATE_FUNCTION_SUCCESS,
                    payload: response.data.historical,
                  });
                })
                .catch((error) =>
                  dispatch({
                    type: CREATE_FUNCTION_ERROR,
                    error,
                    errorNewFunction: error.response.data.error,
                  })
                );
            })
            .catch((error) =>
              dispatch({
                type: CREATE_FUNCTION_ERROR,
                error: error.response.data.error,
                errorNewFunction: error.response.data.error,
              })
            );
        })
        .catch((error) =>
          dispatch({
            type: CREATE_FUNCTION_ERROR,
            error: error.response.data.error,
            errorNewFunction: error.response.data.error,
          })
        );
    } else {
      uploadClone(s3Data)
        .then((response) => {
          setFunc(data)
            .then((response) => {
              dispatch({
                type: CREATE_FUNCTION_SUCCESS,
                payload: response.data.historical,
              });
            })
            .catch((error) =>
              dispatch({
                type: CREATE_FUNCTION_ERROR,
                error,
                errorNewFunction: error.response.data.error,
              })
            );
        })
        .catch((error) =>
          dispatch({
            type: CREATE_FUNCTION_ERROR,
            error: error.response.data.error,
            errorNewFunction: error.response.data.error,
          })
        );
    }
  };
};

export const listFunctions = (habitat_id) => {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_FUNCTION_BEGIN,
      loading_message: 'Loading functions...',
    });
    fetchFuncs(habitat_id)
      .then((response) => {
        dispatch({
          type: GET_ALL_FUNCTION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_FUNCTION_ERROR,
          errorListFunctions: error.response.data.error,
          error: error.response.data.error,
        });
      });
  };
};

export const deleteFunction = (func_id, habitat_id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_FUNCTION_BEGIN,
      loading_message: 'Deleting function...',
    });
    removeFunc(func_id, habitat_id)
      .then((response) => {
        dispatch({
          type: DELETE_FUNCTION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_FUNCTION_ERROR,
          errorDeleteFunction: error.response.data.error,
          error: error.response.data.error,
        });
      });
  };
};

export const editFunction = (func, id) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FUNCTION_BEGIN,
      loading_message: 'Updating function...',
    });

    updateFunc(func, id)
      .then((response) => {
        dispatch({
          type: UPDATE_FUNCTION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_FUNCTION_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const lambdaBuildStatus = (lambda_id, status, habitat_id) => {
  const data = {
    lambda_id,
    status,
    habitat_id,
  };
  return (dispatch) => {
    dispatch({
      type: SET_BUILD_STATUS_BEGIN,
      loading_message: 'Setting build status...',
    });
    lambdaStatus(data)
      .then((response) => {
        dispatch({
          type: SET_BUILD_STATUS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: SET_BUILD_STATUS_ERROR,
          error: error.response.data.error,
        })
      );
  };
};

export const saveTest = (lambda_id, test_name, funcPayload) => {
  const data = {
    lambda_id,
    test_name,
    payload: funcPayload,
  };

  return (dispatch) => {
    dispatch({
      type: STORE_TEST_BEGIN,
      loading_message: 'Store test....',
    });
    storeTest(data)
      .then((response) => {
        dispatch({
          type: STORE_TEST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: STORE_TEST_ERROR,
          errorStoreTest: error.response.data.error,
          error: error.response.data.error,
        });
      });
  };
};

export const getTests = (lambdaID) => {
  return (dispatch) => {
    dispatch({
      type: GET_TEST_BEGIN,
      loading_message: 'Get tests...',
    });
    indexTest(lambdaID)
      .then((response) => {
        dispatch({
          type: GET_TEST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TEST_ERROR,
          error: error.response.data.error,
        });
      });
  };
};
