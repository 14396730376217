import React, { useEffect } from 'react';
import TeamNav from '../components/header/TeamNavbar';
import { connect } from 'react-redux';
import { getServer } from '../actions/server-status.actions';
import InternalServerErrorPage from '../containers/InternalServerErrorPage';
import '../assets/styles/components/MobileTablet.scss';
import telescope from '../assets/images/telescope-image.png';
import { logout } from '../actions/user.actions';


const AppTeamLayout = ({ children, error, logout, currentUser }) => {
  useEffect(() => {
    getServer();
  }, [getServer]);

  return (
    <>
    {error ? (
    <InternalServerErrorPage />
    ):(
      <div className="d-none d-lg-block">
        <div>
          <TeamNav teamName={children.props.match.params.name} />
          {children}
        </div>
      </div>
      )}
      <div
        id="mobile-tablet-main"
        className="d-sm-block d-md-block d-lg-none d-xl-none"
      >
        <div className="mobile-tablet-container">
          <p className="mobile-tablet-message">
            KOR is not optimized for tablet/ mobile at this current time.
            <br />
            Click below to suggest features you would like to have.
          </p>
          <a
            id="suggetion-btn"
            className="btn btn-primary"
            role="button"
            href="https://getkor.freshdesk.com/support/tickets/new"
            target="_blank"
            rel="noopener noreferrer"
          >
            Suggestions
          </a>
          {currentUser && (
            <div className="log-out-button" onClick={() => logout()}>
              <p>Log Out</p>
            </div>
          )}
          <img className="telescope" src={telescope} alt="Telescope" />
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  error: state.serverStatus.error,
  currentUser: state.user.user,
});

const mapDispatch = {
  getServer,
};

export default connect(mapState, mapDispatch)(AppTeamLayout);
