import React from 'react';
import '../../assets/styles/modals/Modal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button'

const RemoveBuildModal = (props) => {
    const handleCloseModal = () => {
        props.setShowRemoveModal('display-none-kor');
    }

    return (
        <div className={props.showRemoveModal + ' modal-kor-container'} >

            <div className="box-modal-options-container">
            <p><FontAwesomeIcon icon={faExclamationTriangle}  className="delete-icon"/> Delete Build Project</p>
            <p> Are you sure you want to delete this build project?</p>
            <div className="buttons-modal-kor">
                <Button variant="primary" size="sm" onClick={() => props.handleDeleteBuild(props.build.aws_build_id, props.habitat.id)}>
                    Delete
                </Button>
                <Button variant="primary" size="sm" onClick={handleCloseModal}>
                    Cancel
                </Button>
            </div>

        </div>
        </div>
    )
}

export default RemoveBuildModal;