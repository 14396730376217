import React from 'react';
import '../../assets/styles/modals/Modal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button'

const RemoveHabitatModal = (props) => {
    const handleCloseModal = () => {
        props.setShowRemoveHabitat('display-none-kor');
    }

    return (
        <div className={props.showRemoveHabitat + ' modal-kor-container'} >

        {props.errorDeleteHabitatCode === 409 ? (
            <div className="box-modal-options-container">
            <p>Delete Habitat</p>
            <p><FontAwesomeIcon icon={faExclamationTriangle}  className="delete-icon"/> You still have <b>{props.numResources}</b> resource(s) attached to this habitat, please address them before deletion.</p>
            <Button variant="primary" size="sm" onClick={handleCloseModal}>
                Close
            </Button>

        </div>
        ):(
            <div className="box-modal-options-container">
            <p>Delete Habitat</p>
            
            <p><FontAwesomeIcon icon={faExclamationTriangle}  className="delete-icon-safe"/> You have <b>{props.numResources}</b> resource(s) attached to this habitat, you can safely delete it.</p>
            <p> Are you sure you want to delete this habitat?</p>
            <div className="buttons-modal-kor">
                <Button variant="primary" size="sm" onClick={() => props.handleRemoveHabitat(props.habitat.id)}>
                    Delete
                </Button>
                <Button variant="primary" size="sm" onClick={handleCloseModal}>
                    Cancel
                </Button>
            </div>

        </div>
        )}


        </div>
    )
}

export default RemoveHabitatModal;