import {
    CREATE_HABITAT_BEGIN,
    CREATE_HABITAT_SUCCESS,
    CREATE_HABITAT_ERROR,
    GET_ALL_HABITATS_BEGIN,
    GET_ALL_HABITATS_SUCCESS,
    GET_ALL_HABITATS_ERROR,
    GET_HABITAT_BEGIN,
    GET_HABITAT_SUCCESS,
    GET_HABITAT_ERROR,
    SELECT_HABITAT_BEGIN,
    SELECT_HABITAT_SUCCESS,
    SELECT_HABITAT_ERROR,
    DELETE_HABITAT_BEGIN,
    DELETE_HABITAT_SUCCESS,
    DELETE_HABITAT_ERROR,
    UPDATE_HABITAT_BEGIN,
    UPDATE_HABITAT_SUCCESS,
    UPDATE_HABITAT_ERROR,
    SELECT_CREDS_HAB_BEGIN,
    SELECT_CREDS_HAB_SUCCESS,
    SELECT_CREDS_HAB_ERROR,
    GET_ALL_CONNECTED_S3_BEGIN,
    GET_ALL_CONNECTED_S3_SUCCESS,
    GET_ALL_CONNECTED_S3_ERROR,
    CONNECT_HAB_S3_BEGIN,
    CONNECT_HAB_S3_SUCCESS,
    CONNECT_HAB_S3_ERROR,
    DISCONNECT_HAB_S3_BEGIN,
    DISCONNECT_HAB_S3_SUCCESS,
    DISCONNECT_HAB_S3_ERROR,
    UPDATE_HAB_S3_BEGIN,
    UPDATE_HAB_S3_SUCCESS,
    UPDATE_HAB_S3_ERROR,
    HABITAT_SETUP_BEGIN,
    HABITAT_SETUP_SUCCESS,
    HABITAT_SETUP_ERROR,
    REQUEST_DELETE_HABITAT_BEGIN,
    REQUEST_DELETE_HABITAT_SUCCESS,
    REQUEST_DELETE_HABITAT_ERROR,
    GET_HABITAT_COSTS_BEGIN,
    GET_HABITAT_COSTS_SUCCESS,
    GET_HABITAT_COSTS_ERROR,
    GET_HABITAT_COSTS_ACTIVATION_BEGIN,
    GET_HABITAT_COSTS_ACTIVATION_SUCCESS,
    GET_HABITAT_COSTS_ACTIVATION_ERROR,
  } from './types';
  import {
    setHabitatLocal,
    updateHabitat,
    getAllHabitats,
    getConnectedS3s,
    connectS3s,
    disconnectS3s,
    getHabitat,
    createHabitat,
    removeHabitat,
    attachCredHabitat,
    fetchHabitatLocal,
    requestRemoveHabitat,
    getCosts,
    costsActivation,
  } from '../api/habitats';
  import {
    createAWSCredential,
    requestEncryptKey,
    awsRedirect,
    accessAwsCredential,
  } from '../api/aws/creds';
  import { habitatModel } from '../models/habitat';
  import { awsCredModel } from '../models/awsCreds';
  import { verifyCreds } from '../api/aws/sdk';
  import { createAWSRole } from '../api/awsRoles';
  import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
  import { faIgloo } from '@fortawesome/free-solid-svg-icons';
  import randomRickandMorty from 'random-rick-and-morty-character';
  
  export const updateActivationCost = (habitat_id, statAct) => {
    const data = {
      costs_activation: statAct,
    };
  
    return (dispatch) => {
      dispatch({
        type: CREATE_HABITAT_BEGIN,
        loading_message: 'Loading activation...',
      });
      costsActivation(data, habitat_id)
        .then((response) => {
          dispatch({
            type: GET_HABITAT_COSTS_ACTIVATION_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_HABITAT_COSTS_ACTIVATION_ERROR,
            error,
          });
        });
    };
  };
  
  export const addHabitadWithURL = (team_id) => {
    return (dispatch) => {
      dispatch({
        type: CREATE_HABITAT_BEGIN,
        loading_message: 'Creating habitat...',
      });
      const name = randomRickandMorty.rickAndMortyCharacter();
      const habitat = {
        name,
        team_id,
      };
      awsRedirect()
        .then((response) => {
          window.open(response.data.aws_link, '_blank');
          const accessInfo = {
            topic_arn: response.data.topic_arn,
            role_name: response.data.role_name,
          };
          accessAwsCredential(accessInfo)
            .then((response) => {
              const awsCred =
                response.data.aws_credentials[
                  response.data.aws_credentials.length - 1
                ];
              createHabitat(habitat)
                .then((response) => {
                  const habitat = response.data;
                  const newHabCredId = { aws_credential_id: awsCred.id };
                  verifyCreds(awsCred.id)
                    .then((response) => {
                      attachCredHabitat(habitat.id, newHabCredId)
                        .then((response) => {
                          const payload = response.data;
                          let awsPolicyDocument = {
                            Version: '2012-10-17',
                            Statement: [
                              {
                                Effect: 'Allow',
                                Action: [
                                  'logs:CreateLogGroup',
                                  'logs:CreateLogStream',
                                  'logs:DescribeLogGroups',
                                  'logs:DescribeLogStreams',
                                  'logs:PutLogEvents',
                                  'logs:GetLogEvents',
                                  'logs:FilterLogEvents',
                                ],
                                Resource: '*',
                              },
                              {
                                Effect: 'Allow',
                                Action: 'states:*',
                                Resource: '*',
                              },
                              {
                                Effect: 'Allow',
                                Action: 'logs:CreateLogGroup',
                                Resource: 'arn:aws:logs:us-east-1:973651203340:*',
                              },
                              {
                                Effect: 'Allow',
                                Action: [
                                  'logs:CreateLogStream',
                                  'logs:PutLogEvents',
                                ],
                                Resource: [
                                  'arn:aws:logs:us-east-1:973651203340:log-group:/aws/lambda/my-app-lambda:*',
                                  'arn:aws:logs:us-east-1:973651203340:log-group:/aws/codebuild/*',
                                ],
                              },
                              {
                                Effect: 'Allow',
                                Action: ['ecr:*', 'cloudtrail:LookupEvents'],
                                Resource: '*',
                              },
                              {
                                Sid: 'CloudWatchEventsFullAccess',
                                Effect: 'Allow',
                                Action: 'events:*',
                                Resource: '*',
                              },
                              {
                                Action: ['iam:PassRole'],
                                Resource: '*',
                                Effect: 'Allow',
                                Condition: {
                                  StringEqualsIfExists: {
                                    'iam:PassedToService': [
                                      'cloudformation.amazonaws.com',
                                      'elasticbeanstalk.amazonaws.com',
                                      'ec2.amazonaws.com',
                                      'ecs-tasks.amazonaws.com',
                                    ],
                                  },
                                },
                              },
                              {
                                Action: [
                                  'codecommit:CancelUploadArchive',
                                  'codecommit:GetBranch',
                                  'codecommit:GetCommit',
                                  'codecommit:GetUploadArchiveStatus',
                                  'codecommit:UploadArchive',
                                ],
                                Resource: '*',
                                Effect: 'Allow',
                              },
                              {
                                Action: [
                                  'codedeploy:CreateDeployment',
                                  'codedeploy:GetApplication',
                                  'codedeploy:GetApplicationRevision',
                                  'codedeploy:GetDeployment',
                                  'codedeploy:GetDeploymentConfig',
                                  'codedeploy:RegisterApplicationRevision',
                                ],
                                Resource: '*',
                                Effect: 'Allow',
                              },
                              {
                                Action: ['codestar-connections:UseConnection'],
                                Resource: '*',
                                Effect: 'Allow',
                              },
                              {
                                Action: [
                                  'elasticbeanstalk:*',
                                  'ec2:*',
                                  'elasticloadbalancing:*',
                                  'autoscaling:*',
                                  'cloudwatch:*',
                                  's3:*',
                                  'sns:*',
                                  'cloudformation:*',
                                  'rds:*',
                                  'sqs:*',
                                  'ecs:*',
                                ],
                                Resource: '*',
                                Effect: 'Allow',
                              },
                              {
                                Action: [
                                  'lambda:InvokeFunction',
                                  'lambda:ListFunctions',
                                ],
                                Resource: '*',
                                Effect: 'Allow',
                              },
                              {
                                Action: [
                                  'opsworks:CreateDeployment',
                                  'opsworks:DescribeApps',
                                  'opsworks:DescribeCommands',
                                  'opsworks:DescribeDeployments',
                                  'opsworks:DescribeInstances',
                                  'opsworks:DescribeStacks',
                                  'opsworks:UpdateApp',
                                  'opsworks:UpdateStack',
                                ],
                                Resource: '*',
                                Effect: 'Allow',
                              },
                              {
                                Action: [
                                  'cloudformation:CreateStack',
                                  'cloudformation:DeleteStack',
                                  'cloudformation:DescribeStacks',
                                  'cloudformation:UpdateStack',
                                  'cloudformation:CreateChangeSet',
                                  'cloudformation:DeleteChangeSet',
                                  'cloudformation:DescribeChangeSet',
                                  'cloudformation:ExecuteChangeSet',
                                  'cloudformation:SetStackPolicy',
                                  'cloudformation:ValidateTemplate',
                                ],
                                Resource: '*',
                                Effect: 'Allow',
                              },
                              {
                                Action: [
                                  'codebuild:BatchGetBuilds',
                                  'codebuild:StartBuild',
                                  'codebuild:BatchGetBuildBatches',
                                  'codebuild:StartBuildBatch',
                                ],
                                Resource: '*',
                                Effect: 'Allow',
                              },
                              {
                                Effect: 'Allow',
                                Action: [
                                  'devicefarm:ListProjects',
                                  'devicefarm:ListDevicePools',
                                  'devicefarm:GetRun',
                                  'devicefarm:GetUpload',
                                  'devicefarm:CreateUpload',
                                  'devicefarm:ScheduleRun',
                                ],
                                Resource: '*',
                              },
                              {
                                Effect: 'Allow',
                                Action: [
                                  'servicecatalog:ListProvisioningArtifacts',
                                  'servicecatalog:CreateProvisioningArtifact',
                                  'servicecatalog:DescribeProvisioningArtifact',
                                  'servicecatalog:DeleteProvisioningArtifact',
                                  'servicecatalog:UpdateProduct',
                                ],
                                Resource: '*',
                              },
                              {
                                Effect: 'Allow',
                                Action: ['cloudformation:ValidateTemplate'],
                                Resource: '*',
                              },
                              {
                                Effect: 'Allow',
                                Action: ['ecr:DescribeImages'],
                                Resource: '*',
                              },
                              {
                                Effect: 'Allow',
                                Action: [
                                  'states:DescribeExecution',
                                  'states:DescribeStateMachine',
                                  'states:StartExecution',
                                ],
                                Resource: '*',
                              },
                              {
                                Effect: 'Allow',
                                Action: [
                                  'appconfig:StartDeployment',
                                  'appconfig:StopDeployment',
                                  'appconfig:GetDeployment',
                                ],
                                Resource: '*',
                              },
                              {
                                Sid: 'IAMPassRoleForCloudWatchEvents',
                                Effect: 'Allow',
                                Action: 'iam:PassRole',
                                Resource:
                                  'arn:aws:iam::*:role/AWS_Events_Invoke_Targets',
                              },
                              {
                                Effect: 'Allow',
                                Action: [
                                  'xray:PutTraceSegments',
                                  'xray:PutTelemetryRecords',
                                  'xray:GetSamplingRules',
                                  'xray:GetSamplingTargets',
                                ],
                                Resource: ['*'],
                              },
                              {
                                Effect: 'Allow',
                                Action: [
                                  'cloudformation:DescribeChangeSet',
                                  'cloudformation:DescribeStackResources',
                                  'cloudformation:DescribeStacks',
                                  'cloudformation:GetTemplate',
                                  'cloudformation:ListStackResources',
                                  'cloudwatch:*',
                                  'cognito-identity:ListIdentityPools',
                                  'cognito-sync:GetCognitoEvents',
                                  'cognito-sync:SetCognitoEvents',
                                  'dynamodb:*',
                                  'ec2:DescribeSecurityGroups',
                                  'ec2:DescribeSubnets',
                                  'ec2:DescribeVpcs',
                                  'events:*',
                                  'iam:GetPolicy',
                                  'iam:GetPolicyVersion',
                                  'iam:GetRole',
                                  'iam:GetRolePolicy',
                                  'iam:ListAttachedRolePolicies',
                                  'iam:ListRolePolicies',
                                  'iam:ListRoles',
                                  'iam:PassRole',
                                  'iot:AttachPrincipalPolicy',
                                  'iot:AttachThingPrincipal',
                                  'iot:CreateKeysAndCertificate',
                                  'iot:CreatePolicy',
                                  'iot:CreateThing',
                                  'iot:CreateTopicRule',
                                  'iot:DescribeEndpoint',
                                  'iot:GetTopicRule',
                                  'iot:ListPolicies',
                                  'iot:ListThings',
                                  'iot:ListTopicRules',
                                  'iot:ReplaceTopicRule',
                                  'kinesis:DescribeStream',
                                  'kinesis:ListStreams',
                                  'kinesis:PutRecord',
                                  'kms:ListAliases',
                                  'codebuild:*',
                                  'lambda:*',
                                  'logs:*',
                                  's3:*',
                                  'sns:ListSubscriptions',
                                  'sns:ListSubscriptionsByTopic',
                                  'sns:ListTopics',
                                  'sns:Publish',
                                  'sns:Subscribe',
                                  'sns:Unsubscribe',
                                  'sqs:ListQueues',
                                  'sqs:SendMessage',
                                  'tag:GetResources',
                                  'xray:PutTelemetryRecords',
                                  'xray:PutTraceSegments',
                                ],
                                Resource: '*',
                              },
                            ],
                          };
  
                          let awsRolePolicy = {
                            Version: '2012-10-17',
                            Statement: {
                              Effect: 'Allow',
                              Principal: {
                                Service: [
                                  'lambda.amazonaws.com',
                                  'apigateway.amazonaws.com',
                                  'codebuild.amazonaws.com',
                                ],
                              },
                              Action: 'sts:AssumeRole',
                            },
                          };
  
                          const jsonPolicyDocument = JSON.stringify(
                            awsPolicyDocument
                          );
                          const awsPolicyDocumentReplace = jsonPolicyDocument
                            .replace(/\\n/g, '\\n')
                            .replace(/\\'/g, "\\'")
                            .replace(/\\"/g, '\\"')
                            .replace(/\\&/g, '\\&')
                            .replace(/\\r/g, '\\r')
                            .replace(/\\t/g, '\\t')
                            .replace(/\\b/g, '\\b')
                            .replace(/\\f/g, '\\f');
  
                          const jsonAwsRolePolicy = JSON.stringify(awsRolePolicy);
                          const awsRolePolicyReplace = jsonAwsRolePolicy
                            .replace(/\\n/g, '\\n')
                            .replace(/\\'/g, "\\'")
                            .replace(/\\"/g, '\\"')
                            .replace(/\\&/g, '\\&')
                            .replace(/\\r/g, '\\r')
                            .replace(/\\t/g, '\\t')
                            .replace(/\\b/g, '\\b')
                            .replace(/\\f/g, '\\f');
                          const role_name = `KOR-TEMPLATE-${Date.now()}`;
                          const roleInfo = {
                            role_name,
                            role_policy: awsRolePolicyReplace,
                            habitat_id: habitat.id,
                            policy_document: awsPolicyDocumentReplace,
                          };
  
                          createAWSRole(roleInfo)
                            .then((response) => {
                              const role = response.data;
                              dispatch({
                                type: CREATE_HABITAT_SUCCESS,
                                payload,
                              });
                            })
                            .catch((error) => {
                              removeHabitat(habitat.id);
                              dispatch({
                                type: CREATE_HABITAT_ERROR,
                                error: error.response.data.error,
                              });
                            });
                        })
  
                        .catch((error) => {
                          removeHabitat(habitat.id);
                          dispatch({
                            type: CREATE_HABITAT_ERROR,
                            error: error.response.data.error,
                          });
                        });
                    })
                    .catch((error) => {
                      removeHabitat(habitat.id);
                      dispatch({
                        type: CREATE_HABITAT_ERROR,
                        error: error.response.data.error,
                      });
                    });
                })
                .catch((error) => {
                  removeHabitat(habitat.id);
                  dispatch({
                    type: CREATE_HABITAT_ERROR,
                    error: error.response.data.error,
                  });
                });
            })
            .catch((error) =>
              dispatch({
                type: CREATE_HABITAT_ERROR,
                error: error.response.data.error,
              })
            );
        })
        .catch((error) =>
          dispatch({
            type: CREATE_HABITAT_ERROR,
            error: error.response.data.error,
          })
        );
    };
  };
  
  export const getHabitatCosts = (
    habitat_id,
    init_date,
    end_date,
    granularity
  ) => {
    const data = {
      init_date: init_date,
      end_date: end_date,
      granularity: granularity,
      habitat_id: habitat_id,
      broken_down: 'SERVICE',
    };
    return (dispatch) => {
      dispatch({
        type: GET_HABITAT_COSTS_BEGIN,
        loading_message: 'Loading habitat costs...',
      });
      const timePeriod = [];
      const costsGroup = [];
      const amountA = [];
  
      getCosts(data)
        .then((response) => {
          const last = response.data.trend[response.data.trend.length - 1];
          response.data.trend.forEach((time) => {
            timePeriod.push({
              date: time.time_period.start,
            });
          });
  
          if (data.granularity === 'DAILY') {
            timePeriod.push({
              date: last.time_period.end,
            });
          }
  
          response.data.trend.forEach((costGroup) => {
            costGroup.groups.forEach((c) => {
              costsGroup.push({
                name: c.keys[0],
                data: [1, 1],
              });
            });
          });
  
          response.data.trend.forEach((amount) => {
            amount.groups.forEach((amountTrend) => {
              amountA.push({
                date: amount.time_period.start,
                name: amountTrend.keys[0],
                amount: amountTrend.metrics.NetAmortizedCost.amount,
              });
            });
          });
  
          const completeArray = amountA;
          const names = [];
  
          completeArray.forEach((arr) => {
            names.push(arr.name);
          });
  
          const dates = [];
  
          completeArray.forEach((arr) => {
            dates.push(arr.date);
          });
  
          const uniqueName = new Set(names);
          const uniqueDates = new Set(dates);
          const uniqueDatesArray = [...uniqueDates];
          const uniqueNamesArray = [...uniqueName];
  
          const result3 = [];
  
          uniqueName.forEach((name) => {
            result3.push({
              name: name,
              data: [],
            });
          });
  
          const totalAmount = [];
  
          uniqueName.forEach((name) => {
            totalAmount.push({
              name: name,
              totalamount: [],
              total: [],
              percents: [],
            });
          });
  
          completeArray.forEach((complete) => {
            totalAmount.forEach((res) => {
              if (complete.name === res.name) {
                res.totalamount.push(complete.amount);
              }
            });
          });
  
          totalAmount.forEach((amount) => {
            amount.total.push(
              amount.totalamount
                .map(Number)
                .reduce((a, b) => a + b, 0)
                .toFixed(2)
            );
          });
  
          const totalCost = [];
  
          let total = 0;
  
          totalAmount.forEach((amount) => {
            totalCost.push(parseFloat(amount.total));
          });
  
          const reducer = (accumulator, currentValue) =>
            accumulator + currentValue;
  
          if (totalAmount.length === 0) {
            total = 0;
          } else {
            total = totalCost.reduce(reducer);
            total = total.toFixed(2);
            totalAmount.forEach((amount) => {
              if (
                (
                  (amount.totalamount.map(Number).reduce((a, b) => a + b, 0) /
                    total) *
                  100
                ).toFixed(2) > 0.5
              ) {
                amount.percents.push(
                  (
                    (amount.totalamount.map(Number).reduce((a, b) => a + b, 0) /
                      total) *
                    100
                  ).toFixed(2)
                );
              } else {
                amount.percents.push(0);
              }
            });
          }
  
          completeArray.forEach((complete) => {
            result3.forEach((res) => {
              if (complete.name === res.name) {
                res.data.push(Number.parseFloat(complete.amount).toFixed(2));
              }
            });
          });
  
          const totalDates = [];
  
          uniqueDates.forEach((date) => {
            totalDates.push(date);
          });
  
          result3.forEach((res) => {
            uniqueDates.forEach((date, index) => {
              if (totalDates.length > res.data.length) {
                res.data.push('0');
              }
            });
          });
  
          const pieChart = {
            percents: [],
            labels: [],
          };
  
          totalAmount.forEach((amount) => {
            pieChart.percents.push(parseFloat(amount.percents));
            pieChart.labels.push(amount.name);
          });
  
          dispatch({
            type: GET_HABITAT_COSTS_SUCCESS,
            payload: response.data,
            time: uniqueDatesArray,
            amount: amountA,
            costGroup: costsGroup,
            totalAmount: totalAmount,
            dataChart: result3,
            pieChart: pieChart,
            totalCosts: total,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_HABITAT_COSTS_ERROR,
            error,
          });
        });
    };
  };
  
  export const newHabitat = (
    name,
    apiKey,
    apiSecret,
    region,
    nameHabitat,
    team_id
  ) => {
    const habitat = habitatModel(nameHabitat, team_id);
    const crypto = require('crypto');
    const algorithm = 'aes-256-cfb';
    let key = '';
    const iv = '0000000000000000';
    return (dispatch) => {
      dispatch({
        type: CREATE_HABITAT_BEGIN,
        loading_message: 'Creating habitat...',
      });
      createHabitat(habitat)
        .then((response) => {
          const habitat_id = response.data.id;
          requestEncryptKey(habitat_id)
            .then((response) => {
              key = response.data.key;
              const cipherApiKey = crypto.createCipheriv(algorithm, key, iv);
              let cryptedApiKey = cipherApiKey.update(apiKey, 'utf-8', 'base64');
              cryptedApiKey += cipherApiKey.final('base64');
  
              const cipherApiSecret = crypto.createCipheriv(algorithm, key, iv);
              let cryptedApiSecret = cipherApiSecret.update(
                apiSecret,
                'utf-8',
                'base64'
              );
              cryptedApiSecret += cipherApiSecret.final('base64');
  
              const awsCreds = awsCredModel(
                name,
                cryptedApiKey,
                cryptedApiSecret,
                region,
                habitat_id,
                key
              );
              createAWSCredential(awsCreds)
                .then((response) => {
                  const awsCred = response.data;
                  const credId = response.data.id;
                  const newHabCredId = { aws_credential_id: credId };
                  verifyCreds(credId)
                    .then((response) => {
                      awsCred.verified = true;
                      attachCredHabitat(habitat_id, newHabCredId)
                        .then((response) => {
                          const payload = response.data;
                          let awsPolicyDocument = {
                            Version: '2012-10-17',
                            Statement: [
                              {
                                Effect: 'Allow',
                                Action: [
                                  'logs:CreateLogGroup',
                                  'logs:CreateLogStream',
                                  'logs:DescribeLogGroups',
                                  'logs:DescribeLogStreams',
                                  'logs:PutLogEvents',
                                  'logs:GetLogEvents',
                                  'logs:FilterLogEvents',
                                ],
                                Resource: '*',
                              },
                              {
                                Effect: 'Allow',
                                Action: 'states:*',
                                Resource: '*',
                              },
                              {
                                Effect: 'Allow',
                                Action: 'logs:CreateLogGroup',
                                Resource: 'arn:aws:logs:us-east-1:973651203340:*',
                              },
                              {
                                Effect: 'Allow',
                                Action: [
                                  'logs:CreateLogStream',
                                  'logs:PutLogEvents',
                                ],
                                Resource: [
                                  'arn:aws:logs:us-east-1:973651203340:log-group:/aws/lambda/my-app-lambda:*',
                                  'arn:aws:logs:us-east-1:973651203340:log-group:/aws/codebuild/*',
                                ],
                              },
                              {
                                Effect: 'Allow',
                                Action: ['ecr:*', 'cloudtrail:LookupEvents'],
                                Resource: '*',
                              },
                              {
                                Sid: 'CloudWatchEventsFullAccess',
                                Effect: 'Allow',
                                Action: 'events:*',
                                Resource: '*',
                              },
                              {
                                Effect: 'Allow',
                                Action: ['ses:SendEmail', 'ses:SendRawEmail'],
                                Resource: '*',
                              },
                              {
                                Action: ['iam:PassRole'],
                                Resource: '*',
                                Effect: 'Allow',
                                Condition: {
                                  StringEqualsIfExists: {
                                    'iam:PassedToService': [
                                      'cloudformation.amazonaws.com',
                                      'elasticbeanstalk.amazonaws.com',
                                      'ec2.amazonaws.com',
                                      'ecs-tasks.amazonaws.com',
                                    ],
                                  },
                                },
                              },
                              {
                                Action: [
                                  'codecommit:CancelUploadArchive',
                                  'codecommit:GetBranch',
                                  'codecommit:GetCommit',
                                  'codecommit:GetUploadArchiveStatus',
                                  'codecommit:UploadArchive',
                                ],
                                Resource: '*',
                                Effect: 'Allow',
                              },
                              {
                                Action: [
                                  'codedeploy:CreateDeployment',
                                  'codedeploy:GetApplication',
                                  'codedeploy:GetApplicationRevision',
                                  'codedeploy:GetDeployment',
                                  'codedeploy:GetDeploymentConfig',
                                  'codedeploy:RegisterApplicationRevision',
                                ],
                                Resource: '*',
                                Effect: 'Allow',
                              },
                              {
                                Action: ['codestar-connections:UseConnection'],
                                Resource: '*',
                                Effect: 'Allow',
                              },
                              {
                                Action: [
                                  'elasticbeanstalk:*',
                                  'ec2:*',
                                  'elasticloadbalancing:*',
                                  'autoscaling:*',
                                  'cloudwatch:*',
                                  's3:*',
                                  'sns:*',
                                  'cloudformation:*',
                                  'rds:*',
                                  'sqs:*',
                                  'ecs:*',
                                ],
                                Resource: '*',
                                Effect: 'Allow',
                              },
                              {
                                Action: [
                                  'lambda:InvokeFunction',
                                  'lambda:ListFunctions',
                                ],
                                Resource: '*',
                                Effect: 'Allow',
                              },
                              {
                                Action: [
                                  'opsworks:CreateDeployment',
                                  'opsworks:DescribeApps',
                                  'opsworks:DescribeCommands',
                                  'opsworks:DescribeDeployments',
                                  'opsworks:DescribeInstances',
                                  'opsworks:DescribeStacks',
                                  'opsworks:UpdateApp',
                                  'opsworks:UpdateStack',
                                ],
                                Resource: '*',
                                Effect: 'Allow',
                              },
                              {
                                Action: [
                                  'cloudformation:CreateStack',
                                  'cloudformation:DeleteStack',
                                  'cloudformation:DescribeStacks',
                                  'cloudformation:UpdateStack',
                                  'cloudformation:CreateChangeSet',
                                  'cloudformation:DeleteChangeSet',
                                  'cloudformation:DescribeChangeSet',
                                  'cloudformation:ExecuteChangeSet',
                                  'cloudformation:SetStackPolicy',
                                  'cloudformation:ValidateTemplate',
                                ],
                                Resource: '*',
                                Effect: 'Allow',
                              },
                              {
                                Action: [
                                  'codebuild:BatchGetBuilds',
                                  'codebuild:StartBuild',
                                  'codebuild:BatchGetBuildBatches',
                                  'codebuild:StartBuildBatch',
                                ],
                                Resource: '*',
                                Effect: 'Allow',
                              },
                              {
                                Effect: 'Allow',
                                Action: [
                                  'devicefarm:ListProjects',
                                  'devicefarm:ListDevicePools',
                                  'devicefarm:GetRun',
                                  'devicefarm:GetUpload',
                                  'devicefarm:CreateUpload',
                                  'devicefarm:ScheduleRun',
                                ],
                                Resource: '*',
                              },
                              {
                                Effect: 'Allow',
                                Action: [
                                  'servicecatalog:ListProvisioningArtifacts',
                                  'servicecatalog:CreateProvisioningArtifact',
                                  'servicecatalog:DescribeProvisioningArtifact',
                                  'servicecatalog:DeleteProvisioningArtifact',
                                  'servicecatalog:UpdateProduct',
                                ],
                                Resource: '*',
                              },
                              {
                                Effect: 'Allow',
                                Action: ['cloudformation:ValidateTemplate'],
                                Resource: '*',
                              },
                              {
                                Effect: 'Allow',
                                Action: ['ecr:DescribeImages'],
                                Resource: '*',
                              },
                              {
                                Effect: 'Allow',
                                Action: [
                                  'states:DescribeExecution',
                                  'states:DescribeStateMachine',
                                  'states:StartExecution',
                                ],
                                Resource: '*',
                              },
                              {
                                Effect: 'Allow',
                                Action: [
                                  'appconfig:StartDeployment',
                                  'appconfig:StopDeployment',
                                  'appconfig:GetDeployment',
                                ],
                                Resource: '*',
                              },
                              {
                                Sid: 'IAMPassRoleForCloudWatchEvents',
                                Effect: 'Allow',
                                Action: 'iam:PassRole',
                                Resource:
                                  'arn:aws:iam::*:role/AWS_Events_Invoke_Targets',
                              },
                              {
                                Effect: 'Allow',
                                Action: [
                                  'xray:PutTraceSegments',
                                  'xray:PutTelemetryRecords',
                                  'xray:GetSamplingRules',
                                  'xray:GetSamplingTargets',
                                ],
                                Resource: ['*'],
                              },
                              {
                                Effect: 'Allow',
                                Action: [
                                  'cloudformation:DescribeChangeSet',
                                  'cloudformation:DescribeStackResources',
                                  'cloudformation:DescribeStacks',
                                  'cloudformation:GetTemplate',
                                  'cloudformation:ListStackResources',
                                  'cloudwatch:*',
                                  'cognito-identity:ListIdentityPools',
                                  'cognito-sync:GetCognitoEvents',
                                  'cognito-sync:SetCognitoEvents',
                                  'dynamodb:*',
                                  'ec2:DescribeSecurityGroups',
                                  'ec2:DescribeSubnets',
                                  'ec2:DescribeVpcs',
                                  'events:*',
                                  'iam:GetPolicy',
                                  'iam:GetPolicyVersion',
                                  'iam:GetRole',
                                  'iam:GetRolePolicy',
                                  'iam:ListAttachedRolePolicies',
                                  'iam:ListRolePolicies',
                                  'iam:ListRoles',
                                  'iam:PassRole',
                                  'iot:AttachPrincipalPolicy',
                                  'iot:AttachThingPrincipal',
                                  'iot:CreateKeysAndCertificate',
                                  'iot:CreatePolicy',
                                  'iot:CreateThing',
                                  'iot:CreateTopicRule',
                                  'iot:DescribeEndpoint',
                                  'iot:GetTopicRule',
                                  'iot:ListPolicies',
                                  'iot:ListThings',
                                  'iot:ListTopicRules',
                                  'iot:ReplaceTopicRule',
                                  'kinesis:DescribeStream',
                                  'kinesis:ListStreams',
                                  'kinesis:PutRecord',
                                  'kms:ListAliases',
                                  'codebuild:*',
                                  'lambda:*',
                                  'logs:*',
                                  's3:*',
                                  'sns:ListSubscriptions',
                                  'sns:ListSubscriptionsByTopic',
                                  'sns:ListTopics',
                                  'sns:Publish',
                                  'sns:Subscribe',
                                  'sns:Unsubscribe',
                                  'sqs:ListQueues',
                                  'sqs:SendMessage',
                                  'tag:GetResources',
                                  'xray:PutTelemetryRecords',
                                  'xray:PutTraceSegments',
                                ],
                                Resource: '*',
                              },
                            ],
                          };
  
                          let awsRolePolicy = {
                            Version: '2012-10-17',
                            Statement: {
                              Effect: 'Allow',
                              Principal: {
                                Service: [
                                  'lambda.amazonaws.com',
                                  'apigateway.amazonaws.com',
                                  'codebuild.amazonaws.com',
                                ],
                              },
                              Action: 'sts:AssumeRole',
                            },
                          };
  
                          const jsonPolicyDocument = JSON.stringify(
                            awsPolicyDocument
                          );
                          const awsPolicyDocumentReplace = jsonPolicyDocument
                            .replace(/\\n/g, '\\n')
                            .replace(/\\'/g, "\\'")
                            .replace(/\\"/g, '\\"')
                            .replace(/\\&/g, '\\&')
                            .replace(/\\r/g, '\\r')
                            .replace(/\\t/g, '\\t')
                            .replace(/\\b/g, '\\b')
                            .replace(/\\f/g, '\\f');
  
                          const jsonAwsRolePolicy = JSON.stringify(awsRolePolicy);
                          const awsRolePolicyReplace = jsonAwsRolePolicy
                            .replace(/\\n/g, '\\n')
                            .replace(/\\'/g, "\\'")
                            .replace(/\\"/g, '\\"')
                            .replace(/\\&/g, '\\&')
                            .replace(/\\r/g, '\\r')
                            .replace(/\\t/g, '\\t')
                            .replace(/\\b/g, '\\b')
                            .replace(/\\f/g, '\\f');
                          const role_name = `KOR-TEMPLATE-${Date.now()}`;
                          const roleInfo = {
                            role_name,
                            role_policy: awsRolePolicyReplace,
                            habitat_id,
                            policy_document: awsPolicyDocumentReplace,
                          };
                          createAWSRole(roleInfo)
                            .then((response) => {
                              dispatch({
                                type: CREATE_HABITAT_SUCCESS,
                                payload,
                              });
                            })
                            .catch((error) => {
                              removeHabitat(habitat_id);
                              dispatch({
                                type: CREATE_HABITAT_ERROR,
                                errorCreateHabitat: 'Create habitat fail',
                                error,
                              });
                            });
                        })
                        .catch((error) => {
                          removeHabitat(habitat_id);
                          dispatch({
                            type: CREATE_HABITAT_ERROR,
                            errorCreateHabitat: 'Create habitat fail',
                            error,
                          });
                        });
                    })
                    .catch((error) => {
                      removeHabitat(habitat_id);
                      dispatch({
                        type: CREATE_HABITAT_ERROR,
                        errorCreateHabitat: 'Create habitat fail',
                        error,
                      });
                    });
                })
                .catch((error) => {
                  removeHabitat(habitat_id);
                  dispatch({
                    type: CREATE_HABITAT_ERROR,
                    errorCreateHabitat: 'Create habitat fail',
                    error,
                  });
                });
            })
            .catch((error) => {
              removeHabitat(habitat_id);
              dispatch({
                type: CREATE_HABITAT_ERROR,
                errorCreateHabitat: 'Create habitat fail',
                error,
              });
            });
        })
        .catch((error) =>
          dispatch({
            type: CREATE_HABITAT_ERROR,
            errorCreateHabitat: 'Create habitat fail',
            error,
          })
        );
    };
  };
  
  export const newHabitatWithCreds = (idCreds, team_id) => {
    const nameHabitat = randomRickandMorty.rickAndMortyCharacter();
    const habitat = habitatModel(nameHabitat, team_id);
    return (dispatch) => {
      dispatch({
        type: CREATE_HABITAT_BEGIN,
        loading_message: 'Creating habitat...',
      });
      createHabitat(habitat)
        .then((response) => {
          const habitat_id = response.data.id;
          const newHabCredId = { aws_credential_id: idCreds };
          attachCredHabitat(habitat_id, newHabCredId)
            .then((response) => {
              const payload = response.data;
              let awsPolicyDocument = {
                Version: '2012-10-17',
                Statement: [
                  {
                    Effect: 'Allow',
                    Action: [
                      'logs:CreateLogGroup',
                      'logs:CreateLogStream',
                      'logs:DescribeLogGroups',
                      'logs:DescribeLogStreams',
                      'logs:PutLogEvents',
                      'logs:GetLogEvents',
                      'logs:FilterLogEvents',
                    ],
                    Resource: '*',
                  },
                  {
                    Effect: 'Allow',
                    Action: 'states:*',
                    Resource: '*',
                  },
                  {
                    Effect: 'Allow',
                    Action: 'logs:CreateLogGroup',
                    Resource: 'arn:aws:logs:us-east-1:973651203340:*',
                  },
                  {
                    Effect: 'Allow',
                    Action: ['logs:CreateLogStream', 'logs:PutLogEvents'],
                    Resource: [
                      'arn:aws:logs:us-east-1:973651203340:log-group:/aws/lambda/my-app-lambda:*',
                      'arn:aws:logs:us-east-1:973651203340:log-group:/aws/codebuild/*',
                    ],
                  },
                  {
                    Effect: 'Allow',
                    Action: ['ecr:*', 'cloudtrail:LookupEvents'],
                    Resource: '*',
                  },
                  {
                    Sid: 'CloudWatchEventsFullAccess',
                    Effect: 'Allow',
                    Action: 'events:*',
                    Resource: '*',
                  },
                  {
                    Action: ['iam:PassRole'],
                    Resource: '*',
                    Effect: 'Allow',
                    Condition: {
                      StringEqualsIfExists: {
                        'iam:PassedToService': [
                          'cloudformation.amazonaws.com',
                          'elasticbeanstalk.amazonaws.com',
                          'ec2.amazonaws.com',
                          'ecs-tasks.amazonaws.com',
                        ],
                      },
                    },
                  },
                  {
                    Action: [
                      'codecommit:CancelUploadArchive',
                      'codecommit:GetBranch',
                      'codecommit:GetCommit',
                      'codecommit:GetUploadArchiveStatus',
                      'codecommit:UploadArchive',
                    ],
                    Resource: '*',
                    Effect: 'Allow',
                  },
                  {
                    Action: [
                      'codedeploy:CreateDeployment',
                      'codedeploy:GetApplication',
                      'codedeploy:GetApplicationRevision',
                      'codedeploy:GetDeployment',
                      'codedeploy:GetDeploymentConfig',
                      'codedeploy:RegisterApplicationRevision',
                    ],
                    Resource: '*',
                    Effect: 'Allow',
                  },
                  {
                    Action: ['codestar-connections:UseConnection'],
                    Resource: '*',
                    Effect: 'Allow',
                  },
                  {
                    Action: [
                      'elasticbeanstalk:*',
                      'ec2:*',
                      'elasticloadbalancing:*',
                      'autoscaling:*',
                      'cloudwatch:*',
                      's3:*',
                      'sns:*',
                      'cloudformation:*',
                      'rds:*',
                      'sqs:*',
                      'ecs:*',
                    ],
                    Resource: '*',
                    Effect: 'Allow',
                  },
                  {
                    Action: ['lambda:InvokeFunction', 'lambda:ListFunctions'],
                    Resource: '*',
                    Effect: 'Allow',
                  },
                  {
                    Action: [
                      'opsworks:CreateDeployment',
                      'opsworks:DescribeApps',
                      'opsworks:DescribeCommands',
                      'opsworks:DescribeDeployments',
                      'opsworks:DescribeInstances',
                      'opsworks:DescribeStacks',
                      'opsworks:UpdateApp',
                      'opsworks:UpdateStack',
                    ],
                    Resource: '*',
                    Effect: 'Allow',
                  },
                  {
                    Action: [
                      'cloudformation:CreateStack',
                      'cloudformation:DeleteStack',
                      'cloudformation:DescribeStacks',
                      'cloudformation:UpdateStack',
                      'cloudformation:CreateChangeSet',
                      'cloudformation:DeleteChangeSet',
                      'cloudformation:DescribeChangeSet',
                      'cloudformation:ExecuteChangeSet',
                      'cloudformation:SetStackPolicy',
                      'cloudformation:ValidateTemplate',
                    ],
                    Resource: '*',
                    Effect: 'Allow',
                  },
                  {
                    Action: [
                      'codebuild:BatchGetBuilds',
                      'codebuild:StartBuild',
                      'codebuild:BatchGetBuildBatches',
                      'codebuild:StartBuildBatch',
                    ],
                    Resource: '*',
                    Effect: 'Allow',
                  },
                  {
                    Effect: 'Allow',
                    Action: [
                      'devicefarm:ListProjects',
                      'devicefarm:ListDevicePools',
                      'devicefarm:GetRun',
                      'devicefarm:GetUpload',
                      'devicefarm:CreateUpload',
                      'devicefarm:ScheduleRun',
                    ],
                    Resource: '*',
                  },
                  {
                    Effect: 'Allow',
                    Action: [
                      'servicecatalog:ListProvisioningArtifacts',
                      'servicecatalog:CreateProvisioningArtifact',
                      'servicecatalog:DescribeProvisioningArtifact',
                      'servicecatalog:DeleteProvisioningArtifact',
                      'servicecatalog:UpdateProduct',
                    ],
                    Resource: '*',
                  },
                  {
                    Effect: 'Allow',
                    Action: ['cloudformation:ValidateTemplate'],
                    Resource: '*',
                  },
                  {
                    Effect: 'Allow',
                    Action: ['ecr:DescribeImages'],
                    Resource: '*',
                  },
                  {
                    Effect: 'Allow',
                    Action: [
                      'states:DescribeExecution',
                      'states:DescribeStateMachine',
                      'states:StartExecution',
                    ],
                    Resource: '*',
                  },
                  {
                    Effect: 'Allow',
                    Action: [
                      'appconfig:StartDeployment',
                      'appconfig:StopDeployment',
                      'appconfig:GetDeployment',
                    ],
                    Resource: '*',
                  },
                  {
                    Sid: 'IAMPassRoleForCloudWatchEvents',
                    Effect: 'Allow',
                    Action: 'iam:PassRole',
                    Resource: 'arn:aws:iam::*:role/AWS_Events_Invoke_Targets',
                  },
                  {
                    Effect: 'Allow',
                    Action: [
                      'xray:PutTraceSegments',
                      'xray:PutTelemetryRecords',
                      'xray:GetSamplingRules',
                      'xray:GetSamplingTargets',
                    ],
                    Resource: ['*'],
                  },
                  {
                    Effect: 'Allow',
                    Action: [
                      'cloudformation:DescribeChangeSet',
                      'cloudformation:DescribeStackResources',
                      'cloudformation:DescribeStacks',
                      'cloudformation:GetTemplate',
                      'cloudformation:ListStackResources',
                      'cloudwatch:*',
                      'cognito-identity:ListIdentityPools',
                      'cognito-sync:GetCognitoEvents',
                      'cognito-sync:SetCognitoEvents',
                      'dynamodb:*',
                      'ec2:DescribeSecurityGroups',
                      'ec2:DescribeSubnets',
                      'ec2:DescribeVpcs',
                      'events:*',
                      'iam:GetPolicy',
                      'iam:GetPolicyVersion',
                      'iam:GetRole',
                      'iam:GetRolePolicy',
                      'iam:ListAttachedRolePolicies',
                      'iam:ListRolePolicies',
                      'iam:ListRoles',
                      'iam:PassRole',
                      'iot:AttachPrincipalPolicy',
                      'iot:AttachThingPrincipal',
                      'iot:CreateKeysAndCertificate',
                      'iot:CreatePolicy',
                      'iot:CreateThing',
                      'iot:CreateTopicRule',
                      'iot:DescribeEndpoint',
                      'iot:GetTopicRule',
                      'iot:ListPolicies',
                      'iot:ListThings',
                      'iot:ListTopicRules',
                      'iot:ReplaceTopicRule',
                      'kinesis:DescribeStream',
                      'kinesis:ListStreams',
                      'kinesis:PutRecord',
                      'kms:ListAliases',
                      'codebuild:*',
                      'lambda:*',
                      'logs:*',
                      's3:*',
                      'sns:ListSubscriptions',
                      'sns:ListSubscriptionsByTopic',
                      'sns:ListTopics',
                      'sns:Publish',
                      'sns:Subscribe',
                      'sns:Unsubscribe',
                      'sqs:ListQueues',
                      'sqs:SendMessage',
                      'tag:GetResources',
                      'xray:PutTelemetryRecords',
                      'xray:PutTraceSegments',
                    ],
                    Resource: '*',
                  },
                ],
              };
  
              let awsRolePolicy = {
                Version: '2012-10-17',
                Statement: {
                  Effect: 'Allow',
                  Principal: {
                    Service: [
                      'lambda.amazonaws.com',
                      'apigateway.amazonaws.com',
                      'codebuild.amazonaws.com',
                    ],
                  },
                  Action: 'sts:AssumeRole',
                },
              };
  
              const jsonPolicyDocument = JSON.stringify(awsPolicyDocument);
              const awsPolicyDocumentReplace = jsonPolicyDocument
                .replace(/\\n/g, '\\n')
                .replace(/\\'/g, "\\'")
                .replace(/\\"/g, '\\"')
                .replace(/\\&/g, '\\&')
                .replace(/\\r/g, '\\r')
                .replace(/\\t/g, '\\t')
                .replace(/\\b/g, '\\b')
                .replace(/\\f/g, '\\f');
  
              const jsonAwsRolePolicy = JSON.stringify(awsRolePolicy);
              const awsRolePolicyReplace = jsonAwsRolePolicy
                .replace(/\\n/g, '\\n')
                .replace(/\\'/g, "\\'")
                .replace(/\\"/g, '\\"')
                .replace(/\\&/g, '\\&')
                .replace(/\\r/g, '\\r')
                .replace(/\\t/g, '\\t')
                .replace(/\\b/g, '\\b')
                .replace(/\\f/g, '\\f');
  
              const role_name = `KOR-TEMPLATE-${Date.now()}`;
              const roleInfo = {
                role_name,
                role_policy: awsRolePolicyReplace,
                habitat_id,
                policy_document: awsPolicyDocumentReplace,
              };
  
              createAWSRole(roleInfo)
                .then((response) => {
                  dispatch({
                    type: CREATE_HABITAT_SUCCESS,
                    payload,
                  });
                })
                .catch((error) => {
                  removeHabitat(habitat_id);
                  dispatch({
                    type: CREATE_HABITAT_ERROR,
                    errorCreateHabitat: 'Create habitat fail',
                    error,
                  });
                });
            })
            .catch((error) => {
              removeHabitat(habitat_id);
              dispatch({
                type: CREATE_HABITAT_ERROR,
                errorCreateHabitat: 'Create habitat fail',
                error,
              });
            });
        })
        .catch((error) =>
          dispatch({
            type: CREATE_HABITAT_ERROR,
            errorCreateHabitat: 'Create habitat fail',
            error,
          })
        );
    };
  };
  
  export const listConnectedS3s = (habitat) => {
    return (dispatch) => {
      dispatch({
        type: GET_ALL_CONNECTED_S3_BEGIN,
        loading_message: 'Loading S3 Buckets...',
      });
      getConnectedS3s(habitat)
        .then((response) => {
          dispatch({
            type: GET_ALL_CONNECTED_S3_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_ALL_CONNECTED_S3_ERROR, error });
        });
    };
  };
  
  export const chooseS3Bucket = (habitat, s3Name) => {
    return (dispatch) => {
      dispatch({
        type: CONNECT_HAB_S3_BEGIN,
        loading_message: 'Connecting S3 Bucket...',
      });
      connectS3s(habitat, s3Name)
        .then((response) => {
          dispatch({
            type: CONNECT_HAB_S3_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: CONNECT_HAB_S3_ERROR,
            errorChooseS3: 'Connect S3 Bucket fail',
            error,
          });
        });
    };
  };
  
  export const disconnectS3Bucket = (habitat, s3Name) => {
    return (dispatch) => {
      dispatch({
        type: DISCONNECT_HAB_S3_BEGIN,
        loading_message: 'Disconnecting S3 Bucket...',
      });
      disconnectS3s(habitat, s3Name)
        .then(() => {
          dispatch({
            type: DISCONNECT_HAB_S3_SUCCESS,
            payload: s3Name,
          });
        })
        .catch((error) => {
          dispatch({
            type: DISCONNECT_HAB_S3_ERROR,
            errorDisconnectS3: 'Disconnect S3 fail',
            error,
          });
        });
    };
  };
  
  export const selectCreds = (habitat, credsId) => {
    return (dispatch) => {
      dispatch({
        type: SELECT_CREDS_HAB_BEGIN,
        loading_message: 'Selecting creds...',
      });
      attachCredHabitat(habitat.id, credsId)
        .then((response) => {
          dispatch({
            type: SELECT_CREDS_HAB_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({ type: SELECT_CREDS_HAB_ERROR, error });
        });
    };
  };
  
  export const listHabitats = (team) => {
    return (dispatch) => {
      dispatch({
        type: GET_ALL_HABITATS_BEGIN,
        loading_message: 'Loading habitats...',
      });
      getAllHabitats(team.id)
        .then((response) => {
          dispatch({
            type: GET_ALL_HABITATS_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_ALL_HABITATS_ERROR, error });
        });
    };
  };
  
  export const selectHabitat = (habitat) => {
    return (dispatch) => {
      dispatch({
        type: SELECT_HABITAT_BEGIN,
        loading_message: 'Selecting habitat...',
      });
      try {
        const payload = setHabitatLocal(habitat);
        dispatch({
          type: SELECT_HABITAT_SUCCESS,
          payload,
        });
      } catch (error) {
        dispatch({ type: SELECT_HABITAT_ERROR, error });
      }
    };
  };
  
  export const requestDeleteHabitat = (id) => {
    const resourcesArray = [];
    return (dispatch) => {
      dispatch({
        type: REQUEST_DELETE_HABITAT_BEGIN,
        loading_message: 'Requesting delete habitat...',
      });
      requestRemoveHabitat(id)
        .then((response) => {
          dispatch({
            type: REQUEST_DELETE_HABITAT_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          resourcesArray.push({
            name: 'Lambdas',
            total: error.response.data.resources.lambdas,
            icon: 'faNetworkWired',
          });
          resourcesArray.push({
            name: 'Buckets',
            total: error.response.data.resources.buckets,
            icon: 'faFill',
          });
          resourcesArray.push({
            name: 'Dynamos',
            total: error.response.data.resources.dynamos,
            icon: 'faDatabase',
          });
          resourcesArray.push({
            name: 'Code Builds',
            total: error.response.data.resources.code_builds,
            icon: 'faInfinity',
          });
          resourcesArray.push({
            name: 'Api Gateways',
            total: error.response.data.resources.api_gateways,
            icon: 'faNetworkWired',
          });
          resourcesArray.push({
            name: 'Cognitos',
            total: error.response.data.resources.cognitos,
            icon: 'faFileCode',
          });
          resourcesArray.push({
            name: 'Sites',
            total: error.response.data.resources.full_sites,
            icon: 'faLayerGroup',
          });
          dispatch({
            type: REQUEST_DELETE_HABITAT_ERROR,
            error,
            resources: resourcesArray,
          });
        });
    };
  };
  
  export const deleteHabitat = (id) => {
    return (dispatch) => {
      dispatch({
        type: DELETE_HABITAT_BEGIN,
        loading_message: 'Deleting habitat...',
      });
      removeHabitat(id)
        .then(() => {
          dispatch({
            type: DELETE_HABITAT_SUCCESS,
            payload: id,
          });
        })
        .catch((error) => {
          dispatch({
            type: DELETE_HABITAT_ERROR,
            errorDeleteHabitat: 'Delete habitat fail',
            error,
          });
        });
    };
  };
  
  export const editHabitat = (habitat, newHabitat) => {
    return (dispatch) => {
      dispatch({
        type: UPDATE_HABITAT_BEGIN,
        loading_message: 'Updating habitat...',
      });
      updateHabitat(habitat.id, newHabitat)
        .then(() => {
          getHabitat(habitat.id)
            .then((response) => {
              dispatch({
                type: UPDATE_HABITAT_SUCCESS,
                payload: response.data,
              });
            })
            .catch((error) => {
              dispatch({
                type: UPDATE_HABITAT_ERROR,
                errorRenameHabitat: 'Rename habitat fail',
                error,
              });
            });
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_HABITAT_ERROR,
            errorRenameHabitat: 'Rename habitat fail',
            error,
          });
        });
    };
  };
  
  export const fetchHabitat = () => {
    return (dispatch) => {
      dispatch({
        type: GET_HABITAT_BEGIN,
        loading_message: 'Loading habitat...',
      });
      try {
        let response = fetchHabitatLocal();
        dispatch({
          type: GET_HABITAT_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: GET_HABITAT_ERROR,
          errorShowHabitats: 'Show habitats fail',
          error,
        });
      }
    };
  };
  
  export const retrieveHabitat = (id) => {
    return (dispatch) => {
      dispatch({ type: GET_HABITAT_BEGIN });
      getHabitat(id)
        .then((response) => {
          dispatch({
            type: GET_HABITAT_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_HABITAT_ERROR, error });
        });
    };
  };
  
  export const selectSetUpFeature = (feature) => {
    return (dispatch) => {
      dispatch({ type: HABITAT_SETUP_BEGIN });
      try {
        const response = feature;
        dispatch({
          type: HABITAT_SETUP_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({ type: HABITAT_SETUP_ERROR, error });
      }
    };
  };
  