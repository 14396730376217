import React, { useState } from 'react';
import '../../../assets/styles/components/billing/modals-billing.scss';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

const PlanUserModal = (props) => {
  const [showCancel, setShowCancel] = useState(false);

  const handleCloseModal = () => {
    if (props.modalComparePlanUser === 'display-modal') {
      props.setModalComparePlanUser('display-none');
    }
  };

  const handleShowCancel = () => {
    setShowCancel(!showCancel);
  }

  const handleCancelSubscription = () => {
    setShowCancel(!showCancel);
    props.handeleCancelSubscription();
  }

  const plan1 = props.plan1;
  const priceYearly = props.priceYearly;
  const nameInterval = props.nameInterval;

  return (
    <div className={props.modalComparePlanUser + ' modal-billing-container'}>
      <div className="container-plans">
        <div className="title-toggle">
          <div className="title-plans">
            <p>
              <b>Streamline serverless development now</b>
            </p>
          </div>
          <div className="close-modal" onClick={handleCloseModal}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
        <div className="plan-cards-container">
          {!showCancel ? (
            <div
              className={`plan-card-1 ${
                props.currentUser.subscription === 'Basic' && 'select'
              }`}
            >
              <div className="title-description-plan">
                <p>Basic</p>
                <p className="mt-3">Access KOR´s basic features for an unlimited amount of time</p>
              </div>
              <div className="price-plan">
                <p>$0 USD/mo</p>
                <p>Free Forever</p>
              </div>
              <div className="plan-features">
                <p>Top Features</p>
                <div className="feature-billing-item">
                
                  <FontAwesomeIcon icon={faCheck} className="color-green" /> 
                  <p>2 habitats</p>
                </div>

                <div className="feature-billing-item">
                
                  <FontAwesomeIcon icon={faCheck} className="color-green" /> 
                  <p>3 functions</p>
                </div>

              </div>
              {props.currentUser.subscription === 'Basic' ? (
                <div className="button-up-select">
                  <p>Current Plan</p>
                </div>
              ) : (
                <div className="button-up-downgrade">
                  <p className={plan1} onClick={handleShowCancel}>Free</p>
                </div>
              )}
            </div>
          ) : (
            <div
              className={`plan-card-1 ${
                props.currentUser.subscription === 'Basic' && 'select'
              }`}
            >
              <div className="title-description-plan">
                <p>Are you sure to cancel subscription?</p>
              </div>
              <div className="plan-features text-center">
                <Button 
                  variant="outline-primary" 
                  className="m-2"
                  onClick={handleCancelSubscription}
                >Yes</Button>
                <Button 
                  variant="outline-secondary"
                  className="m-2"
                  onClick={handleShowCancel}
                >No</Button>
              </div>
            </div>
          )}

          <div
            className={`plan-card-1 ${
              props.currentUser.subscription === 'Pro' && 'select'
            }`}
          >
            <div className="title-description-plan">
              <p>Pro</p>
              <p className="mt-3">For power users that require advanced tools</p>
            </div>
            <div className="price-plan">
              <p>$9.99 USD/mo</p>
              <p>Billed annually <br></br> 12.99 USD billed monthly</p>
            </div>
            <div className="plan-features">
              <p>Top Features</p>
              <div className="feature-billing-item">
              
                <FontAwesomeIcon icon={faCheck} className="color-green" />{' '}
                <p>Unlimited habitats</p>
             
              </div>
              <div className="feature-billing-item">
                <FontAwesomeIcon icon={faCheck} className="color-green" />{' '}
                <p> Unlimited functions</p>
              </div>
            </div>
            {props.currentUser.subscription === 'Pro' ? (
              <div className="button-up-select">
                <p>Current Plan</p>
              </div>
            ) : (
              <div className="button-up-downgrade">
                <p
                  onClick={(event) =>
                    props.handleGoCheckout(props.plan5, nameInterval)
                  }
                  className={props.plan5}
                >
                  {props.currentPlan2}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanUserModal;
