import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Image, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import StaticImage from '../../assets/images/icons/browser.png';
import StrapiImage from '../../assets/images/icons/strapi.svg';
import WebApp from '../../assets/images/icons/laptop.png';
import { faQuestionCircle, faStripeS } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const CardTemplate = ({ 
  handleSelectTemplate,
  team,
  habitats,
  currentUser
}) => {

  useEffect(() => {
    handleDisabled();
  }, []);

  let disabled = false;

  const handleDisabled = () => {
    if(team.id){
      if((team.subscription === 'Project' && habitats.length >= 2) && (team.subscription === 'Startup' && habitats.length >= 8)){
        disabled = true;
      }
    }else{
      if(currentUser.subscription === 'Basic' && habitats.length >= 2 ){
        disabled = true;
      }
    }
  }

  return (
    <Row className="justify-content-center">
      <Col xs={12} md={{ span: 4, offset: 2 }} className="ml-2 mr-2">
        <Card className="card-template shadow">
          <div className="question-mark-documentation-template">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip className="red-tooltip">
                  <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                </Tooltip>
              }

            >
              <a
                href="https://getkor.freshdesk.com/support/solutions/articles/47001162889-static-site-template"
                rel="noopener noreferrer"
                className="help"
                target="_blank"
              >

                <FontAwesomeIcon icon={faQuestionCircle} />
              </a>
            </OverlayTrigger>
          </div>
          <Card.Body className="card-template-body text-center card-template-button">
            <Card.Title className="d-flex justify-content-center">
              <div className="d-flex">
                <div className="ctn-img mr-2">
                  <Image className="bg-transparent" src={StaticImage} fluid />
                </div>
                <h4 className="font-weight-bold">STATIC SITE</h4>
              </div>
            </Card.Title>
            <div className="border-line"></div>
            <Card.Text className="mt-4 mb-5">
            </Card.Text>
            <Button 
              variant="outline-primary" 
              onClick={() => handleSelectTemplate('STATIC SITE')} 
              disabled={
                team.id ? (team.subscription === 'Project' && habitats.length >= 2) || 
                (team.subscription === 'Startup' && habitats.length >= 8) 
                : (currentUser.subscription === 'Basic' && habitats.length >= 2 )}>
                Select
              </Button>
          </Card.Body>
        </Card>
      </Col>

      <Col xs={12} md={{ span: 4, offset: 2 }} className=" ml-2 mr-2">

        <Card className="card-template shadow">
          <div className="question-mark-documentation-template">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip className="red-tooltip">
                  <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                </Tooltip>
              }

            >
              <a
                href="https://getkor.freshdesk.com/support/solutions/articles/47001163208-web-application-template"
                rel="noopener noreferrer"
                className="help"
                target="_blank"
              >

                <FontAwesomeIcon icon={faQuestionCircle} />
              </a>
            </OverlayTrigger>
          </div>
          <Card.Body className="card-template-body text-center card-template-button">
            <Card.Title className="d-flex justify-content-center">
              <div className="d-flex">
                <div className="ctn-img mr-2">
                  <Image className="bg-transparent" src={WebApp} fluid />
                </div>
                <h4 className="font-weight-bold">WEB APP</h4>
              </div>
            </Card.Title>
            <div className="border-line"></div>
            <Card.Text className="mt-4 mb-5">
            </Card.Text>
            <Button 
              variant="outline-primary" 
              onClick={() => handleSelectTemplate('WEB APP')} 
              disabled={
                team.id ? (team.subscription === 'Project' && habitats.length >= 2) || 
                (team.subscription === 'Startup' && habitats.length >= 8) 
                : (currentUser.subscription === 'Basic' && habitats.length >= 2 )}>
                Select
              </Button>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={{ span: 4, offset: 2 }} className=" ml-2 mr-2 mt-5">

        <Card className="card-template shadow">
          <div className="question-mark-documentation-template">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip className="red-tooltip">
                  <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                </Tooltip>
              }

            >
              <a
                href="https://getkor.freshdesk.com/support/solutions/articles/47001163208-web-application-template"
                rel="noopener noreferrer"
                className="help"
                target="_blank"
              >

                <FontAwesomeIcon icon={faQuestionCircle} />
              </a>
            </OverlayTrigger>
          </div>
          <Card.Body className="card-template-body text-center card-template-button">
            <Card.Title className="d-flex justify-content-center">
              <div className="d-flex">
                <div className="ctn-img mr-2">
                  <Image className="bg-transparent" src={StrapiImage} fluid />
                </div>
                <h4 className="font-weight-bold">STRAPI</h4>
              </div>
            </Card.Title>
            <div className="border-line"></div>
            <Card.Text className="mt-4 mb-5">
            </Card.Text>
            <Button 
              variant="outline-primary" 
              onClick={() => handleSelectTemplate('STRAPI')} 
              disabled={
                team.id ? (team.subscription === 'Project' && habitats.length >= 2) || 
                (team.subscription === 'Startup' && habitats.length >= 8) 
                : (currentUser.subscription === 'Basic' && habitats.length >= 2 )}>
                Select
              </Button>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default CardTemplate;