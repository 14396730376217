import React, { useState, useEffect } from 'react';
import {useStripe, useElements, CardNumberElement} from '@stripe/react-stripe-js';
import '../../assets/styles/components/billing/billing-checkout.scss';
import ToastPayment from '../toast/ToastPayment';
import { Card, Form, Row, Col } from 'react-bootstrap';
import CardSection from './CardSection';
import SaveCard from './SaveCard';
import { withRouter } from 'react-router-dom';
import routes from '../../constants/routes';

const CheckoutForm = withRouter(({
  card,
  user,
  error,
  history,
  payment,
  message,
  loading,
  nameplan,
  interval,
  checkout,
  getCustomer,
  stripeCustomer,
  paymentConfirm,
  AddSubscription,
  addCheckoutCard,
  intervalCheckout
}) => {
  const [name, setName] = useState('');
  const [show, setShow] = useState(false);
  const [address, setAddress] = useState('');
  const [newPaid, setNewPaid] = useState(false);

  const stripe     = useStripe();
  const elements   = useElements();


  const handleClear = () => {
    setShow(false);
    history.push(routes.BILLING)
  };

  const handlePaidNewCard = () => {
    const stripeCard = elements.getElement(CardNumberElement);
    addCheckoutCard(
      stripe, 
      stripeCard,
      checkout.team,
      name,
      address,
      checkout.product.quantity,
      checkout.product.nameplan,
      intervalCheckout
    )
    setShow(true);
  }

  const handlePaid = () => {
    AddSubscription(
      checkout.product.nameplan, 
      intervalCheckout,
      checkout.product.quantity, 
      checkout.team
    )
    setShow(true);
  }

  return (
    <div className="container">
      <Card>
        <Card.Body>
          <div className="h-75 d-inline-block w-100 mb-3">
            <ToastPayment
              show={show}
              error={error}
              payment={message}
              loading={loading}
              handleClear={handleClear}
            />
          </div>
          <Row>
            <Col md={2}>
              <Form.Control
                className="custom-check"
                checked={newPaid}
                onChange={() => setNewPaid(!newPaid)}
                type="radio"
                size="sm"
              />
            </Col>
            <Col md={10}>
              <p className="p-format"><b>New payment method</b></p>
            </Col>
            <Col md={12} className="mb-5">
              <CardSection setName={setName} setAddress={setAddress} />
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <Form.Control
                className="custom-check"
                checked={!newPaid}
                onChange={() => setNewPaid(!newPaid)}
                type="radio"
                size="sm"
              />
            </Col>
            <Col md={10}>
            <p className="p-format"><b>Current payment method</b></p>
            </Col>
            <Col md={12} className="mb-4">
              {card && (
                <SaveCard 
                  card={card}
                  disableEdit={true}
                />
              )}
            </Col>
          </Row>
        </Card.Body>
        <div className="d-flex justify-content-center mb-5">
          {!newPaid ? (
            <button className="mt-5" disabled={!stripe} onClick={handlePaid}>
              Pay Now
            </button>
          ):(
            <button className="mt-5" disabled={!stripe} onClick={handlePaidNewCard}>
              Pay Now
            </button>
          )}
        </div>
      </Card>
    </div>
  );
});

export default CheckoutForm;
