import produce from 'immer';

import {
  GET_CREDENTIALS_GIT_BEGIN,
  GET_CREDENTIALS_GIT_SUCCESS,
  GET_CREDENTIALS_GIT_ERROR,
  CREATE_CREDENTIALS_GIT_BEGIN,
  CREATE_CREDENTIALS_GIT_SUCCESS,
  CREATE_CREDENTIALS_GIT_ERROR,
  UPDATE_CREDENTIAL_GIT_BEGIN,
  UPDATE_CREDENTIAL_GIT_SUCCESS,
  UPDATE_CREDENTIAL_GIT_ERROR,
  GET_CREDENTIAL_GIT_BEGIN,
  GET_CREDENTIAL_GIT_SUCCESS,
  GET_CREDENTIAL_GIT_ERROR,
  DELETE_CREDENTIAL_GIT_BEGIN,
  DELETE_CREDENTIAL_GIT_ERROR,
  DELETE_CREDENTIAL_GIT_SUCCESS,
  ADD_GITHUB_OAUTH_BEGIN,
  ADD_GITHUB_OAUTH_SUCCESS,
  ADD_GITHUB_OAUTH_ERROR,
  ADD_GITLAB_OAUTH_BEGIN,
  ADD_GITLAB_OAUTH_SUCCESS,
  ADD_GITLAB_OAUTH_ERROR
} from '../actions/types';

const initialState = {
  oauth: null,
  gitCredential: null,
  gitCredentials: [],
  loading: false,
  error: null
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case CREATE_CREDENTIALS_GIT_BEGIN:
    case UPDATE_CREDENTIAL_GIT_BEGIN:
    case GET_CREDENTIAL_GIT_BEGIN:
    case GET_CREDENTIALS_GIT_BEGIN:
    case ADD_GITHUB_OAUTH_BEGIN:
    case DELETE_CREDENTIAL_GIT_BEGIN:
    case ADD_GITLAB_OAUTH_BEGIN:
      draft.loading = true;
      draft.error = null;
      return;
    case GET_CREDENTIALS_GIT_SUCCESS:
      draft.loading = false;
      draft.gitCredentials = action.payload;
      draft.error = null;
      return;
    case GET_CREDENTIAL_GIT_SUCCESS:
    case UPDATE_CREDENTIAL_GIT_SUCCESS:
    case CREATE_CREDENTIALS_GIT_SUCCESS:
      draft.loading = false;
      draft.gitCredentials = action.payload;
      draft.error = null;
      return;
    case DELETE_CREDENTIAL_GIT_SUCCESS:
      draft.loading = false;
      draft.gitCredentials = action.payload;
      draft.error = null;
      return;
    case ADD_GITHUB_OAUTH_SUCCESS:
    case ADD_GITLAB_OAUTH_SUCCESS:
      draft.loading = false;
      draft.oauth = 'successs';
      draft.error = null;
      return;
    case ADD_GITHUB_OAUTH_ERROR:
    case ADD_GITLAB_OAUTH_ERROR:
    case GET_CREDENTIAL_GIT_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return;
    case CREATE_CREDENTIALS_GIT_ERROR:
      draft.loading = false;
      draft.error = action.errorNewGitCred;
      return;
    case GET_CREDENTIALS_GIT_ERROR:
      draft.loading = false;
      draft.error = action.errorListCreds;
      return;
    case UPDATE_CREDENTIAL_GIT_ERROR:
      draft.loading = false;
      draft.error = action.errorEditCred;
      return;
    case DELETE_CREDENTIAL_GIT_ERROR:
      draft.loading = false;
      draft.error = action.errorRemoveGit;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;