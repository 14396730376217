import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

export const getAllStepFunc = (habitat_id) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  let request_url = `${URL}/aws/aws_step_function/?habitat_id=${habitat_id}`;

  return axios.get(request_url, { headers: authToken });
}

export const createStepFunc = (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  let request_url = `${URL}/aws/aws_step_function/`;

  return axios.post(request_url, data, { headers: authToken });
}

export const updateStepFunc = (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  let request_url = `${URL}/aws/aws_step_function/`;

  return axios.put(request_url, data, { headers: authToken });
}

export const deleteStepFunc = (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  let request_url = `${URL}/aws/aws_step_function/`;

  return axios.delete(request_url, data, { headers: authToken });
}