import React from 'react';
import Chart from 'react-apexcharts';
import { Row, Col } from 'react-bootstrap';

const HabitatAPIChart = ({
  apiMetrics
}) => {
  
  return (
    <Row>
      <Col xs={12} md={6}>
        <div className="mt-5 p-3">
          {apiMetrics.Count && (
            <Chart
              options={apiMetrics.Count.options}
              series={apiMetrics.Count.series}
              type="line"
              width="100%"
            />
          )}
        </div>
      </Col>
      <Col xs={12} md={6}>
        <div className="mt-5 p-3">
          {apiMetrics.Latency && (
            <Chart
              options={apiMetrics.Latency.options}
              series={apiMetrics.Latency.series}
              type="line"
              width="100%"
            />
          )}
        </div>
      </Col>
      <Col xs={12} md={6}>
        <div className="mt-5 p-3">
          {apiMetrics.IntegrationLatency && (
            <Chart
              options={apiMetrics.IntegrationLatency.options}
              series={apiMetrics.IntegrationLatency.series}
              type="line"
              width="100%"
            />
          )}
        </div>
      </Col>
      <Col xs={12} md={6}>
        <div className="mt-5 p-3">
          {apiMetrics.Errors && (
            <Chart
              options={apiMetrics.Errors.options}
              series={apiMetrics.Errors.series}
              type="pie"
              width="100%"
            />
          )}
        </div>
      </Col>
    </Row>
  );
}

export default HabitatAPIChart;