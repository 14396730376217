import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

/*********************************
 * Oauth GitHub redirect link
 *********************************/
export const githubOauthRedirect = (name, service) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/oauth_redirect?name=${name}&service=${service}`, { headers: authToken });
}

/*********************************
 * Index all Oauths 
 *********************************/
export const getOauths = () => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/authorization`, { headers: authToken });
} 