import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

/*********************************
 * Oauth Gitlab redirect link
 *********************************/
export const gitlabRedirect = (name, service) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/oauth_redirect?name=${name}&service=${service}`, { headers: authToken });
}