import {
  SET_AWS_DYNAMO_BEGIN,
  SET_AWS_DYNAMO_SUCCESS,
  SET_AWS_DYNAMO_ERROR,
  GET_AWS_DYNAMO_BEGIN,
  GET_AWS_DYNAMO_SUCCESS,
  GET_AWS_DYNAMO_ERROR,
  REMOVE_AWS_DYNAMO_BEGIN,
  REMOVE_AWS_DYNAMO_SUCCESS,
  REMOVE_AWS_DYNAMO_ERROR,
} from './types';

import { getDB, createDB, removeDB } from '../api/aws/sdk';

export const getDynamos = (habitatId) => {
  return (dispatch) => {
    dispatch({
      type: GET_AWS_DYNAMO_BEGIN,
      loading_message: 'Loading Dynamo DB...',
    });
    getDB(habitatId)
      .then((response) => {
        dispatch({
          type: GET_AWS_DYNAMO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_AWS_DYNAMO_ERROR,
          errorGetDynamo: 'Get Dynamos fail',
          error,
        })
      );
  };
};

export const createDynamo = (habitat_id, table_name, info) => {
  return (dispatch) => {
    dispatch({
      type: SET_AWS_DYNAMO_BEGIN,
      loading_message: 'Creating Dynamo DB...',
    });
    const attributes = [];
    const keys = [];

    info.forEach((info) => {
      attributes.push({
        attribute_name: info.attribute_name,
        attribute_type: info.attribute_type,
      });
      keys.push({
        attribute_name: info.attribute_name,
        key_type: info.key_type,
      });
    });

    const schema = {
      attribute_definitions: attributes,
      key_schema: keys,
      provisioned_throughput: {
        read_capacity_units: 5,
        write_capacity_units: 5,
      },
      table_name: table_name,
    };

    const definition = JSON.stringify(schema)
      .replace(/\\n/g, '\\n')
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, '\\&')
      .replace(/\\r/g, '\\r')
      .replace(/\\t/g, '\\t')
      .replace(/\\b/g, '\\b')
      .replace(/\\f/g, '\\f');

    const data = {
      habitat_id,
      table_name,
      definition,
    };

    createDB(data)
      .then((response) => {
        dispatch({
          type: SET_AWS_DYNAMO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: SET_AWS_DYNAMO_ERROR,
          errorCreateDynamo: 'Create Dynamo fail',
          error,
        })
      );
  };
};

export const removeDynamo = (id) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_AWS_DYNAMO_BEGIN,
      loading_message: 'Deleting Dynamo DB...',
    });
    removeDB(id)
      .then((response) => {
        dispatch({
          type: REMOVE_AWS_DYNAMO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: REMOVE_AWS_DYNAMO_ERROR,
          errorRemoveDynamo: 'Remove Dynamo fail',
          error,
        })
      );
  };
};
