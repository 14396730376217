import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

const HabitatDeployEnvVars = ({ fields, setFields }) => {
  const handleNewField = () => {
    setFields(currenField => [...currenField, {
      key: '',
      value: '',
    }]);
  }

  const handleAddKey = (event, index) => {
    const key = event.target.value;
    const field = [...fields];
    field[index].key = key;
  }

  const handleAddValue = (event, index) => {
    const value = event.target.value;
    const field = [...fields];
    field[index].value = value;
  }

  return (
    <>
      <p className="dynamo-management-title mt-3">Enviroments Variables</p>
      {fields.map((field, index) => (
        <Row key={index}>
          <Col xs={12} md={6}>
            <Form.Control
              type="text"
              custom
              placeholder="Key"
              onChange={e => handleAddKey(e, index)}
            />
          </Col>
          
          <Col xs={12} md={6}>
            <Form.Control
              type="text"
              custom
              placeholder="Value"
              onChange={e => handleAddValue(e, index)}
            />
          </Col>
        </Row>
      ))}
      <Button 
        variant="primary"
        className="ml-3" 
        onClick={handleNewField}
      >+ Add Field</Button>
    </>
  );
}

export default HabitatDeployEnvVars;