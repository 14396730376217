import produce from 'immer';

import {
  GET_INVITATIONS_BEGIN,
  GET_INVITATIONS_SUCCESS,
  GET_INVITATIONS_ERROR,
  ACCEPT_INVITATION_BEGIN,
  ACCEPT_INVITATION_SUCCESS,
  ACCEPT_INVITATION_ERROR,
  REJECT_INVITATION_BEGIN,
  REJECT_INVITATION_SUCCESS,
  REJECT_INVITATION_ERROR
} from '../actions/types';

const initialState = {
  invitations: [],  
  loading: false,
  error: null
};

function remove(array, key, value) {
  const index = array.findIndex(obj => obj[key] === value);
  return index >= 0 ? [
      ...array.slice(0, index),
      ...array.slice(index + 1)
  ] : array;
}

const reducer = produce((draft, action) => {
  switch (action.type) {    
    case REJECT_INVITATION_BEGIN:
    case ACCEPT_INVITATION_BEGIN:
    case GET_INVITATIONS_BEGIN:
      draft.loading = true;
      draft.error = null;
      return;
    case GET_INVITATIONS_SUCCESS:    
      draft.loading = false;
      draft.invitations = action.payload.invitations;
      return;
    case REJECT_INVITATION_SUCCESS:
    case ACCEPT_INVITATION_SUCCESS:
      draft.loading = false;
      draft.invitations = remove(draft.invitations, 'invite_id', action.payload)
      return;
    case REJECT_INVITATION_ERROR:
    case ACCEPT_INVITATION_ERROR:
    case GET_INVITATIONS_ERROR:    
      draft.loading = false;
      draft.error = action.error;
      draft.teams = null;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;
