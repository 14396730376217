import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Form } from 'react-bootstrap';

const HabitatAuthManagement = (props) => {
    return (
        <div>
            <div className="title-habitat-costs title-auth">
                <p>Management</p>
            </div>

            {props.selectedUser ? (

                <div>
                    <div className="info-user-selected">
                        <p><b>Pool selected:</b> {props.selectedCognitoName}</p>
                        <p><b>User selected:</b> {props.selectedUser.username}</p>
                        <p><b>Email: </b>{props.selectedUser.attributes[2].value}</p>
                        <p><b>Created At:</b> {props.selectedUser.user_create_date}</p>
                        {props.selectedUser.enabled ? (
                            <p><b>Status:</b> <span className="text-success-1-management">  <FontAwesomeIcon icon={faCheckCircle} /> Enabled</span></p>
                        ) : (
                            <p><b>Status:</b><span className="text-danger-management">  <FontAwesomeIcon icon={faTimesCircle} /> Disabled</span></p>
                        )}
                    </div>

                    <div className="subtitle-auth">
                        Actions
                    </div>

                    <div className="user-management">
                        <Button
                            variant="outline-info"
                            size='sm'
                            className="button-reset"
                            onClick={props.handleShowReset}
                        >Reset User Password</Button>

                        {props.selectedUser.enabled ? (
                            <Button
                                variant="outline-warning"
                                size='sm'
                                onClick={props.handleShowDisableModal}
                            >Disable User</Button>
                        ) : (
                                <Button
                                    variant="outline-success"
                                    size='sm'
                                    onClick={() => props.handleEnableUser(props.selectedCognito, props.habitat.id, props.selectedUser.username)}
                                >Enable User</Button>
                            )}



                    </div>
                </div>
            ) : (
                    <div className="empty-section-auth-management">
                        <p>No user management selected yet.</p>
                    </div>
                )}

            {props.messageDisable && (
                <div className="error-display text-success">
                    <p><FontAwesomeIcon icon={faCheckCircle} /> Done: {props.messageDisable}</p>
                </div>
            )}

            {props.resetMessage && (
                <div className="error-display text-success">
                    <p><FontAwesomeIcon icon={faCheckCircle} /> Done: {props.resetMessage}</p>
                </div>
            )}


            <div className={'new-pool-manage ' + props.showResetPass}>
                <p>Reset Password</p>

                <div className="input-button-auth">
                    <div className="inputs-new-aws-credential">
                        <Form.Control
                            type="password"
                            aria-label="handler"
                            placeholder="New Password"
                            aria-describedby="handler"
                            onChange={e => props.setResetPass(e.target.value)}
                        />
                    </div>
                    <div>
                        <Button
                            variant="primary"
                            size="sm"
                            className="newCredBtn-1"
                            onClick={props.handleResetPassword}
                        >
                            Reset Password
              </Button>
                    </div>
                </div>
            </div>


            <div className={'new-pool-manage ' + props.showNewPool}>
                <p>New User Pool</p>

                <div className="input-button-auth">
                    <div className="inputs-new-aws-credential">
                        <Form.Control
                            type="text"
                            aria-label="handler"
                            placeholder="User Pool Name"
                            aria-describedby="handler"
                            onChange={e => props.setNewCognitoName(e.target.value)}
                        />
                    </div>
                    <div>
                        <Button
                            variant="primary"
                            size="sm"
                            className="newCredBtn-1"
                            onClick={props.handleNewPool}
                        >
                            + New Pool
              </Button>
                    </div>
                </div>
            </div>







        </div>
    )
}

export default HabitatAuthManagement;