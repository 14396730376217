import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Nav from '../components/header/navbar';
import '../assets/styles/layouts/AppLayout.scss';
import SideBar from '../components/sidebar/sidebar';
import getTitle from '../utils/NavTitle';
import { getServer } from '../actions/server-status.actions';
import '../assets/styles/components/MobileTablet.scss';
import telescope from '../assets/images/telescope-image.png';
import InternalServerErrorPage from '../containers/InternalServerErrorPage';
import { logout } from '../actions/user.actions';

const AppLayout = ({ children, classNavbar, getServer, error, logout, currentUser }) => {
  useEffect(() => {
    getServer();
  }, [getServer]);
  const pagetitle = getTitle(children.props.match.url);

  return (
    <>
      {error ? (
        <InternalServerErrorPage />
      ) : (
          <div className="d-none d-lg-block">
            <div className="row-style">
              <div className="side-bar">
                <SideBar />
              </div>
              <div className={'right-cont'}>
                <div className="nav-bar">
                  <Nav pageTitle={pagetitle} />
                </div>
                <div className="main-cont">{children}</div>
              </div>
            </div>
          </div>
        )}
      <div id="mobile-tablet-main" className="d-sm-block d-md-block d-lg-none d-xl-none">
        <div className="mobile-tablet-container">
          <p className="mobile-tablet-message">
            KOR is not optimized for tablet/ mobile at this current time.
            <br />
            Click below to suggest features you would like to have.
          </p>
          <a
            id="suggetion-btn"
            className="btn btn-primary"
            role="button"
            href="https://getkor.freshdesk.com/support/tickets/new"
            target="_blank"
            rel="noopener noreferrer"
          >
            Suggestions
          </a>
          <img className="telescope" src={telescope} alt="Telescope" />
          {currentUser && (
            <div className="log-out-button" onClick={() => logout()}>
              <p>Log Out</p>
            </div>
          )}

        </div>




      </div>
    </>
  )
};

const mapState = (state) => ({
  classNavbar: state.navbar.class,
  error: state.serverStatus.error,
  currentUser: state.user.user,

});

const mapDispatch = {
  getServer,
  logout
};

export default connect(mapState, mapDispatch)(AppLayout);
