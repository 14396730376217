import React, { useState } from 'react';
import { connect } from 'react-redux';
import Loading from '../Loading';
import { clearS3, getS3Info, deleteAWSS3, createS3Bucket, disconnectS3Bucket } from '../../actions/aws-s3.actions';
import { chooseS3Bucket } from '../../actions/habitat.actions';
import { Button, Table, Col, Alert, Popover, Overlay, OverlayTrigger, Form } from 'react-bootstrap';
import { faInfoCircle, faFill, faExclamationCircle, faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../assets/styles/components/AwsS3.scss';
import '../../assets/styles/components/habitats/HabitatS3.scss'
import Spinner from 'react-bootstrap/Spinner';

const HabitatS3 = ({
  allS3,
  files,
  s3Load,
  clearS3,
  habitat,
  buckets,
  s3Error,
  quantity,
  awsCreds,
  getS3Info,
  s3AwsLoad,
  s3AwsError,
  clearBucket,
  deleteAWSS3,
  errorClearS3,
  errorDeleteS3,
  allConnectedS3,
  chooseS3Bucket,
  createS3Bucket,
  disconnectS3Bucket,
  loadingMessage,
  error,
  loadingConnect,
  loadingConnectMessage,
  errorMessage
}) => {
  const [show, setShow] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [nameBucket, setNameBucket] = useState('');
  const [showS3Error, setShowS3Error] = useState(true);
  const [showS3AwsError, setShowS3AwsError] = useState(true);
  const [showConfirmDel, setShowConfirmDel] = useState(false);
  const [selectedS3Radio, setSelectedS3Radio] = useState('');
  const [s3BucketName, setS3BucketName] = useState('');

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">AWS Restrictions</Popover.Title>
      <Popover.Content>
        <li>Bucket names must be between 3 and 63 characters long.</li>
        <li>Bucket names can consist only of lowercase letters, numbers, dots (.), and hyphens (-).</li>
        <li>Bucket names must begin and end with a letter or number.</li>
        <li>Bucket names must not be formatted as an IP address.</li>
        <li>Bucket names can&apos;t begin with xn-- (for buckets created after February 2020).</li>
        <li>Bucket names must be unique within a partition. A partition is a grouping of Regions.</li>
        <li>Buckets used with Amazon S3 Transfer Acceleration can&apos;t have dots (.) in their names.</li>
      </Popover.Content>
    </Popover>
  );

  const RestrictionsTooltip = () => (
    <OverlayTrigger trigger={['hover', 'focus']} placement="left" overlay={popover}>
      <p className="restrictionsText">
        Naming restrictions &nbsp; <FontAwesomeIcon className="restrictInfo" icon={faInfoCircle} />
      </p>
    </OverlayTrigger>
  );

  const handleShow = () => {
    setShowNew(true);
    document.body.click();
  };

  const handleConnect = () => {
    chooseS3Bucket(habitat.id, selectedS3Radio);
  }

  const handleDisconnect = (s3Name) => {
    disconnectS3Bucket(habitat.id, s3Name);
  }

  const handleShowDel = (s3Name) => {
    setNameBucket(s3Name);
    setShowConfirmDel(true);
  }

  const handleShowInfo = (bucket) => {
    setS3BucketName(bucket)
    getS3Info(bucket, habitat.id);
  }

  const handleHideInfo = () => {
    setShow(false);
  }

  const handleCloseS3Error = () => {
    setShowS3Error(false);
  }
  const handleCloseS3AwsError = () => {
    setShowS3AwsError(false);
  }

  const handleNewBucket = () => {
    const region = awsCreds.region
    setShowNew(false);
    createS3Bucket(nameBucket, habitat.id, region);
  };

  const handleDelete = () => {
    setShowConfirmDel(false);
    deleteAWSS3(habitat.id, selectedS3Radio);
    setSelectedS3Radio('');
    setS3BucketName('');
  }

  const handleSelectedRadio = (e) => {
    setSelectedS3Radio(e.target.value);
  }


  return (
    <div>
      <div className="habitat-s3-management-container">
        <div className="title-setup-w-new">
          <div className="title-setup-management-funcs">
            <div className="box-feature-name-icon-s3"><FontAwesomeIcon icon={faFill} /></div>
            <h5>S3 Buckets</h5>
          </div>
        </div>

        {errorMessage && (
          <div className="error-display text-danger">
            <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {errorMessage}</p>
          </div>
        )}

        {s3AwsLoad && (
          <div className="spinner-container s3bucket-spinner">
            <Spinner animation="border" variant="primary" />
            <p>{loadingMessage}</p>
          </div>
        )}

        {loadingConnect && (
          <div className="spinner-container s3bucket-spinner">
            <Spinner animation="border" variant="primary" />
            <p>{loadingConnectMessage}</p>
          </div>
        )}

        <div className="list-s3-creds">
          <div className="management-s3-container">
            <p>Connected S3 Buckets</p>
          </div>
          <Table className="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Created at</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {buckets && (
                buckets.map((cs3) => (
                  <tr key={`${cs3.bucket}`}>
                    <td>{cs3.bucket}</td>
                    <td>{cs3.created_at}</td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>


        <div className="list-s3-creds">
          <div className="management-s3-container">
            <p>Available S3 Buckets</p>
            <div className="options-aws">
              <Button variant="outline-primary" size="sm" disabled={!selectedS3Radio} onClick={() => handleConnect()}>Connect</Button>
              <Button variant="outline-warning" size="sm" disabled={!selectedS3Radio} onClick={() => clearS3(selectedS3Radio, habitat.id)}>Clear</Button>
              <Button variant="outline-danger" size="sm" disabled={!selectedS3Radio} onClick={() => handleDelete()}>Remove</Button>
            </div>
          </div>
          <Table className="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Name</th>
                <th scope="col">Files</th>
                <th scope="col">Created at</th>
              </tr>
            </thead>
            <tbody className="tr-ctn">
              {buckets && buckets.length > 0 && (
                buckets.map((s3) => (
                  <tr
                    key={s3.bucket}
                  >
                    <td className="check-radio">
                      <div onChange={handleSelectedRadio}>
                        <fieldset>
                          <Form.Check
                            type="radio"
                            aria-label="radio 1"
                            name="s3Bucket"
                            id={s3.bucket}
                            value={s3.bucket}
                          />
                        </fieldset>
                      </div>
                    </td>
                    <td>{s3.bucket}</td>
                    <td className="files-downdrop">
                      <div onClick={() => handleShowInfo(s3.bucket)}>
                        <b>See files</b>  <FontAwesomeIcon icon={faEye} />
                      </div>

                    </td>
                    <td>{s3.created_at}</td>
                  </tr>

                ))
              )}
            </tbody>
          </Table>
        </div>

        <div className="s3-files-container">
          <p className="subtitle-s3">Bucket Files</p>
          <div className="info-bucket">
            <p>Bucket selected: {s3BucketName}</p>
            <p>Files quantity: <b> {quantity}</b></p>
          </div>

          <div className="file-inspector">

            {files.length === 0 && quantity === 0 && (
              <pre>No bucket selected yet or bucket empty.</pre>
            )}

            {files.map((file, index) => (
              <pre key={index}>{file}</pre>
            ))}
          </div>

          <p className="text-l"><b>*This files works as reference.</b></p>
        </div>

        <div className="new-s3-container">
          <p className="subtitle-s3">New S3 Bucket</p>
          <Col className="naming-restr">
            <RestrictionsTooltip />
          </Col>

          <div className="inputs-new-s3">
            <Form.Control
              aria-label="Name"
              aria-describedby="new-name"
              className="aws-creds-form"
              autoComplete="off"
              onChange={e => setNameBucket(e.target.value)}
              type="text"
              name="credsName"
              placeholder="Bucket Name"
            />
            <Button variant="primary" size="sm" className="btn-newRole" disabled={!nameBucket} onClick={handleNewBucket}>
              + New S3 Bucket
            </Button>
          </div>
        </div>
      </div>


    </div>
  );
}
const mapState = (state, props) => ({
  habitat: props.habitat,
  files: state.awsS3.files,
  quantity: state.awsS3.quantity,
  buckets: state.awsS3.s3Buckets,
  s3AwsLoad: state.awsS3.loading,
  loadingMessage: state.awsS3.loading_message,
  allS3: state.habitat.habitat_s3s,
  s3Error: state.habitat.s3s_error,
  error: state.awsS3,
  errorMessage: state.awsS3.error_message,
  s3Load: state.habitat.s3s_loading,
  clearBucket: state.awsS3.clearBucket,
  errorClearS3: state.awsS3.errorClearS3,
  awsCreds: state.awsCreds.awsCredentials,
  errorDeleteS3: state.awsS3.errorDeleteS3,
  allConnectedS3: state.habitat.habitat_s3s,
  loadingConnect: state.habitat.loading,
  loadingConnectMessage: state.habitat.loading_message
})

const mapDispatch = {
  clearS3,
  getS3Info,
  deleteAWSS3,
  chooseS3Bucket,
  createS3Bucket,
  disconnectS3Bucket,
}

export default connect(mapState, mapDispatch)(HabitatS3);