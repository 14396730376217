import React, { useState } from 'react';
import Folder from '../../assets/images/folder.svg';
import { Row, Col, Card, Form, Badge } from 'react-bootstrap';
import { faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button'
import trash from '../../assets/images/icons/trash.svg';
import pull from '../../assets/images/icons/GitPullRequest.svg';
import Moment from 'moment';

const CloneItem = ({
  clone,
  branches,
  pullClone,
  removeClone,
  cloneCheckout,
  cloneBranches,
}) => {

  const [showInput, setShowInput] = useState(false);

  const handleBranch = () => {
    setShowInput(!showInput);
    if (!showInput) {
      cloneBranches(clone);
    }
  }

  const handleSelectBranch = event => {
    if (clone.git_branch === event) {
      setShowInput(!showInput);
    } else {
      clone.git_branch = event;
      cloneCheckout(clone);
      setShowInput(!showInput);
    }
  }

  return (
    <div className="rounded-kor-repo">
          <div className="info-repo-kor">
              <div className="folder-icon-repo">
                <img src={Folder}/>
              </div>
              <div>
                <p className="title-item">{clone.project_name}</p>
                <p className="text-item"><b>LAST UPDATED</b> <span className="text-muted ml-2">{Moment(clone.updated_at).format('lll')}</span></p>
                <div className="managed-by-repo">
                  <p className="managed-by-text">Managed</p>
                  <div className="avatar-repo-author">
                    <div className="avatar-1">
                      <p>A</p>
                    </div>
                    <div className="name-author">
                      {clone.user_name === null ? (
                        <p>Unknown</p>
                      ) : (
                        <p>{clone.user_name}</p>
                      )}
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div className="branch-form-control">
            <div className="branch-container-clone">
              <p>Branch</p>
              <Form.Control
                as="select"
                defaultValue={'DEFAULT'}
                onChange={e => handleSelectBranch(e.target.value)}
                onClick={() => handleBranch()}
              >
                <option disabled value="DEFAULT">
                  Select Branch
                </option>
                {branches && branches.map((branch, index) => (
                  <option
                    key={index}
                    value={branch.name}
                  >
                    {branch.name}
                  </option>
                ))}
              </Form.Control>
            </div>
          </div>
          <div className="buttons-delete-view">
            <Button onClick={() => pullClone(clone)}>
              <img src={pull} /> Pull
          </Button>
            <Button className="delete-button-kor" onClick={() => removeClone(clone.id, clone.directory_name)}>
              <img src={trash} /> Remove Repository
          </Button>
          </div>
    </div>
  );
}

export default CloneItem;