import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Nav from '../components/header/navbar';
import SideBar from '../components/sidebar/sidebar';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import getTitle from '../utils/NavTitle';
import { getServer } from '../actions/server-status.actions';
import InternalServerErrorPage from '../containers/InternalServerErrorPage';
import '../assets/styles/components/MobileTablet.scss';
import { logout } from '../actions/user.actions';
import telescope from '../assets/images/telescope-image.png';

const SettingsLayout = ({ children, classNavbar, getServer, error, logout, currentUser }) => {
  useEffect(() => {
    getServer();
  }, [getServer]);
  const publishableKey = process.env.REACT_APP_PUBLISHABLE_KEY;
  const stripePromise = loadStripe(`${publishableKey}`);
  const pageTitle = getTitle(children.props.match.url);

  return (
    <>
     {error ? (
    <InternalServerErrorPage />
    ):(
      <div className="d-none d-lg-block">
        <div className="row-style">
          <div className="side-bar">
            <SideBar />
          </div>
          <div className={'right-cont'}>
            <div className="nav-bar">
              <Nav pageTitle={pageTitle} />
            </div>
            <div className="main-cont">
              <Elements stripe={stripePromise}>{children}</Elements>
            </div>
          </div>
        </div>
      </div>
       )}
      <div
        id="mobile-tablet-main"
        className="d-sm-block d-md-block d-lg-none d-xl-none"
      >
        <div className="mobile-tablet-container">
          <p className="mobile-tablet-message">
            KOR is not optimized for tablet/ mobile at this current time.
            <br />
            Click below to suggest features you would like to have.
          </p>
          <a
            id="suggetion-btn"
            className="btn btn-primary"
            role="button"
            href="https://getkor.freshdesk.com/support/tickets/new"
            target="_blank"
            rel="noopener noreferrer"
          >
            Suggestions
          </a>
          {currentUser && (
            <div className="log-out-button" onClick={() => logout()}>
              <p>Log Out</p>
            </div>
          )}
          <img className="telescope" src={telescope} alt="Telescope" />
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  classNavbar: state.navbar.class,
  error: state.serverStatus.error,
  currentUser: state.user.user,
});

const mapDispatch = {
  getServer,
};

export default connect(mapState, mapDispatch)(SettingsLayout);
