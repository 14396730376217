import React, { useState } from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../constants/routes';
import logo from '../assets/images/Kor-dark-logo.svg';
import SignUpMessage from '../components/SignUpMessage';
import { waitLambdaList } from '../actions/user.actions';

const SignupPage = withRouter(
  ({ error, loading, waitLambdaList, currentUser }) => {
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [show, setShow] = useState(false);

    const handleNewUser = () => {
      waitLambdaList(name, username);
      setShow(true);
    };

    if (currentUser) {
      return <Redirect to={routes.CONFIRMPEND} noThrow />;
    }

    return (
      <div className="log-big-box">
        <div className="log-container log-container-left">
          {error && (
            <div className="alert alert-warning" role="alert">
              {error.message}
            </div>
          )}
          <div className="logo">
            <img className="kor-size" src={logo} alt="logo" />
          </div>
          <div className="sign-title">
            <p className="sigtext">SIGN UP</p>
          </div>
          {show ? (
            <SignUpMessage />
          ) : (
            <div className="sign-form">
              <form onSubmit={(e) => e.preventDefault()} className="login">
                <div className="sign-instructions">
                  <p className="tto">Simplify your serverless projects.</p>
                </div>
                <div className="nothing">
                  <input
                    name="fullName"
                    type="text"
                    value={name}
                    className="sign-input spacing-top"
                    placeholder="First and Last Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="nothing">
                  <input
                    name="email"
                    type="text"
                    value={username}
                    className="sign-input spacing-top"
                    id="email"
                    placeholder="Email address"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>

                <div className="spacing-top button-signup-page">
                  <button
                    type="submit"
                    disabled={loading}
                    className="button-signup"
                    onClick={() => handleNewUser()}
                  >
                    <p className="bl"> Send</p>
                  </button>
                </div>
                <div className="create-account-sign-up spacing-top">
                  <p>Already have an account?</p>
                  <p>
                    <Link to={routes.SIGNIN} className="linked-to">
                      Sign In
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          )}
        </div>
        <div className="log-container log-container-right">
          <div className="log-image" />
        </div>
      </div>
    );
  }
);

const mapState = (state) => ({
  loading: state.user.loading,
  error: state.user.error,
  currentUser: state.user.user,
});
const mapDispatch = {
  waitLambdaList,
};
export default connect(mapState, mapDispatch)(SignupPage);
