import React, { useState } from 'react';
import { connect } from 'react-redux';
import { faNetworkWired, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form } from 'react-bootstrap';
import '../../assets/styles/components/AwsCredManage.scss';
import '../../assets/styles/components/habitats/HabitatRoles.scss';
import {
  deleteApi,
  getApiMethods,
  getApiResources
} from '../../actions/aws-apigateway.actions'
import Spinner from 'react-bootstrap/Spinner';
import HabitatAPITest from './HabitatAPITest';

const HabitatAPIGateway = ({
  error,
  methods,
  habitat,
  apiTest,
  testApi,
  loading,
  getTests,
  setApiId,
  funcTest,
  saveTest,
  deleteApi,
  funcLoading,
  apiGateways,
  selectedAPI,
  getApiMethods,
  setSelectedAPI,
  loadingMessage,
  getApiResources,
  loadingFuncMessage
}) => {

  const [ showTest, setShowTest ]     = useState(false);
  const [ apiGateway, setApiGateway ] = useState();

  const handleTestApi = (gateway) => {
    setShowTest(true);
    setApiGateway(gateway);
  }

  const handleSelectedRadio = (apiGateway) => {
    setApiId(apiGateway.api_id);
    setSelectedAPI(apiGateway.id);
    getApiMethods(apiGateway.id, habitat.id);
    getApiResources(apiGateway.id, habitat.id);
  };

  const handleDeleteAPI = () => {
    deleteApi(habitat.id, selectedAPI);
  }

  return (
    <>
      {showTest ? (
        <HabitatAPITest
          methods={methods}
          habitat={habitat}
          apiTest={apiTest}
          testApi={testApi}
          getTests={getTests}
          funcTest={funcTest}
          saveTest={saveTest}
          apiGateway={apiGateway}
          funcLoading={funcLoading}
          setShowTest={setShowTest}
          getApiMethods={getApiMethods}
          loadingFuncMessage={loadingFuncMessage}
        />
      ) : (
        <div className="habitat-funcs-management-container">

          <div className="title-setup-w-new">
            <div className="title-setup-management-funcs">
              <div className="box-feature-name-icon-api">
                <FontAwesomeIcon icon={faNetworkWired} />
              </div>
              <h5>List API Gateways</h5>
            </div>
          </div>

          {error && (
            <div className="error-display text-danger">
              <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
            </div>
          )}

          {loading && (
            <div className="spinner-container funcs-habitat-spinner">
              <Spinner animation="border" variant="primary" />
              <p>{loadingMessage}</p>
            </div>
          )}



          <div className="list-roles">
            <div className="management-roles">
              <p>API Gateway</p>
              <div className="options-aws">
                <Button
                  variant="outline-danger"
                  size="sm"
                  disabled={!selectedAPI}
                  onClick={handleDeleteAPI}
                >Remove</Button>
              </div>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">API Name</th>
                  <th scope="col">Path</th>
                  <th scope="col">Endpoint</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {apiGateways.map(apiGateway => (
                  <tr key={apiGateway.id}>
                    <td className="check-radio">
                      <div onChange={() => handleSelectedRadio(apiGateway)}>
                        <fieldset>
                          <Form.Check
                            type="radio"
                            aria-label="radio 1"
                            name="awsCred"
                          />
                        </fieldset>
                      </div>
                    </td>
                    <td>{apiGateway.name}</td>
                    <td>{apiGateway.path}</td>
                    {apiGateway.endpoint ? (
                      <td>{apiGateway.endpoint}</td>
                    ) : (
                      <td className="text-danger">You need to create a method</td>
                    )}
                    <td>
                      <Button variant="outline-primary" onClick={e => handleTestApi(apiGateway)} >Test</Button>
                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>

        </div>
      )}
    </>

  );
}
const mapState = (state, props) => ({
  error: state.apiGateway.error,
  loading: state.apiGateway.loading,
  loadingMessage: state.apiGateway.loading_message
})

const mapDispatch = {
  deleteApi,
  getApiMethods,
  getApiResources,
}

export default connect(mapState, mapDispatch)(HabitatAPIGateway);