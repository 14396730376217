import React from 'react';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HabitatDynamo from '../Habitats/HabitatDynamo';
import HabitatAuroraDB from '../Habitats/HabitatAuroraDB';
import HabitatDynamos from '../Habitats/HabitatDynamos';
import HabitatAuroras from '../Habitats/HabitatAuroras';

const HabitatDB = (props) => {
    return (
        <div className="habitatdb-container">

            <div className="feature-info-habitatdb">
                <div className="title-feature-habitatdb">
                    <div className="question-mark-documentation-habitat">
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip className="red-tooltip">
                                    <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                                </Tooltip>
                            }

                        >
                            <a
                                href="https://getkor.freshdesk.com/support/solutions/articles/47001163957-clone-a-repository"
                                rel="noopener noreferrer"
                                className="help"
                                target="_blank"
                            >

                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </a>
                        </OverlayTrigger>
                    </div>

                    {props.selectedDB === 'Dynamo' && (
                        <>
                            <p className="active-feature" onClick={() => props.handleSelectDB('Dynamo')}>Dynamo DB</p>
                            <p className="inactive-feature" onClick={() => props.handleSelectDB('Aurora')}>Aurora DB</p>
                        </>
                    )}

                    {props.selectedDB === 'Aurora' && (
                        <>
                            <p className="inactive-feature" onClick={() => props.handleSelectDB('Dynamo')}>Dynamo DB</p>
                            <p className="active-feature" onClick={() => props.handleSelectDB('Aurora')}>Aurora DB</p>
                        </>
                    )}

                    {props.selectedDB === 'RDS' && (
                        <>
                            <p className="inactive-feature" onClick={() => props.handleSelectDB('Dynamo')}>Dyanmo DB</p>
                            <p className="inactive-feature" onClick={() => props.handleSelectDB('Aurora')}>Aurora DB</p>
                        </>
                    )}


                </div>
                {props.selectedDB === 'Dynamo' && (
                    <HabitatDynamos
                        dynamos={props.dynamos}
                        handleRemoveDynamo={props.handleRemoveDynamo}
                        handleShowDyanoManagement={props.handleShowDynamoManagement}
                        showDynamoManagement={props.showDynamoManagement}
                    />
                )}

                {props.selectedDB === 'Aurora' && (
                    <HabitatAuroras
                        auroras={props.auroras}
                        handleShowAuroraManagement={props.handleShowAuroraManagement}
                        handleRemoveAurora={props.handleRemoveAurora}
                    />
                )}

            </div>

            <div className="feature-management-habitatdb">
                {props.selectedDB === 'Dynamo' && (
                    <HabitatDynamo
                        habitat={props.habitat}
                        showDynamoManagement={props.showDynamoManagement}

                    />
                )}

                {props.selectedDB === 'Aurora' && (
                    <HabitatAuroraDB
                        habitat={props.habitat}
                        showAuroraManagement={props.showAuroraManagement}
                        loading={props.loading}
                        loadingMessage={props.loadingMessage}
                        error={props.error}
                    />
                )}

            </div>

        </div>
    )
}

export default HabitatDB;