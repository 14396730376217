import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen, faFileCode, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import '../../../assets/styles/components/funcs/func-tree-file.scss';
import { faCodeBranch, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import DeleteRepositoryModal from '../../Modals/DeleteRepository'
import PopUpOptions from '../folderTree/popUp'
import 'animate.css/animate.css'

const Tree = (props) => {

  const [popDisplay, setPopDisplay] = useState('pop-up-display-none');
  const [activeClassPop, setActiveClassPop] = useState(null)

  const handleShowPopUp = (id) => {
    setActiveClassPop(id)
    if(activeClassPop === id){
      setActiveClassPop(null)
    }
  }

  const Collapsible = styled.div`
      height: ${(p) => (p.isOpen ? 'auto' : '0')};
      overflow: hidden;
    `;

  const File = ({ name, url }) => {
    return (
     <div className="styledFile">
        <div className="file--label" onClick={() => props.handleGetFile({ url })}>
          <div className="icon-name-repo file--icon">
            <div className="icon-repo">
              <FontAwesomeIcon icon={faFileCode} />
            </div>
            <span>{name}</span>
          </div>
        </div>
      </div>
    );
  };

  const Folder = ({ name, idRepo, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [animation, setAnimation] = useState('');
    const [showDeleteRepository, setShowDeleteRepository] = useState('display-none-kor')

    const handleToggle = (e) => {
      e.preventDefault();
      setIsOpen(!isOpen);
      setAnimation('SlideDown');
    };

    const handleShowDelete = () => {
      setShowDeleteRepository('')
    }

    const handleDeleteRepository = (name_repo) => {
      props.handleDeleteRepo(name_repo, idRepo)
      setActiveClassPop(null)
    }

    return (
      <div className="styledFolder">
        <div className={animation + ' folder--label'} onClick={handleToggle}>


          <div className="icon-name-repo folder--icon">
            <div className="icon-repo">
              <FontAwesomeIcon icon={faFolderOpen} />
            </div>
            <div className="icon-name-git-container">
              <span>{name}</span>
              {props.gitClones && (
                props.gitClones.map(clone => (
                  name === clone.project_name && (
                    clone.git_hash && clone.git_branch && (
                      <div key={clone.id} className="git-ctn" onClick={() => props.handleOpenGit(clone)}>
                        <p>
                          <FontAwesomeIcon icon={faCodeBranch} className="mr-1" />
                          {clone.git_branch} - {clone.git_hash.substring(0, 8)}
                        </p>
                      </div>
                    )
                  )
                ))
              )}
            </div>

          </div>

          <DeleteRepositoryModal
            showDeleteRepository={showDeleteRepository}
            setShowDeleteRepository={setShowDeleteRepository}
            handleDeleteRepository={handleDeleteRepository}
            name={name}
            setActiveClassPop={setActiveClassPop}
          />

          {props.gitClones && (
            props.gitClones.map(clone => (
              name === clone.project_name && (
                clone.git_hash && clone.git_branch && (
                  <div key={clone.id} className="menu-repository" onClick={() => handleShowPopUp(idRepo)}>
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </div>
                )
              )
            ))
          )}

          {props.gitClones && (
            props.gitClones.map(clone => (
              name === clone.project_name && (
                clone.git_hash && clone.git_branch && (
                  <PopUpOptions
                    key={clone.id}
                    popDisplay={popDisplay}
                    handleShowDelete={handleShowDelete}
                    idRepo={idRepo}
                    activeClassPop={activeClassPop}
                  />
                )
              )
            ))
          )}
        </div>
        <Collapsible isOpen={isOpen}>{children}</Collapsible>
        </div>
    );
  };

  const TreeRecursive = ({ data }) => {
    return data.map((item) => {
      if (item.icon === 'file') {
        return <File name={item.name} url={item.url} key={item.id} />;
      }
      if (item.icon === 'folder') {
        return (
          <Folder name={item.name} idRepo={item.id} key={item.id + item.name}>
            <TreeRecursive data={item.childrens} />
          </Folder>
        );
      }
    });
  };

  const Tree = ({ data, children }) => {
    const isImparative = data && !children;

    return (
      <div className="styledTree">
        {isImparative ? <TreeRecursive data={data} /> : children}
      </div>
    );
  };

  Tree.File = File;
  Tree.Folder = Folder;

  const structure = props.fileTree;

  return (
    <div className="tree-file-container">
      <Tree data={structure} />
    </div>
  )
}

export default Tree;