import React, {useState} from 'react';
import { connect } from 'react-redux';
import {      
    CardNumberElement, 
    CardExpiryElement,
    CardCVCElement,
    injectStripe
} from 'react-stripe-elements';
import { subscribeStartup, updatePlanStartup } from '../../actions/payment.actions';
//import { updatePlanStartup } from '../../actions/user.actions';

const StartupCheckoutForm = ({subscribeStartup, closeModal, stripe, updatePlanStartup, teamEmail, seats}) => {  
  const [errorMessage, setErrorMessage] = useState('');
  const handleResult = (tok_resp) => {
    subscribeStartup(tok_resp, 'monthly', teamEmail, seats)
    updatePlanStartup('starter')
    closeModal()    
  }

  const handleChange = ({error}) => {
    if (error) {
      setErrorMessage(error.message);
    }
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (stripe) {
        stripe.createToken().then((resp) => {
          handleResult(resp)
        });
    }
  }
  
  const style = {
      'height': '2.4em',
      'paddingTop': '.7em',
      'marginBottom': '1em'
  }
  const formStyle = {
      'bottomRight': '0',
      'margin': '8px 0 0 0'
  }

  return (      
      <div className="checkout">
      <form style={formStyle} onSubmit={handleSubmit.bind(this)}>      
          <div className="form-control" style={style}>          
              <CardNumberElement onChange={handleChange} placeholder='Card Number' />
          </div>
          <div className="form-control" style={style}>                       
              <CardExpiryElement onChange={handleChange} placeholder='MM/YY' />
          </div>
          <div className="form-control" style={style}>
              <CardCVCElement onChange={handleChange} placeholder='CVC'/>
          </div>                      
          <div className="error" role="alert">
              {errorMessage}
          </div>
          <button className="btn btn-primary btn-pay btn-block">Pay</button>             
      </form>
    </div>
  );
}

const mapState = (state, props) => ({
  stripe: props.stripe,
  closeModal: props.closeModal,
  currentUser: state.user.user,  
  payment: state.payment.proPayment,
  loading: state.payment.loading,
  teamEmail: state.team.team.email,
  seats: props.seats
});

const mapDispatch = { 
  subscribeStartup,
  updatePlanStartup
};

export default connect(mapState, mapDispatch)(injectStripe(StartupCheckoutForm));