import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLayerGroup
} from '@fortawesome/free-solid-svg-icons';

const HabitatTemplates = (props) => {

    return (
        <div>
        {props.habitatSetUp === 'templates' ? (
            <div className={props.habitatSetUp + '-selected box-feature'} onClick={() => props.handleSelectFeature('templates')}>
            <div className="box-feature-name">
                <div className="box-feature-name-icon-templates"><FontAwesomeIcon icon={faLayerGroup} /></div>
                <p>Static Sites</p>
            </div>

            <div className="box-feature-plus-1">
                <p>No. Sites</p>
                <p>{props.templates.length}</p>
            </div>

            <div className="box-feature-plus">
                <p>Last Update</p>
                <p>2020-08-14T16:41:59.400Z</p>
            </div>
        </div>
        ):(
            <div className="box-feature" onClick={() => props.handleSelectFeature('templates')}>
            <div className="box-feature-name">
                <div className="box-feature-name-icon-templates"><FontAwesomeIcon icon={faLayerGroup} /></div>
                <p>Static Sites</p>
            </div>

            <div className="box-feature-plus-1">
                <p>No. Sites</p>
                <p>{props.templates.length}</p>
            </div>

            <div className="box-feature-plus">
                <p>Last Update</p>
                <p>2020-08-14T16:41:59.400Z</p>
            </div>
        </div> 
        )}

        </div>
    )
}

export default HabitatTemplates;