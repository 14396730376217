import React from 'react';
import ReactDOM from 'react-dom';
import {
  createStore,
  applyMiddleware,
  compose
} from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { saveAuthToken, catchUnauth } from './middleware';
import { loadLastUser, validateSession } from './actions/user.actions';
import reducer from './reducers';
import App from './App';
import './assets/styles/index.scss';
import * as serviceWorker from './serviceWorker';



const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk, saveAuthToken, catchUnauth)
);

const store = createStore(reducer, enhancer);
store.dispatch(loadLastUser());
store.dispatch(validateSession());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector('#root')
);

serviceWorker.unregister();
