import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

export const fetchFuncs = (habitat_id) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/aws/aws_lambdas?habitat_id=${habitat_id}`, { headers: authToken });
}

export const setFunc = (func) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/aws/aws_lambdas`, func, { headers: authToken });
}

export const updateFunc = (func, funcId) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.put(`${URL}/aws/aws_lambdas/${funcId}`, func, { headers: authToken });
}

export const removeFunc = (funcId, habitat_id) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.delete(`${URL}/aws/aws_lambdas/${funcId}?habitat_id=${habitat_id}`, { headers: authToken });
}

export const invokeFunc = (id, function_name, habitat_id, invocation_type, payload) => {
  const data = {
    id,
    function_name,
    habitat_id,
    invocation_type,
    payload
  }

  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/aws/invoke`, data, { headers: authToken });  
}

export const deployFunc = (id, env_variables) => {
  const data = {
    id,
    env_variables
  }
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/aws/deploy`, data, { headers: authToken });  
}

export const deploymentHistory = (habitat_id, initial_date, final_date, page_num) => {
  const data = {
    habitat_id,
    initial_date,
    final_date,
    page_num
  }
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/aws/deploy_history`, data, { headers: authToken })
}

export const updateCode = (func) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/aws/update_code`, func, { headers: authToken });  
}

export const lambdaStatus = (data) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/aws/lambda_status`, data, { headers: authToken });  
}

export const updateHashLambda = (data) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.put(`${URL}/aws/git_hash_lambda_update`, data, { headers: authToken });  
}

export const storeTest = (data) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/aws/store_test`, data, { headers: authToken }); 
}

export const indexTest = (id) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/aws/index_tests/${id}`, { headers: authToken });
}