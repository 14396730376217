import produce from 'immer';

import {
  CREATE_S3_BEGIN,
  CREATE_S3_SUCCESS,
  CREATE_S3_ERROR,
  DELETE_S3_BEGIN,
  DELETE_S3_SUCCESS,
  DELETE_S3_ERROR,
  GET_ALL_S3_BEGIN,
  GET_ALL_S3_SUCCESS,
  GET_ALL_S3_ERROR,
  CLEAR_S3_BEGIN,
  CLEAR_S3_SUCCESS,
  CLEAR_S3_ERROR,
  GET_S3_FILES_BEGIN,
  GET_S3_FILES_SUCCESS,
  GET_S3_FILES_ERROR,
  DISCONNECT_HAB_S3_BEGIN,
  DISCONNECT_HAB_S3_SUCCESS,
  DISCONNECT_HAB_S3_ERROR
} from '../actions/types';

const initialState = {
  s3Buckets: [],
  quantity: 0,
  files: [],
  loading: false,
  loading_message: '',
  loadingInfo: false,
  error: null,
  error_message: '',
};

function remove(array, key, value) {
  const index = array.findIndex(obj => obj[key] === value);
  return index >= 0 ? [
    ...array.slice(0, index),
    ...array.slice(index + 1)
  ] : array;
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case CLEAR_S3_BEGIN:
    case DELETE_S3_BEGIN:
    case CREATE_S3_BEGIN:
    case GET_ALL_S3_BEGIN:
    case DISCONNECT_HAB_S3_BEGIN:
      draft.loading = true;
      draft.error = null;
      draft.loading_message = action.loading_message; 
      draft.errorS3Info = null;
      draft.error_message = '';
      return;
    case GET_S3_FILES_BEGIN:
      draft.loading = true;
      draft.loadingInfo = true;
      draft.errorS3Info = null;
      draft.error = null;
      draft.quantity = 0;
      draft.files = [];
      draft.loading_message = action.loading_message;
      draft.error_message = ''; 
      return;
    case CLEAR_S3_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.clearBucket = action.payload;
      draft.errorS3Info = null;
      draft.error_message = ''; 
      return;
    case CREATE_S3_SUCCESS:
      draft.loading = false;
      draft.s3Buckets = action.payload;
      draft.error = null;
      draft.errorS3Info = null;
      draft.error_message = ''; 
      return;
    case DELETE_S3_SUCCESS:
    case DISCONNECT_HAB_S3_SUCCESS:
      draft.loading = false;
      draft.s3Buckets = action.payload;
      draft.files = [];
      draft.quantity = 0;
      draft.error = null;
      draft.errorS3Info = null;
      draft.error_message = ''; 
      return;
    case GET_ALL_S3_SUCCESS:
      draft.loading = false;
      draft.s3Buckets = action.payload;
      draft.error = null;
      draft.errorS3Info = null;
      draft.error_message = ''; 
      return;
    case GET_S3_FILES_SUCCESS:
      draft.loadingInfo = false;
      draft.loading = false;
      draft.errorS3Info = null;
      draft.error = null;
      draft.quantity = action.payload.quantity;
      draft.files = action.payload.files;
      draft.error_message = ''; 
      return;
    case DELETE_S3_ERROR:
      draft.loading = false;
      draft.error_message = action.error
      draft.error = true;
      return;
    case CREATE_S3_ERROR:
      draft.loading = false;
      draft.error_message = action.error_message;
      draft.error = true;
      return;
    case GET_ALL_S3_ERROR:
      draft.loading = false;
      draft.error_message = action.error_message
      draft.error = true;
      return;
    case CLEAR_S3_ERROR:
    case DISCONNECT_HAB_S3_ERROR:
      draft.loading = false;
      draft.error_message = action.error_message
      draft.error = true;
      return;
    case GET_S3_FILES_ERROR:
      draft.loadingInfo = false;
      draft.loading = false;
      draft.quantity = 0;
      draft.files = [];
      draft.error = action.error;
      draft.error_message = action.error_message;
      draft.error = true;
      return;
    default:
  }
}, initialState);

export default reducer;
