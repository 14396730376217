import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import routes from '../../constants/routes';
import { Link } from 'react-router-dom';

const LaunchTemplate = ({
  team,
  name,
  deploy,
  prefix,
  habitat,
  loading,
  repoURI,
  gitCred,
  awsCreds,
  domainName,
  setRepoURI,
  errorTemplates,
  endpointStatic,
  siteWithoutDomain
}) => {
  useEffect(() => {
    if (!repoURI) {
      setRepoURI('https://gitlab.com/kor-comunity/kor-static-template.git');
    }
  }, []);

  return (
    <>
      {loading ? (
        <Row className="p-5">
          <Col xs={12}>
            <span className="text-muted mb-5">
              We are connecting all of the components needed to create your web application.
            </span>
          </Col>
          <Col xs={12}>
            <div className="d-flex justify-content-center mt-5 mb-5">
              <div className="loader"></div>
            </div>
          </Col>
          <Col xs={12} className="mt-5">
            <span className="text-muted mt-5">
              We are completing the infrastructure creation, this could take up to 10 minutes.
            </span>
          </Col>
        </Row>
      ) : (
        deploy ? (
          (errorTemplates ? (
            <Row className="p-5">
              <Col xs={12} className="text-center mt-5 mb-5">
                <h4 className="text-danger"><b>Something went wrong!</b></h4>
                <span className="text-muted">Details: {errorTemplates}</span> <br />
                <Link to={routes.TEMPLATES} className="link-to-templates mt-5">
                  <p className="mt-5">Click here to try again.</p>
                </Link>
              </Col>
            </Row>
          ) : (
            <Row className="p-5">
              <Col xs={12} className="text-center mt-5 mb-5">
                <span className="text-muted">
                  Please wait a few moments while your site is being generated, DNS is propagated and your content is distributed globally.
              </span>
              </Col>

              {siteWithoutDomain === false ? (
                <Col xs={12} className="text-center mb-5">
                  <a className="deploy-link" target="_blank" rel="noopener noreferrer" href={prefix ? `https://${prefix}.${domainName}` : `https://${domainName}`}>
                    {prefix ? (
                      `https://${prefix}.${domainName}`
                    ) : (
                      `https://${domainName}`
                    )}
                  </a>
                </Col>
              ) : (
                <Col xs={12} className="text-center mb-5">
                  <a className="deploy-link" target="_blank" rel="noopener noreferrer" href={`http://${endpointStatic}`}>
                    http://{endpointStatic}
                  </a>
                </Col>
              )}

              <Col xs={12} className="text-center mb-5">
                <Link to={`${routes.HABITAT}/${habitat.id}`} className="link-to-templates">
                  <p>Click here to to go to the habitat.</p>
                </Link>
              </Col>
            </Row>
          ))
        ) : (
          <Row className="p-5">
            <Col xs={12}>
              <span className="text-muted">
                Verify your information before launching the static site.
              </span>
            </Col>
            <Col xs={12}>
              <h5 className="font-weight-bold mt-5 mb-3">Application name & Domain</h5>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={12} md={3}>Name</Col>
                <Col xs={12} md={9}>{name}</Col>
                <Col xs={12} md={3}>Domain</Col>
                <Col xs={12} md={9}>
                  {prefix ? `${prefix}.${domainName}` : domainName}
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <h5 className="font-weight-bold mt-5 mb-3">Repository</h5>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={12} md={3}>Link</Col>
                <Col xs={12} md={9}>{repoURI}</Col>
              </Row>
            </Col>
            <Col xs={12}>
              <h5 className="font-weight-bold mt-5 mb-3">Aws Credentials</h5>
            </Col>
            <Col xs={6}>
              {awsCreds && (
                <Row>
                  <Col xs={12} md={5}>Profile</Col>
                  <Col xs={12} md={7}>
                    {awsCreds.name}
                  </Col>
                  <Col xs={12} md={5}>Region</Col>
                  <Col xs={12} md={7}>{awsCreds.region}</Col>
                </Row>
              )}
            </Col>
            <Col xs={6}>
              <Row>
                <Col xs={12} md={5}>Verify</Col>
                <Col xs={12} md={7}>
                  {awsCreds && (
                    awsCreds.verified ? (
                      <FontAwesomeIcon
                        className="greenLight"
                        icon={faCheckCircle}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="redLight"
                        icon={faTimesCircle}
                      />
                    )
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="mt-3">
              <span className="text-muted">
                Before we create the web application please verify that all of the information is correct. If you have to make changes click back to the previous steps.
                  </span>
            </Col>
          </Row>

        )
      )}
    </>
  );
}

export default LaunchTemplate;