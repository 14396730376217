import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import AddDynamoFields from './AddDynamoFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { createDynamo, removeDynamo } from '../../actions/aws-dynamo.actions';

const HabitatDynamo = ({
  habitat,
  dynamos,
  loading,
  loadingMessage,
  createDynamo,
  removeDynamo,
  showDynamoManagement,
  error
}) => {
  const [showForm, setShowForm] = useState(true);
  const [tableName, setTableName] = useState('');
  const [hash, setHash] = useState(false);
  const [schema, setSchema] = useState(false);
  const [schemaCount, setSchemaCount] = useState(0);
  const [fields, setFields] = useState([
    {
      attribute_name: '',
      attribute_type: '',
      key_type: ''
    }
  ]);

  const handleNewField = () => {
    setFields(currenField => [...currenField, {
      attribute_name: '',
      attribute_type: '',
      key_type: ''
    }]);
  }

  const handleAddAttribute = (event, index) => {
    const attribute_name = event.target.value;
    const field = [...fields];
    field[index].attribute_name = attribute_name;
  }

  const handleAddType = (event, index) => {
    const attribute_type = event.target.value;
    const field = [...fields];
    field[index].attribute_type = attribute_type;
  }

  const handleAddKey = (event, index) => {
    const key_type = event.target.value;
    const field = [...fields];
    setSchemaCount(schemaCount + 1);
    field[index].key_type = key_type;
    if (schemaCount < 1) {
      if (key_type === 'HASH') {
        setHash(true);
      }
    } else {
      setSchema(true);
    }

  }

  const handleToggleForm = () => {
    setShowForm(!showForm);
  }

  const handleRemove = (db) => {
    removeDynamo(db.id);
  }

  const handleCreateDB = () => {
    createDynamo(habitat.id, tableName, fields);
    setTableName('');
    setShowForm(false);
    setFields([
      {
        attribute_name: '',
        attribute_type: '',
        key_type: ''
      }
    ]);
    setHash(false);
    setSchema(false);
    setSchemaCount(0);
  }

  return (
    <>
      {showDynamoManagement === 'display-none' ? (
        <div className="blank-section">No action selected.</div>
      ):(
      <div className={showDynamoManagement + ' habitat-funcs-management-container'}>
        <div className="title-setup-w-new">
          <div className="title-setup-management-funcs">
            <div className="box-feature-name-icon-funcs">
              <FontAwesomeIcon icon={faDatabase} />
            </div>
            <h5>Dynamo DB</h5>
          </div>
        </div>

        {loading && (
          <div className="spinner-container funcs-habitat-spinner">
            <Spinner animation="border" variant="primary" />
            <p>{loadingMessage}</p>
          </div>
        )}


        {error && (
          <div className="error-display text-danger">
            <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
          </div>
        )}


        {showForm && (
          <AddDynamoFields
            hash={hash}
            schema={schema}
            fields={fields}
            tableName={tableName}
            setTableName={setTableName}
            handleAddKey={handleAddKey}
            handleAddType={handleAddType}
            handleNewField={handleNewField}
            handleCreateDB={handleCreateDB}
            handleAddAttribute={handleAddAttribute}
          />
        )}

      </div>
      )}

    </>
  );
}

const mapState = (state, props) => ({
  habitat: props.habitat,
  loading: state.awsDynamo.loading,
  dynamos: state.awsDynamo.dynamos,
  error: state.awsDynamo.error,
  loadingMessage: state.awsDynamo.loading_message
});

const mapDispatch = {
  createDynamo,
  removeDynamo
}

export default connect(mapState, mapDispatch)(HabitatDynamo);