import React from 'react';
import { connect } from 'react-redux';
import routes from '../constants/routes.json';
import AppLayout from './AppLayout';
import { Route, Redirect } from 'react-router-dom';

// App Layout Route
const AppLayoutRoute = ({
    component: Component,
    currentUser,
    ...rest
}) => {    
    return (
        <Route {...rest} render={matchProps => (    
            currentUser ?                
            <AppLayout>
                <Component {...matchProps} />
            </AppLayout>
            : <Redirect to={{ pathname: routes.SIGNIN }} />
        )} />
    )
};

const mapState = (state, props) => {    
    return {
        currentUser: state.user.user,
        localtion: props.location        
    };
};
export default connect(mapState)(AppLayoutRoute);
  
