import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

export const createAWSRole = (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));      
    return axios.post(`${URL}/aws/aws_roles/`, data,  { headers: authToken }) 
}

export const deleteAWSRole = (id, data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));   
    return axios.delete(`${URL}/aws/aws_roles/${id}?habitat_id=${data.habitat_id}&role_name=${data.role_name}&policy_arn=${data.policy_arn}`, { headers: authToken })  
}

export const getAWSRoles = (id) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.get(`${URL}/aws/aws_roles/?habitat_id=${id}`, { headers: authToken })
}