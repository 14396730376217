import React from 'react';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HabitatInstanceBox = ({
  instances,
  habitatSetUp,
  handleSelectFeature
}) => {
  return (
    <>
      {habitatSetUp === 'instance' ? (
        <div className={habitatSetUp + '-selected box-feature'} onClick={() => handleSelectFeature('instance')}>
          <div className="box-feature-name">
            <div className="box-feature-name-icon-funcs"><FontAwesomeIcon icon={faServer} /></div>
            <p>EC2 Instances</p>
          </div>

          <div className="box-feature-plus-1">
            <p>No. Sites</p>
            <p>{instances.length}</p>
          </div>

          <div className="box-feature-plus">
            <p>Last Update</p>
            <p>2021-08-14T16:41:59.400Z</p>
          </div>
        </div>
      ) : (
        <div className="box-feature" onClick={() => handleSelectFeature('instance')}>
          <div className="box-feature-name">
            <div className="box-feature-name-icon-funcs"><FontAwesomeIcon icon={faServer} /></div>
            <p>EC2 Instances</p>
          </div>

          <div className="box-feature-plus-1">
            <p>No. Sites</p>
            <p>{instances.length}</p>
          </div>

          <div className="box-feature-plus">
            <p>Last Update</p>
            <p>2021-08-14T16:41:59.400Z</p>
          </div>
        </div>
      )}
    </>
  );
}

export default HabitatInstanceBox;