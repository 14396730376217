import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNetworkWired, faFill, faDatabase, faInfinity, faFileCode, faUserFriends, faLayerGroup, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const HabitatResourceReport = (props) => {
    return (
        <div className="resources-report-container">
            <p><b>Resources Report</b></p>

            <div className="report-container">
                {props.resources.length > 0 ? (
                    <div className="resources-list-container">
                        <p className="title-resources">AWS Created Resources</p>
                        {props.resources.map(resource => (
                            <div className="resource-box" key={resource.id}>
                                <div className="icon-resource">
                                    {resource.name === 'Lambdas' && (
                                        <div className="lambdas-box">
                                            <FontAwesomeIcon icon={faFileCode} />
                                        </div>
                                    )}

                                    {resource.name === 'Buckets' && (
                                        <div className="buckets-box">
                                            <FontAwesomeIcon icon={faFill} />
                                        </div>
                                    )}


                                    {resource.name === 'Dynamos' && (
                                        <div className="dynamos-box">
                                            <FontAwesomeIcon icon={faDatabase} />
                                        </div>
                                    )}


                                    {resource.name === 'Code Builds' && (
                                        <div className="builds-box">
                                            <FontAwesomeIcon icon={faInfinity} />
                                        </div>
                                    )}

                                    {resource.name === 'Api Gateways' && (
                                        <div className="api-box">
                                            <FontAwesomeIcon icon={faNetworkWired} />
                                        </div>
                                    )}


                                    {resource.name === 'Cognitos' && (
                                        <div className="cognito-box">
                                            <FontAwesomeIcon icon={faUserFriends} />
                                        </div>
                                    )}


                                    {resource.name === 'Sites' && (
                                        <div className="sites-box">
                                            <FontAwesomeIcon icon={faLayerGroup} />
                                        </div>
                                    )}
                                    <p>
                                        {resource.name}
                                    </p>
                                </div>
                                <div className="number-resource">
                                    {resource.total}
                                </div>
                            </div>
                        ))}

                    </div>
                ) : (
                    <div className="blank-section">No resources added yet.</div>
                )}

            </div>

        </div>
    )
}

export default HabitatResourceReport;