import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

const AddDynamoFields = ({
  hash,
  schema,
  fields,
  tableName,
  setTableName,
  handleAddKey,
  handleAddType,
  handleNewField,
  handleCreateDB,
  handleAddAttribute
}) => {
  return (
    <>
      <p className="dynamo-management-title">New Dynamo DB</p>

      <div className="form-container-dynamo">
        <div className="new-dynamo-input">
          <Form.Control
            type="text"
            placeholder="Table Name"
            onChange={(e) => setTableName(e.target.value)}
          />
          <Button variant="primary" onClick={handleCreateDB}> + Create DB</Button>
        </div>
        {tableName && (
          fields.map((field, index) => (
            <>
              <p className="dynamo-management-sub">New Attribute</p>
              <div className="attr-container" key={index}>
                <div>
                    <Form.Control
                      type="text"
                      custom
                      placeholder="Attrribute Name"
                      onChange={(e) => handleAddAttribute(e, index)}
                    />
                </div>
                <div>
                    <Form.Control
                      as="select"
                      custom
                      onChange={(e) => handleAddType(e, index)}
                    >
                      <option>Select type</option>
                      <option value="S">String</option>
                      <option value="N">Number</option>
                      <option value="B">Binary</option>
                    </Form.Control>
                </div>
                <div>
                    <Form.Control
                      as="select"
                      custom
                      onChange={(e) => handleAddKey(e, index)}
                      disabled={schema}
                    >
                      <option>Select Key</option>
                      <option disabled={hash}>HASH</option>
                      <option>RANGE</option>
                    </Form.Control>
                </div>
              </div>
            </>
          ))
        )}
        <div className="button-add-fields">
          {tableName && fields.length < 2 && (
            <Button disabled={fields.length === 2} variant="primary" onClick={handleNewField}> + Add Field</Button>
          )}
        </div>
      </div>
    </>
  );
}

export default AddDynamoFields;