import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

export const getCosts = (data) => {
  const habitat_id = data.habitat_id;
  const init_date = data.init_date;
  const end_date = data.end_date;
  const granularity = data.granularity
  const broken_down = data.broken_down
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/aws/get_costs/?habitat_id=${habitat_id}&init_date=${init_date}&end_date=${end_date}&granularity=${granularity}&broken_down=${broken_down}`, { headers: authToken })
}

export const costsActivation = (data, idHabitat) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders')); 
  return axios.put(`${URL}/activate_costs/${idHabitat}`, data , { headers: authToken})
}

export const setHabitatLocal = (habitat) => {
    localStorage.setItem('currentHabitat', JSON.stringify(habitat));
    let currentHabitat = JSON.parse(localStorage.getItem('currentHabitat'));
    return currentHabitat;
}

export const fetchHabitatLocal = () => {    
    let currentHabitat = JSON.parse(localStorage.getItem('currentHabitat'));
    return currentHabitat;
}

export const getAllHabitats = (team_uuid) => {
    const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    let request_url;
    if(team_uuid) {
        request_url = `${URL}/habitats/?team_id=${team_uuid}`; 
    } else {
        request_url = `${URL}/habitats/`; 
    }  
    return axios.get(request_url, { headers: authToken })
}

export const requestRemoveHabitat = (id) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));    
  return axios.get(`${URL}/habitat_verification/${id}`, { headers: authToken })
}

export const removeHabitat = (id) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));    
    return axios.delete(`${URL}/habitats/${id}`, { headers: authToken })
}

export const createHabitat = (habitat) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));      
    return axios.post(`${URL}/habitats`, habitat, { headers: authToken }) 
}

export const getHabitat = (id) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.get(`${URL}/habitats/${id}`, { headers: authToken })
}

export const updateHabitat = (id, newHabitatParams) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));       
    return axios.put(`${URL}/habitats/${id}`, newHabitatParams, { headers: authToken})
}

export const attachCredHabitat = (habId, credId) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.put(`${URL}/habitat_attach_cred/${habId}`, credId, { headers: authToken})
}

export const getConnectedS3s = (habId) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));  
  return axios.get(`${URL}/aws/aws_s3s/hbuckets/${habId}`, { headers: authToken})
}

export const connectS3s = (habId, bucketName) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));         
  let data = {
    bucket: bucketName
  }
  return axios.post(`${URL}/aws/aws_s3s/select/${habId}`, data, { headers: authToken})
}

export const disconnectS3s = (habId, bucketName) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));         
  let data = {
    bucket: bucketName
  }
 
  return axios.post(`${URL}/aws/aws_s3s/deselect/${habId}`, data, { headers: authToken})
}