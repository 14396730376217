import produce from 'immer';

import {
  GET_TEST_BEGIN, GET_TEST_SUCCESS, GET_TEST_ERROR,
  STORE_TEST_BEGIN, STORE_TEST_SUCCESS, STORE_TEST_ERROR,
  UPDATA_CODE_BEGIN, UPDATA_CODE_SUCCESS, UPDATA_CODE_ERROR,  
  CREATE_FUNCTION_BEGIN, CREATE_FUNCTION_SUCCESS, CREATE_FUNCTION_ERROR, 
  GET_ALL_FUNCTION_BEGIN, GET_ALL_FUNCTION_SUCCESS, GET_ALL_FUNCTION_ERROR,
  UPDATE_FUNCTION_BEGIN, UPDATE_FUNCTION_SUCCESS, UPDATE_FUNCTION_ERROR,
  DELETE_FUNCTION_BEGIN, DELETE_FUNCTION_SUCCESS, DELETE_FUNCTION_ERROR,
  INVOKE_FUNCTION_BEGIN, INVOKE_FUNCTION_SUCCESS, INVOKE_FUNCTION_ERROR,
  DEPLOY_FUNCTION_BEGIN, DEPLOY_FUNCTION_SUCCESS, DEPLOY_FUNCTION_ERROR,
  BUILD_FUNCTION_BEGIN, BUILD_FUNCTION_SUCCESS, BUILD_FUNCTION_ERROR,
  GET_ALL_FUNCTIONS_BEGIN, GET_ALL_FUNCTIONS_SUCCESS, GET_ALL_FUNCTIONS_ERROR,
  UPDATE_ALL_FUCTIONS_BEGIN, UPDATE_ALL_FUCTIONS_SUCCESS, UPDATE_ALL_FUCTIONS_ERROR,
  DEPLOY_ALL_FUCTIONS_BEGIN, DEPLOY_ALL_FUCTIONS_SUCCESS, DEPLOY_ALL_FUCTIONS_ERROR,
  GET_DEPLOYMENTHISTORY_BEGIN, GET_DEPLOYMENTHISTORY_SUCCESS, GET_DEPLOYMENTHISTORY_ERROR,
  GET_FUNC_FILTER_BEGIN, GET_FUNC_FILTER_SUCCESS, GET_FUNC_FILTER_ERROR,
  CHANGE_STATE_FUNC_BEGIN, CHANGE_STATE_FUNC_SUCCESS, CHANGE_STATE_FUNC_ERROR,
  GIT_REMOTE_BEGIN, GIT_REMOTE_SUCCESS, GIT_REMOTE_ERROR,
  SET_BUILD_STATUS_BEGIN, SET_BUILD_STATUS_SUCCESS, SET_BUILD_STATUS_ERROR
} from '../actions/types';

const initialState = {
  count: 0,
  funcs: [],
  invoke: null,
  error: null,
  allFuncs: [],
  funcTest: null,
  builFunc: null,
  loading: false,
  loading_message: '',
  loading_message_deploy: '',
  loading_deploy: false,
  deploymentHistory: [],
  funcFilterSelected: [],
  deploymentHistoryStatus: false,
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case GET_TEST_BEGIN:
    case STORE_TEST_BEGIN:
    case GIT_REMOTE_BEGIN:
    case UPDATA_CODE_BEGIN:
    case GET_ALL_FUNCTION_BEGIN:    
    case CREATE_FUNCTION_BEGIN:
    case UPDATE_FUNCTION_BEGIN:
    case DELETE_FUNCTION_BEGIN:
    case INVOKE_FUNCTION_BEGIN:
    case DEPLOY_FUNCTION_BEGIN:
    case BUILD_FUNCTION_BEGIN:
    case SET_BUILD_STATUS_BEGIN:
    case GET_ALL_FUNCTIONS_BEGIN:
    case UPDATE_ALL_FUCTIONS_BEGIN:
    case DEPLOY_ALL_FUCTIONS_BEGIN:
    case GET_FUNC_FILTER_BEGIN:
    case CHANGE_STATE_FUNC_BEGIN:
      draft.loading = true;
      draft.error = null;
      draft.loading_message = action.loading_message;
      return;
    case GET_DEPLOYMENTHISTORY_BEGIN:
      draft.loading_deploy = true;
      draft.error = null;
      draft.loading_message_deploy = action.loading_message;
      return;
    case UPDATA_CODE_SUCCESS:    
    case GET_ALL_FUNCTION_SUCCESS:
    case CREATE_FUNCTION_SUCCESS:
    case UPDATE_FUNCTION_SUCCESS:
    case DELETE_FUNCTION_SUCCESS:
    case DEPLOY_FUNCTION_SUCCESS:
    case UPDATE_ALL_FUCTIONS_SUCCESS:
    case DEPLOY_ALL_FUCTIONS_SUCCESS:
      draft.loading = false;      
      draft.funcs = action.payload;
      draft.error = null;
      return;
    case STORE_TEST_SUCCESS:
      draft.loading = false;
      draft.funcTest = action.payload;
      draft.error = null;
      return;
    case SET_BUILD_STATUS_SUCCESS:
      draft.loading = false;
      draft.funcs = action.payload.historic
      draft.error = null;
      return;
    case INVOKE_FUNCTION_SUCCESS:
      draft.loading = false;      
      draft.invoke = action.payload;
      draft.error = null;
      return;   
    case  GET_ALL_FUNCTIONS_SUCCESS:
      draft.allFuncs = action.payload;
      draft.count = action.payload.length;
      return;
    case GET_DEPLOYMENTHISTORY_SUCCESS:
      draft.loading_deploy = false; 
      draft.deploymentHistory = action.payload;
      draft.deploymentHistoryStatus = true;
      return;
    case GET_FUNC_FILTER_SUCCESS:
      draft.loading = false; 
      draft.funcFilterSelected = action.payload;
      draft.deploymentHistoryStatus = false;
      return;
    case CHANGE_STATE_FUNC_SUCCESS:
      draft.loading = false; 
      draft.deploymentHistoryStatus = action.payload;
      return;
    case BUILD_FUNCTION_SUCCESS:
      draft.loading = false;
      draft.builFunc = 'Build success';
      return;
    case GIT_REMOTE_SUCCESS:
      draft.loading = false;
      return;
    case GET_TEST_SUCCESS:
      draft.loading = false;
      draft.funcTest = action.payload;
      draft.error = null;
      return;
    case GET_TEST_ERROR:
    case STORE_TEST_ERROR:
    case UPDATA_CODE_ERROR: 
    case UPDATE_FUNCTION_ERROR:
    case INVOKE_FUNCTION_ERROR:
    case SET_BUILD_STATUS_ERROR:
    case UPDATE_ALL_FUCTIONS_ERROR:   
    case DEPLOY_ALL_FUCTIONS_ERROR:
    case GET_DEPLOYMENTHISTORY_ERROR: 
    case GET_FUNC_FILTER_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return;
    case DEPLOY_FUNCTION_ERROR:
      draft.loading = false;
      draft.error = action.errorDeployFunction;
      return;
    case GIT_REMOTE_ERROR:
        draft.loading = false;
        draft.error = action.errorRemoteGit
        return;
    case BUILD_FUNCTION_ERROR:
      draft.loading = false;
      draft.error = action.errorBuildDeploy
      return;
    case GET_ALL_FUNCTION_ERROR:
      draft.loading = false;
      draft.error = action.errorListFunctions
      return; 
    case DELETE_FUNCTION_ERROR:
      draft.loading = false;
      draft.error = action.errorDeleteFunction
      return;  
    case CREATE_FUNCTION_ERROR:
      draft.loading = false;
      draft.error = action.errorDeleteFunction
      return;  
    default:
      return;
  }
}, initialState);
  
export default reducer;
