import React, { useState } from 'react';
import { Form, Modal, Badge, Alert, Button, Spinner } from 'react-bootstrap';

const GitModal = ({
  show,
  clone,
  setShow,
  branches,
  gitCreds,
  remoteGit,
  handlePull,
  loadingClone,
  handleCheckout
}) => {
  const URI = 'https://gitlab.com/kor-comunity/kor-web-app-template.git';
  const [ gitCred, setGitCred ]       = useState('');
  const [ remoteUri, setRemoteUri ]   = useState('');
  const [ showRemote, setShowRemote ] = useState(false);

  const handleRemoteToggle = () => {
    setShowRemote(!showRemote);
  }

  const handleChangeRemote = () => {
    remoteGit(clone.id, gitCred, remoteUri);
    setShowRemote(!showRemote);
    setShow(false);
  }

  return (
    <Modal
      size="md"
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Git Controller
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loadingClone ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
            <p>Loading...</p>
          </div>
        ):(
          !showRemote ? (
            <div>
              {clone && branches && (
                branches.map(branch => (
                  branch.name === clone.git_branch && (
                    clone.git_hash === branch.sha ? (
                      <div className="w-100 d-flex justify-content-between">
                        <div>
                          <span>KOR hash: </span>
                          <Badge variant="success">{clone.git_hash.substring(0,8)}</Badge>
                        </div>
                        <div>
                          <span>Remote hash: </span>
                          <Badge variant="success">{branch.sha.substring(0,8)}</Badge>
                        </div>
                      </div>
                    ):(
                      <div className="w-100">
                        <div className="w-100 d-flex justify-content-between mb-4">
                          <div>
                            <span>KOR hash: </span>
                            <Badge variant="warning">{clone.git_hash.substring(0,8)}</Badge>
                          </div>
                          <div>
                            <span>Remote hash: </span>
                            <Badge variant="danger">{branch.sha.substring(0,8)}</Badge>
                          </div>
                        </div>
                        <Button 
                          size="sm" 
                          variant="outline-info" 
                          block
                          onClick={handlePull}  
                        >Pull</Button>
                      </div>
                    )
                  )  
                ))
              )}
              {branches && clone && branches.length > 0 && (
                <div>
                  {clone.uri === URI ? (
                    <div className="p-3 mt-4 mb-4">
                      <Alert variant="warning">
                        You need to change the repository to your own, you must click on change git remote!
                      </Alert>
                    </div>
                  ) : (
                  <Form>
                    <Form.Group className="mt-5">
                      <Form.Label>Change Branch</Form.Label>
                      <Form.Control 
                        as="select"
                        defaultValue={'DEFAULT'}
                        onChange={event => handleCheckout(event)}
                      >
                        <option disabled value="DEFAULT">--Select a Branch--</option>
                        {branches.map(branch => (
                          <option disabled={clone.git_branch === branch.name} key={branch.id} value={branch.name}>{branch.name}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                  )}
                  <Button onClick={handleRemoteToggle}>Change Git Remote</Button>
                </div>
              )}
            </div>
          ):(
            <div>
              <Form>
                <Form.Group>
                  <Form.Control
                    type="text" 
                    aria-label="URL"
                    value={remoteUri}
                    placeholder="New Repo URL"
                    onChange={e => setRemoteUri(e.target.value)}
                  />
                  <Form.Control
                    as="select"
                    defaultValue={'DEFAULT'}
                    onChange={e => setGitCred(e.target.value)}
                  >
                    <option disabled value="DEFAULT">--Select Git Credentials--</option>
                    {gitCreds.map(cred => (
                      <option key={cred.id} value={cred.id}>{cred.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Button block disabled={!gitCred} onClick={handleChangeRemote}>Update</Button>
              </Form>
            </div>
          )
        )}
      </Modal.Body>
    </Modal>
  )
}

export default GitModal;