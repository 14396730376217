import React from 'react';
import { Button } from 'react-bootstrap';

const HabitatAPIMethodsList = ({ apiId, habitat, methods, awsCreds, deleteMethod }) => {
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Method</th>
            <th scope="col">Endpoint</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {methods.map(method => (
            <tr key={method.id}>
              <td>{method.method}</td>
              <td>{`https://${apiId}.execute-api.${awsCreds.region}.amazonaws.com/production${method.resource}`}</td>
              <td>
                <Button
                  variant="outline-danger"
                  size='sm'
                  onClick={e => deleteMethod(method.id, habitat.id)}
                >
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default HabitatAPIMethodsList;