import React from 'react';
import {connect} from 'react-redux';
import '../../assets/styles/components/billing/billing-user-subscription.scss';
import Avatar from '../avatar/Avatar';

const BillingUserSubscription = ({handleGetInfo, activeName, currentUser}) => {
    return(
        <div className="user-subscription-container">
            <p className="user-subscription-tittle">My Subscription</p>

            <div className={(activeName === currentUser.name) ? 'card-user-subscription-active' : 'card-user-subscription'} onClick={handleGetInfo}>
                 <div className="avatar-user ml-2"><Avatar userName={currentUser.name} avatarClass="avatarPicTop" /></div>
                 <p className="name-user">{currentUser.name}</p>
                 <p className="type-sub">{currentUser.subscription}</p>
            </div>
        </div>
    )
}

const mapState = (state) => ({
    currentUser: state.user.user,
});


export default connect(mapState)(BillingUserSubscription);
