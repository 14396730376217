import React from 'react';
import { Toast } from 'react-bootstrap';
import '../../assets/styles/toast/ToastFuncs.scss'

const ToastComponent = (props) => {
  return (
    <div className="toast-container">
        <Toast onClose={() => props.handleCloseToast()} show={props.showToast} delay={1000} autohide>
          <Toast.Header>
            <strong className="mr-auto">{props.title}</strong>
            <small>Just now</small>
          </Toast.Header>
          <Toast.Body>
            <p className="message-toast">{props.message}</p>
          </Toast.Body>
        </Toast>
    </div>
  );
};

export default ToastComponent;