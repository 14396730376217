import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';

const DomainFormTemplate = ({
  name,
  prefix,
  domains,
  setName,
  setCount,
  setPrefix,
  domainName,
  templateName,
  setDomainName,
  setSiteWithoutDomain,
  siteWithoutDomain
}) => {

  return (
    <>
      <Row className="p-5">
        <Col xs={12}>
          <div>
            <span className="text-muted">
              Please enter the name of the application and the domain that will be used for the web application.
            </span>
          </div>
        </Col>
        <div className="input-title-templates">
          <h5 className="font-weight-bold mt-3 mb-3">Domain Name</h5>
        </div>
        <div className="pl-0 input-title-input">
          <Form.Control
            as="select"
            onChange={e => setDomainName(e.target.value)}
            defaultValue={domainName ? domainName : 'DEFAULT'}
            disabled={siteWithoutDomain}
          >
            <option disabled value="DEFAULT">--Select a domain--</option>
            {domains && (
              domains.map(domain => (
                <option key={domain.name}>
                  {domain.name.substring(0, domain.name.length - 1)}
                </option>
              ))
            )}
          </Form.Control>
        </div>

        <div xs={12} className="input-subdomain-container">
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                <strong>You can leave the subdomain in blank</strong>
              </Tooltip>
            }
          >
            <h5 className="font-weight-bold mt-3 mb-3 width-flex">
              Subdomain
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="text-muted ml-2"
              />
            </h5>
          </OverlayTrigger>
        </div>
        <div className="input-subdomain-container">
          <Form.Control
            type="text"
            value={prefix}
            placeholder="Prefix"
            onChange={e => setPrefix(e.target.value)}
            disabled={siteWithoutDomain}
          />
        </div>

        <div className="check-no-domain-container">
          <div className="check-enable-no-domain">
            <Form.Check
              checked={siteWithoutDomain}
              type="checkbox"
              className="check-enable-build-1"
              onChange={() => setSiteWithoutDomain(!siteWithoutDomain)}
            />
            <p>Create template without a domain</p>
          </div>

          <div className="dom-reg-question">
            <p>AWS Domain Registration</p>
            <div className="question-mark-documentation-habitat">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip className="red-tooltip">
                    <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                  </Tooltip>
                }

              >
                <a
                  href="https://getkor.freshdesk.com/support/solutions/articles/47001163815-aws-domain-registration"
                  rel="noopener noreferrer"
                  className="help"
                  target="_blank"
                >

                  <FontAwesomeIcon icon={faQuestionCircle} />
                </a>
              </OverlayTrigger>
            </div>
          </div>
        </div>


        {siteWithoutDomain ? (
          <div className="next-button-templates-2">
            <Button
              className="btn-template-outline mt-3 mb-5"
              onClick={() => setCount(3)}
            >Next</Button>
          </div>
        ):(
          <div className="next-button-templates-2">
            <Button
              className="btn-template-outline mt-3 mb-5"
              onClick={() => setCount(3)}
              disabled={!domainName}
            >Next</Button>
          </div>
        )}

      </Row>
    </>
  );
}

export default DomainFormTemplate;