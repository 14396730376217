import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import NewGITCredModal from '../git/NewGITCredModal';
import NewGitCredsModal from '../git/NewGitCredsModal';
import EditGITCredModal from '../git/EditGITCredModal';
import DeleteGITCredModal from '../git/DeleteGITCredModal';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { faCheckCircle, faTimesCircle, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  newGitCred, 
  listGitCreds, 
  editGitCred, 
  removeGitCred, 
  newGitCredWithOauth, 
  newGitLabCredWithOauth 
} from '../../actions/git-creds.actions';
import { Card, Button, Table, Row, Col } from 'react-bootstrap';
import '../../assets/styles/components/AwsCredManage.scss';
import { faGithub, faGitAlt, faGitlab, faBitbucket } from '@fortawesome/free-brands-svg-icons'

const GITCredManage = ({
  oauth,
  error,
  gitCreds,
  newGitCred,
  editGitCred,
  listGitCreds,
  removeGitCred,
  newGitCredWithOauth,
  newGitLabCredWithOauth 
}) => {
  const [showNew, setShowNew] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [nameCert, setNameCert] = useState('');
  const [username, setUserName] = useState('');
  const [credId, setCredId] = useState('');
  const [name, setName] = useState('');
  const [service, setService] = useState('');
  const [userSecret, setUserSecret]                 = useState('');
  const [showGitModal, setShowGitModal]             = useState('display-none')
  const [showVerifError, setShowVerifError]         = useState(true);
  const [showGitModalOthers, setShowGitModalOthers] = useState('display-none')

  useEffect(() => { listGitCreds() }, []);
  
  const handleShow = () => {
    setShowNew(true);
    document.body.click();
  };

  const handleShowGit = () => {
    setShowGitModal('')
  }

  const handleShowGitOthers = () => {
    setShowGitModalOthers('')
    document.body.click();
  }

  const handleGitOauth = () => {
    newGitCredWithOauth(name, service);
    setShowGitModal('display-none');
    setService('');
  }

  const handleShowEdit = (e) => {
    let id = e.target.getAttribute('data-cred-id');
    let foundIndex = gitCreds.findIndex(obj => obj['id'] === id);
    let cred = gitCreds[foundIndex];
    setCredId(id);
    setName(cred.name);
    setUserName(cred.username);
    setShowEdit(true);
    document.body.click();
    
  };

  const handleShowDelete = (e) => {
    let id = e.target.getAttribute('data-cred-id');
    let foundIndex = gitCreds.findIndex(obj => obj['id'] === id);
    let cred = gitCreds[foundIndex];
    setCredId(id);
    setShowDelete(true);
  }


  const handleUpdateGITCred = () => {
    let newCred = {
      name: name,
      username: username,
      usersecret: userSecret,
      id: credId
    }
    editGitCred(newCred);
    setShowEdit(false);
  };

  const handleShowGitModal = (service) => {
    setService(service);
    setShowGitModal('');
    document.body.click();
  }

  const handleNewGITCred = () => {
    newGitCred(name, username, userSecret);
    setShowNew(false);
    setShowGitModalOthers('display-none')
  }

  const handleCloseGitModal = () => {
    setShowGitModal('display-none')
  }

  const handleCloseGitModalOthers = () => {
    setShowGitModalOthers('display-none')
  }

  const handleDeleteGITCred = () => {
    let newCred = {
      id: credId
    }
    removeGitCred(newCred);
    setShowDelete(false);
  }

  useEffect(() => {
    setTimeout(() => {
      listGitCreds(); 
    }, 2000); 
  }, [oauth]);


  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        <div className="new-collection" onClick={() => handleShowGitModal('git')}>
          <FontAwesomeIcon icon={faGithub} />
          <span> &nbsp;Github</span>
        </div>
        <div className="new-collection" onClick={() => handleShowGitModal('gitlab')}>
          <FontAwesomeIcon icon={faGitlab} />
          <span> &nbsp;Gitlab</span>
        </div>
        <div className="new-collection" onClick={() => handleShowGitModal('bitbucket')}>
          <FontAwesomeIcon icon={faBitbucket} />
          <span> &nbsp;BitBucket</span>
        </div>
        <div className="new-function" onClick={handleShowGitOthers}>
          <FontAwesomeIcon icon={faGitAlt}/>
          <span>  &nbsp; Other</span>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <div>
      <Card className="awsCredManageCard">
        <NewGitCredsModal
          name={name}
          setName={setName}
          service={service}
          showGitModal={showGitModal}
          handleGitOauth={handleGitOauth}
          handleCloseGitModal={handleCloseGitModal}
        />
        <NewGITCredModal
          showNew={showNew}
          setShowNew={setShowNew}
          setNameCert={setNameCert}
          handleNewGITCred={handleNewGITCred}
          username={username}
          name={name}
          userSecret={userSecret}
          setUserName={setUserName}
          setName={setName}
          setUserSecret={setUserSecret}
          showGitModalOthers={showGitModalOthers}
          handleCloseGitModalOthers={handleCloseGitModalOthers}
        />

        <EditGITCredModal
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          username={username}
          name={name}
          userSecret={userSecret}
          setUserName={setUserName}
          setName={setName}
          setUserSecret={setUserSecret}
          handleUpdateGITCred={handleUpdateGITCred}
        />

        <DeleteGITCredModal
          showDelete={showDelete}
          setShowDelete={setShowDelete}
          handleDeleteGITCred={handleDeleteGITCred}
        />

        <Card.Header className="cred-title" as="h5">Git Credentials</Card.Header>
        <div className="section-settings-section-content">

          <Row >
            <Col className="new-cred-btn">
              <OverlayTrigger rootClose={true} trigger="click" placement="bottom" overlay={popover}>
                <div className="new-git-creds">
                  <Button variant="outline-primary">
                    + Git Credentials
                  </Button>
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <Col >
              <Table responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Username</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {gitCreds && gitCreds.map((gitCred) => (
                    <tr key={gitCred.id}>
                      <td>{gitCred.name}</td>
                      <td>{gitCred.username}</td>
                      <td className="myInfo" data-cred-id={gitCred.id} onClick={handleShowEdit}>Edit</td>
                      <td className="myDanger" data-cred-id={gitCred.id} onClick={handleShowDelete}>Remove</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
        {error && (
          <div className="error-display text-danger">
            <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
          </div>
        )}


      </Card>
    </div>

  );
}
const mapState = (state, props) => ({
  error: state.gitCreds.error,
  oauth : state.gitCreds.oauth,
  gitCreds: state.gitCreds.gitCredentials.git_credentials,
})

const mapDispatch = {
  newGitCred,
  editGitCred,
  listGitCreds,
  removeGitCred,
  newGitCredWithOauth,
  newGitLabCredWithOauth 
}

export default connect(mapState, mapDispatch)(GITCredManage);