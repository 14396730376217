import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import '../assets/styles/components/onboarding/welcomepage.scss'
import headImg from '../assets/images/onboarding-1.png';
import iconTemplates from '../assets/images/templates-icon.png';
import OnboardingStepSelect from '../components/onboarding/OnboardingStepSelect';
import OnboardingStepCreds from '../components/onboarding/OnboardingStepCreds';
import {
    newGitCredWithOauth,
} from '../actions/git-creds.actions';
import { addAwsCredWithURL, getUserAWSCreds } from '../actions/aws-creds.actions';
import { nextStepTour } from '../actions/app-tour.actions';
import { changeStatusOnboarding } from '../actions/user.actions';
import { fetchTeamsWithMembers } from '../actions/team.actions';

const OnboardingPage = ({
    newGitCredWithOauth,
    addAwsCredWithURL,
    loadingGit,
    loadingAws,
    awsCredDone,
    gitCredsDone,
    getUserAWSCreds,
    onboardingStatus,
    nextStepTour,
    stepTour,
    changeStatusOnboarding,
    errorGitCreds,
    errorAwsCreds,
    awsCredAlert
}) => {
    const [name, setName] = useState('');
    const [service, setService] = useState('');
    const [alertMessage, setAlertMessage] = useState(false);

    useEffect(() => {
        getUserAWSCreds();
    }, [])

    const handleGitOauth = () => {
        newGitCredWithOauth(name, service);
        setService('');
        setName('')
    }

    const handleAddAWSCredsURL = () => {
        addAwsCredWithURL()
        setAlertMessage(true);
    }

    const handleNextStep = () => {
        nextStepTour();
        changeStatusOnboarding(false)
    }


    return (
        <>

            {onboardingStatus && (
                <>
                    {stepTour === 0 && (
                        <OnboardingStepCreds
                            handleGitOauth={handleGitOauth}
                            setName={setName}
                            name={name}
                            service={service}
                            setService={setService}
                            handleAddAWSCredsURL={handleAddAWSCredsURL}
                            loadingGit={loadingGit}
                            loadingAws={loadingAws}
                            awsCredDone={awsCredDone}
                            gitCredsDone={gitCredsDone}
                            handleNextStep={handleNextStep}
                            stepTour={stepTour}
                            onboardingStatus={onboardingStatus}
                            errorGitCreds={errorGitCreds}
                            errorAwsCreds={errorAwsCreds}
                            awsCredAlert={awsCredAlert}
                            alertMessage={alertMessage}
                            setAlertMessage={setAlertMessage}
                        />
                    )}

                    {stepTour === 1 && (
                        <OnboardingStepSelect
                            headImg={headImg}
                            iconTemplates={iconTemplates}
                        />
                    )}
                </>
            )}

            {!onboardingStatus && (
                <>
                    <OnboardingStepSelect
                        headImg={headImg}
                        iconTemplates={iconTemplates}
                    />
                </>
            )}




        </>
    )
}

const mapState = (state, props) => ({
    loadingGit: state.gitCreds.loading,
    loadingAws: state.awsCreds.loading,
    awsCredDone: state.awsCreds.userCredentials.aws_credentials,
    awsCredAlert: state.awsCreds.awsCredential,
    gitCredsDone: state.gitCreds.oauth,
    onboardingStatus: state.user.user.onboarding,
    stepTour: state.appTour.stepAppTour,
    errorGitCreds: state.gitCreds.error,
    errorAwsCreds: state.awsCreds.error
})

const mapDispatch = {
    newGitCredWithOauth,
    addAwsCredWithURL,
    getUserAWSCreds,
    nextStepTour,
    changeStatusOnboarding,
    fetchTeamsWithMembers
}

export default connect(
    mapState,
    mapDispatch
)(OnboardingPage)