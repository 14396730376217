import React from 'react';
import '../../../assets/styles/modals/Modal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button'


const HabitatAuthDisableUser = (props) => {
    return (
        <>
            <div className={props.showDisableUserModal + ' modal-kor-container'}>
                <div className="box-modal-options-container">
                    <p><FontAwesomeIcon icon={faExclamationTriangle} className="delete-icon" /> Disable User</p>
                    <p>Are you sure you want to disable this user?</p>
                    <div className="buttons-modal-kor">
                        <Button variant="primary" size="sm" onClick={() => props.handleDisableUser(props.selectedCognito, props.habitat.id, props.selectedUser.username)}>
                            Disable
                        </Button>
                        <Button variant="primary" size="sm" onClick={() => props.setShowDisableModal('display-none-disable-user-modal')}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </ div>
        </>
    )
}

export default HabitatAuthDisableUser;