import produce from 'immer';
import {
  GET_RDS_DB_BEGIN, GET_RDS_DB_SUCCESS, GET_RDS_DB_ERROR,
  DELETE_RDS_DB_BEGIN, DELETE_RDS_DB_SUCCESS, DELETE_RDS_DB_ERROR,
  GET_AURORA_DB_BEGIN, GET_AURORA_DB_SUCCESS, GET_AURORA_DB_ERROR,
  CREATE_RDS_DB_BEGIN, CREATE_RDS_DB_SUCCESS, CREATE_RDS_DB_ERROR,
  CREATE_AURORA_DB_BEGIN, CREATE_AURORA_DB_SUCCESS, CREATE_AURORA_DB_ERROR,
  DELETE_AURORA_DB_BEGIN, DELETE_AURORA_DB_SUCCESS, DELETE_AURORA_DB_ERROR,
} from '../actions/types';

const initialState = {
  rdsDB: [],
  error: null,
  auroraDB: [], 
  loading: false,
  loading_message: ''
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case GET_RDS_DB_BEGIN:
    case CREATE_RDS_DB_BEGIN:
    case DELETE_RDS_DB_BEGIN:
    case GET_AURORA_DB_BEGIN:
    case CREATE_AURORA_DB_BEGIN:
    case DELETE_AURORA_DB_BEGIN:
      draft.error = null;  
      draft.loading = true;
      draft.loading_message = action.loading_message;
      return;
    case GET_AURORA_DB_SUCCESS:
    case CREATE_AURORA_DB_SUCCESS:
    case DELETE_AURORA_DB_SUCCESS:
      draft.error = null;  
      draft.loading = false;
      draft.auroraDB = action.payload;
      return;
    case GET_RDS_DB_SUCCESS:
    case CREATE_RDS_DB_SUCCESS:
    case DELETE_RDS_DB_SUCCESS:
      draft.error = null;  
      draft.loading = false;
      draft.rdsDB = action.payload;
      return;
    case GET_RDS_DB_ERROR:
    case CREATE_RDS_DB_ERROR:
    case DELETE_RDS_DB_ERROR:
    case GET_AURORA_DB_ERROR:
    case CREATE_AURORA_DB_ERROR:
    case DELETE_AURORA_DB_ERROR:
      draft.error = action.error;  
      draft.loading = false;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;
