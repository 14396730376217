import {
    CHANGE_NAVBAR_CLASS_BEGIN,
    CHANGE_NAVBAR_CLASS_SUCCESS,
    CHANGE_NAVBAR_CLASS_ERROR,
    CHANGE_INITIAL_NAVBAR_BEGIN,
    CHANGE_INITIAL_NAVBAR_SUCCESS
  } from './types';

  export const changeInitial = () => {
    return(dispatch) => {
      dispatch({ type: CHANGE_INITIAL_NAVBAR_BEGIN });
      dispatch({
        type: CHANGE_INITIAL_NAVBAR_SUCCESS,
        payload: {
          initalClass: 'display-menu'
        }
      })
    }
  }
  
  export const changeClass = (active) => {
    return (dispatch) => {
      dispatch({ type: CHANGE_NAVBAR_CLASS_BEGIN });
      if (active) {
        dispatch({
          type: CHANGE_NAVBAR_CLASS_SUCCESS,
          payload: {
            active: false,
            class: 'close-navbar ',
          },
        });
      } else {
        dispatch({
          type: CHANGE_NAVBAR_CLASS_SUCCESS,
          payload: {
            active: true,
            class: 'open-navbar ',
          },
        });
      }
    };
  };
  