/****************************************************************************************
 * Git Credentials Model
 *
 * Description:
 *
 * Git credentials store profile information from the user's Git accounts.
 * 
 * Schema:
 *
 * gitCred = {
 *  name: "Git name",
 *  username: "Git username",
 *  usersecret: "password"
 * }
 *
 * ************************************************************************************/

 export const gitCredModel = (name, username, usersecret) => {
  return (
    {
      name: name,
      username: username,
      usersecret: usersecret
    }
  )
 }
