import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../assets/styles/pages/account.scss';
import UserInfo from '../components/settings/UserInfo';
import UserSettings from '../components/settings/UserSettings';
import { Row, Col } from 'react-bootstrap';
import { listHabitats } from '../actions/habitat.actions';
import {
  updateName,
  removeUser,
  updatePassword,
} from '../actions/user.actions';
import AccountCancelModal from '../components/Modals/AccountCancelModal';
import ToastComponent from '../components/toast/ToastComponent';
import SettingsFooter from '../components/settings/Footer';

const AccountPage = ({
  team,
  error,
  loader,
  message,
  habitats,
  removeUser,
  updateName,
  currentUser,
  listHabitats,
  updatePassword,
}) => {
  const infoName                              = currentUser.name;
  const [name, setName]                       = useState();
  const [show, setShow]                       = useState(false);
  const [toastShow, setToastShow]             = useState(false);
  const [newPassword, setNewPassword]         = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    listHabitats(team);
  }, []);

  const handleToggle = () => {
    setShow(!show);
  };
  
  const handleUpdateName = () => {
    currentUser.name = name;
    updateName(currentUser);
    setName('');
    setToastShow(!loader);
  };
  
  const handleCloseToast = () => {
    setToastShow(false);  
  };
  
  const handleRemoveUser = () => {
    removeUser();
  };

  const handleUpdatePassword = () => {
    const email = currentUser.email;
    updatePassword(email, newPassword);
  };

  return (
    <>
      <Row className="account-ctn">
        <Col sm={12} md={5} lg={5} xl={5} className="border-right">
          <Row>
            <Col sm={12}>
              <UserInfo currentUser={currentUser} name={infoName} />
            </Col>
            <Col sm={12} className="text-center">
            </Col>
            <SettingsFooter />
          </Row>
        </Col>
        <Col sm={12} md={7} lg={7} xl={7} className="account-ctn-right">
          <Col sm={12} className="userSetting-account">
            <ToastComponent
              message={message}
              title="Update Name"
              toastShow={toastShow}
              setToastShow={setToastShow}
              handleCloseToast={handleCloseToast}
            />
            <UserSettings
              name={name}
              error={error}
              setName={setName}
              newPassword={newPassword}
              handleToggle={handleToggle}
              setNewPassword={setNewPassword}
              confirmPassword={confirmPassword}
              handleUpdateName={handleUpdateName}
              setConfirmPassword={setConfirmPassword}
              handleUpdatePassword={handleUpdatePassword}
            />
          </Col>
        </Col>
        <AccountCancelModal
          show={show}
          habitats={habitats}
          handleToggle={handleToggle}
          handleRemoveUser={handleRemoveUser}
        />
      </Row>
    </>
  );
};

const mapState = (state, props) => ({
  team: state.team.team,
  error: state.user.error,
  loader: state.user.loader,
  message: state.user.message,
  currentUser: state.user.user,
  habitats: state.habitat.habitats,
});

const mapDispatch = {
  updateName,
  removeUser,
  listHabitats,
  updatePassword,
};

export default connect(mapState, mapDispatch)(AccountPage);
