import React, { useState } from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import logo from '../../assets/images/Kor-logo.svg';
import { Button } from 'react-bootstrap';
import Moment from 'moment';
import trash from '../../assets/images/icons/trash.svg';
import eye from '../../assets/images/icons/eye-open.svg';
import user from '../../assets/images/icons/UserCircle.svg';

const TeamCard = (props) => {
	const handleDeleteTeam = (teamId) => {
		props.handleGetDetailsTeam(teamId);
		props.handleModalDeleteTeam(teamId)
	}

	const handleDetails = (teamId) => {
		props.setShow(true)
		props.handleGetDetailsTeam(teamId);
	}

	return (
		<div className="team-list-boxes">
			{props.teams && props.teams.map(teams => (
				<div className="team-box" key={teams.id}>
					<div className="team-name-avatar">
						<div className="avatar-team">
							<img src={user} alt='logo'></img>
						</div>
						<div className="info-team-avatar">
							<p className="team-name">{teams.name}</p>
							<p className="team-last-updated"><span>Last updated</span> {Moment(teams.updated_at).format('lll')}</p>
							<div className="owner-team">
								<p>Managed</p>
								<div>
									<p>
										A
									</p>
									{teams.created_by ? (
										<p>
											{teams.created_by}
										</p>
									) : (
										<p>
											Unknown
										</p>
									)}

								</div>
							</div>
						</div>

					</div>

					<div className="team-role-members">
						<div className="members-count">
							<p>{teams.attached_users}</p>
							<p>Members</p>
						</div>
						<div className="members-count">
							<p>{teams.role}</p>
							<p>Role</p>
						</div>
					</div>

					<div className="buttons-team-m">
						{teams.role === "Owner" ? (
							<Button onClick={() => handleDeleteTeam(teams.id)}>
								<img src={trash} /> Delete Team
							</Button>
						) : (
							<Button disabled>
								<img src={trash} /> Delete Team
							</Button>
						)}

						<Button onClick={() => handleDetails(teams.id)}>
							<img src={eye} /> View Details
						</Button>
					</div>
				</div>
			))}
		</div>
	);
}

export default TeamCard;