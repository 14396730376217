import produce from 'immer';
import {
  GET_STEP_FUNCTION_BEGIN, GET_STEP_FUNCTION_SUCCESS, GET_STEP_FUNCTION_ERROR,
  CREATE_STEP_FUNCTION_BEGIN, CREATE_STEP_FUNCTION_SUCCESS, CREATE_STEP_FUNCTION_ERROR,
  UPDATE_STEP_FUNCTION_BEGIN, UPDATE_STEP_FUNCTION_SUCCESS, UPDATE_STEP_FUNCTION_ERROR,
  DELETE_STEP_FUNCTION_BEGIN, DELETE_STEP_FUNCTION_SUCCESS, DELETE_STEP_FUNCTION_ERROR
} from '../actions/types';

const initialState = {
  stateMachines: [],
  loading: false,
  loading_message: '',
  error: null
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case GET_STEP_FUNCTION_BEGIN:
    case CREATE_STEP_FUNCTION_BEGIN:
    case UPDATE_STEP_FUNCTION_BEGIN:
    case DELETE_STEP_FUNCTION_BEGIN:
      draft.loading = true;
      draft.error = null;
      draft.loading_message = action.loading_message;
      return;
    case GET_STEP_FUNCTION_SUCCESS:
      draft.loading = false;
      draft.stateMachines = action.payload.stateMachines;
      return;
    case CREATE_STEP_FUNCTION_SUCCESS:
      draft.loading = false;
      draft.stateMachines = action.payload.stateMachines;
      return;
    case UPDATE_STEP_FUNCTION_SUCCESS:
      draft.loading = false;
      draft.stateMachines = action.payload.stateMachines;
      return;
    case DELETE_STEP_FUNCTION_SUCCESS:
      draft.loading = false;
      draft.stateMachines = action.payload.stateMachines;
      return;
    case DELETE_STEP_FUNCTION_ERROR:
      draft.loading = false;
      draft.error = action.errorRemoveStep;
      return;
    case GET_STEP_FUNCTION_ERROR:
      draft.loading = false;
      draft.error = action.errorGetStepFunc;
      return;
    case CREATE_STEP_FUNCTION_ERROR:
      draft.loading = false;
      draft.error = action.errorAddStepFunc;
      return;
    case UPDATE_STEP_FUNCTION_ERROR:
      draft.loading = false;
      draft.error = action.errorEditStepFunc;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;