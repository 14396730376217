import React from 'react';

const Message = () => {
  return (
    <div className="sign-title">
      <p>
        “Unfortunately KOR Platform is currently not active. We are working on
        bringing you a better version soon. If you have any questions please
        email us at <a href="mailto:support@getkor.io">support@getkor.io</a>”
      </p>
    </div>
  );
};

export default Message;
