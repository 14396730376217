import React from 'react';
import { Toast, Row, Col, Alert } from 'react-bootstrap';

const ToastSuccessError = (props) => {
  return (
    <Row>
      {!props.loading && (
        <Col xs={12}>
          <Toast onClose={() => props.setShow(false)} show={props.show} delay={3000} autohide>
            <Toast.Body>
              {props.success && (
                <Alert variant="success" className="capitalize">
                  {props.success}
                </Alert>
              )}
              {props.error && (
                <Alert variant="danger">
                  {props.error}
                </Alert>
              )}
            </Toast.Body>
          </Toast>
        </Col>
      )}
    </Row>
  );
};

export default ToastSuccessError;