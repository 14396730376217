// Retieve name initials to display as avatar
// Usage:
// avatarInitials("John Doe");
// Out: "JT"
// avatarInitials("Jane Alice Dow");
// Out: "JD"
// avatarInitials("Alice");
// Out: "A"
export const avatarInitials = (name) => {
    let nameArray     = name.split(' ');
    let firstInitial  = '';
    let lastInitial   = '';
    let initials      = '';
    let naLength      = 0;
  
    if(nameArray.length > 0) {
      firstInitial = nameArray[0][0]
      if(nameArray.length > 1) {
        naLength = nameArray.length
        lastInitial = nameArray[naLength - 1][0]
      } else {
        lastInitial = '';
      }
    } else {
      return false
    }
  
    initials = firstInitial + lastInitial;
    return initials;
  }
  
  