import React, { useState } from 'react';
import HabitatAPIMethods from './HabitatAPIMethods';
import { Spinner, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNetworkWired, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const HabitatDynamoAPI = ({
  team,
  funcs,
  error,
  apiId,
  habitat,
  loading,
  methods,
  awsCreds,
  awsRoles,
  cognitos,
  s3Buckets,
  createAPI,
  createRoute,
  selectedAPI,
  methodError,
  deleteMethod,
  createCrudApi,
  loadingMessage,
  createCognitoAPI,
  createCognitoCrudApi
}) => {
  
  const [ nameApi, setNameApi ]                 = useState('');
  const [ nameCrud, setNameCrud ]               = useState('');
  const [ cognitoArn, setCognitoArn ]           = useState('');
  const [ cognitoAuth, setCognitoAuth ]         = useState(false);
  const [ cognitoCrudArn, setCognitoCrudArn ]   = useState('');
  const [ cognitoCrudAuth, setCognitoCrudAuth ] = useState(false);

  const handleNewAPI = () => {
    cognitoAuth ?
      createCognitoAPI(nameApi, habitat.id, cognitoArn) :
      createAPI(nameApi, habitat.id)
  }

  const handleNewCRUDAPI = () => {
    cognitoCrudAuth ?
      createCognitoCrudApi(team, awsRoles, nameCrud, awsCreds, s3Buckets, cognitoCrudArn, habitat.id) :
      createCrudApi(team, awsRoles, nameCrud, awsCreds, s3Buckets, habitat.id);
    setNameCrud('');
  }

  return (
    <div className="habitat-funcs-management-container">

      <div className="title-setup-w-new">
        <div className="title-setup-management-funcs">
          <div className="box-feature-name-icon-api">
            <FontAwesomeIcon icon={faNetworkWired} />
          </div>
          <h5>Create API Gateway</h5>
        </div>
      </div>

      {error && (
        <div className="error-display text-danger">
          <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
        </div>
      )}

      {loading && (
        <div className="spinner-container funcs-habitat-spinner">
          <Spinner animation="border" variant="primary" />
          <p>{loadingMessage}</p>
        </div>
      )}

      {methods && (
        <HabitatAPIMethods
          funcs={funcs}
          apiId={apiId}
          methods={methods}
          habitat={habitat}
          awsRoles={awsRoles}
          awsCreds={awsCreds}
          selectedAPI={selectedAPI}
          createRoute={createRoute}
          methodError={methodError}
          deleteMethod={deleteMethod}
        />
      )}

      <div className="new-function-show new-function-container mt-4">
        <p>New API</p>

        <div className="inputs-new-aws-credential">
          <Form.Control
            type="text"
            aria-label="handler"
            placeholder="API Name"
            aria-describedby="handler"
            onChange={e => setNameApi(e.target.value)}
          />
          <div className="checkbox-ctn d-flex">
            <Button
              variant="outline-primary"
              onClick={() => setCognitoAuth(!cognitoAuth)}
            >
              Add Authentication
            </Button>
          </div>
        </div>
        {cognitoAuth && (
          <div className="cognito-ctn mt-4">
            <Form.Control
              as="select"
              defaultValue={'DEFAULT'}
              onChange={e => setCognitoArn(e.target.value)}
            >
              <option disabled value="DEFAULT">--Select a Cognito--</option>
              {cognitos.map(cognito => (
                <option key={cognito.id} value={cognito.arn}>{cognito.name}</option>
              ))}
            </Form.Control>
          </div>
        )}
        <div className="button-container-aws">
          <Button
            variant="primary"
            size="sm"
            className="newCredBtn-1"
            onClick={handleNewAPI}
          >
            + New API
              </Button>
        </div>

      </div>

      <div className="new-function-show new-function-container mt-4">
        <p>New API CRUD</p>
        <div className="inputs-new-aws-credential mt-4">
          <Form.Control
            type="text"
            aria-label="handler"
            placeholder="CRUD Name"
            aria-describedby="handler"
            onChange={e => setNameCrud(e.target.value)}
          />
          <div className="checkbox-ctn d-flex">
            <Button
              variant="outline-primary"
              onClick={() => setCognitoCrudAuth(!cognitoCrudAuth)}
            >
              Add Authentication
            </Button>
          </div>
        </div>
        {cognitoCrudAuth && (
          <div className="cognito-ctn mt-4">
            <Form.Control
              as="select"
              defaultValue={'DEFAULT'}
              onChange={e => setCognitoCrudArn(e.target.value)}
            >
              <option disabled value="DEFAULT">--Select a Cognito--</option>
              {cognitos.map(cognito => (
                <option key={cognito.id} value={cognito.arn}>{cognito.name}</option>
              ))}
            </Form.Control>
          </div>
        )}
        <div className="button-container-aws">
          <Button
            variant="primary"
            size="sm"
            className="newCredBtn-1"
            onClick={handleNewCRUDAPI}
          >
            + New API CRUD
          </Button>
        </div>
      </div>
    </div>
  );
}

export default HabitatDynamoAPI;