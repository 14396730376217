import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchHabitat } from '../../actions/habitat.actions';
import '../../assets/styles/pages/Habitats.scss';
import HabitatSetUpMenu from './HabitatSetupMenu';

const HabitatSettings = (props) => {
  useEffect(() => {
    if (props.habitat.length === 0) {
      fetchHabitat();
    }  
  }, []);

  return (
    <div className="global-settings-habitat">
      {props.habitat && (
        <>
          <HabitatSetUpMenu habitatSetUp={props.habitatSetUp} />
        </>
      )}
    </div>
  );
};

const mapState = (state, props) => ({});

const mapDispatch = {
  fetchHabitat,
};

export default connect(mapState, mapDispatch)(HabitatSettings);
