import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'

const PopUpOptions = (props) => {
    return(
        <div className={(props.activeClassPop === props.idRepo) ? 'pop-up-display pop-up-tree-file animate__animated animate__fadeIn' : 'pop-up-display-none pop-up-tree-file animate__animated animate__fadeOut'}>
        <div className="gg-shape-triangle"></div>
        <div className="options-pop-up" onClick={props.handleShowDelete}>
          <div className="option-1-pop-up">
            <FontAwesomeIcon icon={faTrashAlt} />
            <p>Delete</p>
          </div>
        </div>
      </div>
    )
}

export default PopUpOptions;