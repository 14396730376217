import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import Moment from 'moment';
import { Form, Button } from 'react-bootstrap';
import { faExclamationCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const HabitatCosts = (props) => {
  const chart2 = {
    series: [44, 55, 13, 43, 22],
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
      colors: [
        '#909aff',
        '#0f2589',
        '#1e7aff',
        '#a5adfa',
        '#bfc6ff'
      ],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  }

  const chart = {
    series: props.costs.dataChart,
    options: {
      chart: {
        type: 'bar',
        height: 100,
        stacked: true,
        toolbar: {
          show: true
        },
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        type: 'date',
        categories: props.costs.timeperiod,
      },
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      },
      colors: [
        '#909aff',
        '#0f2589',
        '#1e7aff',
        '#a5adfa',
        '#bfc6ff',
        '#2a3a80',
        '#374582',
        '#596dc2'

      ]
    },
  };

  const chart3 = {
    series: [{
      name: '',
      data: [0, 0, 0, 0, 0, 0]
    }, {
      name: '',
      data: [0, 0, 0, 0, 0, 0]
    }, {
      name: '',
      data: [0, 0, 0, 0, 0, 0]
    }, {
      name: '',
      data: [0, 0, 0, 0, 0, 0]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 100,
        stacked: true,
        toolbar: {
          show: true
        },
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        type: 'date',
        categories: ['0', '1', '2', '3'],
      },
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      },
      colors: [
        '#909aff',
        '#0f2589',
        '#1e7aff',
        '#a5adfa',
        '#bfc6ff',
        '#2a3a80',
        '#374582',
        '#596dc2'

      ]
    },
  };

  const chart4 = {
    series: props.costs.pieChart.percents,
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: props.costs.pieChart.labels,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          },
        }
      }],
      colors: [
        '#909aff',
        '#0f2589',
        '#1e7aff',
        '#a5adfa',
        '#bfc6ff',
        '#2a3a80',
        '#374582',
        '#596dc2'
      ]
    },
}




  useEffect(() => {
  }, [])

  return (
    <div className="cost-container">
    <div className="costs-cont">
      <div className="title-habitat-costs">
        <p>Habitat Costs</p>
      </div>

      <div className="error-display text-danger">
        {props.error && (
          <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {props.error.error}</p>
        )}
      </div>
      <div className="error-display text-warning">
        {props.costs.total === 0 && !props.error && (
          <p><FontAwesomeIcon icon={faExclamationCircle} /> Alert: You don&apos;t have any data to show right now.</p>
        )}
      </div>
      
      {props.loading && (
      <div className="spinner-container habitat-costs-loader">
        <Spinner animation="border" variant="primary" />
        <p>{props.loadingMessage}</p>
      </div>
      )}


      <div className="dates-from-to">
        <p>From</p>
        <Form.Control
          type="date"
          className="logs-selectbox"
          onChange={(s) =>
            props.setStartDate(Moment(s.target.value).format('YYYY-MM-DD'))
          }
        />
        <p>To</p>
        <Form.Control
          type="date"
          className="logs-selectbox"
          onChange={(s) =>
            props.setEndDate(Moment(s.target.value).format('YYYY-MM-DD'))
          }
        />
        <Form.Control
          className="metric-selectbox"
          as="select"
          defaultValue={props.granularity}
          onChange={e => props.setGranularity(e.target.value)}
        >
          <option value="DAILY">Daily</option>
          <option value="MONTHLY">Monthly</option>
        </Form.Control>
        <Button
          variant="primary"
          size="sm"
          className="btn-search-deployed"
          onClick={() => props.handleGetCost()}
        >
          Search
        </Button>
      </div>

      <div className="charts-container">

        <div className="cont-chart-1">
          {props.costs.amount.length > 0 ? (
            <Chart
              options={chart.options}
              series={chart.series}
              type="bar"
              width="700"
            />
          ) : (
              <Chart
                options={chart3.options}
                series={chart3.series}
                type="bar"
                width="700"
              />
            )}

            {props.costs.amount.length > 0 && (
              <div className="pie-chart">
              <Chart
                options={chart4.options}
                series={chart4.series}
                type="pie"
                width="530"
              />
              </div>
            )}
        </div>
      </div>
    </div>

    <div className="cont-chart-2">
    <div className="title-habitat-costs">
        <p>Summary</p>
      </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Service</th>
                <th scope="col">Total (USD)</th>
                <th scope="col">Percent (%)</th>
              </tr>
            </thead>
            <tbody>
              {props.costs.totalAmount.length > 0 && (
                props.costs.totalAmount.map(amountItem => (
                  <tr key={amountItem.name}>
                    <td><b>{amountItem.name}</b></td>
                    <td>${amountItem.total}</td>
                    <td>{amountItem.percents}%</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          <div className="total-costs">
            <p><b>Total Amount: ${props.costs.total}USD</b></p>
          </div>

          <div className="question-mark-documentation-habitat">
            <p>Why my cost is not showing anything?</p>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip className="red-tooltip">
                    <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                  </Tooltip>
                }

              >
                <a
                  href="https://docs.aws.amazon.com/awsaccountbilling/latest/aboutv2/ce-enable.html"
                  rel="noopener noreferrer"
                  className="help"
                  target="_blank"
                >

                  <FontAwesomeIcon icon={faQuestionCircle} />
                </a>
              </OverlayTrigger>
            </div>
        </div>
    </div>

  )
}


export default HabitatCosts