import React from 'react';
import routes from '../constants/routes.json';
import { connect } from 'react-redux';
import SettingsLayout from './SettingsLayout';
import { Route, Redirect } from 'react-router-dom';

// App Layout Route
const SettingsLayoutRoute = ({
    component: Component,
    currentUser,
    ...rest
}) => {    
    return (
        <Route {...rest} render={matchProps => (    
            currentUser ?                
            <SettingsLayout>
                <Component {...matchProps} />
            </SettingsLayout>
            : <Redirect to={{ pathname: routes.SIGNIN }} />
        )} />
    )
};

const mapState = (state, props) => {    
    return {
        currentUser: state.user.user,
        localtion: props.location        
    };
};
export default connect(mapState)(SettingsLayoutRoute);
  
