import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import routes from '../constants/routes';
import Background from '../assets/images/NotFoundBackground.png';
import '../assets/styles/components/notfound.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const NotFoundPage = withRouter(({ history }) => {
  const GoBackToOverview = (e) => {
    e.preventDefault();
    history.push(routes.OVERVIEW);
  };

  return (
    <div>
      <img
        className="background"
        src={Background}
        alt="Not Found Page background"
      />
      <div className="error-message">
        <h1 id="error-title1">PAGE NOT FOUND</h1>
        <h2 id="error-title2">4O4</h2>
        <p id="error-text">
          <FontAwesomeIcon icon={faExclamationTriangle} id="notFoundIcon" />
          The page you are looking for might have been removed,
          <br />
          had its name changed, or is temporarily unavailable.
        </p>
        <Button className="goback-btn" onClick={GoBackToOverview}>
          Go Back
        </Button>
      </div>
    </div>
  );
});

const mapState = (props, state) => ({
  history: props.history,
});
const mapDispatch = {};
export default connect(mapState, mapDispatch)(NotFoundPage);
