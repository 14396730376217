import produce from 'immer';

import {
  PAYMENT_BEGIN,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
  GET_USER_PAYMENT_BEGIN, 
  GET_USER_PAYMENT_SUCCESS,
  GET_USER_PAYMENT_ERROR, 
  GET_STARTUP_PAYMENT_SUCCESS,
  DOWNGRADE_BEGIN,
  DOWNGRADE_SUCCESS,
  DOWNGRADE_ERROR
} from '../actions/types';

const initialState = {
  proPayment: [],
  proPayments: [],
  loading: false,
  notLoad: false,
  error: null,
  downSuccess: false
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case GET_USER_PAYMENT_BEGIN:
      draft.loading     = true;
      draft.error       = null;
      return;
    case PAYMENT_BEGIN:
      draft.loading     = true;
      draft.notLoad     = true;
      draft.error       = null;
      return;
    case PAYMENT_SUCCESS:
      draft.loading     = false;
      draft.notLoad     = false;
      draft.proPayment  = action.payload;
      return;
    case GET_USER_PAYMENT_SUCCESS:
      draft.loading     = false
      draft.proPayment  = action.payload;
      return;
    case GET_USER_PAYMENT_ERROR:
      draft.loading     = false;
      draft.error       = action.error;
      return;
    case PAYMENT_ERROR:    
      draft.loading     = false;
      draft.notLoad     = false;
      draft.error       = action.error;
      return;
    case GET_STARTUP_PAYMENT_SUCCESS:
      draft.loading     = false
      draft.proPayments = action.payload;
        return;
    case DOWNGRADE_BEGIN:
      draft.loading     = true;
      draft.error       = null;
      draft.downSuccess = false;
      return;
    case DOWNGRADE_SUCCESS:
      draft.loading     = false;
      draft.notLoad     = false;
      draft.downSuccess = true;
      draft.proPayment  = action.payload;
      return;
    case DOWNGRADE_ERROR:    
      draft.loading     = false;
      draft.notLoad     = false;
      draft.downSuccess = false;
      draft.error       = action.error;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;
