import React from 'react';
import '../../assets/styles/components/billing/billing-table.scss';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimesCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

const BillingTable = ({ billing }) => {
  return (
    <div className="billing-history-containter-1">
      <p>
        <b>Billing History</b>
      </p>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Paid</th>
            <th scope="col">Amount</th>
            <th scope="col">Invoice</th>
          </tr>
        </thead>
        <tbody>
          {billing &&
            billing.map((bill) => (
              <tr key={bill.id}>
                <td>{Moment(bill.created, 'X').format('l')}</td>
                <td>
                  {(Moment(bill.created, 'X').add(30, 'day').format() > Moment().format()) ? (
                    <span className="text-info">Free trial</span>
                  ) : (
                    bill.paid ? (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="ml-2 text-success"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="ml-2 text-danger"
                      />
                    )
                  )}
                </td>
                <td>
                  <span className="mr-2">{bill.currency.toUpperCase()}</span> 
                  {(bill.total / 100)}
                </td>
                <td>
                  {bill.invoice_pdf && (
                    <a 
                      href={bill.invoice_pdf} 
                      rel="noopener noreferrer"
                      target="_blank" 
                    >PDF</a>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default BillingTable;
