import {
    CREATE_AWS_COGNITO_BEGIN,
    CREATE_AWS_COGNITO_SUCCESS,
    CREATE_AWS_COGNITO_ERROR,
    GET_LIST_AWS_COGNITO_BEGIN,
    GET_LIST_AWS_COGNITO_SUCCESS,
    GET_LIST_AWS_COGNITO_ERROR,
    DELETE_AWS_COGNITO_BEGIN,
    DELETE_AWS_COGNITO_SUCCESS,
    DELETE_AWS_COGNITO_ERROR,
    LIST_USERS_COGNITO_BEGIN,
    LIST_USERS_COGNITO_SUCCESS,
    LIST_USERS_COGNITO_ERROR,
    DISABLE_USER_COGNITO_BEGIN,
    DISABLE_USER_COGNITO_SUCCESS,
    DISABLE_USER_COGNITO_ERROR,
    DELETE_USER_COGNITO_BEGIN,
    DELETE_USER_COGNITO_SUCCESS,
    DELETE_USER_COGNITO_ERROR,
    RESET_USER_COGNITO_BEGIN,
    RESET_USER_COGNITO_SUCCESS,
    RESET_USER_COGNITO_ERROR,
    ENABLE_USER_COGNITO_BEGIN,
    ENABLE_USER_COGNITO_SUCCESS,
    ENABLE_USER_COGNITO_ERROR,
  } from './types';
  import {
    newCognito,
    listUsers,
    deleteUser,
    listPool,
    deletePool,
    disableUser,
    resetUser,
  } from '../api/aws/cognito';
  
  export const createUserPool = (cognitoName, habitatId) => {
    return (dispatch) => {
      dispatch({
        type: CREATE_AWS_COGNITO_BEGIN,
        loading_message: 'Creating User Pool...',
      });
      newCognito(cognitoName, habitatId)
        .then((response) => {
          const lastCognito = response.data.historical;
          dispatch({
            type: CREATE_AWS_COGNITO_SUCCESS,
            payload: lastCognito,
          });
        })
        .catch((error) => {
          const error_message = 'Error at: Congito Creation';
          dispatch({
            type: CREATE_AWS_COGNITO_ERROR,
            error: error_message,
          });
        });
    };
  };
  
  export const getUsers = (poolId, habitatId) => {
    return (dispatch) => {
      dispatch({
        type: LIST_USERS_COGNITO_BEGIN,
        loading_message: 'Loading users...',
      });
      listUsers(poolId, habitatId)
        .then((response) => {
          dispatch({
            type: LIST_USERS_COGNITO_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          const error_message = 'Error at: Get List Users';
          dispatch({
            type: LIST_USERS_COGNITO_ERROR,
            error: error_message,
          });
        });
    };
  };
  
  export const deleteCognito = (poolId, habitatId) => {
    return (dispatch) => {
      dispatch({
        type: DELETE_AWS_COGNITO_BEGIN,
        loading_message: 'Deleting user pool...',
      });
      deletePool(poolId, habitatId)
        .then((response) => {
          dispatch({
            type: DELETE_AWS_COGNITO_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: DELETE_AWS_COGNITO_ERROR,
            errorDeleteCognito: 'Delete cognito fail',
            error,
          });
        });
    };
  };
  
  export const listCognito = (habitatId) => {
    return (dispatch) => {
      dispatch({
        type: GET_LIST_AWS_COGNITO_BEGIN,
        loading_message: 'Loading user pools...',
      });
      listPool(habitatId)
        .then((response) => {
          dispatch({
            type: GET_LIST_AWS_COGNITO_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_LIST_AWS_COGNITO_ERROR,
            errorlistCognito: 'Get cognitos fail',
            error,
          });
        });
    };
  };
  
  export const disableUsers = (userPoolId, habitatId, username) => {
    const data = {
      user_pool_id: userPoolId,
      habitat_id: habitatId,
      user_name: username,
      enable: false,
    };
    return (dispatch) => {
      dispatch({
        type: DISABLE_USER_COGNITO_BEGIN,
        loading_message: 'Disabling user...',
      });
      disableUser(data)
        .then((response) => {
          dispatch({
            type: DISABLE_USER_COGNITO_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: DISABLE_USER_COGNITO_ERROR,
            error: 'Error at: disabled user',
          });
        });
    };
  };
  
  export const enableUsers = (userPoolId, habitatId, username) => {
    const data = {
      user_pool_id: userPoolId,
      habitat_id: habitatId,
      user_name: username,
      enable: true,
    };
  
    return (dispatch) => {
      dispatch({
        type: ENABLE_USER_COGNITO_BEGIN,
        loading_message: 'Enabling user...',
      });
      disableUser(data)
        .then((response) => {
          dispatch({
            type: ENABLE_USER_COGNITO_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: ENABLE_USER_COGNITO_ERROR,
            error: 'Error at: enabling user',
          });
        });
    };
  };
  
  export const resetPassword = (userPoolId, habitatId, username, password) => {
    const data = {
      user_pool_id: userPoolId,
      habitat_id: habitatId,
      user_name: username,
      password: password,
      permanent: true,
    };
    return (dispatch) => {
      dispatch({
        type: RESET_USER_COGNITO_BEGIN,
        loading_message: 'Reseting password...',
      });
      resetUser(data)
        .then((response) => {
          dispatch({
            type: RESET_USER_COGNITO_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: RESET_USER_COGNITO_ERROR,
            error: 'Error at: Reseting password',
          });
        });
    };
  };
  