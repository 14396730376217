import { combineReducers } from 'redux';
import team from './team';
import habitat from './habitat';
import payment from './payment';
import user from './user';
import func from './funcs';
import invitation from './invitation';
import navbar from './navbar';
import deployment from './deployment';
import awsCreds from './aws-creds';
import awsSdk from './aws-sdk';
import awsS3 from './aws-s3';
import settings from './settings';
import checkout from './checkout';
import stepFunc from './step-func';
import gitCreds from './git-creds';
import gitClone from './git-clone';
import awsRoles from './aws-roles';
import serverStatus from './server-status';
import appTour from './app-tour';
import billing from './billing';
import templates from './templates';
import awsDynamo from './aws-dynamo';
import route53 from './aws-route53';
import awsCodeBuild from './aws-code-build';
import apiGateway from './aws-apigateway';
import cognito from './aws-cognito';
import awsRds from './aws-rds';
import instances from './aws-ec2';

export default combineReducers({
    team,
    user,
    func,
    awsS3,
    awsSdk,
    awsRds,
    navbar,
    payment,
    habitat,
    appTour,
    route53,
    cognito,
    billing,
    settings,
    awsCreds,
    checkout,
    stepFunc,
    gitCreds,
    gitClone,
    awsRoles,
    awsDynamo,
    templates,
    instances,
    invitation,
    apiGateway,
    deployment,    
    awsCodeBuild,
    serverStatus,
});