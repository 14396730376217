import React from 'react';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import HabitatDeployAll from '../../components/Habitats/HabitatDeployAll';

const HabitatRelasesTitle = (props) => {
  return (
    <div className="title-deployments">
      <div className="flex-funcs-title">
        <div className="question-mark-documentation-releases">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className="red-tooltip">
                <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
              </Tooltip>
            }
          >
            <a
              href="https://getkor.freshdesk.com/support/solutions/articles/47001164312-functions"
              rel="noopener noreferrer"
              className="help"
              target="_blank"
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
            </a>
          </OverlayTrigger>
        </div>

        {props.featureSelected === 'funcs' ? (
          <>
            <p className="funcs-title-releases active-funcs" onClick={() => props.handleChangeActive('funcs')}>Functions</p>
            <p className="sites-title-releases inactive" onClick={() => props.handleChangeActive('sites')}>Sites</p>
          </>
        ) : (
            <>
            <p className="funcs-title-releases inactive" onClick={() => props.handleChangeActive('funcs')}>Functions</p>
            <p className="sites-title-releases active-sites" onClick={() => props.handleChangeActive('sites')}>Sites</p>
            </>
          )}


      </div>
    </div>
  )
}

export default HabitatRelasesTitle;