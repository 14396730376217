import React, { useState } from 'react';
import { connect } from 'react-redux';
import DeployLogModal from '../Modals/DeployLogModal';
import HabitatSaveTestModal from './modals/HabitatSaveTestModal';
import { Button } from 'react-bootstrap';
import { deleteDeployment, allDeployments } from '../../actions/deployment.actions';
import { getAwsLogs, getAwsLog } from '../../actions/aws-sdk.actions';
import { updateFunction, funcInvoke, saveTest, deploy } from '../../actions/funcs.actions';
import { withRouter } from 'react-router-dom';
import { nextStepTour, stopTour } from '../../actions/app-tour.actions';
import routes from '../../constants/routes';
import HabitatDeployLogItem from './HabitatDeployLogItem';
import HabitatListTests from './HabitatListTests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTerminal,
  faInfinity,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';

const HabitatDeployItem = withRouter(({
  log,
  func,
  logs,
  team,
  deploy,
  invoke,
  habitat,
  history,
  loading,
  funcTest,
  saveTest,
  getTests,
  logsFunc,
  getAwsLog,
  getAwsLogs,
  funcInvoke,
  stepAppTour,
  nextStepTour,
  updateFunction,
  handleLogsFunc,
  loadingMessage,
}) => {
  const [show, setShow] = useState(false);
  const [type, setType] = useState('');
  const [payload, setPayload] = useState('');
  const [testName, setTestName] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [showLogs, setShowLogs] = useState('display-log');
  const [showAllLog, setShowAllLog] = useState('display-all-log')
  const [showInvoke, setShowInvoke] = useState('display-invoke-deploy')
  const [showListTest, setShowListTest] = useState(false);


  const handleToggle = () => {
    setModalShow(!modalShow);
  }

  const handleTestToggle = () => {
    setShowListTest(!showListTest);
  }
  
  const handleSaveTest = () => {
    saveTest(func.id, testName, payload);
    setModalShow(false);
  }

  const handleInvoke = () => {
    funcInvoke(func, habitat.id, type, payload);
  }

  const handleSetTest = (test) => {
    setPayload(JSON.stringify(test.payload));
  }

  const handleDeploy = (func) => {
    deploy(func.id);
    if (stepAppTour === 14) {
      nextStepTour();
      history.push(routes.OVERVIEW);
    }
  }

  const handleShowLogs = () => {
    if (showLogs === 'display-log') {
      setShowLogs('display-log-show')
    } else {
      setShowLogs('display-log')
    }
  }


  const handleShowInvoke = () => {
    if (showInvoke == 'display-invoke-deploy') {
      setShowInvoke('display-invoke-deploy-show')
    } else {
      setShowInvoke('display-invoke-deploy')
    }
  }

  const handleGetLog = (logStreamName) => {
    if (showAllLog == 'display-all-log') {
      setShowAllLog('display-all-log-show')
    } else {
      setShowAllLog('display-all-log')
    }
    getAwsLog(habitat.id, func.function_name, logStreamName);
  }


  return (
    <div>

      <div className="box-func-deployed" key={func.id}>
        <div>
          <div className="name-func-deployed">
            <p>{func.function_name}</p>
            <p>{func.created_at}</p>
          </div>

          <div className="info-func-deployed">

            <div className="info-func-deployed-box success-color">
              <p className="success-color"><FontAwesomeIcon icon={faCheckCircle} /> </p>
              <p className="success-color">Successful</p>
            </div>

            <div className="info-func-deployed-box" onClick={handleShowLogs}>
              <p className="logs-text"><FontAwesomeIcon icon={faTerminal} /> </p>
              <p className="logs-text" onClick={() => handleLogsFunc(func.function_name)}>Deployment</p>
            </div>

            <div className="info-func-deployed-box" onClick={handleShowLogs}>
              <p className="logs-text"><FontAwesomeIcon icon={faInfinity} /> </p>
              <p className="logs-text" onClick={() => handleTestToggle()}>Save tests</p>
            </div>

            <div className="info-func-deployed-box">
              <Button variant="outline-warning btn-search-deployed" size="sm" onClick={handleShowInvoke}>Test Function</Button>
            </div>


          </div>

          <div className={showLogs + ' logs-funcs-deploy'}>
            {logsFunc.map((log) =>
              <>
                <HabitatDeployLogItem
                  showAllLog={showAllLog}
                  setShowAllLog={setShowAllLog}
                  key={log.id}
                  log={log}
                  handleGetLog={handleGetLog}
                />
              </>
            )}

          </div>

          {showListTest && (
            <div className="display-log-show logs-funcs-deploy">
              <HabitatListTests 
                funcId={func.id}
                loading={loading}
                funcTest={funcTest}
                getTests={getTests}
                handleSetTest={handleSetTest}
                loadingMessage={loadingMessage}
              />
            </div>
          )}

          <div className={showInvoke + ' test-function-deployment'}>
            <p><b>Invocation Message</b></p>
            <DeployLogModal
              log={log}
              func={func}
              logs={logs}
              show={show}
              type={type}
              invoke={invoke}
              payload={payload}
              setShow={setShow}
              setType={setType}
              loading={loading}
              habitat={habitat}
              history={history}
              getAwsLog={getAwsLog}
              setPayload={setPayload}
              getAwsLogs={getAwsLogs}
              handleToggle={handleToggle}
              handleInvoke={handleInvoke}
            />
          </div>
        </div>
      </div>
      {modalShow && (
        <div className="modal-kor-container">
          <HabitatSaveTestModal 
            testName={testName}
            setShow={setModalShow}
            setTestName={setTestName}
            handleSaveTest={handleSaveTest}
          />
        </div>
      )}       
    </div>
  )

})

const mapState = (state, props) => ({
  func: props.func,
  index: props.index,
  log: state.awsSdk.log,
  team: state.team.team,
  history: props.history,
  logs: state.awsSdk.logs,
  invoke: state.func.invoke,
  loading: state.func.loading,
  funcTest: state.func.funcTest,
  habitat: state.habitat.habitat,
  logsFunc: state.awsSdk.logFunc,
  gitClones: state.gitClone.gitClones,
  handleLogsFunc: props.handleLogsFunc,
  stepAppTour: state.appTour.stepAppTour,
  statusAppTour: state.appTour.stateAppTour,
  handleFilterFuncs: props.handleFilterFuncs,
  loadingMessage: state.func.loading_message
})

const mapDispatch = {
  deploy,
  saveTest,
  stopTour,
  getAwsLog,
  funcInvoke,
  getAwsLogs,
  nextStepTour,
  updateFunction,
  allDeployments,
  deleteDeployment,
}

export default connect(
  mapState,
  mapDispatch
)(HabitatDeployItem);
