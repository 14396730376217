import produce from 'immer';

import {
  CREATE_HABITAT_BEGIN, 
  CREATE_HABITAT_SUCCESS, 
  CREATE_HABITAT_ERROR,    
  GET_ALL_HABITATS_BEGIN, 
  GET_ALL_HABITATS_SUCCESS, 
  GET_ALL_HABITATS_ERROR,
  DELETE_HABITAT_BEGIN,
  DELETE_HABITAT_SUCCESS,
  DELETE_HABITAT_ERROR,
  GET_HABITAT_BEGIN,
  GET_HABITAT_SUCCESS,
  GET_HABITAT_ERROR,
  UPDATE_HABITAT_BEGIN,
  UPDATE_HABITAT_SUCCESS,
  UPDATE_HABITAT_ERROR,
  SELECT_HABITAT_BEGIN,
  SELECT_HABITAT_SUCCESS,
  SELECT_HABITAT_ERROR,
  SELECT_CREDS_HAB_BEGIN,
  SELECT_CREDS_HAB_SUCCESS,   
  SELECT_CREDS_HAB_ERROR,
  CONNECT_HAB_S3_BEGIN,
  CONNECT_HAB_S3_SUCCESS,
  CONNECT_HAB_S3_ERROR,
  DISCONNECT_HAB_S3_BEGIN,
  DISCONNECT_HAB_S3_SUCCESS,
  DISCONNECT_HAB_S3_ERROR,
  HABITAT_SETUP_BEGIN, 
  HABITAT_SETUP_SUCCESS, 
  HABITAT_SETUP_ERROR,
  GET_ALL_CONNECTED_S3_BEGIN,
  GET_ALL_CONNECTED_S3_SUCCESS,
  GET_ALL_CONNECTED_S3_ERROR,
  REQUEST_DELETE_HABITAT_BEGIN,
  REQUEST_DELETE_HABITAT_SUCCESS,
  REQUEST_DELETE_HABITAT_ERROR,
  GET_HABITAT_COSTS_BEGIN, 
  GET_HABITAT_COSTS_SUCCESS, 
  GET_HABITAT_COSTS_ERROR,
  GET_HABITAT_COSTS_ACTIVATION_BEGIN, 
  GET_HABITAT_COSTS_ACTIVATION_SUCCESS, 
  GET_HABITAT_COSTS_ACTIVATION_ERROR
} from '../actions/types';

const initialState = {
  habitats: [],
  habitat: [],  
  habitat_s3s: [],
  habitatSetup: '',
  s3s_loading: false,
  error_message: '',
  s3s_connect_loading: false,
  s3s_connect_error: null,  
  s3s_disconnect_loading: false,
  s3s_disconnect_error: null,
  remove_habitat_request: {},
  resources_habitat: {},
  s3s_error: null,
  costsActivation: false,
  loading_message: '',
  costs: {
    allCosts: {},
    timeperiod: {},
    costGroups: {},
    amount: [],
    dataChart: {},
    totalAmount: [],
    total: 0.00,
    pieChart: []
  },
  loading: false,
  error: null,
  error_costs: null,
};

function remove(array, key, value) {
    const index = array.findIndex(obj => obj[key] === value);
    return index >= 0 ? [
        ...array.slice(0, index),
        ...array.slice(index + 1)
    ] : array;
}
  
function update(array, key, value) {    
    var foundIndex = array.findIndex(obj => obj[key] === value.id);
    array[foundIndex] = value;
    return array;
}

const reducer = produce((draft, action) => {
  switch (action.type) {    
    case GET_ALL_CONNECTED_S3_BEGIN:
      draft.s3s_loading = true;
      draft.s3s_error = null;
      draft.loading_message = action.loading_message;
      return;
    case DISCONNECT_HAB_S3_BEGIN:
      draft.s3s_disconnect_loading = true;
      draft.s3s_disconnect_error = null;
      draft.loading_message = action.loading_message;
      return;
    case CONNECT_HAB_S3_BEGIN:
      draft.s3s_connect_loading = true;
      draft.s3s_connect_error = null;
      draft.loading_message = action.loading_message;
      return;
    case SELECT_CREDS_HAB_BEGIN:
    case SELECT_HABITAT_BEGIN:
    case UPDATE_HABITAT_BEGIN:
    case GET_HABITAT_BEGIN:
    case DELETE_HABITAT_BEGIN:
    case CREATE_HABITAT_BEGIN:    
    case GET_ALL_HABITATS_BEGIN:
    case REQUEST_DELETE_HABITAT_BEGIN:
      draft.loading = true;
      draft.error = null;
      draft.resources_habitat = {};
      draft.loading_message = action.loading_message;
      return;
    case HABITAT_SETUP_BEGIN:
    case GET_HABITAT_COSTS_BEGIN:
    case GET_HABITAT_COSTS_ACTIVATION_BEGIN: 
        draft.loading = true;
        draft.loading_message = action.loading_message;
        draft.error = null;
        return;
    case GET_ALL_HABITATS_SUCCESS:      
        draft.loading = false;      
        draft.habitats = action.payload.habitats;
        draft.error = null;
        return;    
    case CREATE_HABITAT_SUCCESS:
        draft.loading = false;
        draft.habitats.push(action.payload);
        draft.habitat = action.payload;
        draft.error = null;
        return;
    case SELECT_HABITAT_SUCCESS: 
    case GET_HABITAT_SUCCESS:
        draft.loading = false;
        draft.habitat = action.payload;
        draft.error = null;
        return;
    case SELECT_CREDS_HAB_SUCCESS:
        draft.loading = false;
        draft.habitat = action.payload;
        draft.habitats = update(draft.habitats, 'id', action.payload);
        draft.error = null;
        return;
    case REQUEST_DELETE_HABITAT_SUCCESS:
      draft.loading = false;
      draft.remove_habitat_request = action.payload;
      return;
    case UPDATE_HABITAT_SUCCESS:
        draft.loading = false;
        draft.habitats = update(draft.habitats, 'id', action.payload)
        draft.error = null;
        return;
    case DELETE_HABITAT_SUCCESS:
        draft.loading = false;
        draft.habitat = [];
        draft.habitats = remove(draft.habitats, 'id', action.payload)
        draft.error = null;
        return;
    case HABITAT_SETUP_SUCCESS:
        draft.habitatSetup = action.payload;
        draft.loading = false;
        return;
    case GET_ALL_CONNECTED_S3_SUCCESS:
        draft.s3s_loading = false;
        draft.habitat_s3s = action.payload.aws_s3s;
        draft.s3s_error = null;
        return;
    case DISCONNECT_HAB_S3_SUCCESS:
      draft.s3s_disconnect_loading = false;
      draft.habitat_s3s = remove(draft.habitat_s3s, 'name', action.payload)
      draft.s3s_disconnect_error = action.error;
      return;
    case CONNECT_HAB_S3_SUCCESS:
      draft.s3s_connect_loading = false;
      draft.habitat_s3s.push(action.payload)
      draft.s3s_connect_error = action.error;
      return;
    case GET_HABITAT_COSTS_SUCCESS:
      draft.loading = false;
      draft.costs.timeperiod = action.time;
      draft.costs.costGroups = action.costGroup;
      draft.costs.amount = action.amount;
      draft.costs.dataChart = action.dataChart;
      draft.costs.totalAmount = action.totalAmount;
      draft.costs.allCosts = action.payload;
      draft.costs.total = action.totalCosts;
      draft.costs.pieChart = action.pieChart;
      draft.error_costs = null
      return;
    case GET_HABITAT_COSTS_ACTIVATION_SUCCESS:
      draft.loading = false;
      draft.costsActivation = action.payload;
      return;
    case GET_HABITAT_COSTS_ACTIVATION_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return;
    case GET_ALL_CONNECTED_S3_ERROR:
      draft.s3s_loading = false;
      draft.s3s_error = action.error;
      return;
    case DISCONNECT_HAB_S3_ERROR:
      draft.s3s_disconnect_loading = false;
      draft.s3s_disconnect_error = action.error;
      return;
    case CONNECT_HAB_S3_ERROR:
      draft.s3s_connect_loading = false;
      draft.s3s_disconnect_error = action.error;
      return;
    case SELECT_CREDS_HAB_ERROR:
    case SELECT_HABITAT_ERROR:
    case GET_ALL_HABITATS_ERROR:   
    case HABITAT_SETUP_ERROR:
        draft.loading = false;
        draft.error = action.error;      
        return;
    case REQUEST_DELETE_HABITAT_ERROR:
      draft.loading = false;
      draft.remove_habitat_request = action.error.response.data;
      draft.resources_habitat = action.resources;
      return;
    case GET_HABITAT_COSTS_ERROR:
        draft.loading = false;
        if(action.error.response){
          draft.error_costs = action.error.response.data
        }
        draft.costs.amount = []
        draft.costs.totalAmount = []
        draft.costs.total = 0.00
        draft.error = action.error; 
        draft.costs.pieChart = []   
        return;
    case CREATE_HABITAT_ERROR:
      draft.loading = false;
      draft.error = action.errorCreateHabitat;
      draft.error_message = action.errorCreateHabitat;
      return;
    case UPDATE_HABITAT_ERROR:
      draft.loading = false;
      draft.error = action.errorRenameHabitat;
      draft.error_message = action.errorRenameHabitat;
      return;
    case DELETE_HABITAT_ERROR:
      draft.loading = false;
      draft.error = action.errorDeleteHabitat;
      draft.error_message = action.errorDeleteHabitat;
      return;
    case GET_HABITAT_ERROR:
      draft.loading = false;
      draft.error = action.errorShowHabitats;
      draft.error_message =  action.errorShowHabitats;
      return;
    default:
        return;
  }
}, initialState);

export default reducer;