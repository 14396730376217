import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationItem = (props) => {
    let active = props.page;
    let itemsPage = [];
    for( let number = 0; number < props.pagesDeploymentHistory; number++){
        itemsPage.push(
            <Pagination.Item key={number} active={number===active} onClick={() => props.handlePaginationNumber(number)}>
                {number+1}
            </Pagination.Item>
        )
    }

    return(
        <div className="number-items-container">
            {itemsPage}
        </div>
    )
}

export default PaginationItem;