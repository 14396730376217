import {
  GET_RDS_DB_BEGIN,
  GET_RDS_DB_SUCCESS,
  GET_RDS_DB_ERROR,
  DELETE_RDS_DB_BEGIN,
  DELETE_RDS_DB_SUCCESS,
  DELETE_RDS_DB_ERROR,
  GET_AURORA_DB_BEGIN,
  GET_AURORA_DB_SUCCESS,
  GET_AURORA_DB_ERROR,
  CREATE_RDS_DB_BEGIN,
  CREATE_RDS_DB_SUCCESS,
  CREATE_RDS_DB_ERROR,
  CREATE_AURORA_DB_BEGIN,
  CREATE_AURORA_DB_SUCCESS,
  CREATE_AURORA_DB_ERROR,
  DELETE_AURORA_DB_BEGIN,
  DELETE_AURORA_DB_SUCCESS,
  DELETE_AURORA_DB_ERROR,
} from './types';

import {
  createAurora,
  deleteAurora,
  getAuroras,
  createRds,
  deleteRds,
  getRds,
} from '../api/aws/rds';

export const addAurora = (
  port,
  engine,
  password,
  habitat_id,
  database_name,
  engine_version,
  master_username,
  db_cluster_identifier
) => {
  const data = {
    port,
    engine,
    password,
    habitat_id,
    database_name,
    engine_version,
    master_username,
    db_cluster_identifier,
  };
  return (dispatch) => {
    dispatch({
      type: CREATE_AURORA_DB_BEGIN,
      loading_message: 'Creating Aurora...',
    });
    createAurora(data)
      .then((response) => {
        const payload = response.data.historical;
        dispatch({
          type: CREATE_AURORA_DB_SUCCESS,
          payload,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_AURORA_DB_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const fetchAurora = (habitat_id) => {
  return (dispatch) => {
    dispatch({
      type: GET_AURORA_DB_BEGIN,
      loading_message: 'Loading Auroras...',
    });
    getAuroras(habitat_id)
      .then((response) => {
        dispatch({
          type: GET_AURORA_DB_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_AURORA_DB_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const removeAurora = (habitat_id, id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_AURORA_DB_BEGIN,
      loading_message: 'Deleting Aurora...',
    });
    deleteAurora(habitat_id, id)
      .then((response) => {
        dispatch({
          type: DELETE_AURORA_DB_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_AURORA_DB_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const addRds = (
  port,
  name,
  engine,
  db_name,
  storage,
  password,
  habitat_id,
  engine_version,
  master_username,
  db_instance_class
) => {
  const data = {
    port,
    name,
    engine,
    db_name,
    storage,
    password,
    habitat_id,
    engine_version,
    master_username,
    db_instance_class,
  };
  return (dispatch) => {
    dispatch({
      type: CREATE_RDS_DB_BEGIN,
      loading_message: 'Creating RDS...',
    });
    createRds(data)
      .then((response) => {
        const payload = response.data.historical;
        dispatch({
          type: CREATE_RDS_DB_SUCCESS,
          payload,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_RDS_DB_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const fetchRds = (habitat_id) => {
  return (dispatch) => {
    dispatch({
      type: GET_RDS_DB_BEGIN,
      loading_message: 'Loading RDS...',
    });
    getRds(habitat_id)
      .then((response) => {
        dispatch({
          type: GET_RDS_DB_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_RDS_DB_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const removeRds = (habitat_id, id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_RDS_DB_BEGIN,
      loading_message: 'Deleting RDS...',
    });
    deleteRds(habitat_id, id)
      .then((response) => {
        dispatch({
          type: DELETE_RDS_DB_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_RDS_DB_ERROR,
          error: error.response.data.error,
        });
      });
  };
};
