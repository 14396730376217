import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const CreateHabitatModal = ({
    nameCert,
    nameHabitat,
    apiKey,
    region,
    apiSecret,
    setNameHabitat,
    setNameCert,
    setAPIKey,
    setRegion,
    AWSRegions,
    setAPISecret,
    handleCreateHabitat,
    handleCloseHabitat,
    awsCredsUser,
    habitats,
    userCreds,
    handleCreateHabitatWithCreds,
    setSelectedCred,
    selectedCred,
    handleSetSelectedCred,
    handleNewHabitatWithURL,
    error
}) => {

    return (
        <div className="create-team-form">
            <p className="modal-title">Create Habitat</p>
            {error && (
                <div className="error-display text-danger error-create-habitat">
                    <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
                </div>
            )}
            <p className="modal-instructions">Please connect to AWS. Authorize the AWS account that you will be using for your habitat.</p>

            <Form onSubmit={e => e.preventDefault()}>

                <Button
                    variant='outline-primary'
                    className='button-authorize-aws'
                    onClick={handleNewHabitatWithURL}
                >
                    Authorize AWS
                </Button>

                {userCreds.length > 0 && (
                    <>
                        <p className="modal-instructions-2">or select an AWS Credential that you&apos;ve added already.</p>


                        <Form.Control
                            as="select"
                            defaultValue={'DEFAULT'}
                            className="select-cred-habitat"
                            onChange={(e) => handleSetSelectedCred(e.target.value)}
                        >
                            <option value="DEFAULT">Select Credential</option>
                            {userCreds.map((cred) => (
                                <option key={cred.id} value={cred.id}>{cred.name}</option>
                            ))}
                        </Form.Control>

                    </>
                )}

                <div className="buttons-container">
                    <Button
                        variant="primary"
                        size='sm'
                        onClick={handleCloseHabitat}
                    >Cancel</Button>

                    {selectedCred ? (
                        <Button
                            variant="primary"
                            size='sm'
                            onClick={handleCreateHabitatWithCreds}
                            disabled={!selectedCred}
                        >Create Habitat With Creds</Button>
                    ) : (
                        <Button
                            variant="primary"
                            size='sm'
                            onClick={handleCreateHabitat}
                            disabled={!nameCert || !apiKey || !apiSecret || !region}
                        >Create Habitat With Creds</Button>
                    )}

                </div>


            </Form>
        </div>
    )
}

export default CreateHabitatModal;