import React from 'react';
import '../../assets/styles/components/CreateStateMachine.scss';
import { Row, Col, ListGroup, FormControl } from 'react-bootstrap';
import habitatIcon from '../../assets/images/icons/HabitatLight.svg';

const CreateStateMachine = ({
  role, 
  name, 
  setRole, 
  setName, 
  comment, 
  awsRoles, 
  handleComment
}) => {
  return (
    <>
      <ListGroup>
        <div className="habitat-list-item-wrapper">
          <div className="habitat-list-machine">
            <Row>
              <Col xs={2}>
                <div className="habitat-icon">
                  <div className="mzero">
                    <img className="habitat-icon-box" src={habitatIcon} alt="collection-icon" />
                  </div>
                </div>
              </Col>
              <Col xs={10} className="mt-2">
                <FormControl
                  placeholder="Step Function Name"
                  aria-describedby="basic-addon2"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
              <Col xs={12} className="mt-2">
                <FormControl 
                  as="select" 
                  defaultValue={'DEFAULT'}
                  onChange={e => setRole(e.target.value)}
                >
                  <option disabled value="DEFAULT">--Select a Role--</option>
                  {awsRoles.map(role => (
                    <option key={role.id} value={role.arn}>{role.role_name}</option>
                  ))}
                </FormControl>
              </Col>
              <Col xs={12} className='mt-3'>
                <FormControl 
                  as="textarea" 
                  placeholder="Comment"
                  value={comment}
                  onChange={(e) => handleComment(e)} 
                />
              </Col>
            </Row>
          </div>
        </div>
      </ListGroup>
    </>
  );
}

export default CreateStateMachine;