import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

/*********************************
 * Save git credentials
 *********************************/
export const createGitCredentials = (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.post(`${URL}/git_credentials`, data, { headers: authToken });  
}

/*********************************
 * Get all git credentials
 *********************************/
export const getAllGitCredentials = () => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/git_credentials`, { headers: authToken });
}

/*********************************
 * Get git credential
 *********************************/
export const getGitCredential = (credential_uuid) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/git_credentials/${credential_uuid}`, { headers: authToken });
}

/*********************************
 * Update git credential
 *********************************/
export const updateGitCredential = (credential_uuid, data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.put(`${URL}/git_credentials/${credential_uuid}`, data, { headers: authToken }); 
}

/*********************************
 * Delete git credential
 *********************************/
export const deleteGitCredential = (credential_uuid) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.delete(`${URL}/git_credentials/${credential_uuid}`, { headers: authToken });
}
