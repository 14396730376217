import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

export const newCognito = (cognitoName, habitatId) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    const data = {
        habitat_id: habitatId,
        name: cognitoName
    } 
    return axios.post(`${URL}/aws/aws_cognito`, data , { headers: authToken })
}

export const listUsers = (poolId, habitatId) => {
    const data = {
        'user_pool_id': poolId,
        'habitat_id': habitatId
    }
    let authToken = JSON.parse(localStorage.getItem('currentHeaders')); 
    return axios.get(`${URL}/aws/cognito_pool_users/?user_pool_id=${poolId}&habitat_id=${habitatId}`, { headers: authToken })
}

export const listPool = (habitatId) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.get(`${URL}/aws/aws_cognito/?habitat_id=${habitatId}`, { headers: authToken })
}

export const deletePool = (poolId, habitatId) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    const data = {
        habitat_id: habitatId
    }
    return axios.delete(`${URL}/aws/aws_cognito/${poolId}?habitat_id=${habitatId}` , { headers: authToken })
}


export const createCognitoUser = (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders')); 
    return axios.post(`${URL}/aws/create_pool_client`, data, { headers: authToken });
}

export const disableUser = (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders')); 
    return axios.post(`${URL}/aws/aws_cognito/disable_user`, data, { headers: authToken })
}

export const enableUser = (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders')); 
    return axios.post(`${URL}/aws/aws_cognito/disable_user`, data, { headers: authToken })
}

export const  resetUser = (data) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders')); 
    return axios.post(`${URL}/aws/aws_cognito/reset_password`, data, { headers: authToken })
}