import produce from 'immer';

import {
    CREATE_AWSROLE_BEGIN,
    CREATE_AWSROLE_SUCCESS,
    CREATE_AWSROLE_ERROR,
    DELETE_AWSROLE_BEGIN,
    DELETE_AWSROLE_SUCCESS,
    DELETE_AWSROLE_ERROR,
    GET_AWSROLE_BEGIN,
    GET_AWSROLE_SUCCESS,
    GET_AWSROLE_ERROR
} from '../actions/types';

const initialState = {
    awsRoles: [],
    loading: false,
    loading_message: '',
    error: null
}

const reducer = produce((draft, action) => {
    switch (action.type) {
        case CREATE_AWSROLE_BEGIN:
        case DELETE_AWSROLE_BEGIN:
        case GET_AWSROLE_BEGIN:
            draft.loading = true;
            draft.error = null;
            draft.loading_message = action.loading_message
            return;
        case CREATE_AWSROLE_SUCCESS:
            draft.loading = false;
            draft.awsRoles = action.payload;
            draft.error = null;
            return;
        case GET_AWSROLE_SUCCESS:
            draft.loading = false;
            draft.awsRoles = action.payload;
            draft.error = null;
            return;
        case DELETE_AWSROLE_SUCCESS:
            draft.loading = false;
            draft.awsRoles = action.payload;
            draft.error = null;
            return;
        case DELETE_AWSROLE_ERROR:
        case CREATE_AWSROLE_ERROR:
            draft.loading = false;
            draft.error = action.error;
            return;
        case GET_AWSROLE_ERROR:
            draft.loading = false;
            draft.error = action.errorGetRoles;
            return;
        default:
            return;
    }
}, initialState)

export default reducer;