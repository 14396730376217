import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  faCheckCircle,
  faTimesCircle,
  faCloud,
  faExclamationCircle,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  deleteAWSCred,
  editAWSCreds,
  validateCreds,
  clearVerifiedErrorState,
  requestKey,
} from '../../actions/aws-creds.actions';
import { selectCreds } from '../../actions/habitat.actions';
import '../../assets/styles/components/habitats/HabitatCredentials.scss';
import {
  nextStepTour,
  stopTour,
  changeOnboardingClassHabitat,
  changeOnboardingClassAWS,
  changeOnboardingClassRoles,
} from '../../actions/app-tour.actions';
import Spinner from 'react-bootstrap/Spinner';

const HabitatCreds = ({
  habitat,
  awsCred,
  stopTour,
  awsCreds,
  requestKey,
  encryptKey,
  selectCreds,
  stepAppTour,
  listAWSCreds,
  editAWSCreds,
  nextStepTour,
  validateCreds,
  statusAppTour,
  deleteAWSCred,
  credsVerifiedError,
  credsVerifiedLoading,
  onboardingClassHabitat,
  clearVerifiedErrorState,
  changeOnboardingClassAWS,
  changeOnboardingClassRoles,
  changeOnboardingClassHabitat,
  error,
  loading,
  loadingMessage
}) => {
  const [selectedCred, setSelectedCred] = useState({});

  useEffect(() => {
    handleShowSelected();
  }, []);


  const handleShowSelected = () => {
    setSelectedCred(awsCreds);
  };

  return (
    <>
      <div className={onboardingClassHabitat}>
        <div className="aws-creds-management-container">
          <div className="title-setup-management">
            <div className="box-feature-name-icon">
              <FontAwesomeIcon icon={faCloud} />
            </div>
            <h5>AWS Creds</h5>
          </div>

          {error && (
            <div className="error-display text-danger">
              <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {error}</p>
            </div>
          )}


          <div className="list-roles">
            <div className="management-roles">
              <p>Selected: &nbsp;
            {habitat && habitat.cred_name ? (
                  <span>{habitat.cred_name}</span>
                ) : (
                    <span>None</span>
                  )} </p>
              <div className="options-aws">
              </div>
            </div>

            {loading && (
              <div className="spinner-container">
                <Spinner animation="border" variant="primary" />
                <p>{loadingMessage}</p>
              </div>
            )}



            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Profile</th>
                  <th scope="col">Region</th>
                  <th scope="col">Verified</th>
                  <th scope="col">Created At</th>
                </tr>
              </thead>
              <tbody>
                {habitat && habitat.cred_name && selectedCred && (
                  <tr>
                    <td>{habitat.cred_name}</td>
                    <td>{selectedCred.region}</td>
                    {selectedCred.verified ? (
                      <td>
                        <FontAwesomeIcon
                          className="greenLight"
                          icon={faCheckCircle}
                        />
                      </td>
                    ) : (
                        <td>
                          <FontAwesomeIcon
                            className="redLight"
                            icon={faTimesCircle}
                          />
                        </td>
                      )}
                    <td>{selectedCred.created_at}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>



        </div>
      </div>
    </>
  );
};
const mapState = (state, props) => ({
  habitat: props.habitat,
  awsCred: state.awsCreds.awsCredential,
  encryptKey: state.awsCreds.encryptkey,
  stepAppTour: state.appTour.stepAppTour,
  awsCreds: state.awsCreds.awsCredentials,
  statusAppTour: state.appTour.stateAppTour,
  credsVerifiedError: state.awsCreds.credsVerifiedError,
  credsVerifiedLoading: state.awsCreds.credsVerifiedLoading,
  onboardingClassHabitat: state.appTour.onboardingClassHabitat,
  error: state.awsCreds.error,
  loading: state.awsCreds.loading,
  loadingMessage: state.awsCreds.loading_message
});

const mapDispatch = {
  stopTour,
  requestKey,
  selectCreds,
  editAWSCreds,
  nextStepTour,
  validateCreds,
  deleteAWSCred,
  clearVerifiedErrorState,
  changeOnboardingClassAWS,
  changeOnboardingClassRoles,
  changeOnboardingClassHabitat,
};

export default connect(mapState, mapDispatch)(HabitatCreds);
