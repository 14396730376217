import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Chart from 'react-apexcharts';

const HabitatMonitor = ({
    handleChart,
    selectLastHour,
    memoryChart,
    awsDuration,
    funcs,
    loading,
    hours
}) => {
    return (
        <>
            <Row>
                <Col xs={12}>
                    <div className="selecbox-section">
                        <Form.Control
                            className="metric-selectbox"
                            as="select"
                            onChange={e => handleChart(e.target.value)}
                        >
                            {funcs.map((func) => (
                                <option key={func.id} value={func.function_name}>{func.function_name}</option>
                            ))}
                        </Form.Control>
                        <Form.Control
                            className="metric-selectbox"
                            as="select"
                            defaultValue={hours}
                            onChange={e => selectLastHour(e.target.value)}
                        >
                            <option value="3">Last 3 hours</option>
                            <option value="12">Last 12 hours</option>
                            <option value="24">Last day</option>
                        </Form.Control>
                    </div>
                </Col>
            </Row>
            {loading ? (
                <div className="w-100 d-flex justify-content-center">
                    <Spinner />
                </div>
            ) : (
                    <Row className="metric-container">
                        <div className="metric-1">
                            <div className="chart-container">
                                <div className="monitoring-grey-box">
                                    <Chart
                                        options={awsDuration.options}
                                        series={awsDuration.series}
                                        type="area"
                                        width="100%"
                                        height="400"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="metric-1">
                            <div className="chart-container">
                                <div className="monitoring-grey-box">
                                    <Chart
                                        options={memoryChart.options}
                                        series={memoryChart.series}
                                        type="area"
                                        width="100%"
                                        height="400"
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                )}
        </>
    )
}

export default HabitatMonitor;