import React from 'react';
import { avatarInitials } from '../../utils/Avatar';
import '../../assets/styles/components/AvatarBig.scss';

const AvatarBig = (props) => {
  return (
    <div className={props.avatarClass}>
        {props.avatarClass === 'avatarBigPicTop' && (
            <h1 className='h1B'>{avatarInitials(props.userName)}</h1>
        )}
        {props.avatarClass === 'avatarPicProfile' && (
          <div className="card text-white avatarCard">
            <h1 className="avtProf">{avatarInitials(props.userName)}</h1>
          </div>
        )}
        {props.avatarClass === 'avatarPicTop-leftMenu' && (
            <p>{avatarInitials(props.userName)}</p>
        )}
        {props.avatarClass === 'avatarTeamPicProfile' && (
          <p className="font-avatarTeamPicProfile">{avatarInitials(props.userName)}</p>
        )}
        {props.avatarClass === 'avatarTeamPicTop-Left' && (
            <p className="font-avatarPicTop-leftMenu">{avatarInitials(props.userName)}</p>
        )}
    </div>
  )
}

export default AvatarBig;