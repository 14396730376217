import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { awsCredsToggle } from '../../actions/settings.actions';
import '../../assets/styles/components/AwsCredCard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';

const AWSCredCard = ({ awsCredsToggle,  awsCredSelected}) => {
    const [shadow, setshadow]   = useState('');
    const [glow, setGlow]   = useState('');

    /*******************
     * Style Management
     *******************/
    const handleHoverCard = () => {
        if(shadow === '') {
            setshadow('shadow')
        } else {
            setshadow('')
        }
    }
    const handleOnPress = () => {
        awsCredsToggle('awsCreds')
    }
    /********************/

    return (
        <Card className={`awsCredCard ${shadow} ${glow}`} 
         onMouseEnter={handleHoverCard}
         onMouseLeave={handleHoverCard}
         onClick={handleOnPress}
         >
            <Card.Header>
                <FontAwesomeIcon icon={faKey} className="credIcon" />
                <span className="cardHeader">AWS Credentials</span>
            </Card.Header>
            <Card.Body>            
                <p className="cardText">
                    Add AWS user credentials. You will be able to add more than one credential and use them in your habitats.
                </p>            
            </Card.Body>
        </Card>
    );
}
const mapState = (state) => ({
    
});
const mapDispatch = { 
    awsCredsToggle
};
export default connect(mapState, mapDispatch)(AWSCredCard);