import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

// Get all pending user invites
export const getInvitations = () => {  
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));  
  return axios.get(`${URL}/invitations`, { headers: authToken })
};

// Accept invitation
export const acceptInvitation = (inviteId) => {  
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/accept_invite/${inviteId}`, { headers: authToken })
};

// Reject invitation
export const rejectInvitation = (inviteId) => {  
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.delete(`${URL}/invitations/${inviteId}`, { headers: authToken })
};