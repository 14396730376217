import produce from 'immer';

import {
  GET_TEAMS_BEGIN,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_ERROR,
  GET_TEAM_BEGIN,
  GET_TEAM_SUCCESS,
  GET_TEAM_ERROR,
  REMOVE_MEMBER_BEGIN,
  REMOVE_MEMBER_SUCCESS,
  REMOVE_MEMBER_ERROR,
  CREATE_TEAM_BEGIN,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_ERROR,
  GET_MEMBERS_BEGIN,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_ERROR,
  INVITE_MEMBER_BEGIN,
  INVITE_MEMBER_SUCCESS,
  INVITE_MEMBER_ERROR,
  GET_TEAM_SUBSCRIPTION_BEGIN,
  GET_TEAM_SUBSCRIPTION_SUCCESS,
  GET_TEAM_SUBSCRIPTION_ERROR,
  DELETE_TEAM_BEGIN,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_ERROR,
  UNSELECT_TEAM_BEGIN,
  UNSELECT_TEAM_SUCCESS,
  UNSELECT_TEAM_ERROR,
  CHANGE_SELECTED_TEAM_BEGIN,
  CHANGE_SELECTED_TEAM_SUCCESS,
  CHANGE_SELECTED_TEAM_ERROR,
  CHANGE_DUPLICATED_TEAM_BEGIN,
  CHANGE_DUPLICATED_TEAM_SUCCESS,
  CHANGE_DUPLICATED_TEAM_ERROR,
  GET_VERIFICATION_DELETE_TEAM_BEGIN,
  GET_VERIFICATION_DELETE_TEAM_SUCCESS,
  GET_VERIFICATION_DELETE_TEAM_ERROR,
  GET_DETAILS_TEAM_BEGIN,
  GET_DETAILS_TEAM_SUCCESS,
  GET_DETAILS_TEAM_ERROR,
  GET_TEAMS_WITHMEMBERS_BEGIN,
  GET_TEAMS_WITHMEMBERS_SUCCESS,
  GET_TEAMS_WITHMEMBERS_ERROR
} from '../actions/types';

const initialState = {
  teams: [],
  team: {},
  teams_withmembers: [],
  team_members: [],
  team_invites: [],
  team_subscriptions: [],
  loading: false,
  error: null,
  errorTeams: null,
  team_verification: {},
  team_details: {},
  loading_message: '',
};

function remove(array, key, value) {
  const index = array.findIndex(obj => obj[key] === value);
  return index >= 0 ? [
    ...array.slice(0, index),
    ...array.slice(index + 1)
  ] : array;
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case INVITE_MEMBER_BEGIN:
    case DELETE_TEAM_BEGIN:
    case GET_MEMBERS_BEGIN:
    case CREATE_TEAM_BEGIN:
    case GET_TEAM_BEGIN:
    case GET_TEAMS_BEGIN:
    case REMOVE_MEMBER_BEGIN:
    case GET_TEAM_SUBSCRIPTION_BEGIN:
    case UNSELECT_TEAM_BEGIN:
    case CHANGE_SELECTED_TEAM_BEGIN:
    case CHANGE_DUPLICATED_TEAM_BEGIN:
    case GET_VERIFICATION_DELETE_TEAM_BEGIN:
    case GET_DETAILS_TEAM_BEGIN:
    case GET_TEAMS_WITHMEMBERS_BEGIN:
      draft.loading = true;
      draft.error = null;
      draft.loading_message = action.loading_message
      return;
    case GET_TEAMS_SUCCESS:
      draft.loading = false;
      draft.teams = action.payload.teams;
      draft.team = action.payload.team;
      draft.team_members = action.payload.members;
      return;
    case DELETE_TEAM_SUCCESS:
      draft.loading = false;
      draft.teams = remove(draft.teams, 'id', action.payload);
      draft.team = {};
      return;
    case GET_TEAM_SUCCESS:
    case UNSELECT_TEAM_SUCCESS:
      draft.loading = false;
      draft.team = action.payload;
      return;
    case CHANGE_SELECTED_TEAM_SUCCESS:
      draft.loading = false;
      draft.team = action.payload.teamInfo;
      draft.teams = action.payload.teams;
      return;
    case CREATE_TEAM_SUCCESS:
      draft.loading = false;
      draft.teams.push(action.payload);
      return;
    case GET_MEMBERS_SUCCESS:
      draft.loading = false;
      draft.team_members = action.payload.members
      return;
    case REMOVE_MEMBER_SUCCESS:
      draft.loading = false;
      draft.team = {};
      draft.teams = action.payload.teams;
      draft.team_members = [];
      return;
    case INVITE_MEMBER_SUCCESS:
      draft.loading = false;
      draft.team_invites.push(action.payload);
      return;
    case GET_TEAM_SUBSCRIPTION_SUCCESS:
      draft.loading = false;
      draft.team.team_subscriptions = action.payload
      return;
    case CHANGE_DUPLICATED_TEAM_SUCCESS:
      draft.loading = false;
      draft.errorTeams = null;
      return;
    case GET_VERIFICATION_DELETE_TEAM_SUCCESS:
      draft.loading = false;
      draft.team_verification = action.payload;
      return;
    case GET_DETAILS_TEAM_SUCCESS:
      draft.loading = false;
      draft.team_details = action.payload;
      return;
    case GET_TEAMS_WITHMEMBERS_SUCCESS:
      draft.loading = false;
      draft.teams_withmembers = action.payload;
      return;
    case REMOVE_MEMBER_ERROR:
    case GET_TEAM_SUBSCRIPTION_ERROR:
    case CHANGE_SELECTED_TEAM_ERROR:
    case CHANGE_DUPLICATED_TEAM_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return;
    case CREATE_TEAM_ERROR:
      draft.loading = false;
      draft.errorTeams = action.error.response.data.name[0];
      return;
    case GET_VERIFICATION_DELETE_TEAM_ERROR:
      draft.loading = false;
      draft.team_verification = action.error.response.data;
      return;
    case GET_TEAMS_ERROR:
      draft.loading = false;
      draft.error = action.errorGetTeams
      return;
    case GET_TEAM_ERROR:
      draft.loading = false;
      draft.error = action.errorGetTeam
      return;
    case DELETE_TEAM_ERROR:
      draft.loading = false;
      draft.error = action.errorDeleteTeam
      return;
    case INVITE_MEMBER_ERROR:
      draft.loading = false;
      draft.error = action.errorInviteMember
      return;   
    case GET_MEMBERS_ERROR:
      draft.loading = false;
      draft.error = action.errorGetMembers
      return;  
    case GET_DETAILS_TEAM_ERROR:
    case GET_TEAMS_WITHMEMBERS_ERROR:
      draft.loading = false;
      draft.error = action.error;
    default:
      return;
  }
}, initialState);

export default reducer;
