import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { awsCredsToggle } from '../../actions/settings.actions';
import '../../assets/styles/components/AwsCredCard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { faTimes, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const GITCredCard = ({ awsCredsToggle }) => {
    const [shadow, setshadow] = useState('');
    const [glow, setGlow] = useState('');

    /*******************
     * Style Management
     *******************/
    const handleHoverCard = () => {
        if (shadow === '') {
            setshadow('shadow')
        } else {
            setshadow('')
        }
    }
    const handleOnPress = () => {
        awsCredsToggle('gitCreds');
        
    }
    /********************/

    return (
        <Card className={`awsCredCard ${shadow} ${glow}`}
            onMouseEnter={handleHoverCard}
            onMouseLeave={handleHoverCard}
            onClick={handleOnPress}
        >
            <Card.Header>
                <FontAwesomeIcon icon={faKey} className="credIcon" />
                <div className="cardHeader">
                    <p>GIT Credentials</p>
                <div className="question-mark-documentation-habitat">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip className="red-tooltip">
                                <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                            </Tooltip>
                        }

                    >
                        <a
                            href="https://getkor.freshdesk.com/support/solutions/articles/47001164532-git-credentials"
                            rel="noopener noreferrer"
                            className="help"
                            target="_blank"
                        >

                            <FontAwesomeIcon icon={faQuestionCircle} />
                        </a>
                    </OverlayTrigger>
                </div>
                </div>
            </Card.Header>
            <Card.Body>
                <p className="cardText">
                    Add git credentials. You will be able to choose which credentials to use per respository.
                </p>
            </Card.Body>
        </Card>
    );
}
const mapState = (state) => ({

});
const mapDispatch = {
    awsCredsToggle
};
export default connect(mapState, mapDispatch)(GITCredCard);