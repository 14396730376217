import produce from 'immer';
import {
  GET_AWS_BUILDS_BEGIN, GET_AWS_BUILDS_SUCCESS, GET_AWS_BUILDS_ERROR,
  GET_AWS_BUILDS_LOGS_BEGIN, GET_AWS_BUILDS_LOGS_SUCCESS, GET_AWS_BUILDS_LOGS_ERROR,
  GET_AWS_BUILDS_STATUS_BEGIN, GET_AWS_BUILDS_STATUS_SUCCESS, GET_AWS_BUILDS_STATUS_ERROR,
  GET_AWS_BUILDS_EXECUTION_BEGIN, GET_AWS_BUILDS_EXECUTION_SUCCESS, GET_AWS_BUILDS_EXECUTION_ERROR,
  DELETE_BUILD_BEGIN, DELETE_BUILD_SUCCESS, DELETE_BUILD_ERROR
} from '../actions/types';

const initialState = {
  builds: [],
  error: null,
  error_logs: null,
  buildLogs: '',
  loading: false,
  loading_message: '',
  buildStatus: [],
  buildsExecutions: []
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case GET_AWS_BUILDS_BEGIN:
    case GET_AWS_BUILDS_LOGS_BEGIN:
    case GET_AWS_BUILDS_STATUS_BEGIN:
    case GET_AWS_BUILDS_EXECUTION_BEGIN:
    case DELETE_BUILD_BEGIN:
      draft.loading = true;
      draft.error = null;
      draft.loading_message = action.loading_message;
      return;
    case GET_AWS_BUILDS_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.builds = action.payload;
      draft.buildLogs = '';
      return;
    case GET_AWS_BUILDS_LOGS_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.buildLogs = action.payload;
      return;
    case GET_AWS_BUILDS_STATUS_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.buildStatus = action.payload;
      return;
    case GET_AWS_BUILDS_EXECUTION_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.buildsExecutions = action.payload;
      return;
    case DELETE_BUILD_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.builds = action.payload
      return;
    case GET_AWS_BUILDS_STATUS_ERROR:
    case DELETE_BUILD_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return;
    case GET_AWS_BUILDS_ERROR:
      draft.loading = false;
      draft.error = action.errorGetBuilds;
      return;
    case GET_AWS_BUILDS_LOGS_ERROR:
      draft.loading = false;
      draft.error_logs = action.errorListLogs;
      return;
    case GET_AWS_BUILDS_EXECUTION_ERROR:
      draft.loading = false;
      draft.error = action.errorListExec;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;