import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import HabitatSettings from '../components/Habitats/HabitatSettings';
import '../assets/styles/pages/Habitats.scss';
import HabitatTopMenu from '../components/Habitats/HabitatTopMenu';
import {
  fetchEC2s,
  deleteInstance,
  getInstaceStatus,
  instanceChangeStatus
} from '../actions/aws-ec2.actions';
import { 
  nextStepTour, 
  stopTour, 
  changeOnboardingClassHabitat, 
  changeOnboardingClassRoles,
  changeOnboardingClassAWS,
  changeOnboardingClassFunctions
} from '../actions/app-tour.actions';
import AppTour from '../components/AppTour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { changeStatusOnboarding } from '../actions/user.actions';
import HabitatCreds from '../components/Habitats/HabitatCredentials';
import HabitatRoles from '../components/Habitats/HabitatRoles';
import HabitatFunc from '../components/Habitats/HabitatFunc';
import HabitatS3 from '../components/Habitats/HabitatS3';
import HabitatDynamo from '../components/Habitats/HabitatDynamo';
import HabitatInstances from '../components/Habitats/HabitatInstances';
import HabitatTemplates from '../components/Habitats/HabitatTemplates';
import HabitatAPIGateway from '../components/Habitats/HabitatAPIGateway';
import HabitatDocumentationLink from '../components/Habitats/HabitatDocumentationLink';
import { listAWSS3 } from '../actions/aws-s3.actions';
import { getClones } from '../actions/git-clone.actions';
import { listFunctions } from '../actions/funcs.actions';
import { getDynamos } from '../actions/aws-dynamo.actions';
import { listGitCreds } from '../actions/git-creds.actions';
import { fetchAWSRole } from '../actions/aws-roles.actions';
import { listAWSCreds } from '../actions/aws-creds.actions';
import { createStatic, createStaticWithoutDomain } from '../actions/templates.actions';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { listCognito } from '../actions/aws-cognito.actions';
import { getAwsDomains } from '../actions/aws-route53.actions';
import { getApiList } from  '../actions/aws-apigateway.actions';
import { getStaticSites, removeTemplate } from '../actions/templates.actions';
import { retrieveHabitat, listConnectedS3s } from '../actions/habitat.actions';

const HabitatSettingsPage = ({
  team,
  funcs,
  params,
  status,
  habitat,
  domains,
  awsCreds,
  cognitos,
  stopTour,
  awsRoles,
  instances,
  templates,
  getClones,
  gitClones,
  listAWSS3,
  fetchEC2s,
  getApiList,
  getDynamos,
  apiGateways,
  stepAppTour,
  listCognito,
  fetchAWSRole,
  listAWSCreds,
  createStatic,
  habitatSetUp,
  nextStepTour,
  listGitCreds,
  getAwsDomains,
  statusAppTour,
  listFunctions,
  instanceError,
  deleteInstance,
  removeTemplate,
  errorTemplates,
  getStaticSites,
  loadingTemplate,
  loadingInstance,
  onboardingClass,
  retrieveHabitat,
  listConnectedS3s,
  getInstaceStatus,
  instanceChangeStatus,
  changeStatusOnboarding,
  createStaticWithoutDomain,
  changeOnboardingClassHabitat,
  changeOnboardingClassRoles,
  changeOnboardingClassAWS, 
  changeOnboardingClassFunctions,
}) => {
  useEffect(() => {
    if (habitat.id) {
      fetchEC2s(habitat.id);
      listAWSS3(habitat.id);
      fetchAWSRole(habitat.id);
      listAWSCreds(habitat.id);
      getStaticSites(habitat.id);
      listConnectedS3s(habitat.id);
    } else {
      fetchEC2s(params.habitatId);
      listAWSS3(params.habitatId);
      getApiList(params.habitatId);
      getDynamos(params.habitatId);
      listCognito(params.habitatId);
      fetchAWSRole(params.habitatId);
      listAWSCreds(params.habitatId);
      listFunctions(params.habitatId);
      getStaticSites(params.habitatId);
      retrieveHabitat(params.habitatId);
      listConnectedS3s(params.habitatId);
    }
  }, [])

  return (
    <div>
      <div className="habitat-sub-menu">
        <AppTour
          stopTour={stopTour}
          stepAppTour={stepAppTour}
          nextStepTour={nextStepTour}
          statusAppTour={statusAppTour}
          changeStatusOnboarding={changeStatusOnboarding}
          changeOnboardingClassHabitat={changeOnboardingClassHabitat}
          changeOnboardingClassRoles={changeOnboardingClassRoles}
          changeOnboardingClassAWS={ changeOnboardingClassAWS}
          changeOnboardingClassFunctions={changeOnboardingClassFunctions}
        />
        <div className="sub-menu-side-l">
          <FontAwesomeIcon icon={faGlobe} />
          <p>{habitat.name}</p>
        </div>
        <HabitatTopMenu 
          habitat={habitat}
        />
      </div>

      <div className="habitat-container-settings">
        <div className="habitat-setup-container">
        <div className="title-deployments">
            <p>Habitat Setup</p>
          </div>

          <HabitatSettings
            habitat={habitat}
            habitatSetUp={habitatSetUp}
          />
        </div>

        <div className="habitat-feature-container">

          <HabitatDocumentationLink 
            habitatSetUp={habitatSetUp}
          />


          {habitatSetUp === '' && (
            <div className="blank-section">No feature selected.</div>
          )}

          {habitatSetUp && habitatSetUp === 'habitat' && (
            <HabitatCreds
              habitat={habitat}
            />
          )}

          {habitatSetUp && habitatSetUp === 'roles' && (
            <HabitatRoles
              habitat={habitat}
            />
          )}

          {habitatSetUp && habitatSetUp === 'funcs' && (
            <HabitatFunc
              habitat={habitat}
            />
          )}

          {habitatSetUp && habitatSetUp === 's3' && (
            <HabitatS3
              habitat={habitat}
            />
          )}

          {habitatSetUp && habitatSetUp === 'templates' && (
            <HabitatTemplates
              team={team}
              habitat={habitat}
              domains={domains}
              awsCreds={awsCreds}
              getClones={getClones}
              templates={templates}
              gitClones={gitClones}
              createStatic={createStatic}
              createStaticWithoutDomain={createStaticWithoutDomain}
              listGitCreds={listGitCreds}
              getAwsDomains={getAwsDomains}
              getStaticSites={getStaticSites}
              removeTemplate={removeTemplate}
              loadingTemplate={loadingTemplate}
              errorTemplates={errorTemplates}
            />
          )}

          {habitatSetUp && habitatSetUp === 'instance' && (
            <HabitatInstances
              status={status}
              habitat={habitat}
              instances={instances}
              error={instanceError}
              loading={loadingInstance} 
              deleteInstance={deleteInstance}
              getInstaceStatus={getInstaceStatus}
              instanceChangeStatus={instanceChangeStatus}
            />
          )}

        </div>
      </div>
    </div>
  )

}

const mapState = (state, props) => ({
  team: state.team.team,
  funcs: state.func.funcs,
  params: props.match.params,
  status: state.instances.status,
  habitat: state.habitat.habitat,
  domains: state.route53.domains,
  awsRoles: state.awsRoles.awsRoles,
  gitClones: state.gitClone.gitClones,
  cognitos: state.cognito.cognitoList,
  templates: state.templates.templates,
  instances: state.instances.instances,
  instanceError: state.instances.error,
  errorTemplates: state.templates.error,
  stepAppTour: state.appTour.stepAppTour,
  awsCreds: state.awsCreds.awsCredentials,
  habitatSetUp: state.habitat.habitatSetup,
  loadingTemplate: state.templates.loading,
  loadingInstance: state.instances.loading,
  statusAppTour: state.user.user.onboarding,
  apiGateways: state.apiGateway.apiGateways,
  onboardingClass: state.appTour.onboardingClassHabitat,
  gitCreds: state.gitCreds.gitCredentials.git_credentials,
})

const mapDispatch = {
  stopTour,
  fetchEC2s,
  listAWSS3,
  getClones,
  getApiList,
  getDynamos,
  listCognito,
  fetchAWSRole,
  nextStepTour,
  createStatic,
  listAWSCreds,
  listGitCreds,
  getAwsDomains,
  listFunctions,
  getStaticSites,
  deleteInstance,
  removeTemplate,
  retrieveHabitat,
  getInstaceStatus,
  listConnectedS3s,
  instanceChangeStatus,
  changeStatusOnboarding,
  changeOnboardingClassAWS, 
  createStaticWithoutDomain,
  changeOnboardingClassRoles,
  changeOnboardingClassHabitat,
  changeOnboardingClassFunctions
}

export default connect(
  mapState,
  mapDispatch
)(HabitatSettingsPage);