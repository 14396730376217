import React from 'react';
import '../../assets/styles/components/billing/billing-subscription-card.scss';
import Moment from 'moment';
import RemoveModal from '../Modals/RemoveModal';
import { Button  } from 'react-bootstrap';

const SubscriptionCard = (props) => {

  return (
    <>
			<RemoveModal 
				show={props.show}
				name="subscription"
				setShow={props.setShow}
				handleRemove={props.handeleCancelSubscription}
			/>
      {props.subscriptionInfo ? (
        <div className="subscription-card">
          <p className="subscription-card-tittle">
            <b>Subscription</b>
          </p>
          <p>Your next billing cycle starts on:</p>
          <p>
            <b>
              {Moment(props.subscriptionInfo.current_period_end, 'X').format(
                'MMMM Do YYYY'
              )}
            </b>
          </p>
          <p
            className="cancel"
            onClick={() => props.setShow(true)}
          >
            Cancel Subscription
          </p>
          {!props.team.id ? (
            <div className="d-flex justify-content-center">
              <Button disabled={props.team.id} className="btn btn-primary" onClick={props.handleModalPlanUser}>
                Plans
              </Button>
            </div>
          ):(
            <div className="d-flex justify-content-center">
              <Button disabled={!props.team.id} className="btn btn-primary"  onClick={props.handleModalPlanTeam}>
                Plans
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className="subscription-card">
          <p className="subscription-card-tittle">
            <b>Subscription</b>
          </p>
          <div className="plans-button-billing">
          <p>You don&apos;t have any subscription yet</p>
          {!props.team.id ? (
            <div className="d-flex justify-content-center">
              <Button disabled={props.team.id} className="btn btn-primary" onClick={props.handleModalPlanUser}>
                Plans
              </Button>
            </div>
          ):(
            <div className="d-flex justify-content-center">
              <Button disabled={!props.team.id} className="btn btn-primary"  onClick={props.handleModalPlanTeam}>
                Plans
              </Button>
            </div>
          )}
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionCard;
