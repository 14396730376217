import routes from '../constants/routes.json';

const findInRoutes = (route) => {
  let index = Object.values(routes).indexOf(route);
  return index;
};

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  let str = s.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const getTitle = (route) => {
  let keyIndex = findInRoutes(route);
  let keys = Object.keys(routes);
  let key = [];

  if (keyIndex >= 0) {
    key = keys[keyIndex];
  } else {
    return '';
  }

  let title = capitalize(key);
  return title;
};

export default getTitle;
