import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

export const getAllAWSCredentials = (habitat_id) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));      
    return axios.get(`${URL}/habitat_credential/${habitat_id}`, { headers: authToken }) 
}

export const removeAWSCredential = (id) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));    
    return axios.delete(`${URL}/aws_credentials/${id}`, { headers: authToken })
}

export const createAWSCredential = (aws_creds) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders')); 
    return axios.post(`${URL}/aws_credentials`, aws_creds, { headers: authToken }) 
}

export const getAWSCredential = (id) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.get(`${URL}/aws_credentials/${id}`, { headers: authToken })
}

export const updateAWSCredentialAPI = (id, creds) => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));       
    return axios.put(`${URL}/aws_credentials/${id}`, creds, { headers: authToken})
}

export const requestEncryptKey = (habitat_id) => {
    const request = 0;
    const data = {
        request,
        habitat_id
    }
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/aws_credentials/`, data, { headers: authToken})
}

export const getUserAWSCredential = () => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.get(`${URL}/aws_credentials/`, { headers: authToken })
}

export const awsRedirect = () => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.get(`${URL}/aws_redirect/`, { headers: authToken })
}

export const accessAwsCredential = data => {
    let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
    return axios.post(`${URL}/access_aws_credentials/`, data, { headers: authToken})
}