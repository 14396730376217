import React from 'react';
import '../assets/styles/components/team-billing.scss';
import BillingPlans from './billing/BillingPlan';
import BillingHistory from './billing/BillingHistory';
import { connect } from 'react-redux';

const basicFeatures = [
    '3 Functions',
    '1 Habitat',
    'Documentation'
  ]
  
  const proFeatures = [
    'Unlimited Functions',
    'Unlimited Habitats',
    'Support'
  ]

const TeamBilling = ({payments, subscription, teamId}) =>{
    return (
      <div>
        <div className="container-kds">
          <div className="page-nav-main">
            <p className="page-title">Billing</p>
          </div>
        </div>
        <div className="col-md-12 page-content">
          <p className="page-subtitle">AVAILABLE PLANS</p>
          <br />
          <div className="container">
            <div className="row">
              <div className="col-sm">
                <div className="team-billing-card1"> 
                  <BillingPlans
                    planPrice={'0.00'}
                    priceCurrency={'$'}
                    features={basicFeatures}
                    planTitle={'Project'}
                    value={'plan1'}
                    subscription={subscription[teamId-1].name}
                    payment={payments} />
                </div>
              </div>
              <div className="col-sm">
                <div className="team-billing-card2">
                  <BillingPlans
                    planPrice={'9.99'}
                    priceCurrency={'$'}
                    features={proFeatures}
                    planTitle={'Startup'}
                    value={'plan2'}
                    subscription={subscription[teamId-1].name}
                    payment={payments} />
                </div>
              </div>
              <div className="col-sm">
                <div className="team-billing-card3">
                  <BillingPlans
                    planPrice={''}
                    priceCurrency={'$'}
                    features={proFeatures}
                    planTitle={'Enterprise'}
                    value={'plan3'}
                    subscription={subscription[teamId-1].name}
                    payment={payments} />
                </div>
              </div>
            </div>
          </div>
         <p className="page-title-cc">CREDIT CARD</p>         
          <p className="page-subtitle">BILLING HISTORY</p>
          <BillingHistory billingType="team"/>
        </div>
      </div>
    );   
}
const mapState = (state, props) => ({
  subscription: state.user.user.team_subscriptions,
  payments: state.payment.proPayments['data'],
  teamId: props.match.params.teamId
});

const mapDispatch = {

}

export default connect(
  mapState,
  mapDispatch
)(TeamBilling);