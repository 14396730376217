import {
    GET_INVITATIONS_BEGIN,
    GET_INVITATIONS_SUCCESS,
    GET_INVITATIONS_ERROR,
    ACCEPT_INVITATION_BEGIN,
    ACCEPT_INVITATION_SUCCESS,
    ACCEPT_INVITATION_ERROR,
    REJECT_INVITATION_BEGIN,
    REJECT_INVITATION_SUCCESS,
    REJECT_INVITATION_ERROR,
  } from './types';
  import {
    getInvitations,
    acceptInvitation,
    rejectInvitation,
  } from '../api/invitations';
  
  // Get all invitations
  export const fetchInvitations = () => {
    return (dispatch) => {
      dispatch({ type: GET_INVITATIONS_BEGIN });
      getInvitations()
        .then((response) => {
          dispatch({
            type: GET_INVITATIONS_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_INVITATIONS_ERROR, error });
        });
    };
  };
  
  // Accept Invitation
  export const acceptInvite = (inviteId) => {
    return (dispatch) => {
      dispatch({ type: ACCEPT_INVITATION_BEGIN });
      acceptInvitation(inviteId)
        .then(() => {
          dispatch({
            type: ACCEPT_INVITATION_SUCCESS,
            payload: inviteId,
          });
        })
        .catch((error) => {
          dispatch({ type: ACCEPT_INVITATION_ERROR, error });
        });
    };
  };
  
  // Reject Invitation
  export const rejectInvite = (inviteId) => {
    return (dispatch) => {
      dispatch({ type: REJECT_INVITATION_BEGIN });
      rejectInvitation(inviteId)
        .then(() => {
          dispatch({
            type: REJECT_INVITATION_SUCCESS,
            payload: inviteId,
          });
        })
        .catch((error) => {
          dispatch({ type: REJECT_INVITATION_ERROR, error });
        });
    };
  };
  