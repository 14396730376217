import {
  ENCRYPT_KEY_BEGIN,
  ENCRYPT_KEY_SUCCESS,
  ENCRYPT_KEY_ERROR,
  CREATE_AWSCRED_BEGIN,
  CREATE_AWSCRED_SUCCESS,
  CREATE_AWSCRED_ERROR,
  GET_ALL_AWSCREDS_BEGIN,
  GET_ALL_AWSCREDS_SUCCESS,
  GET_ALL_AWSCREDS_ERROR,
  GET_AWSCRED_BEGIN,
  GET_AWSCRED_SUCCESS,
  GET_AWSCRED_ERROR,
  VERIFY_CREDS_BEGIN,
  VERIFY_CREDS_SUCCESS,
  VERIFY_CREDS_ERROR,
  DELETE_AWSCRED_BEGIN,
  DELETE_AWSCRED_SUCCESS,
  DELETE_AWSCRED_ERROR,
  UPDATE_AWSCRED_BEGIN,
  UPDATE_AWSCRED_SUCCESS,
  UPDATE_AWSCRED_ERROR,
  CLEAR_VERIFIED_ERROR_STATE,
  GET_ALL_USER_AWSCREDS_BEGIN,
  GET_ALL_USER_AWSCREDS_SUCCESS,
  GET_ALL_USER_AWSCREDS_ERROR,
  ENCRYPT_REQUEST_AWSCRED_BEGIN,
  ENCRYPT_REQUEST_AWSCRED_SUCCESS,
  ENCRYPT_REQUEST_AWSCRED_ERROR,
  CREATE_AWSCREDS_WITH_URL_BEGIN,
  CREATE_AWSCREDS_WITH_URL_SUCCESS,
  CREATE_AWSCREDS_WITH_URL_ERROR,
} from './types';
import { awsCredModel } from '../models/awsCreds';
import {
  awsRedirect,
  getAWSCredential,
  requestEncryptKey,
  accessAwsCredential,
  createAWSCredential,
  removeAWSCredential,
  getAllAWSCredentials,
  getUserAWSCredential,
  updateAWSCredentialAPI,
} from '../api/aws/creds';
import { verifyCreds } from '../api/aws/sdk';

export const clearVerifiedErrorState = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_VERIFIED_ERROR_STATE });
  };
};

export const addAwsCredWithURL = () => {
  return (dispatch) => {
    dispatch({
      type: CREATE_AWSCREDS_WITH_URL_BEGIN,
      loading_message: 'Loading AWS Credentials...',
    });
    awsRedirect()
      .then((response) => {
        window.open(response.data.aws_link, '_blank');
        const accessInfo = {
          topic_arn: response.data.topic_arn,
          role_name: response.data.role_name,
        };
        accessAwsCredential(accessInfo)
          .then((response) => {
            const payload = {
              credential:
                response.data.aws_credentials[
                  response.data.aws_credentials.length - 1
                ],
              credentials: response.data,
            };
            dispatch({
              type: CREATE_AWSCREDS_WITH_URL_SUCCESS,
              payload,
            });
          })
          .catch((error) =>
            dispatch({
              type: CREATE_AWSCREDS_WITH_URL_ERROR,
              error: error.response.data.error,
            })
          );
      })
      .catch((error) =>
        dispatch({
          type: CREATE_AWSCREDS_WITH_URL_ERROR,
          error: error.response.data.error,
        })
      );
  };
};

export const requestKey = (name, apiKey, apiSecret, region, habitat_id) => {
  const crypto = require('crypto');
  const algorithm = 'aes-256-cfb';
  let key = '';
  const iv = '0000000000000000';

  return (dispatch) => {
    dispatch({ type: ENCRYPT_REQUEST_AWSCRED_BEGIN });
    requestEncryptKey(habitat_id)
      .then((response) => {
        key = response.data.key;

        var cipherApiKey = crypto.createCipheriv(algorithm, key, iv);
        var cryptedApiKey = cipherApiKey.update(apiKey, 'utf-8', 'base64');
        cryptedApiKey += cipherApiKey.final('base64');

        var cipherApiSecret = crypto.createCipheriv(algorithm, key, iv);
        var cryptedApiSecret = cipherApiSecret.update(
          apiSecret,
          'utf-8',
          'base64'
        );
        cryptedApiSecret += cipherApiSecret.final('base64');

        let awsCreds = awsCredModel(
          name,
          cryptedApiKey,
          cryptedApiSecret,
          region,
          habitat_id,
          key
        );
        createAWSCredential(awsCreds)
          .then((response) => {
            dispatch({
              type: ENCRYPT_REQUEST_AWSCRED_SUCCESS,
              payload: response.data,
            });
          })
          .catch((error) => {
            dispatch({ type: ENCRYPT_REQUEST_AWSCRED_ERROR });
          });
      })
      .catch((error) => {
        dispatch({ type: ENCRYPT_REQUEST_AWSCRED_ERROR });
      });
  };
};

export const validateCreds = (nameCred, regionCred, credId) => {
  let creds = {};
  return (dispatch) => {
    dispatch({ type: VERIFY_CREDS_BEGIN });
    verifyCreds(credId)
      .then((response) => {
        dispatch({
          type: VERIFY_CREDS_SUCCESS,
          payload: response.data,
          cred: {
            id: credId,
            name: nameCred,
            region: regionCred,
            verified: true,
          },
        });
      })
      .catch((error) => {
        dispatch({ type: VERIFY_CREDS_ERROR, error });
      });
  };
};

export const listAWSCreds = (habitat_id) => {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_AWSCREDS_BEGIN,
      loading_message: 'Loading AWS Credentials...',
    });
    getAllAWSCredentials(habitat_id)
      .then((response) => {
        dispatch({
          type: GET_ALL_AWSCREDS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_AWSCREDS_ERROR,
          errorListAwsCreds: 'Get AWS Creds fail',
          error,
        });
      });
  };
};

export const deleteAWSCred = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_AWSCRED_BEGIN });
    removeAWSCredential(id)
      .then(() => {
        dispatch({
          type: DELETE_AWSCRED_SUCCESS,
          payload: id,
        });
      })
      .catch((error) => {
        dispatch({ type: DELETE_AWSCRED_ERROR, error });
      });
  };
};

export const editAWSCreds = (credID, updCred) => {
  const allCreds = listAWSCreds();

  return (dispatch) => {
    dispatch({ type: UPDATE_AWSCRED_BEGIN });
    if (updCred.name) {
      for (var i = 0, l = allCreds.length; i < l; i++) {
        if (i.name === updCred.name) {
          return 'ERROR: profile name must be unique.';
        }
      }
    }
    updateAWSCredentialAPI(credID, updCred)
      .then((response) => {
        dispatch({
          type: UPDATE_AWSCRED_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: UPDATE_AWSCRED_ERROR, error });
      });
  };
};

export const retrieveCreds = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_AWSCRED_BEGIN });
    getAWSCredential(id)
      .then((response) => {
        dispatch({
          type: GET_AWSCRED_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_AWSCRED_ERROR, error });
      });
  };
};

export const getUserAWSCreds = () => {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_USER_AWSCREDS_BEGIN,
      loading_message: 'Loading AWS Credentials...',
    });
    getUserAWSCredential()
      .then((response) => {
        dispatch({
          type: GET_ALL_USER_AWSCREDS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_USER_AWSCREDS_ERROR,
          error: error.response.data.error,
        });
      });
  };
};
