import produce from 'immer';
import { 
  GET_INSTANCES_BEGIN, GET_INSTANCES_SUCCESS, GET_INSTANCES_ERROR,
  DELETE_INSTANCE_BEGIN,  DELETE_INSTANCE_SUCCESS,  DELETE_INSTANCE_ERROR,
  GET_INSTANCE_STATUS_BEGIN, GET_INSTANCE_STATUS_SUCCESS, GET_INSTANCE_STATUS_ERROR,
  CHANGE_INSTANCE_STATUS_BEGIN, CHANGE_INSTANCE_STATUS_SUCCESS, CHANGE_INSTANCE_STATUS_ERROR
} from '../actions/types';

const initialState = {
  instances: null,
  loading: false,
  status: null,
  error: null
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case GET_INSTANCES_BEGIN:
    case DELETE_INSTANCE_BEGIN:
    case GET_INSTANCE_STATUS_BEGIN:
    case CHANGE_INSTANCE_STATUS_BEGIN:
      draft.loading = true;
      draft.error = null;
      return;
    case GET_INSTANCES_SUCCESS:
    case DELETE_INSTANCE_SUCCESS:
      draft.error = null; 
      draft.loading = false;
      draft.instances = action.payload;
      return;
    case GET_INSTANCE_STATUS_SUCCESS:
    case CHANGE_INSTANCE_STATUS_SUCCESS:
      draft.error = null; 
      draft.loading = false;
      draft.status = action.payload;
      return;
    case GET_INSTANCES_ERROR:
    case DELETE_INSTANCE_ERROR:
    case GET_INSTANCE_STATUS_ERROR:
    case CHANGE_INSTANCE_STATUS_ERROR:
      draft.loading = false;
      draft.error = action.error; 
      return;
    default:
      return;
  }
}, initialState);

export default reducer;