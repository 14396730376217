import Moment from 'moment';
import {
  SET_AWSCRED_BEGIN,
  SET_AWSCRED_SUCCESS,
  SET_AWSCRED_ERROR,
  GET_AWS_METRICS_BEGIN,
  GET_AWS_METRICS_SUCCESS,
  GET_AWS_METRICS_ERROR,
  GET_AWS_LOGS_BEGIN,
  GET_AWS_LOGS_SUCCESS,
  GET_AWS_LOGS_ERROR,
  GET_AWS_LOG_BEGIN,
  GET_AWS_LOG_SUCCESS,
  GET_AWS_LOG_ERROR,
  GET_IAM_USER_BEGIN,
  GET_IAM_USER_SUCCESS,
  GET_IAM_USER_ERROR,
  GET_AWS_OVERVIEW_BEGIN,
  GET_AWS_OVERVIEW_SUCCESS,
  GET_AWS_OVERVIEW_ERROR,
  GET_AWS_ALL_LOGS_BEGIN,
  GET_AWS_ALL_LOGS_SUCCESS,
  GET_ALL_AWS_LOGS_ERROR,
  GET_AWS_DURATION_BEGIN,
  GET_AWS_DURATION_ERROR,
  GET_AWS_DURATION_SUCCESS,
  GET_AWS_MEMORY_BEGIN,
  GET_AWS_MEMORY_ERROR,
  GET_AWS_MEMORY_SUCCESS,
  GET_ALL_FUNCTIONS_BEGIN,
  GET_ALL_FUNCTIONS_SUCCESS,
  GET_ALL_FUNCTIONS_ERROR,
  GET_AWS_ALL_FUNC_LOGS_BEGIN,
  GET_AWS_ALL_FUNC_LOGS_SUCCESS,
  GET_AWS_ALL_FUNC_LOGS_ERROR,
} from './types';
import { onlyUnique } from '../lib/helpers';
import {
  setCredentials,
  getMetrics,
  getListLogs,
  getLog,
  getIAMUser,
  deleteBuild,
} from '../api/aws/sdk';
import { fetchFuncs } from '../api/funcs';
import { getAllHabitats } from '../api/habitats';
// Helper: Get AWS Cred details from CredID
const getCredDetails = (credId, awsCreds) => {
  let credentials = {};
  return credentials;
};

export const setAWSCredentials = (credentials) => {
  return (dispatch) => {
    dispatch({
      type: SET_AWSCRED_BEGIN,
      loading_message: 'Setting AWS Credentials...',
    });
    try {
      setCredentials(credentials);
      dispatch({ type: SET_AWSCRED_SUCCESS });
    } catch (error) {
      dispatch({ type: SET_AWSCRED_ERROR, error });
    }
  };
};

export const getIAMUserInfo = (habCredsID, userName, awsCreds) => {
  const credentials = getCredDetails(habCredsID, awsCreds);
  return (dispatch) => {
    dispatch({
      type: GET_IAM_USER_BEGIN,
      loading_message: 'Loading IAM User info...',
    });
  };
};

export const getAwsData = (
  habitat,
  startTime,
  endTime,
  metricName,
  funcName
) => {
  const categories = [
    Moment(startTime).format('llll'),
    Moment(endTime).format('llll'),
  ];
  const id = 'hc1';
  const stat = 'Sum';
  const unit = 'Count';
  const period = 300;

  return (dispatch) => {
    dispatch({
      type: GET_AWS_METRICS_BEGIN,
      loading_message: 'Loading AWS Data',
    });
    getMetrics(
      habitat,
      startTime,
      endTime,
      period,
      metricName,
      funcName,
      stat,
      unit,
      id
    )
      .then((res) => {
        res.data.timestamps.forEach((t) => {
          categories.push(Moment(t).format('llll'));
        });

        const chart = {
          series: [
            {
              name: funcName,
              data: res.data.values,
            },
          ],
          options: {
            chart: {
              id: 'inverr-chart',
              stacked: true,
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: true,
              },
            },
            title: {
              text: metricName,
              align: 'left',
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: false,
            },
            colors: ['#1d7aff', '#ff4f8b', '#ff875c', '#14ca88', '#81889d'],
            stroke: {
              width: 1.5,
              curve: 'smooth',
            },
            yaxis: {
              tickAmount: 5,
              min: 0,
            },
            xaxis: {
              type: 'datetime',
              categories: categories
                .filter(onlyUnique)
                .sort((a, b) => Moment(b, 'llll') - Moment(a, 'llll'))
                .reverse(),
            },
            tooltip: {
              x: {
                format: 'dd MMM yyyy HH:mm',
              },
            },
          },
        };
        dispatch({
          type: GET_AWS_METRICS_SUCCESS,
          payload: {
            series: chart.series,
            options: chart.options,
          },
        });
      })
      .catch((error) => {
        dispatch({ type: GET_AWS_METRICS_ERROR, error });
      });
  };
};

export const getAwsLogs = (habitat, funcName, startTime, endTime) => {
  const limit = 50;
  const start = Moment(startTime).format('L');
  const end = Moment(endTime).format('L');
  const logsFilter = [];

  return (dispatch) => {
    dispatch({
      type: GET_AWS_LOGS_BEGIN,
      loading_message: 'Loading AWS Logs',
    });
    getListLogs(habitat.id, funcName, limit)
      .then((response) => {
        response.data.map((l) => {
          const logDate = Moment(l.creationTime).format('L');
          if (
            Moment(logDate) >= Moment(start) &&
            Moment(logDate) <= Moment(end)
          ) {
            logsFilter.push(l);
          }
        });
        dispatch({
          type: GET_AWS_LOGS_SUCCESS,
          payload: {
            function: funcName,
            logStreams: logsFilter,
          },
        });
      })
      .catch((error) => {
        dispatch({ type: GET_AWS_LOGS_ERROR, error });
      });
  };
};

export const getAWSListLog = (habitatId, funcName) => {
  const limit = 50;
  return (dispatch) => {
    dispatch({
      type: GET_AWS_ALL_FUNC_LOGS_BEGIN,
      loading_message: 'Loading List Logs...',
    });
    getListLogs(habitatId, funcName, limit)
      .then((response) => {
        dispatch({
          type: GET_AWS_ALL_FUNC_LOGS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_AWS_ALL_FUNC_LOGS_ERROR, error });
      });
  };
};

export const getAwsLog = (habitat, funcName, logStreamName) => {
  return (dispatch) => {
    dispatch({
      type: GET_AWS_LOG_BEGIN,
      loading_message: 'Loading AWS log...',
    });
    getLog(habitat.id, funcName, logStreamName)
      .then((response) => {
        dispatch({
          type: GET_AWS_LOG_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_AWS_LOG_ERROR, error });
      });
  };
};

export const getAwsOverview = (metricName, period, startTime, endTime) => {
  const categories = [
    Moment(startTime).format('llll'),
    Moment(endTime).format('llll'),
  ];
  const data = [];
  const id = 'hc1';
  const stat = 'Sum';
  const unit = 'Count';
  return (dispatch) => {
    dispatch({
      type: GET_AWS_OVERVIEW_BEGIN,
      loading_message: 'Loading AWS overview...',
    });
    getAllHabitats()
      .then((response) => {
        response.data.habitats.map((habitat) => {
          fetchFuncs(habitat.id)
            .then((funcs) => {
              funcs.data.map((func) => {
                getMetrics(
                  habitat.id,
                  startTime,
                  endTime,
                  period,
                  metricName,
                  func.function_name,
                  stat,
                  unit,
                  id
                )
                  .then((res) => {
                    if (res.data.values.length > 0) {
                      data.push({
                        name: func.function_name,
                        data: res.data.values,
                      });
                      res.data.timestamps.map((time) => {
                        categories.push(Moment(time).format('llll'));
                      });
                    }
                    const chart = {
                      series: data.sort(),
                      options: {
                        chart: {
                          id: 'overview-chart',
                          stacked: true,
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: true,
                          },
                        },
                        title: {
                          text: 'errors',
                          align: 'left',
                        },
                        dataLabels: {
                          enabled: true,
                        },
                        legend: {
                          show: false,
                        },
                        colors: [
                          '#1d7aff',
                          '#ff4f8b',
                          '#ff875c',
                          '#14ca88',
                          '#81889d',
                        ],
                        stroke: {
                          width: 1.5,
                          curve: 'smooth',
                        },
                        yaxis: {
                          tickAmount: 5,
                          min: 0,
                        },
                        xaxis: {
                          type: 'datetime',
                          categories: categories
                            .filter(onlyUnique)
                            .sort(
                              (a, b) => Moment(b, 'llll') - Moment(a, 'llll')
                            )
                            .reverse(),
                        },
                        tooltip: {
                          x: {
                            format: 'dd MMM yyyy HH:mm',
                          },
                        },
                      },
                    };
                    dispatch({
                      type: GET_AWS_OVERVIEW_SUCCESS,
                      payload: {
                        series: chart.series,
                        options: chart.options,
                      },
                    });
                  })
                  .catch((error) => {
                    dispatch({ type: GET_AWS_OVERVIEW_ERROR, error });
                  });
              });
            })
            .catch((error) =>
              dispatch({ type: GET_AWS_OVERVIEW_ERROR, error })
            );
        });
      })
      .catch((error) => dispatch({ type: GET_AWS_OVERVIEW_ERROR, error }));
  };
};

export const getDuration = (habitat, startTime, endTime, funcName) => {
  return (dispatch) => {
    let awsDuration = {
      dataMax: [],
      dataAvg: [],
      dataMin: [],
      timestamps: [
        Moment(startTime).format('llll'),
        Moment(endTime).format('llll'),
      ],
    };
    const metricName = 'Duration';
    const period = 300;
    const stat = 'Maximum';
    const unit = 'Milliseconds';
    const max = 'maxDuration1';
    const min = 'minDuration1';
    const avg = 'avgDuration1';
    dispatch({ type: GET_AWS_DURATION_BEGIN });
    getMetrics(
      habitat.id,
      startTime,
      endTime,
      period,
      metricName,
      funcName,
      stat,
      unit,
      max
    )
      .then((res) => {
        if (res.data.values.length > 0) {
          awsDuration.dataMax = res.data.values;
          res.data.timestamps.map((t) => {
            awsDuration.timestamps.push(Moment(t).format('llll'));
          });
        }
        getMetrics(
          habitat.id,
          startTime,
          endTime,
          period,
          metricName,
          funcName,
          stat,
          unit,
          min
        )
          .then((res) => {
            if (res.data.values.length > 0) {
              awsDuration.dataMin = res.data.values;
              res.data.timestamps.map((t) => {
                awsDuration.timestamps.push(Moment(t).format('llll'));
              });
            }
            getMetrics(
              habitat.id,
              startTime,
              endTime,
              period,
              metricName,
              funcName,
              stat,
              unit,
              avg
            )
              .then((res) => {
                if (res.data.values.length > 0) {
                  awsDuration.dataAvg = res.data.values;
                  res.data.timestamps.map((t) => {
                    awsDuration.timestamps.push(Moment(t).format('llll'));
                  });
                }

                const durationChart = {
                  series: [
                    {
                      name: 'Duration Maximum',
                      data: awsDuration.dataMax,
                    },
                    {
                      name: 'Duration Average',
                      data: awsDuration.dataAvg,
                    },
                    {
                      name: 'Duration Minimum',
                      data: awsDuration.dataMin,
                    },
                  ],
                  options: {
                    chart: {
                      id: 'dur-chart',
                      stacked: false,
                      zoom: {
                        enabled: false,
                      },
                      toolbar: {
                        show: true,
                      },
                    },
                    title: {
                      text: 'Duration (Milliseconds)',
                      align: 'left',
                    },
                    dataLabels: {
                      enabled: false,
                      labels: {
                        formatter: function (val) {
                          return val.toFixed(2);
                        },
                      },
                    },
                    legend: {
                      show: false,
                    },
                    colors: [
                      '#1d7aff',
                      '#ff4f8b',
                      '#ff875c',
                      '#14ca88',
                      '#81889d',
                    ],
                    stroke: {
                      width: 1.5,
                      curve: 'smooth',
                      lineCap: 'butt',
                    },
                    markers: {
                      size: 3.5,
                      strokeWidth: 2.5,
                      hover: {
                        size: undefined,
                      },
                    },
                    yaxis: {
                      tickAmount: 10,
                      min: 0,
                      labels: {
                        formatter: function (val) {
                          if (val) {
                            return val.toFixed(2);
                          }
                        },
                      },
                    },
                    xaxis: {
                      type: 'datetime',
                      categories: awsDuration.timestamps
                        .filter(onlyUnique)
                        .sort((a, b) => Moment(b, 'llll') - Moment(a, 'llll'))
                        .reverse(),
                    },
                    tooltip: {
                      x: {
                        format: 'dd MMM yyyy HH:mm',
                      },
                    },
                  },
                };
                dispatch({
                  type: GET_AWS_DURATION_SUCCESS,
                  payload: {
                    series: durationChart.series,
                    options: durationChart.options,
                    timestamps: awsDuration.timestamps
                      .filter(onlyUnique)
                      .sort((a, b) => Moment(b, 'llll') - Moment(a, 'llll'))
                      .reverse(),
                  },
                });
              })
              .catch((error) => {
                dispatch({ type: GET_AWS_DURATION_ERROR, error });
              });
          })
          .catch((error) => {
            dispatch({ type: GET_AWS_DURATION_ERROR, error });
          });
      })
      .catch((error) => {
        dispatch({ type: GET_AWS_DURATION_ERROR, error });
      });
  };
};

export const getMemory = (habitat, funcName) => {
  const limit = 1;

  return (dispatch) => {
    dispatch({
      type: GET_AWS_MEMORY_BEGIN,
      loading_message: 'Loading memory...',
    });
    getListLogs(habitat.id, funcName, limit)
      .then((response) => {
        let memoryData = {};
        let memorySize = [];
        let memoryUsed = [];

        const logStreamName = response.data[0].log_stream_name;
        getLog(habitat.id, funcName, logStreamName)
          .then((memoryResponse) => {
            memoryResponse.data.map((e) => {
              if (e.message.indexOf('REPORT') >= 0) {
                const xx = e.message.indexOf('Memory Size:');
                const yy = e.message.indexOf('Max Memory Used:');
                const zz = e.message.indexOf('Init Duration:');
                const aa = e.message.indexOf('Max');

                const memorySizeStr = e.message.substring(xx, yy);

                let memorySizeNum = memorySizeStr.match(/\d/g);
                memorySizeNum = memorySizeNum.join('');

                let testNumber = parseInt(memorySizeNum);
                memorySize.push(testNumber);

                if (e.message.indexOf('Init Duration:') >= 0) {
                  const maxMemoryUsedInit = e.message.substring(aa, zz);

                  let maxMemoryUsedInitNum = maxMemoryUsedInit.match(/\d/g);
                  maxMemoryUsedInitNum = maxMemoryUsedInitNum.join('');

                  let testNumber1 = parseInt(maxMemoryUsedInitNum);
                  memoryUsed.push(testNumber1);
                } else {
                  const maxMemoryUsed = e.message.substring(aa);

                  let maxMemoryUsedNum = maxMemoryUsed.match(/\d/g);
                  maxMemoryUsedNum = maxMemoryUsedNum.join('');

                  let testNumber1 = parseInt(maxMemoryUsedNum);
                  memoryUsed.push(testNumber1);
                }
              }
            });
            memoryData = {
              memorySize: memorySize,
              memoryUsed: memoryUsed,
            };
            dispatch({
              type: GET_AWS_MEMORY_SUCCESS,
              payload: memoryData,
            });
          })
          .catch((error) => {
            dispatch({ type: GET_AWS_MEMORY_ERROR, error });
          });
      })
      .catch((error) => {
        dispatch({ type: GET_AWS_MEMORY_ERROR, error });
      });
  };
};
