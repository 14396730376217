import React, { useState, useEffect } from 'react';
import '../assets/styles/pages/billing.scss';
import { connect } from 'react-redux';
import Noty from 'noty';
import Moment from 'moment';
import './../../node_modules/noty/lib/noty.css';
import './../../node_modules/noty/lib/themes/metroui.css';
import SettingsFooter from '../components/settings/Footer';
import { Row, Col } from 'react-bootstrap';
import BillingUserSubscription from '../components/billing/BillingUserSubscription';
import BillingTeamsList from '../components/billing/BillingTeamsList';
import SubscriptionCard from '../components/billing/SubscriptionCard';
import PaymentCard from '../components/billing/PaymentCard';
import BillingTable from '../components/billing/BillingTable';
import PlanUserModal from '../components/billing/modals/PlanUserModal';
import PlanTeamModal from '../components/billing/modals/PlanTeamModal';
import { withRouter } from 'react-router-dom';
import routes from '../constants/routes';
import { getCheckoutData } from '../actions/checkout-actions';
import {
  getCustomerBilling,
  deleteSubscription, 
  getCustomerCard,
  addCardData,
  addCheckout,
  deleteCard,
} from '../actions/billing.actions';
import { fetchTeams, fetchMembers } from '../actions/team.actions';

const showNotification = {
  showNotification() {
    new Noty({
      theme: 'metroui',
      type: 'information',
      layout: 'bottomRight',
      text: 'Sending Transaction...',
      timeout: 3000,
    }).show();
  },
  showError() {
    new Noty({
      theme: 'metroui',
      type: 'error',
      layout: 'bottomRight',
      text: 'Card Denied',
      timeout: 5000,
    }).show();
  },
  showSucess() {
    new Noty({
      theme: 'metroui',
      type: 'success',
      layout: 'bottomRight',
      text: 'Transation Sucessful',
      timeout: 5000,
    }).show();
  },
};

// Billing is being reworked and for the moment we'll use a placeholder subs
// Usually uses currentUser.subscription
const dummySub = {
  name: 'Prod',
  stripe_id: '123456',
};

let notyBool = false;
const Billing = withRouter(
  ({
    paymentNotification,
    getCustomerBilling,
    deleteSubscription, 
    subscriptionInfo,
    getCheckoutData,
    getCustomerCard,
    fetchMembers,
    paymentStart,
    addCardData,
    addCheckout,
    currentUser,
    deleteCard,
    fetchTeams,
    message,
    members,
    billing,
    history,
    error,
    team,
    card,
  }) => {
    const [modalComparePlanUser, setModalComparePlanUser] = useState(
      'display-none'
    );
    const [modalComparePlanTeam, setModalComparePlanTeam] = useState(
      'display-none'
    );
    const [currentPlan1, setCurrentPlan1] = useState('Current Plan');
    const [currentPlan2, setCurrentPlan2] = useState('Current Plan');
    const [currentPlan3, setCurrentPlan3] = useState('Current Plan');
    const [currentPlan4, setCurrentPlan4] = useState('Current Plan');
    const [currentPlan5, setCurrentPlan5] = useState('Current Plan');
    const [currentPlan6, setCurrentPlan6] = useState('Current Plan');
    const [currentTeam, setCurrentTeam]   = useState(team.team);
    const [plan1, setPlan1]               = useState('basic');
    const [plan2, setPlan2]               = useState('developer');
    const [plan3, setPlan3]               = useState('basic');
    const [plan4, setPlan4]               = useState('startup');
    const [plan5, setPlan5]               = useState('pro');
    const [plan6, setPlan6]               = useState('enterprice');
    const [priceYearly, setPriceYearly]   = useState('12.99');
    const [nameInterval, setNameInterval] = useState('monthly');
    const [priceStartUp, setPriceStartUp] = useState('29.99');
    const [pricePro, setPricePro]         = useState('12.99');
    const [show, setShow]                 = useState(false);
    const [activeName, setActiveName]     = useState('');

    let description_val = '';
    let features_val = [];

    useEffect(() => {
      if (team.name) {
        setActiveName(team.name);
        fetchMembers(team.id);
      } else {
        setActiveName(currentUser.name);
      }
      getCustomerBilling(currentTeam);
      getCustomerCard(currentTeam);
    }, []);

    const handleGetInfo = (team, name) => {
      if (name) {
        setActiveName(name);
      } else {
        setActiveName(currentUser.name);
      }
      setCurrentTeam(team);
      fetchMembers(team.id)
      getCustomerBilling(team);
      getCustomerCard(team);
    };

    const handeleCancelSubscription = () => {
      deleteSubscription(currentTeam);
      setModalComparePlanUser('display-none')
      setModalComparePlanTeam('display-none');
      fetchTeams();
      setShow(false); 
    };

    const handleGetCardInfo = () => {
      addCardData(currentTeam);
      history.push(routes['CREDIT CARD']);
    };

    const handlePrice = () => {
      if (priceYearly === '12.99') {
        setPriceYearly('119.88');
        setNameInterval('yearly');
      } else {
        setPriceYearly('12.99');
        setNameInterval('monthly');
      }
    };

    const handlePriceTeams = () => {
      if (priceStartUp === '29.99') {
        setPriceStartUp('329.99');
        setPriceYearly('329.99');
        setNameInterval('yearly');
      } else {
        setPriceStartUp('29.99');
        setPriceYearly('29.99');
        setNameInterval('monthly');
      }

      if (pricePro === '12.99') {
        setPricePro('119.88');
        setPriceYearly('119.88');
        setNameInterval('yearly');
      } else {
        setPricePro('12.99');
        setPriceYearly('12.99');
        setNameInterval('monthly');
      }
    };

    const handleModalPlanUser = () => {
      if (modalComparePlanUser === 'display-none') {
        setModalComparePlanUser('display-modal');
      } else {
        setModalComparePlanUser('display-none');
      }

      if (currentUser.subscription === plan1) {
        setCurrentPlan1('Current Plan');
      } else {
        setCurrentPlan1('Upgrade');
      }

      if (currentUser.subscription === plan2) {
        setCurrentPlan2('Current Plan');
      } else {
        setCurrentPlan2('Upgrade');
      }
    };

    const handleModalPlanTeam = () => {
      const teamSuscription = team.team.subscription;

      if (modalComparePlanTeam === 'display-none') {
        setModalComparePlanTeam('display-modal');
      } else {
        setModalComparePlanTeam('display-none');
      }

      if (teamSuscription === plan3) {
        setCurrentPlan3('Current Plan');
      } else {
        setCurrentPlan3('Free');
      }

      if (teamSuscription === plan4) {
        setCurrentPlan4('Current Plan');
      } else {
        setCurrentPlan4('Upgrade');
      }

      if (teamSuscription === plan5) {
        setCurrentPlan5('Current Plan');
      } else {
        setCurrentPlan5('Upgrade');
      }
    };

    const handleGoCheckout = (planName, interval) => {
      let price;
      if (planName === 'basic') {
        price = '0.00';
        description_val = 'For small medium-sized ideas';
        features_val = [
          {
            id: '1',
            feature_content: '5 functions',
          },
          {
            id: '2',
            feature_content: '2 Collections',
          },
          {
            id: '3',
            feature_content: '2 Habitats',
          },
        ];
      } else if (planName === 'developer') {
        price = priceYearly;
        description_val =
          'For companies that require advanced tools, dynamic team options and priority support.';
        features_val = [
          {
            id: '1',
            feature_content: 'Unlimited Functions',
          },
          {
            id: '2',
            feature_content: 'Unlimited Collections',
          },
          {
            id: '3',
            feature_content: 'Unlimited Habitats',
          },
        ];
      } else if (planName === 'startup') {
        description_val = 'For small medium-sized ideas';
        price = priceStartUp;
        features_val = [
          {
            id: '1',
            feature_content: 'Up to 5 members',
          },
          {
            id: '2',
            feature_content: 'Up to 8 Habitats',
          },
          {
            id: '3',
            feature_content: 'Up to 6 functions per habitat',
          },
        ];
      } else if (planName === 'pro') {
        price = pricePro
        description_val =
          'For power users that require advanced tools';
        features_val = [
          {
            id: '1',
            feature_content: 'Unlimited team members',
          },
          {
            id: '2',
            feature_content: 'Unlimited habitats',
          },
          {
            id: '3',
            feature_content: 'Unlimited functions',
          },
        ];
      }

      if (currentTeam.name) {
        const data = {
          nameplan: planName,
          interval_d: nameInterval,
          description: description_val,
          features: features_val,
          price: price,
          quantity: members.length,
          loading: false,
          error: null,
        };
        addCheckout(data, currentTeam);
        history.push(routes.CHECKOUT);
      } else {
        const data = {
          nameplan: planName,
          interval_d: nameInterval,
          description: description_val,
          features: features_val,
          price: price,
          quantity: 1,
          loading: false,
          error: null,
        };
        addCheckout(data, currentTeam);
        history.push(routes.CHECKOUT); 
      }
    };

    let subscription = dummySub;
    let paymentNotificationDowngrade;

    if (paymentNotification != null) {
      paymentNotificationDowngrade = paymentNotification.stripe_id;
    } else {
      paymentNotificationDowngrade = dummySub.stripe_id;
    }

    if (paymentStart.notLoad === true && paymentStart.loading === true) {
      showNotification.showNotification();
      notyBool = true;
    }
    if (paymentStart.error != null && notyBool === true) {
      showNotification.showError();
      notyBool = false;
    }

    if (paymentNotification != null && notyBool === true) {
      const payment = paymentNotification;
      if (payment.next_due_date) {
        const dueDate = Moment(payment.next_due_date);
        const today = Moment();
        if (today.isBefore(dueDate)) {
          showNotification.showSucess();
          notyBool = false;
          subscription = 'pro';
          refreshPage();
        }
      }
    }

    function refreshPage() {
      window.location.reload(false);
    }

    return (
      <Row className="billing-main-content">
        <PlanUserModal
          plan5={plan5}
          currentUser={currentUser}
          handlePrice={handlePrice}
          priceYearly={priceYearly}
          currentPlan1={currentPlan1}
          currentPlan2={currentPlan2}
          nameInterval={nameInterval}
          handleGoCheckout={handleGoCheckout}
          handlePriceTeams={handlePriceTeams}
          modalComparePlanUser={modalComparePlanUser}
          setModalComparePlanUser={setModalComparePlanUser}
          handeleCancelSubscription={handeleCancelSubscription}
        />
        <PlanTeamModal
          plan3={plan3}
          plan4={plan4}
          plan5={plan5}
          plan6={plan6}
          team={currentTeam}
          pricePro={pricePro}
          currentPlan3={currentPlan3}
          currentPlan4={currentPlan4}
          currentPlan5={currentPlan5}
          currentPlan6={currentPlan6}
          priceStartUp={priceStartUp}
          handleGoCheckout={handleGoCheckout}
          handlePriceTeams={handlePriceTeams}
          modalComparePlanTeam={modalComparePlanTeam}
          setModalComparePlanTeam={setModalComparePlanTeam}
          handeleCancelSubscription={handeleCancelSubscription}
        />

        <Col md={5} lg={5} xl={5} className="leftSettingsCol border-right">
          <div className="container-billing-left">
            <BillingUserSubscription 
              activeName={activeName}
              handleGetInfo={handleGetInfo} 
            />
            <BillingTeamsList
              activeName={activeName} 
              handleGetInfo={handleGetInfo} 
            />
          </div>
          <SettingsFooter />
        </Col>
        <Col md={7} lg={7} xl={7} className="rightSettingsCol">
          <div className="container-billing-right">
            <div className="payment-subscription-container">
              <SubscriptionCard
                show={show}
                setShow={setShow}
                team={currentTeam}
                currentUser={currentUser}
                subscriptionInfo={subscriptionInfo}
                handleModalPlanUser={handleModalPlanUser}
                handleModalPlanTeam={handleModalPlanTeam}
                setModalComparePlanTeam={setModalComparePlanTeam}
                setModalComparePlanUser={setModalComparePlanUser}
                handeleCancelSubscription={handeleCancelSubscription}
              />
              <PaymentCard
                handleGetCardInfo={handleGetCardInfo}
                deleteCard={deleteCard}
                message={message}
                team={currentTeam}
                error={error}
                card={card}
              />
            </div>
            <div className="container-billing-history">
              <BillingTable billing={billing} />
            </div>
          </div>
        </Col>
      </Row>
    );
  }
);

const mapState = (state) => ({
  team: state.team,
  history: state.history,
  card: state.billing.card,
  error: state.billing.error,
  paymentStart: state.payment,
  currentUser: state.user.user,
  message: state.billing.message,
  members: state.team.team_members,
  billing: state.billing.billing,
  subscriptionInfo: state.billing.subscription,
  paymentNotification: state.payment.proPayment['data'],
});

const mapDispatch = {
  getCustomerBilling,
  deleteSubscription, 
  getCheckoutData,
  getCustomerCard,
  fetchMembers,
  addCardData,
  addCheckout,
  fetchTeams,
  deleteCard,
};

export default connect(mapState, mapDispatch)(Billing);
