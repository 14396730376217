import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import '../assets/styles/containers/SettingsPage.scss';
import { Row, Col } from 'react-bootstrap';
import GITCredCard from '../components/settings/GITCredCard';
import AWSCredCard from '../components/settings/AWSCredCard';
import GITCredManage from '../components/settings/GITCredManage';
import SettingsFooter from '../components/settings/Footer';
import AWSPermissions from '../components/settings/AWSPermissions';
import { getUserAWSCreds } from '../actions/aws-creds.actions.js';
import { addAwsCredWithURL, deleteAWSCred } from '../actions/aws-creds.actions.js';

const SettingsPage = (
    {
        awsCredSelected,
        gitCredSelected,
        awsCredsUser,
        getUserAWSCreds,
        addAwsCredWithURL,
        error,
        loading
    }) => {

    useEffect(() => {
        getUserAWSCreds();
    }, []);

    const handleAddAWSCredsURL = () => {
        addAwsCredWithURL()
    }

    const handleDeleteCred = (credId) => {
        deleteAWSCred(credId)
    }

    return (
        <div className="credentials-main-content">
            <div md={5} lg={5} xl={5} className='leftSettingsCol border-right'>
                <div>
                <GITCredCard />
                <AWSCredCard />
                </div>
                
                <SettingsFooter />
            </div>
            <div className="rightSettingsCol">
                {awsCredSelected === "awsCreds" && (
                    <>
                        <AWSPermissions
                            awsCredsUser={awsCredsUser}
                            handleAddAWSCredsURL={handleAddAWSCredsURL}
                            error={error}
                            loading={loading}
                            awsCredSelected={awsCredSelected}
                            handleDeleteCred={handleDeleteCred}
                        />
                    </>
                )}

                {awsCredSelected === "gitCreds" && (
                    <GITCredManage
                        gitCredSelected={gitCredSelected}
                    />
                )}
            </div>
        </div>
    );
};

const mapState = (state, props) => ({
    awsCredSelected: state.settings.awsCredSelected,
    gitCredSelected: state.settings.gitCredSelected,
    awsCredsUser: state.awsCreds,
    error: state.awsCreds.error,
    loading: state.awsCreds.loading
});

const mapDispatch = {
    getUserAWSCreds,
    addAwsCredWithURL
};

export default connect(mapState, mapDispatch)(SettingsPage);