import {
  GET_AWS_BUILDS_BEGIN,
  GET_AWS_BUILDS_SUCCESS,
  GET_AWS_BUILDS_ERROR,
  GET_AWS_BUILDS_LOGS_BEGIN,
  GET_AWS_BUILDS_LOGS_SUCCESS,
  GET_AWS_BUILDS_LOGS_ERROR,
  GET_AWS_BUILDS_STATUS_BEGIN,
  GET_AWS_BUILDS_STATUS_SUCCESS,
  GET_AWS_BUILDS_STATUS_ERROR,
  GET_AWS_BUILDS_EXECUTION_BEGIN,
  GET_AWS_BUILDS_EXECUTION_SUCCESS,
  GET_AWS_BUILDS_EXECUTION_ERROR,
  DELETE_BUILD_BEGIN,
  DELETE_BUILD_SUCCESS,
  DELETE_BUILD_ERROR,
} from './types';
import {
  getBuilds,
  buildLogs,
  getBuildStatus,
  getBuildExecution,
  deleteBuild,
} from '../api/aws/sdk';

export const listBuilds = (habitat_id) => {
  return (dispatch) => {
    const payload = [];
    getBuilds(habitat_id)
      .then((response) => {
        response.data.forEach((build, index) => {
          payload.push({
            id: build.id,
            build_name: build.name,
            build_status: null,
            build_execution_id: build.build_execution_id,
            aws_build_id: build.id,
            created_at: build.created_at,
            update_at: build.update_at,
            executions: [],
          });
          getBuildExecution(build.id, habitat_id)
            .then((response) => {
              dispatch({
                type: GET_AWS_BUILDS_BEGIN,
                loading_message: 'Loading builds...',
              });
              response.data.forEach((execution) => {
                payload[index].executions.push(execution);
                payload[index].build_status = execution.build_status;
                const statusData = {
                  habitat_id,
                  id: execution.id,
                };
                getBuildStatus(statusData)
                  .then((response) => {
                    dispatch({
                      type: GET_AWS_BUILDS_SUCCESS,
                      payload,
                    });
                  })
                  .catch((error) =>
                    dispatch({
                      type: GET_AWS_BUILDS_ERROR,
                      errorGetBuilds: 'Get builds fail',
                      error,
                    })
                  );
              });
            })
            .catch((error) =>
              dispatch({
                type: GET_AWS_BUILDS_ERROR,
                errorGetBuilds: 'Get builds fail',
                error,
              })
            );
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_AWS_BUILDS_ERROR,
          errorGetBuilds: 'Get builds fail',
          error,
        })
      );
  };
};

export const deleteBuildCICD = (build_id, habitat_id) => {
  return (dispatch) => {
    const payload = [];
    dispatch({
      type: DELETE_BUILD_BEGIN,
      loading_message: 'Deleting build...',
    });
    deleteBuild(build_id, habitat_id)
      .then((response) => {
        if (response.data.length >= 1) {
          response.data.forEach((build, index) => {
            payload.push({
              id: build.id,
              build_name: build.name,
              build_status: null,
              build_execution_id: build.build_execution_id,
              aws_build_id: build.id,
              created_at: build.created_at,
              update_at: build.update_at,
              executions: [],
            });
            getBuildExecution(build.id, habitat_id)
              .then((response) => {
                response.data.forEach((execution) => {
                  payload[index].executions.push(execution);
                  payload[index].build_status = execution.build_status;
                  const statusData = {
                    habitat_id,
                    id: execution.id,
                  };
                  getBuildStatus(statusData)
                    .then((response) => {
                      dispatch({
                        type: DELETE_BUILD_SUCCESS,
                        payload,
                      });
                    })
                    .catch((error) =>
                      dispatch({
                        type: DELETE_BUILD_ERROR,
                        errorGetBuilds: 'Get builds fail',
                        error,
                      })
                    );
                });
              })
              .catch((error) =>
                dispatch({
                  type: DELETE_BUILD_ERROR,
                  errorGetBuilds: 'Get builds fail',
                  error,
                })
              );
          });
        } else {
          dispatch({
            type: DELETE_BUILD_SUCCESS,
            payload,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_BUILD_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const listBuildLogs = (executions_id, habitat_id) => {
  const logs = [];
  let log_message = '';
  return (dispatch) => {
    const data = {
      habitat_id,
      id: executions_id,
    };
    dispatch({
      type: GET_AWS_BUILDS_LOGS_BEGIN,
      loading_message: 'Loading logs...',
    });
    buildLogs(data)
      .then((response) => {
        response.data.forEach((log) => {
          log_message += log.message;
        });
        dispatch({
          type: GET_AWS_BUILDS_LOGS_SUCCESS,
          payload: log_message,
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_AWS_BUILDS_LOGS_ERROR,
          errorListLogs: 'Get logs fail',
          error,
        })
      );
  };
};

export const listExecutions = (habitat_id, build_id) => {
  return (dispatch) => {
    dispatch({
      type: GET_AWS_BUILDS_EXECUTION_BEGIN,
      loading_message: 'Loading build executions...',
    });
    getBuildExecution(build_id, habitat_id)
      .then((response) => {
        dispatch({
          type: GET_AWS_BUILDS_EXECUTION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({ type: GET_AWS_BUILDS_EXECUTION_ERROR, error })
      );
  };
};

export const listBuildStatus = (id, habitat_id) => {
  return (dispatch) => {
    const data = {
      habitat_id,
      id,
    };
    dispatch({
      type: GET_AWS_BUILDS_STATUS_BEGIN,
      loading_message: 'Loading logs...',
    });
    getBuildStatus(data)
      .then((response) => {
        dispatch({
          type: GET_AWS_BUILDS_STATUS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_AWS_BUILDS_STATUS_ERROR,
          errorListExec: 'Get executions fail',
          error,
        })
      );
  };
};
