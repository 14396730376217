import React from 'react';
import { ListGroup } from 'react-bootstrap';
import HabitatItem from './HabitatItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const HabitatList = (props) => {
    const stepAppTour = props.stepAppTour;
    return (
        <div className="habitat-main-list-item">

            {props.errorMessage && (
                <div className="error-display text-danger">
                    <p><b><FontAwesomeIcon icon={faExclamationCircle} /> Error: {props.errorMessage} </b></p>
                </div>
            )}

            {props.habitats.length > 1 && props.stepAppTour === 4 && (
                <ListGroup>
                    {props.habitats.map((habitat, index) => (
                        <div className={'habitat-list-item-wrapper onboardingClassHabitat-' + index} key={habitat.id} id={'habitat-' + index}>
                            <HabitatItem value={habitat} />
                        </div>
                    ))}

                </ListGroup>
            )}

            {props.habitats.length === 1 && props.stepAppTour === 4 && (
                <ListGroup>
                    {props.habitats.map((habitat, index) => (
                        <div className={'habitat-list-item-wrapper onboardingClassHabitat-0'} key={habitat.id} id={'habitat-0'}>
                            <HabitatItem value={habitat} />
                        </div>
                    ))}

                </ListGroup>
            )}

            {props.habitats && props.stepAppTour != 4 && (
                <ListGroup>
                    {props.habitats.map((habitat, index) => (
                        <div className={'habitat-list-item-wrapper'} key={habitat.id} id={'habitat-' + index}>
                            <HabitatItem value={habitat} />
                        </div>
                    ))}
                </ListGroup>
            )}
        </div>
    );
}

export default HabitatList;