import produce from 'immer';

import {
  LOGIN_BEGIN,
  LOGIN_ERROR,
  SIGNUP_BEGIN,
  SIGNUP_ERROR,
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  LOGOUT_SUCCESS,
  DELETE_USER_BEGIN,
  DELETE_USER_ERROR,
  UPDATE_USER_BEGIN,
  UPDATE_USER_ERROR,
  WAITING_LIST_BEGIN,
  WAITING_LIST_ERROR,
  SIGNUP_OAUTH_BEGIN,
  DELETE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  WAITING_LIST_SUCCESS,
  SIGNUP_OAUTH_SUCCESS,
  RESET_PASSWORD_BEGIN,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  CHANGE_ONBOARDING_BEGIN,
  CHANGE_ONBOARDING_ERROR,
  UPDATE_SUBSCRIPTION_PLAN,
  CHANGE_ONBOARDING_SUCCESS,
} from '../actions/types';

const initialState = {
  user: null,
  loading: false,
  error: null,
  message: null
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case LOGIN_BEGIN:
    case SIGNUP_BEGIN:
    case UPDATE_USER_BEGIN:
    case DELETE_USER_BEGIN:
    case WAITING_LIST_BEGIN:
    case SIGNUP_OAUTH_BEGIN:
    case RESET_PASSWORD_BEGIN:
    case CHANGE_ONBOARDING_BEGIN:
      draft.loading = true;
      draft.error = null;
      return;
    case WAITING_LIST_SUCCESS:
      draft.loading = false;
      draft.error = null;
      return;
    case SIGNUP_SUCCESS:
    case LOGIN_SUCCESS:
      draft.loading = false;
      if(action.payload.data == null) {
        draft.user = action.payload;
      } else {
        draft.user = action.payload.data.data;        
      }                  
      return;
    case DELETE_USER_SUCCESS:
    case SIGNUP_OAUTH_SUCCESS:
    case RESET_PASSWORD_SUCCESS:  
      draft.loading = false;
      return;
    case UPDATE_USER_SUCCESS:
      draft.loading = false;
      draft.user = action.payload;
      draft.message = 'Change success';
      return;   
    case  CHANGE_ONBOARDING_SUCCESS:
      draft.loading = false;
      draft.user = action.payload
      return;
    case LOGIN_ERROR:
    case SIGNUP_ERROR:
    case DELETE_USER_ERROR:
    case WAITING_LIST_ERROR:
    case RESET_PASSWORD_ERROR:
    case  CHANGE_ONBOARDING_ERROR:    
      draft.loading = false;
      draft.error = action.error;
      draft.user = null;
      draft.message = action.error;
      return;
    case UPDATE_USER_ERROR:
      draft.loading = false;
      draft.error = action.error;
      draft.message = action.error.error;
      return;    
    case LOGOUT_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.user = null;
      return;
    case UPDATE_SUBSCRIPTION_PLAN:
      draft.user.subscription.name = action.payload;
      break      
    default:
      return;
  }
}, initialState);

export default reducer;
