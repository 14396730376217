export const runtimeList = () => {
    return (
      [
        {
          Name: 'Node.js 12',
          Identifier: 'nodejs12.x',
          SDK: '2.585.0',
          OS: 'Amazon Linux 2'
        },
        {
          Name: 'Node.js 10',
          Identifier: 'nodejs10.x',
          SDK: '2.585.0',
          OS: 'Amazon Linux 2'
        },
        {
          Name: 'Python 3.8',
          Identifier: 'python3.8',
          SDK: 'boto3-1.10.34 botocore-1.13.34',
          OS: 'Amazon Linux 2'
        },
        {
          Name: 'Python 3.7',
          Identifier: 'python3.7',
          SDK: 'boto3-1.10.34 botocore-1.13.34',
          OS: 'Amazon Linux'
        },
        {
          Name: 'Python 3.6',
          Identifier: 'python3.6',
          SDK: 'boto3-1.10.34 botocore-1.13.34',
          OS: 'Amazon Linux'
        },
        {
          Name: 'Python 2.7',
          Identifier: 'python2.7',
          SDK: 'boto3-1.10.34 botocore-1.13.34',
          OS: 'Amazon Linux'
        },
        {
          Name: 'Ruby 2.5',
          Identifier: 'ruby2.5',
          SDK: '3.0.1',
          OS: 'Amazon Linux'
        },
        {
          Name: 'Ruby 2.7',
          Identifier: 'ruby2.7',
          SDK: '3.0.1',
          OS: 'Amazon Linux 2'
        },
        {
          Name: 'Java 11',
          Identifier: 'java11',
          SDK: 'amazon-corretto-11',
          OS: 'Amazon Linux 2'
        },
        {
          Name: 'Java 8',
          Identifier: 'java8',
          SDK: 'java-1.8.0-openjdk',
          OS: 'Amazon Linux'
        },
        {
          Name: 'Go 1.x',
          Identifier: 'go1.x',
          SDK: '',
          OS: 'Amazon Linux'
        },
        {
          Name: '.NET Core 2.1',
          Identifier: 'dotnetcore2.1',
          SDK: 'C# PowerShell Core 6.0',
          OS: 'Amazon Linux'
        }
      ]
    )
  }