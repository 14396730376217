import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
const HabitatAPINewMethod = ({ funcs, habitat, awsRoles, selectedAPI, createRoute, methodError }) => {
  const [ path, setPath ]             = useState('');
  const [ method, setMethod ]         = useState('');
  const [ lambdaArn, setLambdaArn ]   = useState('');
  const [ methodType, setMethodType ] = useState('');
  
  return (
    <div className="mt-5">
      <p className="font-weight-bold">New Endpoint</p>

      {methodError && (
        <div className="error-display text-danger">
          <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {methodError}</p>
        </div>
      )}
      <div className="inputs-new-aws-credential">
        <Form.Control
          as="select"
          defaultValue={'DEFAULT'}
          onChange={e => setLambdaArn(e.target.value)}
        >
          <option disabled value="DEFAULT">--Select a Lambda--</option>
          {funcs.map(func => (
            func.function_arn && (
              <option key={func.id} value={func.function_arn}>{func.function_name}</option>
            )
          ))}
        </Form.Control>
        <Form.Control
          as="select"
          defaultValue={'DEFAULT'}
          onChange={e => setMethod(e.target.value)}
        >
          <option disabled value="DEFAULT">--Select a Method--</option>
          <option>POST</option>
          <option>GET</option>
          <option>PUT</option>
          <option>DELETE</option>
          <option>ANY</option>
        </Form.Control>
        <Form.Control
          type="text"
          placeholder="Path"
          onChange={(e => setPath(e.target.value))}
        />
        <Form.Control
          as="select"
          defaultValue={'DEFAULT'}
          onChange={e => setMethodType(e.target.value)}
        >
          <option disabled value="DEFAULT">--Select a Type--</option>
          <option>AWS</option>
          <option>MOCK</option>
          <option>AWS_PROXY</option>
        </Form.Control>
      </div>
      <div className="button-container-aws">
        <Button
          variant="primary"
          size="sm"
          className="newCredBtn-1"
          onClick={() => createRoute(path, selectedAPI, method, awsRoles, lambdaArn, habitat.id, methodType)}
        >
          + New Endpoint
        </Button>
      </div>
    </div>
  );
}

export default HabitatAPINewMethod;
