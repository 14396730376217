import React from 'react';
import '../../assets/styles/components/SettingsFooter.scss';
import logo from '../../assets/images/Kor-dark-logo.svg';

const Footer = () => {
  return (
    <div className='footer-settings'>            
        <img src={logo} className='logo-center' alt='KOR' />            
        <p className="content-section">
            © {new Date().getFullYear()} KOR Inc. All rights reserved. KOR is a registered trademark of FluxNetwork Inc.
            Terms, conditions, features, availability, pricing, fees, service and support options subject change
            without notice.
        </p>
    </div>
  );
}

export default Footer;