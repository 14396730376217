import {
  GET_TEAMS_BEGIN,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_ERROR,
  GET_TEAM_BEGIN,
  GET_TEAM_SUCCESS,
  GET_TEAM_ERROR,
  CREATE_TEAM_BEGIN,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_ERROR,
  GET_MEMBERS_BEGIN,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_ERROR,
  INVITE_MEMBER_BEGIN,
  INVITE_MEMBER_SUCCESS,
  INVITE_MEMBER_ERROR,
  GET_TEAM_SUBSCRIPTION_BEGIN,
  GET_TEAM_SUBSCRIPTION_SUCCESS,
  GET_TEAM_SUBSCRIPTION_ERROR,
  DELETE_TEAM_BEGIN,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_ERROR,
  UNSELECT_TEAM_BEGIN,
  UNSELECT_TEAM_SUCCESS,
  CHANGE_SELECTED_TEAM_BEGIN,
  CHANGE_SELECTED_TEAM_SUCCESS,
  CHANGE_SELECTED_TEAM_ERROR,
  CHANGE_DUPLICATED_TEAM_BEGIN,
  CHANGE_DUPLICATED_TEAM_SUCCESS,
  REMOVE_MEMBER_BEGIN,
  REMOVE_MEMBER_SUCCESS,
  REMOVE_MEMBER_ERROR,
  GET_VERIFICATION_DELETE_TEAM_BEGIN,
  GET_VERIFICATION_DELETE_TEAM_SUCCESS,
  GET_VERIFICATION_DELETE_TEAM_ERROR,
  GET_DETAILS_TEAM_BEGIN,
  GET_DETAILS_TEAM_SUCCESS,
  GET_DETAILS_TEAM_ERROR,
  GET_TEAMS_WITHMEMBERS_BEGIN,
  GET_TEAMS_WITHMEMBERS_SUCCESS,
  GET_TEAMS_WITHMEMBERS_ERROR
} from './types';
import {
  getTeam,
  newTeam,
  getTeams,
  getMembers,
  sendInvite,
  removeTeam,
  changeStatus,
  removeMember,
  getTeamSubscription,
  deleteVerification,
} from '../api/teams';
import { updateSubscription } from '../api/billing';

export const fetchTeamsWithMembers = () => {
  return (dispatch) => {
      dispatch({
        type: GET_TEAMS_WITHMEMBERS_BEGIN,
        loading_message: 'Loading teams...',
      })
      getTeams()
        .then(response => {
          const teams = response.data.teams;
          const teamsArray = [];
          teams.forEach((team) => {
            getMembers(team.id)
              .then(response => {
                const membersCount = response.data.members.length;
                teamsArray.push({
                  team: team,
                  members: membersCount
                })
                dispatch({
                  type: GET_TEAMS_WITHMEMBERS_SUCCESS,
                  payload: {
                    teams,
                    teamsArray
                  }
                })
              })
              .catch(error => dispatch({
                type: GET_TEAMS_WITHMEMBERS_ERROR ,
                errorGetTeams: 'Get teams fail',
                error
              }));
          });
        })
        .catch(error => dispatch ({
          type: GET_TEAMS_WITHMEMBERS_ERROR ,
          errorGetTeams: 'Get teams fail',
          error  
        }))
  }
}

// Get all teams
export const fetchTeams = () => {
  return (dispatch) => {
    dispatch({
      type: GET_TEAMS_BEGIN,
      loading_message: 'Loading teams...',
    });
    getTeams()
      .then((response) => {
        let team = {};
        let members = [];
        const teams = response.data.teams;
        teams.forEach((t) => {
          if (t.active) {
            team = t;
            getMembers(team.id)
              .then((response) => {
                members = response.data.members;
                dispatch({
                  type: GET_TEAMS_SUCCESS,
                  payload: {
                    teams,
                    team,
                    members,
                  },
                });
              })
              .catch((error) =>
                dispatch({
                  type: GET_TEAMS_ERROR,
                  errorGetTeams: 'Get teams fail',
                  error,
                })
              );
          } else {
            dispatch({
              type: GET_TEAMS_SUCCESS,
              payload: {
                teams,
                team: {},
                members: {},
              },
            });
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TEAMS_ERROR,
          errorGetTeams: 'Get teams fail',
          error,
        });
      });
  };
};

export const getDetailsTeam = (teamId) => {
  return (dispatch) => {
    dispatch({
      type: GET_DETAILS_TEAM_BEGIN,
      loading_message: 'Loading Team...'
    });
    getTeam(teamId)
      .then((response) => {
        let roleTeam = null;
        const team = {
          id: response.data.id,
          name: response.data.name,
          created_at: response.data.created_at,
          updated_at: response.data.updated_at,
          avatar: response.data.avatar,
          role: response.data.role,
          subscription: response.data.subscription,
        }
        getMembers(teamId)
          .then((response) => {
            const members = response.data;
            getTeams()
            .then(response => {
              const teams = response.data;
              teams.teams.forEach(teamA => {
                if(team.id === teamA.id){
                  roleTeam = teamA.role
                }
              })
              dispatch({
                type: GET_DETAILS_TEAM_SUCCESS,
                payload: {
                  teamDetails: team,
                  members: members,
                  roleTeam: roleTeam
                }
              })
            })
            .catch(error => {
              dispatch({
                type: GET_DETAILS_TEAM_ERROR,
                error
              })              
            })
          })
          .catch((error) => {
            dispatch({
              type: GET_DETAILS_TEAM_ERROR,
              error
            })
          })
      })
      .catch((error) => {
        dispatch({
          type: GET_DETAILS_TEAM_ERROR,
          error
        })
      })
  }
} 

// Get single team
export const fetchTeam = (teamId) => {
  return (dispatch) => {
    dispatch({
      type: GET_TEAM_BEGIN,
      loading_message: 'Loading team...',
    });
    getTeam(teamId)
      .then((response) => {
        const team = {
          id: response.data.id,
          name: response.data.name,
          created_at: response.data.created_at,
          updated_at: response.data.updated_at,
          role: response.data.role,
          subscription: response.data.subscription,
        };
        dispatch({
          type: GET_TEAM_SUCCESS,
          payload: team,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TEAM_ERROR,
          errorGetTeam: 'Get team fail',
          error,
        });
      });
  };
};

// Create team
export const createTeam = (teamName) => {
  return (dispatch) => {
    dispatch({
      type: CREATE_TEAM_BEGIN,
      loading_message: 'Creating team...',
    });
    newTeam(teamName)
      .then((response) => {
        dispatch({
          type: CREATE_TEAM_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: CREATE_TEAM_ERROR, error });
      });
  };
};

// Get all team members
export const fetchMembers = (teamId) => {
  return (dispatch) => {
    dispatch({
      type: GET_MEMBERS_BEGIN,
      loading_message: 'Loading members...',
    });
    getMembers(teamId)
      .then((response) => {
        dispatch({
          type: GET_MEMBERS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_MEMBERS_ERROR,
          errorGetMembers: 'Get members fail',
          error,
        });
      });
  };
};

// Invite member
export const inviteMember = (memberEmail, teamId) => {
  return (dispatch) => {
    dispatch({
      type: INVITE_MEMBER_BEGIN,
      loading_message: 'Sending invite...',
    });
    sendInvite(memberEmail, teamId)
      .then((response) => {
        dispatch({
          type: INVITE_MEMBER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: INVITE_MEMBER_ERROR,
          errorInviteMember: 'Invite member fail',
          error,
        });
      });
  };
};

// Invite member and charge
export const inviteAndCharge = (memberEmail, team, members) => {
  const data = {
    plan: team.subscription,
    team_id: team.id,
    quantity: members.length + 1,
  };
  return (dispatch) => {
    dispatch({
      type: INVITE_MEMBER_BEGIN,
      loading_message: 'Sending invite...',
    });
    updateSubscription(data)
      .then((response) => {
        sendInvite(memberEmail, team.id)
          .then((response) => {
            dispatch({
              type: INVITE_MEMBER_SUCCESS,
              payload: response.data,
            });
          })
          .catch((error) => dispatch({ type: INVITE_MEMBER_ERROR, error }));
      })
      .catch((error) => dispatch({ type: INVITE_MEMBER_ERROR, error }));
  };
};

// Get Team Subscription
export const getSubscriptionStartup = (teamId) => {
  return (dispatch) => {
    dispatch({
      type: GET_TEAM_SUBSCRIPTION_BEGIN,
      loading_message: 'Getting suscription...',
    });
    getTeamSubscription(teamId)
      .then((response) => {
        dispatch({
          type: GET_TEAM_SUBSCRIPTION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_TEAM_SUBSCRIPTION_ERROR, error });
      });
  };
};

// Delete Team
export const deleteTeam = (teamId) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_TEAM_BEGIN,
      loading_message: 'Delete team...',
    });
    removeTeam(teamId)
      .then((response) => {
        dispatch({
          type: DELETE_TEAM_SUCCESS,
          payload: teamId,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_TEAM_ERROR,
          errorDeleteTeam: 'Delete team fail',
          error,
        });
      });
  };
};

export const deleteTeamVerification = (teamId) => {
  return (dispatch) => {
    dispatch({
      type: GET_VERIFICATION_DELETE_TEAM_BEGIN,
      loading_message: 'Requesting delete team...',
    });
    deleteVerification(teamId)
      .then((response) => {
        dispatch({
          type: GET_VERIFICATION_DELETE_TEAM_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_VERIFICATION_DELETE_TEAM_ERROR, error });
      });
  };
};

export const unselectTeam = () => {
  return (dispatch) => {
    dispatch({
      type: UNSELECT_TEAM_BEGIN,
      loading_message: 'Unselecting team...',
    });
    dispatch({
      type: UNSELECT_TEAM_SUCCESS,
      payload: {},
    });
  };
};

// Leave team
export const leaveTeam = (team, currentUser, members) => {
  const info = members.find(
    (member) => currentUser.email === member.email && team.id === member.team_id
  );

  return (dispatch) => {
    dispatch({
      type: REMOVE_MEMBER_BEGIN,
      loading_message: 'Removing from team...',
    });
    removeMember(info.team_id, info.id)
      .then((response) => {
        dispatch({
          type: REMOVE_MEMBER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => dispatch({ type: REMOVE_MEMBER_ERROR, error }));
  };
};

export const changeSelectedTeam = (teamId) => {
  return (dispatch) => {
    let teamInfo = {};
    dispatch({
      type: CHANGE_SELECTED_TEAM_BEGIN,
      loading_message: 'Selecting team...',
    });
    changeStatus(teamId)
      .then((response) => {
        const teams = response.data.teams;
        teams.forEach((team) => {
          if (team.id === teamId) {
            if (team.active === true) {
              teamInfo = team;
            } else {
              teamInfo = {};
            }
          } else if (team.id !== teamId) {
            team.active = false;
          }
        });
        dispatch({
          type: CHANGE_SELECTED_TEAM_SUCCESS,
          payload: {
            teams,
            teamInfo,
          },
        });
      })
      .catch((error) => dispatch({ type: CHANGE_SELECTED_TEAM_ERROR, error }));
  };
};

export const deleteError = () => {
  return (dispatch) => {
    dispatch({ type: CHANGE_DUPLICATED_TEAM_BEGIN });
    dispatch({
      type: CHANGE_DUPLICATED_TEAM_SUCCESS,
      payload: {},
    });
  };
};
