import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const RemoveModal = ({ show, name, setShow, handleRemove }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="modal-remove"
    >
      <Modal.Header>Remove {name}</Modal.Header>
      <Modal.Body>Are you sure to delete {name}?</Modal.Body>
      <Modal.Footer>
        <Button size="sm" onClick={() => handleRemove()}>
          Yes
        </Button>
        <Button size="sm" variant="danger" onClick={() => setShow(false)}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveModal;
