import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import '../../assets/styles/modals/ModalKorSmallRight.scss'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import closeIcon from '../../assets/images/icons/close-icon.svg';
import logo from '../../assets/images/Kor-logo.svg';
import plus from '../../assets/images/icons/overview/plus.svg';


const CreateTeamModal = ({
    show,
    setShow,
    repoUrl,
    repoName,
    setRepoName,
    showNewTeam,
    setShowNewTeam,
    handleCreateTeam,
    setTeamName
}) => {
    return (
        <>
            {showNewTeam && (
                <div className="modal-kor-small-right">
                    <div className="box-modal-options-container-small-right teams-modal">

                        <div className="close-title">
                            <img src={closeIcon} onClick={() => setShowNewTeam(!showNewTeam)} />
                            <h3>Create New Team</h3>
                        </div>

                        <div className="team-name-update create-team-modal-input">
                            <div className="update-input-team">
                                <p>Team Name</p>
                                <Form.Control
                                    type="text"
                                    value={repoName}
                                    aria-label="Title"
                                    placeholder="New team name"
                                    aria-describedby="new-title"
                                    onChange={e => setTeamName(e.target.value)}
                                />
                            </div>
                            <p className="text-modal-muted">You are creating a team with the following specifications:</p>
                        </div>

                        <div className="membership-team-container teams-modal-create-membership">
                            <div className="membership-text">
                                <p>Membership: <span>Project</span></p>
                            </div>

                            <div>
                                <Button onClick={() => handleCreateTeam()}>
                                   <img src={plus} /> Create Team
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default CreateTeamModal;