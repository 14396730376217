import {
  GET_CARD_BEGIN,
  GET_CARD_SUCCESS,
  ADD_CHECKOUT_BEGIN,
  ADD_CHECKOUT_SUCCESS,
  PAYMENT_BEGIN,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
  ADD_STRIPE_CARD_BEGIN,
  ADD_STRIPE_CARD_SUCCESS,
  ADD_STRIPE_CARD_ERROR,
  GET_STRIPE_CARD_BEGIN,
  GET_STRIPE_CARD_SUCCESS,
  GET_STRIPE_CARD_ERROR,
  ADD_CHECKOUT_CARD_BEGIN,
  ADD_CHECKOUT_CARD_SUCCESS,
  ADD_CHECKOUT_CARD_ERROR,
  REMOVE_STRIPE_CARD_BEGIN,
  REMOVE_STRIPE_CARD_SUCCESS,
  REMOVE_STRIPE_CARD_ERROR,
  GET_STRIPE_INVOICE_BEGIN,
  GET_STRIPE_INVOICE_SUCCESS,
  GET_STRIPE_INVOICE_ERROR,
  GET_STRIPE_CUSTOMER_BEGIN,
  GET_STRIPE_CUSTOMER_SUCCESS,
  GET_STRIPE_CUSTOMER_ERROR,
  ADD_STRIPE_SUBSCRIPTION_BEGIN,
  ADD_STRIPE_SUBSCRIPTION_SUCCESS,
  ADD_STRIPE_SUBSCRIPTION_ERROR,
  UPDATE_STRIPE_SUBSCRIPTION_BEGIN,
  UPDATE_STRIPE_SUBSCRIPTION_SUCCESS,
  UPDATE_STRIPE_SUBSCRIPTION_ERROR,
  CANCEL_STRIPE_SUBSCRIPTION_BEGIN,
  CANCEL_STRIPE_SUBSCRIPTION_SUCCESS,
  CANCEL_STRIPE_SUBSCRIPTION_ERROR,
} from './types';
import {
  cancelSubscription,
  updateSubscription,
  addSubscription,
  confirmPayment,
  removeCard,
  getInvoice,
  getClient,
  addCard,
  getCard,
} from '../api/billing';
import Moment from 'moment';

export const getCustomer = (plan, interval) => {
  return (dispatch) => {
    dispatch({ type: GET_STRIPE_CUSTOMER_BEGIN });
    getClient(plan, interval)
      .then((response) => {
        dispatch({
          type: GET_STRIPE_CUSTOMER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => dispatch({ type: GET_STRIPE_CUSTOMER_ERROR, error }));
  };
};

export const paymentConfirm = (stripe, clientSecret, card, user) => {
  const data = {
    payment_method: card.id,
    receipt_email: user.email,
  };
  return (dispatch) => {
    dispatch({ type: PAYMENT_BEGIN });
    confirmPayment(stripe, clientSecret, data)
      .then((response) => {
        dispatch({
          type: PAYMENT_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => dispatch({ type: PAYMENT_ERROR, error }));
  };
};

export const addCustomerCard = (stripe, card, team, name, address) => {
  return (dispatch) => {
    dispatch({ type: ADD_STRIPE_CARD_BEGIN });
    stripe
      .createToken(card)
      .then((response) => {
        if (response.error) {
          const error = response.error.message;
          dispatch({ type: ADD_STRIPE_CARD_ERROR, error });
        } else {
          const data = {
            name,
            address,
            team_id: team.id,
            card_token: response.token.id,
          };
          addCard(data)
            .then((response) => {
              dispatch({
                type: ADD_STRIPE_CARD_SUCCESS,
                payload: {
                  message: 'Add card success',
                  card: response.data,
                },
              });
            })
            .catch((error) => dispatch({ type: ADD_STRIPE_CARD_ERROR, error }));
        }
      })
      .catch((error) => dispatch({ type: ADD_STRIPE_CARD_ERROR, error }));
  };
};

export const addCheckoutCard = (
  stripe,
  card,
  team,
  name,
  address,
  quantity,
  plan,
  interval
) => {
  return (dispatch) => {
    dispatch({ type: ADD_CHECKOUT_CARD_BEGIN });
    stripe
      .createToken(card)
      .then((response) => {
        if (response.error) {
          const error = response.error.message;
          dispatch({ type: ADD_CHECKOUT_CARD_ERROR, error });
        } else {
          const data = {
            name,
            address,
            team_id: team.id,
            card_token: response.token.id,
          };
          addCard(data)
            .then((response) => {
              const data = {
                plan,
                interval,
                quantity,
                team_id: team.id,
                trial_end: Moment().add(1, 'month').format('X'),
              };
              addSubscription(data)
                .then((response) => {
                  dispatch({
                    type: ADD_CHECKOUT_CARD_SUCCESS,
                    payload: response.data,
                  });
                })
                .catch((error) =>
                  dispatch({ type: ADD_CHECKOUT_CARD_ERROR, error })
                );
            })
            .catch((error) =>
              dispatch({ type: ADD_CHECKOUT_CARD_ERROR, error })
            );
        }
      })
      .catch((error) => dispatch({ type: ADD_CHECKOUT_CARD_ERROR, error }));
  };
};

export const getCustomerCard = (team) => {
  return (dispatch) => {
    dispatch({ type: GET_STRIPE_CARD_BEGIN });
    const data = {
      team_id: team.id,
    };
    getCard(data)
      .then((response) => {
        dispatch({
          type: GET_STRIPE_CARD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => dispatch({ type: GET_STRIPE_CARD_ERROR, error }));
  };
};

export const deleteCard = (team) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_STRIPE_CARD_BEGIN });
    const data = {
      team_id: team.id,
    };
    removeCard(data)
      .then((response) => {
        dispatch({
          type: REMOVE_STRIPE_CARD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => dispatch({ type: REMOVE_STRIPE_CARD_ERROR, error }));
  };
};

export const getCustomerBilling = (team) => {
  return (dispatch) => {
    dispatch({ type: GET_STRIPE_INVOICE_BEGIN });
    const data = {
      team_id: team.id,
    };
    getInvoice(data)
      .then((response) => {
        dispatch({
          type: GET_STRIPE_INVOICE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => dispatch({ type: GET_STRIPE_INVOICE_ERROR, error }));
  };
};

export const AddSubscription = (plan, interval, quantity, team) => {
  return (dispatch) => {
    dispatch({ type: ADD_STRIPE_SUBSCRIPTION_BEGIN });
    const data = {
      plan,
      interval,
      quantity,
      team_id: team.id,
      trial_end: Moment().add(30, 'day').format('X'),
    };
    addSubscription(data)
      .then((response) => {
        dispatch({
          type: ADD_STRIPE_SUBSCRIPTION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({ type: ADD_STRIPE_SUBSCRIPTION_ERROR, error })
      );
  };
};

export const editSubscription = (plan, interval, team) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_STRIPE_SUBSCRIPTION_BEGIN });
    const data = {
      plan,
      interval,
      team_id: team.id,
    };
    updateSubscription(data)
      .then((response) => {
        dispatch({
          type: UPDATE_STRIPE_SUBSCRIPTION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({ type: UPDATE_STRIPE_SUBSCRIPTION_ERROR, error })
      );
  };
};

export const deleteSubscription = (team) => {
  return (dispatch) => {
    dispatch({ type: CANCEL_STRIPE_SUBSCRIPTION_BEGIN });
    const data = {
      team_id: team.id,
    };
    cancelSubscription(data)
      .then((response) => {
        dispatch({
          type: CANCEL_STRIPE_SUBSCRIPTION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) =>
        dispatch({ type: CANCEL_STRIPE_SUBSCRIPTION_ERROR, error })
      );
  };
};

export const addCheckout = (data, team) => {
  return (dispatch) => {
    dispatch({ type: ADD_CHECKOUT_BEGIN });
    const payload = {
      product: data,
      team,
    };
    dispatch({
      type: ADD_CHECKOUT_SUCCESS,
      payload,
    });
  };
};

export const addCardData = (team) => {
  return (dispatch) => {
    dispatch({ type: GET_CARD_BEGIN });
    dispatch({
      type: GET_CARD_SUCCESS,
      payload: team,
    });
  };
};
