import React from 'react';
import FuncsMain from '../components/Funcs';
import { connect } from 'react-redux';

const FuncsPage = () => {
    return (
        <div className="main-content-app"> 
            <FuncsMain />
        </div>
    )

}

const mapState = () => ({  
})

const mapDispatch = {
}

export default connect(
  mapState,
  mapDispatch
)(FuncsPage)