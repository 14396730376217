import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import '../../assets/styles/modals/ModalKorSmallRight.scss'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import closeIcon from '../../assets/images/icons/close-icon.svg';
import user from '../../assets/images/icons/UserCircle.svg';
import Moment from 'moment';

const DeleteTeamModal = ({
    modalDeleteTeam,
    setModalDeleteTeam,
    deleteTeamVerificationState,
    teamDetailsTeam,
    handleDeleteTeam
}) => {
    const verification = deleteTeamVerificationState.verification
    return (
        <>
            { modalDeleteTeam && (
                <>

                    {verification === 409 ? (
                        <div className="modal-kor-small-right">
                            <div className="box-modal-options-container-small-right  teams-modal delete--modal">

                                <div className="close-title">
                                    <h3>Confirm deletion of <span>{teamDetailsTeam.name}</span></h3>
                                </div>

                                <div className="delete-modal-container">
                                    <div className="team-name-update">
                                        <div className="avatar-team">
                                            <img src={user} alt='logo'></img>
                                        </div>
                                        <div className="details-item">
                                            <p className="name-item-delete">{teamDetailsTeam.name}</p>
                                            <p className="last-updated-team">Last Updated <span>{Moment(teamDetailsTeam.created_at).format('lll')}</span></p>
                                            <div className="owner-team">
                                                <p>Managed</p>
                                                <div>
                                                    <p>
                                                        A
                                                    </p>
                                                    <p>
                                                        Author
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="verification-delete">
                                        <p className="error-text-delete">You can&apos;t delete this team</p>
                                        <div>
                                            <p>Resource Name</p>
                                            <p>{teamDetailsTeam.name}</p>
                                        </div>
                                        <div>
                                            <p>Type</p>
                                            <p>Team</p>
                                        </div>

                                        <p className="muted-text-2">You still have <b> {deleteTeamVerificationState.attached_users} </b> user(s) attached to this team, please address them before deletion.</p>
                                    </div>
                                </div>

                                <div className="buttons-delete-item">
                                    <Button onClick={() => setModalDeleteTeam(false)}>
                                        Return
                                     </Button>
                                </div>

                            </div>
                        </div>
                    ) : (
                        <div className="modal-kor-small-right">
                            <div className="box-modal-options-container-small-right  teams-modal delete--modal">

                                <div className="close-title">
                                    <h3>Confirm deletion of <span>{teamDetailsTeam.name}</span></h3>
                                </div>

                                <div className="delete-modal-container">
                                    <div className="team-name-update">
                                        <div className="avatar-team">
                                            <img src={user} alt='logo'></img>
                                        </div>
                                        <div className="details-item">
                                            <p className="name-item-delete">{teamDetailsTeam.name} </p>
                                            <p className="last-updated-team">Last Updated <span>{Moment(teamDetailsTeam.created_at).format('lll')}</span></p>
                                            <div className="owner-team">
                                                <p>Managed</p>
                                                <div>
                                                    <p>
                                                        A
                                                    </p>
                                                    <p>
                                                        Author
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="verification-delete">
                                        <p className="muted-text-1">Verify your information before delete the resource</p>
                                        <div>
                                            <p>Resource Name</p>
                                            <p>{teamDetailsTeam.name}</p>
                                        </div>
                                        <div>
                                            <p>Type</p>
                                            <p>Team</p>
                                        </div>

                                        <p className="muted-text-2">You can safely delete this team. Before we delete the resource, please verify all the information. </p>
                                    </div>
                                </div>

                                <div className="buttons-delete-item">
                                    <Button onClick={() => setModalDeleteTeam(false)}>
                                        Return
                            </Button>
                                    <Button onClick={() => handleDeleteTeam(teamDetailsTeam.id)}>
                                        Delete
                            </Button>
                                </div>

                            </div>
                        </div>
                    )}

                </>
            )}
        </>
    );
}

export default DeleteTeamModal;