import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import routes from '../constants/routes';
import { withRouter } from 'react-router-dom';
import '../assets/styles/containers/TemplatePage.scss';
import { getTemplateInfo } from '../actions/templates.actions';
import CardTemplate from '../components/templates/CardTemplate';
import HeaderTemplate from '../components/templates/HeaderTemplate';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchTeam } from '../actions/team.actions';
import { listGitCreds } from '../actions/git-creds.actions';
import { getUserAWSCreds } from '../actions/aws-creds.actions';

const TemplatePage = withRouter(({
  history,
  team,
  habitats,
  currentUser,
  listGitCreds,
  getUserAWSCreds,
  getTemplateInfo,
}) => {
  useEffect(() => {
    listGitCreds();
    getUserAWSCreds();
  }, []);

  const handleSelectTemplate = (templateName) => {
    getTemplateInfo(templateName);
    history.push(routes['TEMPLATE FORM']);
  }

  return (
    <div className="ctn-template p-5">
      <HeaderTemplate />

      {team.id ? (
        ((team.subscription === 'Project' && habitats.length >= 2) ||
          (team.subscription === 'Startup' && habitats.length >= 8)) && (
          <div className="error-display text-warning">
            <p><FontAwesomeIcon icon={faExclamationCircle} /> Alert: You need to upgrade your account to add more templates.</p>
          </div>
        )
      ) : currentUser.subscription === 'Basic' &&
      habitats.length >= 2 && (
          <div className="error-display text-warning">
            <p><FontAwesomeIcon icon={faExclamationCircle} /> Alert: You need to upgrade your account to add more templates.</p>
          </div>
      )}
      <CardTemplate 
        handleSelectTemplate={handleSelectTemplate} 
        team={team}
        habitats={habitats}
        currentUser={currentUser}
      />
    </div>
  );
});

const mapState = (state, props) => ({
  history: props.history,
  team: state.team.team,
  habitats: state.habitat.habitats,
  currentUser: state.user.user,
});

const mapDispatch = {
  listGitCreds,
  getUserAWSCreds,
  getTemplateInfo,
};

export default connect(
  mapState,
  mapDispatch
)(TemplatePage);