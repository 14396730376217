import React, { useState } from 'react';
import '../../assets/styles/components/billing/billing-payment-card.scss';
import AddCardModal from './modals/AddCardModal';
import { Button } from 'react-bootstrap';
import ToastSuccessError from '../toast/ToastSuccessError';
import RemoveModal from '../Modals/RemoveModal';
import SaveCard from './SaveCard';
const PaymentCard = (props) => {
  const [show, setShow]             = useState(false);
  const [toast, setToast]           = useState(false);
  const [showRemove, setShowRemove] = useState(false);

  const handleModal = () => {
    setShow(true);
  };

  const handleRemoveModal = () => {
    setShowRemove(true);
  }

  const handleRemove = () => {
    props.deleteCard(props.team);
    setShowRemove(false);
  }

  return (
    <div className="payment-card">
      <ToastSuccessError
        show={toast}
        setShow={setToast}
        success={props.message}
        error={props.error}
      />
      {props.card && (
        <RemoveModal 
          show={showRemove}
          name={'this card ' + props.card.last4}
          setShow={setShowRemove}
          handleRemove={handleRemove}
        />
      )}
      <AddCardModal show={show} setShow={setShow} setToast={setToast} team={props.team} />
      {props.card ? (
        <div>
          <div className="payment-method-card-tittle"><b>Payment Method</b>
            <SaveCard 
              card={props.card}
              handleRemoveModal={handleRemoveModal}
            />
          </div>
          <div className="d-flex justify-content-center mt-4">
            <Button onClick={props.handleGetCardInfo}>Edit Credit Card</Button>
          </div>
        </div>
      ):(
        <div>
          <p className="payment-method-card-tittle">
            <b>Payment Method</b>
          </p>
          <p>Only one credit card is allowed per team or account</p>
          <div className="d-flex justify-content-center">
            <Button onClick={props.handleGetCardInfo}>Add Credit Card</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentCard;
