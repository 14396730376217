import axios from 'axios';
const URL = process.env.REACT_APP_BACKEND_URL;

export const getClient = async (plan, interval) => {
  const data = {
    plan,
    interval
  };
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.post(`${URL}/billing/payment_intent`, data, {headers: authToken});
}

export const confirmPayment = (stripe, clientSecret, data) => {
  return stripe.confirmCardPayment(clientSecret, data);
}

export const addCard = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.post(`${URL}/billing/add_card`, data, {headers: authToken});
}

export const getCard = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.post(`${URL}/billing/get_card`, data, {headers: authToken});
}

export const removeCard = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.post(`${URL}/billing/remove_card`, data, {headers: authToken});
}

export const getInvoice = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.post(`${URL}/billing/get_invoice`, data, {headers: authToken});
}

export const addSubscription = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.post(`${URL}/billing/subscription`, data, {headers: authToken});
}

export const updateSubscription = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.post(`${URL}/billing/update_subscription`, data, {headers: authToken});
}

export const cancelSubscription = async (data) => {
  let authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return await axios.post(`${URL}/billing/cancel_subscription`, data, {headers: authToken});
}
