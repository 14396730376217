import React from 'react';
import '../../../assets/styles/modals/Modal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button'

const HabitatAuthDeletePool = (props) => {
    return (
        <>
            <div className={props.showRemoveModal + ' modal-kor-container'}>
                <div className="box-modal-options-container">
                    <p><FontAwesomeIcon icon={faExclamationTriangle} className="delete-icon" /> Remove User Pool</p>
                    <p>Are you sure you want to delete this user pool?</p>
                    <div className="buttons-modal-kor">
                        <Button variant="primary" size="sm" onClick={props.handleDeleteCognito}>
                            Delete
                        </Button>
                        <Button variant="primary" size="sm" onClick={() => props.setShowRemoveModal('display-none-modal-remove')}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </ div>
        </>
    )
}

export default HabitatAuthDeletePool;