import produce from 'immer';

import {
  ENCRYPT_KEY_BEGIN,
  ENCRYPT_KEY_SUCCESS,
  ENCRYPT_KEY_ERROR,
  CREATE_AWSCRED_BEGIN,
  CREATE_AWSCRED_SUCCESS,
  CREATE_AWSCRED_ERROR,
  GET_ALL_AWSCREDS_BEGIN,
  GET_ALL_AWSCREDS_SUCCESS,
  GET_ALL_AWSCREDS_ERROR,
  DELETE_AWSCRED_BEGIN,
  DELETE_AWSCRED_SUCCESS,
  DELETE_AWSCRED_ERROR,
  UPDATE_AWSCRED_BEGIN,
  UPDATE_AWSCRED_SUCCESS,
  UPDATE_AWSCRED_ERROR,
  GET_AWSCRED_BEGIN,
  GET_AWSCRED_SUCCESS,
  GET_AWSCRED_ERROR,
  VERIFY_CREDS_BEGIN,
  VERIFY_CREDS_SUCCESS,
  VERIFY_CREDS_ERROR,
  CLEAR_VERIFIED_ERROR_STATE,
  ENCRYPT_REQUEST_AWSCRED_BEGIN,
  ENCRYPT_REQUEST_AWSCRED_SUCCESS,
  ENCRYPT_REQUEST_AWSCRED_ERROR,
  GET_ALL_USER_AWSCREDS_BEGIN,
  GET_ALL_USER_AWSCREDS_SUCCESS,
  GET_ALL_USER_AWSCREDS_ERROR,
  CREATE_AWSCREDS_WITH_URL_BEGIN,
  CREATE_AWSCREDS_WITH_URL_SUCCESS,
  CREATE_AWSCREDS_WITH_URL_ERROR
} from '../actions/types';

const initialState = {
  awsCredential: null,
  awsCredentials: null,
  userCredentials: [],
  credsVerifiedLoading: false,
  credsVerified: false,
  credsVerifiedError: false,
  loading: false,
  loading_message: '',
  error: null,
  encryptkey: null
};

function remove(array, key, value) {
  const index = array.findIndex(obj => obj[key] === value);
  return index >= 0 ? [
    ...array.slice(0, index),
    ...array.slice(index + 1)
  ] : array;
}

function update(array, key, value) {
  var foundIndex = array.findIndex(obj => obj[key] === value.id);
  array[foundIndex] = value;
  return array;
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case GET_AWSCRED_BEGIN:
    case UPDATE_AWSCRED_BEGIN:
    case DELETE_AWSCRED_BEGIN:
    case CREATE_AWSCRED_BEGIN:
    case GET_ALL_AWSCREDS_BEGIN:
    case GET_ALL_USER_AWSCREDS_BEGIN:
    case ENCRYPT_REQUEST_AWSCRED_BEGIN:
    case CREATE_AWSCREDS_WITH_URL_BEGIN:
      draft.loading = true;
      draft.error = null;
      draft.loading_message = action.loading_message;
      return;
    case ENCRYPT_REQUEST_AWSCRED_SUCCESS:
      draft.loading = false;
      draft.awsCredential = action.payload
      draft.awsCredentials.push(action.payload);
      return;
    case VERIFY_CREDS_BEGIN:
      draft.credsVerifiedLoading = true;
      draft.credsVerifiedError = null;
      return;
    case GET_AWSCRED_SUCCESS:
      draft.loading = false;
      draft.awsCredential = action.payload
      return;
    case CREATE_AWSCRED_SUCCESS:
      draft.loading = false;
      draft.awsCredential = action.payload;
      return;
    case CREATE_AWSCREDS_WITH_URL_SUCCESS:
      draft.loading = false;
      draft.awsCredential = action.payload.credential;
      draft.userCredentials = action.payload.credentials;
      return;
    case GET_ALL_AWSCREDS_SUCCESS:
      draft.loading = false;
      draft.awsCredentials = action.payload;
      draft.error = null;
      return;
    case DELETE_AWSCRED_SUCCESS:
      draft.loading = false;
      draft.awsCredentials = remove(draft.awsCredentials, 'id', action.payload)
      draft.error = null;
      return;
    case UPDATE_AWSCRED_SUCCESS:
      draft.loading = false;
      draft.awsCredentials = update(draft.awsCredentials, 'id', action.payload)
      draft.error = null;
      return;
    case VERIFY_CREDS_SUCCESS:
      draft.credsVerifiedLoading = false;
      draft.credsVerifiedError = null;
      draft.awsCredentials = update(draft.awsCredentials, 'id', action.cred)
      draft.credsVerified = true;
      return;
    case GET_ALL_USER_AWSCREDS_SUCCESS:
      draft.loading = false;
      draft.userCredentials = action.payload;
      draft.error = null;
      return;
    case CLEAR_VERIFIED_ERROR_STATE:
      draft.credsVerifiedError = null;
      return;
    case GET_AWSCRED_ERROR:
    case UPDATE_AWSCRED_ERROR:
    case DELETE_AWSCRED_ERROR:
    case CREATE_AWSCRED_ERROR:
    case ENCRYPT_REQUEST_AWSCRED_ERROR:
    case CREATE_AWSCREDS_WITH_URL_ERROR:
      draft.loading = false;
      draft.awsCredentials = null;
      draft.error = action.error;
      return;
    case GET_ALL_USER_AWSCREDS_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return;
    case VERIFY_CREDS_ERROR:
      draft.credsVerified = false;
      draft.credsVerifiedLoading = false;
      draft.credsVerifiedError = action.error;
      return;
    case GET_ALL_AWSCREDS_ERROR:
      draft.loading = false;
      draft.error = action.errorListAwsCreds
      return;
    default:
      return;
  }
}, initialState);

export default reducer;
