import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import '../../assets/styles/components/billing/billing-team-subscription.scss';
import BillingTeamsSubscription from '../billing/BillingTeamsSubscription';
import { fetchTeams } from '../../actions/team.actions';

const BillingTeamsList = ({ handleGetInfo, fetchTeams, activeName, teams }) => {

    useEffect(() => {
        fetchTeams();
      }, [fetchTeams]);
    

    return(
        <div className="b-team-list-container">
            <p className="team-subscription-tittle">Teams Subscription</p>

            <BillingTeamsSubscription 
                teams={teams}
                activeName={activeName}
                fetchTeams={fetchTeams}
                handleGetInfo={handleGetInfo}
            />
        </div>
    )
}

const mapState = (state,props) => ({
    teams: state.team.teams
})

const mapDispatch = {
    fetchTeams
};

export default connect(mapState, mapDispatch)(BillingTeamsList);