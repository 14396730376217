import produce from 'immer';
import {
    CREATE_AWS_COGNITO_BEGIN, CREATE_AWS_COGNITO_SUCCESS, CREATE_AWS_COGNITO_ERROR,
    GET_LIST_AWS_COGNITO_BEGIN, GET_LIST_AWS_COGNITO_SUCCESS, GET_LIST_AWS_COGNITO_ERROR,
    DELETE_AWS_COGNITO_BEGIN, DELETE_AWS_COGNITO_SUCCESS, DELETE_AWS_COGNITO_ERROR,
    LIST_USERS_COGNITO_BEGIN, LIST_USERS_COGNITO_SUCCESS, LIST_USERS_COGNITO_ERROR,
    RESET_USER_COGNITO_BEGIN, RESET_USER_COGNITO_SUCCESS, RESET_USER_COGNITO_ERROR,
    DISABLE_USER_COGNITO_BEGIN, DISABLE_USER_COGNITO_SUCCESS, DISABLE_USER_COGNITO_ERROR,
    ENABLE_USER_COGNITO_BEGIN, ENABLE_USER_COGNITO_SUCCESS, ENABLE_USER_COGNITO_ERROR
} from '../actions/types'

const initialState = {
    cognitoList: [],
    userList: [],
    loading: false,
    loading_message: '',
    error: null,
    reset: '',
    messageDisable: ''
}

const reducer = produce((draft, action) => {
    switch (action.type) {
        case CREATE_AWS_COGNITO_BEGIN:
        case GET_LIST_AWS_COGNITO_BEGIN:
        case DELETE_AWS_COGNITO_BEGIN:
        case LIST_USERS_COGNITO_BEGIN:
        case RESET_USER_COGNITO_BEGIN:
        case DISABLE_USER_COGNITO_BEGIN:
        case ENABLE_USER_COGNITO_BEGIN:
            draft.loading = true;
            draft.error = null;
            draft.loading_message = action.loading_message;
            return;
        case DISABLE_USER_COGNITO_SUCCESS: 
            draft.loading = false;
            draft.error = null;
            draft.userList = action.payload.users;
            draft.reset = ''
            return;
        case ENABLE_USER_COGNITO_SUCCESS: 
            draft.loading = false;
            draft.error = null;
            draft.userList = action.payload.users;
            draft.reset = ''
            return;
        case CREATE_AWS_COGNITO_SUCCESS:
            draft.loading = false;
            draft.error = null;
            draft.cognitoList = action.payload;
            return;
        case  RESET_USER_COGNITO_SUCCESS:
            draft.loading = false;
            draft.error = null;
            draft.reset = action.payload.message;
            draft.messageDisable = ''
            return;
        case LIST_USERS_COGNITO_SUCCESS:
            draft.loading = false;
            draft.error = null;
            draft.userList = action.payload;
            return;
        case GET_LIST_AWS_COGNITO_SUCCESS:
            draft.loading = false;
            draft.error = null;
            draft.cognitoList = action.payload;
            draft.userList = []
            return;
        case DELETE_AWS_COGNITO_SUCCESS:
            draft.loading = false;
            draft.error = null;
            draft.cognitoList = action.payload
            return;
        case CREATE_AWS_COGNITO_ERROR:
            draft.loading = false;
            draft.error = action.error
            return;
        case DELETE_AWS_COGNITO_ERROR:
            draft.loading = false;
            draft.error = action.errorDeleteCognito
            return;
        case GET_LIST_AWS_COGNITO_ERROR:
            draft.loading = false;
            draft.error = action.errorlistCognito
            return;
        case LIST_USERS_COGNITO_ERROR:
        case RESET_USER_COGNITO_ERROR:
        case DISABLE_USER_COGNITO_ERROR:
        case ENABLE_USER_COGNITO_ERROR:
            draft.loading = false;
            draft.error = action.error_message
            return;
        default:
            return;
    }
}, initialState);

export default reducer;