import produce from 'immer';
import {
  PAYMENT_BEGIN, PAYMENT_SUCCESS, PAYMENT_ERROR,
  GET_CARD_BEGIN, GET_CARD_SUCCESS, GET_CARD_ERROR,
  ADD_CHECKOUT_BEGIN, ADD_CHECKOUT_SUCCESS, ADD_CHECKOUT_ERROR,  
  ADD_STRIPE_CARD_BEGIN, ADD_STRIPE_CARD_SUCCESS, ADD_STRIPE_CARD_ERROR,
  GET_STRIPE_CARD_BEGIN, GET_STRIPE_CARD_SUCCESS, GET_STRIPE_CARD_ERROR,
  ADD_CHECKOUT_CARD_BEGIN, ADD_CHECKOUT_CARD_SUCCESS, ADD_CHECKOUT_CARD_ERROR,
  REMOVE_STRIPE_CARD_BEGIN, REMOVE_STRIPE_CARD_SUCCESS, REMOVE_STRIPE_CARD_ERROR,
  GET_STRIPE_INVOICE_BEGIN, GET_STRIPE_INVOICE_SUCCESS, GET_STRIPE_INVOICE_ERROR, 
  GET_STRIPE_CUSTOMER_BEGIN, GET_STRIPE_CUSTOMER_SUCCESS, GET_STRIPE_CUSTOMER_ERROR,
  ADD_STRIPE_SUBSCRIPTION_BEGIN, ADD_STRIPE_SUBSCRIPTION_SUCCESS, ADD_STRIPE_SUBSCRIPTION_ERROR,
  UPDATE_STRIPE_SUBSCRIPTION_BEGIN, UPDATE_STRIPE_SUBSCRIPTION_SUCCESS, UPDATE_STRIPE_SUBSCRIPTION_ERROR,
  CANCEL_STRIPE_SUBSCRIPTION_BEGIN, CANCEL_STRIPE_SUBSCRIPTION_SUCCESS, CANCEL_STRIPE_SUBSCRIPTION_ERROR,
} from '../actions/types';

const initialState = {
  stripeCustomer: {},
  card: null,
  payment: null,
  billing: null,
  subscription: null,
  checkout: null,
  error: null,
  message: null,
  info: null,
  loading: false
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case PAYMENT_BEGIN:
    case GET_CARD_BEGIN:
    case ADD_CHECKOUT_BEGIN:
    case ADD_STRIPE_CARD_BEGIN:
    case GET_STRIPE_CARD_BEGIN:
    case ADD_CHECKOUT_CARD_BEGIN:
    case REMOVE_STRIPE_CARD_BEGIN:
    case GET_STRIPE_INVOICE_BEGIN:
    case GET_STRIPE_CUSTOMER_BEGIN:
    case ADD_STRIPE_SUBSCRIPTION_BEGIN:
    case CANCEL_STRIPE_SUBSCRIPTION_BEGIN:
    case UPDATE_STRIPE_SUBSCRIPTION_BEGIN:
      draft.loading = true;
      draft.error = null;
      return;
    case PAYMENT_SUCCESS:
      draft.loading = false;
      draft.payment = action.payload.paymentIntent.status;
      draft.error = null;
      return;
    case GET_CARD_SUCCESS:
      draft.loading = false;
      draft.info = action.payload;
      draft.error = null;
      return;
    case ADD_CHECKOUT_SUCCESS:
      draft.loading = false;
      draft.checkout = action.payload;
      draft.error = null;
      return;
    case ADD_CHECKOUT_CARD_SUCCESS:
      draft.loading = false;
      draft.subscription = action.payload;
      draft.message = 'Payment Success';
      draft.error = null;
      return;
    case GET_STRIPE_CUSTOMER_SUCCESS:
      draft.loading = false;
      draft.stripeCustomer = action.payload;
      draft.error = null;
      return;
    case REMOVE_STRIPE_CARD_SUCCESS:
      draft.loading = false;
      draft.card = null;
      draft.message = action.payload;
      draft.error = null;
      return;
    case ADD_STRIPE_CARD_SUCCESS:
      draft.loading = false;
      draft.card = action.payload.card;
      draft.message = action.payload.message;
      draft.error = null;
      return;
    case GET_STRIPE_CARD_SUCCESS:
      draft.loading = false;
      draft.card = action.payload;
      draft.error = null;
      return;
    case ADD_STRIPE_SUBSCRIPTION_SUCCESS:
      draft.loading = false;
      draft.subscription = action.payload;
      draft.message = 'Payment Success';
      draft.error = null;
      return;
    case UPDATE_STRIPE_SUBSCRIPTION_SUCCESS:
      draft.loading = false;
      draft.subscription = action.payload;
      draft.message = 'Update plan Success';
      draft.error = null;
      return;
    case CANCEL_STRIPE_SUBSCRIPTION_SUCCESS:
      draft.loading = false;
      draft.subscription = null;
      draft.error = null;
      return;
    case GET_STRIPE_INVOICE_SUCCESS:
      draft.loading = false;
      draft.billing = action.payload.invoice_list.data;
      draft.subscription = action.payload.subscription;
      draft.error = null;
      return;
    case GET_STRIPE_INVOICE_ERROR:
      draft.loading = false;
      draft.subscription = null;
      draft.billing = null;
      draft.error = action.error;
      return;
    case PAYMENT_ERROR:
    case GET_CARD_ERROR:
    case ADD_CHECKOUT_ERROR:
    case ADD_STRIPE_CARD_ERROR:
    case ADD_CHECKOUT_CARD_ERROR:
    case REMOVE_STRIPE_CARD_ERROR: 
    case GET_STRIPE_CUSTOMER_ERROR:
    case ADD_STRIPE_SUBSCRIPTION_ERROR:
    case UPDATE_STRIPE_SUBSCRIPTION_ERROR:
    case CANCEL_STRIPE_SUBSCRIPTION_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return;
    case GET_STRIPE_CARD_ERROR:
      draft.loading = false;
      draft.error = action.error;
      draft.card = null;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;