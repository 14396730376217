import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import routes from '../constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Background from '../assets/images/NotFoundBackground.png';
import '../assets/styles/containers/AccountConfirmationError.scss'
import logo from '../assets/images/Kor-dark-logo.svg';

const AccountConfirmationError = withRouter(({ history }) => {

    const GoBackToOverview = (e) => {
        e.preventDefault();
        history.push(routes.OVERVIEW);
    };

    return (
        <div className="account-confirmation-error-container">
            <div className="error-container">
                
                <div>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <div>
                    <p className="t-error-container">Account Confirmation</p>
                    <p className="d-error-container">This link has already been used and the account confirmed.</p>
                    <Button size="sm" onClick={GoBackToOverview}>Continue</Button>
                    </div>
                </div>
                
            </div>

            <div className="footer-error">
            <img src={logo} className="app-logo" alt="logo" />
            <p>© 2020 KOR Inc. All rights reserved. KOR is a registered trademark of FluxNetwork Inc. Terms, conditions, features, availability, pricing, fees, service and support options subject change without notice.</p>
          </div>
        </div>
    )
})

const mapState = (props, state) => ({
    history: props.history
})

const mapDispatch = {};

export default connect(mapState, mapDispatch)(AccountConfirmationError);
