import React, { useState, useEffect } from 'react';
import CountTemplate from './CountTemplate';
import LaunchTemplate from './LaunchTemplate';
import AwsCredTemplate from './AwsCredTemplate';
import DomainFormTemplate from './DomainFormTemplate';
import LaunchStrapiTemplate from './LaunchStrapiTemplate';
import LaunchTemplateWebApp from './LaunchTemplateWebApp';
import { Row, Col, Badge, Button } from 'react-bootstrap';
import AwsCredTemplateWebApp from './AwsCredTemplateWebApp';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../assets/styles/components/templates/templatesPage.scss';

const FormNewTemplate = ({
  team,
  history,
  domains,
  habitat,
  loading,
  awsCreds,
  gitCreds,
  gitClones,
  staticName,
  requestKey,
  awsCredURL,
  dbEndpoint,
  devEndpoint,
  prodEndpoint,
  loadingCreds,
  templateName,
  awsCredsUser,
  validateCreds,
  selectHabitat,
  deployTemplate,
  errorTemplates,
  endpointStatic,
  endpointWebApp,
  deployNewWebApp,
  addHabitadWithURL,
  addAwsCredWithURL,
  addHabitadAndCreds,
  EC2StrapiWithDomain, 
  addHabitadWithCreds,
  strapiEndpointAdmin,
  deployTemplateStrapi,
  deployWebAppWithCreds,
  strapiEndpointFrontend,
  EC2StrapiWithoutDomain,
  deployNewTemplateStrapi,
  deployTemplateWithoutDomain,
  deployWebAppWithCredsWithoutDomain,
  deployNewTemplateStrapiWithoutDomain,
}) => {
  const AWSRegions = [
    'us-east-2',
    'us-east-1',
    'us-west-1',
    'us-west-2',
    'ap-east-1',
    'ap-south-1',
    'ap-northeast-3',
    'ap-northeast-2',
    'ap-southeast-1',
    'ap-southeast-2',
    'ap-northeast-1',
    'ca-central-1',
    'eu-central-1',
    'eu-west-1',
    'eu-west-2',
    'eu-west-3',
    'eu-north-1',
    'me-south-1',
    'sa-east-1'
  ];

  const [pathApi] = useState('template');
  const [repoLambda] = useState('https://gitlab.com/kor-comunity/kor-lambda.git');
  const [runtimeLambda] = useState('Node.js 12');
  const [handlerLambda] = useState('index.handler');
  const [name, setName] = useState('');
  const [count, setCount] = useState(1);
  const [deploy, setDeploy] = useState(false);
  const [prefix, setPrefix] = useState('');
  const [gitCred, setGitCred] = useState('');
  const [repoURI, setRepoURI] = useState('');
  const [nameCred, setNameCred] = useState('');
  const [showCreds, setShowCreds] = useState(false);
  const [domainName, setDomainName] = useState('');
  const [regionCred, setRegionCred] = useState('');
  const [apiKeyCred, setApiKeyCred] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [cognitoName, setCognitoName] = useState('');
  const [selectedCred, setSelectedCred] = useState(null);
  const [apiSecretCred, setApiSecretCred] = useState('');
  const [apiGatewayName, setApiGatewayName] = useState('');
  const [siteWithoutDomain, setSiteWithoutDomain] = useState(false)

  useEffect(() => { 
    staticName && setName(staticName) }, [staticName]
  );


  const handleDeploy = () => {
    selectHabitat(habitat);
    deployTemplate(
      repoURI,
      name,
      prefix,
      awsCreds.region,
      team.id,
      habitat.id,
      domainName,
      gitCred,
    );
    setDeploy(true);
  }

  const handleDeployWithoutDomain = () => {
    selectHabitat(habitat);
    setDomainName('')
    setPrefix('')
    deployTemplateWithoutDomain(
      repoURI,
      name,
      prefix,
      awsCreds.region,
      team.id,
      habitat.id,
      domainName,
      gitCred
    );
    setDeploy(true);
  }

  const handleDeployWebAppWithoutDomain = () => {
    setDomainName('')
    setPrefix('')
    deployWebAppWithCredsWithoutDomain(
      repoURI,
      name,
      prefix,
      team.id,
      awsCreds,
      habitat.id,
      domainName,
      gitCred
    )
    setDeploy(true);
  }

  const handleDeployWebApp = () => {
    deployWebAppWithCreds(
      repoURI,
      name,
      prefix,
      team.id,
      awsCreds,
      habitat.id,
      domainName,
      gitCred
    )
    setDeploy(true);
  }


  const handleDeployStrapiWithoutDomain = () => {
    EC2StrapiWithoutDomain(awsCreds, habitat.id);
    setDeploy(true);
  } 

  const handleDeployStrapi = () => {
    EC2StrapiWithDomain(prefix, awsCreds, habitat.id, domainName);
    setDeploy(true);
  }

  const handleSelectCred = (cred) => {
    setSelectedCred(JSON.parse(cred));
  }

  const handleAuthorizeAws = () => {
    setShowLoading(true);
    addAwsCredWithURL();
  }
  const handleHabitatAndCreds = (cred) => {
    setShowLoading(true);
    addHabitadWithCreds(JSON.parse(cred), team.id);
  }

  const HandleHabitadWithURL = () => {
    setShowLoading(true);
    addHabitadWithURL(team.id)
  }

  return (
    <div className="new-template-steps">
      <div className="card-steps-container">
        <div>
          <div className="ml-4 mt-5 mb-5">
            <h4 className="font-weight-bold text-center">
              <Badge className="badge-template mr-3 pt-2 pb-2">
                <FontAwesomeIcon
                  icon={faLayerGroup}
                />
              </Badge>
              Let&apos;s get started
            </h4>
          </div>
          <CountTemplate
            count={count}
            setCount={setCount}
            templateName={templateName}
          />
          {count === 1 && (
            <AwsCredTemplate
              team={team}
              gitCred={gitCred}
              loading={loading}
              gitCreds={gitCreds}
              awsCreds={awsCreds}
              setCount={setCount}
              showCreds={showCreds}
              requestKey={requestKey}
              AWSRegions={AWSRegions}
              setGitCred={setGitCred}
              showLoading={showLoading}
              templateName={templateName}
              setShowCreds={setShowCreds}
              awsCredsUser={awsCredsUser}
              validateCreds={validateCreds}
              errorTemplates={errorTemplates}
              HandleHabitadWithURL={HandleHabitadWithURL}
              handleHabitatAndCreds={handleHabitatAndCreds}

            />
          )}
          {count === 2 && (
            <DomainFormTemplate
              name={name}
              prefix={prefix}
              domains={domains}
              setName={setName}
              setCount={setCount}
              setPrefix={setPrefix}
              domainName={domainName}
              setDomainName={setDomainName}
              templateName={templateName}
              siteWithoutDomain={siteWithoutDomain}
              setSiteWithoutDomain={setSiteWithoutDomain}
            />
          )}
          {templateName === 'STATIC SITE' && count === 3 && (
            <LaunchTemplate
              team={team}
              name={name}
              deploy={deploy}
              prefix={prefix}
              habitat={habitat}
              loading={loading}
              repoURI={repoURI}
              gitCred={gitCred}
              setCount={setCount}
              awsCreds={awsCreds}
              setRepoURI={setRepoURI}
              domainName={domainName}
              errorTemplates={errorTemplates}
              endpointStatic={endpointStatic}
              siteWithoutDomain={siteWithoutDomain}
            />
          )}
          {templateName === 'STRAPI' && count === 3 && (
            <LaunchStrapiTemplate
              deploy={deploy}
              prefix={prefix}
              habitat={habitat}
              loading={loading}
              awsCreds={awsCreds}
              domainName={domainName}
              dbEndpoint={dbEndpoint}
              devEndpoint={devEndpoint}
              prodEndpoint={prodEndpoint}
              errorTemplates={errorTemplates}
              strapiEndpointAdmin={strapiEndpointAdmin}
              strapiEndpointFrontend={strapiEndpointFrontend}
              siteWithoutDomain={siteWithoutDomain}
            />
          )}
          {templateName === 'WEB APP' && count === 3 && (
            <LaunchTemplateWebApp
              team={team}
              name={name}
              deploy={deploy}
              prefix={prefix}
              habitat={habitat}
              pathApi={pathApi}
              loading={loading}
              repoURI={repoURI}
              gitCred={gitCred}
              setCount={setCount}
              nameCred={nameCred}
              repoLambda={repoLambda}
              regionCred={regionCred}
              setRepoURI={setRepoURI}
              domainName={domainName}
              cognitoName={cognitoName}
              selectedCred={selectedCred}
              handleDeploy={handleDeploy}
              runtimeLambda={runtimeLambda}
              selectHabitat={selectHabitat}
              handlerLambda={handlerLambda}
              apiGatewayName={apiGatewayName}
              errorTemplates={errorTemplates}
              setCognitoName={setCognitoName}
              setApiGatewayName={setApiGatewayName}
              endpointWebApp={endpointWebApp}
              siteWithoutDomain={siteWithoutDomain}
            />
          )}

        </div>
        <Row>
          <Col className="text-center button-launch-template mt-5" xs={12}>
            {templateName === 'STATIC SITE' && count === 3 && !deploy && siteWithoutDomain === true && (
              <Button 
                size="lg" 
                className="btn-template font-weight-bold" 
                onClick={handleDeployWithoutDomain}
                disabled={!gitCred || !awsCreds}>
              Launch
              </Button>
            )}

            {templateName === 'STATIC SITE' && count === 3 && !deploy && siteWithoutDomain === false && (
              <Button 
                size="lg" 
                className="btn-template font-weight-bold" 
                onClick={handleDeploy}
                disabled={!domainName || !gitCreds || !awsCreds}>
              Launch
              </Button>
            )}


            {templateName === 'WEB APP' && count === 3 && !deploy && siteWithoutDomain === true && (
              <Button 
                size="lg" 
                className="btn-template font-weight-bold" 
                onClick={handleDeployWebAppWithoutDomain}
                disabled={!gitCred || !awsCreds}>
              Launch
              </Button>
            )}

            {templateName === 'WEB APP' && count === 3 && !deploy && siteWithoutDomain === false && (
              <Button 
                size="lg" 
                className="btn-template font-weight-bold" 
                onClick={handleDeployWebApp}
                disabled={!domainName || !gitCreds || !awsCreds}
                >
              Launch
              </Button>
            )}

            {templateName === 'STRAPI' && count === 3 && !deploy && siteWithoutDomain === true && (
              <Button 
                size="lg" 
                className="btn-template font-weight-bold" 
                onClick={handleDeployStrapiWithoutDomain}
                disabled={!awsCreds}>
              Launch
              </Button>
            )}

            {templateName === 'STRAPI' && count === 3 && !deploy && siteWithoutDomain === false && (
              <Button 
                size="lg" 
                className="btn-template font-weight-bold" 
                onClick={handleDeployStrapi}
                disabled={!domainName || !awsCreds}
                >
              Launch
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default FormNewTemplate;