import {
    CREATE_CHECKOUT_BEGIN,
    CREATE_CHECKOUT_SUCCESS,
    CREATE_CHECKOUT_ERROR,
  } from './types';
  
  export const getCheckoutData = (data) => {
    return (dispatch) => {
      dispatch({ type: CREATE_CHECKOUT_BEGIN });
      dispatch({
        type: CREATE_CHECKOUT_SUCCESS,
        payload: data,
      });
    };
  };
  