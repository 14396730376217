import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const EditGITCredModal = (props) => {

  return (
    <Modal
      show={props.showEdit}
      onHide={() => props.setShowEdit(false)}
      aria-labelledby="section-set-modal-title"
    >
    <Modal.Header closeButton>
        <Modal.Title id="section-set-modal-title">
          Update GIT Credentials
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form autoComplete="off">
          <Form.Group>            
            <Form.Control
              aria-label="Name"
              aria-describedby="new-name"              
              className="aws-creds-form"
              autoComplete="off"
              onChange={e => props.setName(e.target.value)}
              type="text"
              name="credsName"
              placeholder="Name"
              value={props.name}
            />
            <Form.Control
              aria-label="Username"
              aria-describedby="api-key"              
              className="aws-creds-form"
              autoComplete="off"
              onChange={e => props.setUserName(e.target.value)}
              type="text"
              name="username"
              placeholder="Username"
              value={props.username}
            />
            <Form.Control
              aria-label="Usersecret"
              aria-describedby="api-key"              
              className="aws-creds-form"
              autoComplete="off"
              onChange={e => props.setUserSecret(e.target.value)}
              type="text"
              name="usersecret"
              placeholder="User Secret"
              value={props.userSecret}
            />
          </Form.Group>
        </Form>
        <div className="button-update-name-function">          
          <Button 
            // disabled={!props.usersecret || !props.username || !props.credsName} 
            variant="primary" 
            onClick={props.handleUpdateGITCred}>
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default EditGITCredModal;
