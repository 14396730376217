import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../assets/styles/pages/Help.scss';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import SettingsFooter from '../components/settings/Footer';
import { changeStatusOnboarding } from '../actions/user.actions';
import routes from '../constants/routes';

const HelpPage = withRouter(({history, changeStatusOnboarding}) => {
  const OnboardingTour = (e) => {
    e.preventDefault();
    //history.push();
  };

  const handleChangeStatusOnboarding = () => {
    changeStatusOnboarding(true)
    history.push(routes.OVERVIEW)
  }

  return (
    <Row className="help-main-content">
      <Col md={5} lg={5} xl={5} className="leftSettingsCol border-right">
        {/* <Col className="onboardingTour-container">
          <div className="help-onboarding-icon">
             <FontAwesomeIcon icon={faQuestionCircle} />
          </div>
          <div className="help-onboarding-title">
             <p className="tour-title">Onboarding Tour</p>
             <p className="help-onboarding-date">Updated 04-07-2020</p>
             <div className="onboarding-under-line"></div>
          </div>
             <div className="help-onboarding-description">
             If you would like to take the Onboarding tour click here.
             </div>
             <Button variant="primary" size="sm"
             className="onboarding-btn btn btn-secondary"
             onClick={handleChangeStatusOnboarding}
             >
             Start Tour
             </Button>
        </Col> */}
        <SettingsFooter />
      </Col>
      <Col md={7} lg={7} xl={7} className="rightSettingsCol">
        <p className="additional-title">Additional Assistance</p>
        <div>
          <p className="documentation-tittle">More Documentation</p>
          <div className="under-line"></div>
          <p className="help-description">
          Find more documentation about KOR here. Step by step guides to help you work more <br/>efficiently.
            <a
              href="https://getkor.freshdesk.com/support/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              See more...
            </a>
          </p>
        </div>
        <div>
          <p className="support-title">Support</p>
          <div className="under-line"></div>
          <p className="help-description">
            If you are unable to find what you are looking for. Please create a
            ticket. One of our <br/>engineers will contact you as soon as possible.
            <a
              href="https://getkor.freshdesk.com/support/tickets/new"
              target="_blank"
              rel="noopener noreferrer"
            >
              See more...
            </a>
          </p>
        </div>
      </Col>
    </Row>
  );
});

const mapState = (props, state) => ({
  history: props.history,
});

const mapDispatch = {
  changeStatusOnboarding
};

export default connect(mapState, mapDispatch)(HelpPage);
