import React from 'react';
import '../components/notifications/notifications.scss';
import NotificationSubTitle from '../components/notifications/NotificationSubTitle';
import NotificationSwitch from '../components/notifications/NotificationSwitch';

class Notifications extends React.Component {

    render() { 
        return (
            <div>
                <div className="container-kds">
                    <div className="page-nav-main">
                        <p className="page-title">Notifications</p>
                        
                    </div>
                </div>
                <div className="col-md-12 page-content">
                	<NotificationSubTitle />
                    <div className="page-box notifications-page-box">
                        <div className="notification-digest">
                            <div className="row-notifications">
                                <div className="column-notifications">
                                    <p className="digest-title">Unread notifications</p>
                                    <p className="digest-content">Receive emails to your primary email address for unread notifications.</p>
                                </div>
                                <div className="column-notifications-switch">
                                    <NotificationSwitch className={'checkbox-container one'} toggle={'t3'} boolStatus={false}>
                                    </NotificationSwitch>
                                </div>
                            </div>                        
                        </div>
                        <div className="notification-digest">
                            <div className="row-notifications">
                                <div className="column-notifications">
                                    <p className="digest-title">Announcements & Promotions</p>
                                    <p className="digest-content">Receive emails for product announcements, promotions, and events.</p>        
                                </div>
                                <div className="column-notifications-switch">
                                    <NotificationSwitch className={'checkbox-container one'} toggle={'t4'} boolStatus={true}>
                                    </NotificationSwitch>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }   
}

export default Notifications;
