import React, { useState } from 'react';
import {
  faSpinner,
  faInfinity,
  faExclamation,
  faCheckCircle,
  faTerminal,
  faEllipsisV,
  faTrashAlt,
  faChevronDown,
  faChevronUp,
  faRedoAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import RemoveBuildModal from '../Modals/RemoveBuildModal';

const HabitatBuildHistoryItem = ({
  build,
  habitat,
  loading,
  buildsExecut,
  listBuildLogs,
  listExecutions,
  listBuildStatus,
  deleteBuildCICD,
  buildLogs
}) => {

  const [showRemoveModal, setShowRemoveModal] = useState('display-none-kor');
  const [showMore, setShowMore] = useState('display-none')

  const handleGetLog = (execute) => {
    listBuildLogs(execute.id, habitat.id);
    listBuildStatus(execute.id, habitat.id);
  };

  const handleDeleteBuild = (build_id, habitat_id) => {
    deleteBuildCICD(build_id, habitat_id)
  }

  const handleShowDelete = () => {
    setShowRemoveModal('')
  }

  const handleShowMore = () => {
    if (showMore === 'display-none') {
      setShowMore('')
    } else {
      setShowMore('display-none')
    }
  }



  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        <div className="new-function" onClick={handleShowDelete}>
          <FontAwesomeIcon className="delete-icon-function" icon={faTrashAlt} />
          <span>Delete</span>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="build-item-box">

      <RemoveBuildModal
        showRemoveModal={showRemoveModal}
        setShowRemoveModal={setShowRemoveModal}
        build={build}
        habitat={habitat}
        handleDeleteBuild={handleDeleteBuild}
      />
      <div className="flex-dir-space">
        <div className="flex-dir">
          <div className="icon-cicd">
            <FontAwesomeIcon icon={faInfinity} />
          </div>

          <div className="info-name-date-cicd">
            <p>
              <b>{build.build_name}</b>
            </p>
            <p>{build.created_at}</p>
          </div>
        </div>

        <OverlayTrigger rootClose={true} trigger="click" placement="right" overlay={popover}>
          <div className="menu-build">
            <FontAwesomeIcon icon={faEllipsisV} />
          </div>
        </OverlayTrigger>

      </div>

      <div className="executions-build">
        <p><b>Executions ({build.executions.length})</b></p>
        {showMore === 'display-none' ? (
          <FontAwesomeIcon icon={faChevronDown} onClick={handleShowMore} />
        ) : (
            <FontAwesomeIcon icon={faChevronUp} onClick={handleShowMore} />
          )}

      </div>
      {build.executions &&
        build.executions.map(execut => (
          <div className={showMore + ' more-info-build'} key={execut.id}>
            <div className="remove-build-info">
              {execut.build_status === 'FAILED' && (
                <div className="info-func-deployed-box fail-color">
                  <p className="fail-color"><FontAwesomeIcon icon={faExclamation} /> </p>
                  <p className="fail-color">Fail</p>
                </div>
              )}

              {execut.build_status === 'SUCCEEDED' && (
                <div className="info-func-deployed-box success-color">
                  <p className="success-color"><FontAwesomeIcon icon={faCheckCircle} /> </p>
                  <p className="success-color">Successful</p>
                </div>
              )}

              {execut.build_status === 'IN_PROGRESS' && (
                <div className="info-func-deployed-box progress-color">
                  <p className="progress-color"><FontAwesomeIcon icon={faSpinner} /> </p>
                  <p className="progress-color">In Progress</p>
                </div>
              )}

                <div className="info-func-deployed-box logs-show-cicd" onClick={() => handleGetLog(execut)}>
                  <p className="logs-text"><FontAwesomeIcon icon={faTerminal} /> </p>
                  <p className="logs-text">Show Logs</p>
                </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default HabitatBuildHistoryItem;
