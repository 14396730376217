import {
  GET_CREDENTIALS_GIT_BEGIN,
  GET_CREDENTIALS_GIT_SUCCESS,
  GET_CREDENTIALS_GIT_ERROR,
  CREATE_CREDENTIALS_GIT_BEGIN,
  CREATE_CREDENTIALS_GIT_SUCCESS,
  CREATE_CREDENTIALS_GIT_ERROR,
  UPDATE_CREDENTIAL_GIT_BEGIN,
  UPDATE_CREDENTIAL_GIT_SUCCESS,
  UPDATE_CREDENTIAL_GIT_ERROR,
  GET_CREDENTIAL_GIT_BEGIN,
  GET_CREDENTIAL_GIT_SUCCESS,
  GET_CREDENTIAL_GIT_ERROR,
  DELETE_CREDENTIAL_GIT_BEGIN,
  DELETE_CREDENTIAL_GIT_ERROR,
  DELETE_CREDENTIAL_GIT_SUCCESS,
  ADD_GITHUB_OAUTH_BEGIN,
  ADD_GITHUB_OAUTH_SUCCESS,
  ADD_GITHUB_OAUTH_ERROR,
  ADD_GITLAB_OAUTH_BEGIN,
  ADD_GITLAB_OAUTH_SUCCESS,
  ADD_GITLAB_OAUTH_ERROR,
} from './types';
import {
  createGitCredentials,
  getAllGitCredentials,
  getGitCredential,
  updateGitCredential,
  deleteGitCredential,
} from '../api/git/gitCredentials';

import { githubOauthRedirect } from '../api/git/githuboauth';

import { gitlabRedirect } from '../api/git/gitlabauth';

import { gitCredModel } from '../models/gitCreds';

const openWindow = (uri) => {
  window.open(uri);
  return { type: ADD_GITHUB_OAUTH_SUCCESS };
};

export const newGitCredWithOauth = (name, service) => {
  return (dispatch) => {
    dispatch({ type: ADD_GITHUB_OAUTH_BEGIN });
    githubOauthRedirect(name, service)
      .then((response) => {
        const uri = response.data.callback;
        dispatch(openWindow(uri));
      })
      .catch((error) =>
        dispatch({
          type: ADD_GITHUB_OAUTH_ERROR,
          error: error.response.data.error,
        })
      );
  };
};

export const newGitLabCredWithOauth = (name, service) => {
  return (dispatch) => {
    dispatch({ type: ADD_GITLAB_OAUTH_BEGIN });
    gitlabRedirect(name, service)
      .then((response) => {
        const uri = response.data.callback;
        dispatch(openWindow(uri));
      })
      .catch((error) =>
        dispatch({
          type: ADD_GITLAB_OAUTH_ERROR,
          error: error.response.data.error,
        })
      );
  };
};

export const newGitCred = (name, username, usersecret) => {
  let gitCred = gitCredModel(name, username, usersecret);

  return (dispatch) => {
    dispatch({ type: CREATE_CREDENTIALS_GIT_BEGIN });
    createGitCredentials(gitCred)
      .then((response) => {
        dispatch({
          type: CREATE_CREDENTIALS_GIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_CREDENTIALS_GIT_ERROR,
          errorNewGitCred: 'New git cred fail',
          error,
        });
      });
  };
};

export const listGitCreds = () => {
  return (dispatch) => {
    dispatch({ type: GET_CREDENTIALS_GIT_BEGIN });
    getAllGitCredentials()
      .then((response) => {
        dispatch({
          type: GET_CREDENTIALS_GIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_CREDENTIALS_GIT_ERROR,
          errorListCreds: 'Get git creds fail',
          error,
        });
      });
  };
};

export const listGitCred = (credential) => {
  return (dispatch) => {
    dispatch({ type: GET_CREDENTIAL_GIT_BEGIN });
    getGitCredential(credential.id)
      .then((response) => {
        dispatch({
          type: GET_CREDENTIAL_GIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_CREDENTIAL_GIT_ERROR, error });
      });
  };
};

export const editGitCred = (credential) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CREDENTIAL_GIT_BEGIN });
    updateGitCredential(credential.id, credential)
      .then((response) => {
        dispatch({
          type: UPDATE_CREDENTIAL_GIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_CREDENTIAL_GIT_ERROR,
          errorEditCred: 'Edit cred fail',
          error,
        });
      });
  };
};

export const removeGitCred = (credential) => {
  return (dispatch) => {
    dispatch({ type: DELETE_CREDENTIAL_GIT_BEGIN });
    deleteGitCredential(credential.id)
      .then((response) =>
        dispatch({
          type: DELETE_CREDENTIAL_GIT_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        dispatch({
          type: DELETE_CREDENTIAL_GIT_ERROR,
          errorRemoveGit: 'Remove git cred fail',
          error,
        });
      });
  };
};
