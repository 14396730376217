import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { Button, Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { faTimes, faQuestionCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const HabitatTemplates = (props, createStatic, createStaticWithoutDomain) => {
  useEffect(() => {
    if (props.habitat.id) {
      props.getAwsDomains(props.habitat.id);
      props.getClones(props.team);
      props.listGitCreds();
    }
  }, []);

  const [subdomain, setSubdomain] = useState('');
  const [domainName, setDomainName] = useState('');
  const [repositoryName, setRepositoryName] = useState('');
  const [showNewFunction, setshowNewFunction] = useState('new-function-setup');
  const [siteWithoutDomain, setSiteWithoutDomain] = useState(false);

  const handleShowNew = () => {
    setshowNewFunction('new-function-show');
  };

  const handlenNewSite = () => {
    const clone = props.gitClones.find(clone => clone.id === repositoryName);
    const awsCred = props.awsCreds;

    props.createStatic(subdomain, awsCred.region, clone, props.habitat.id, domainName);
  }

  const handleNewSiteWithoutDomain = () => {
    const clone = props.gitClones.find(clone => clone.id === repositoryName);
    const awsCred = props.awsCreds;
    setDomainName('');
    setSubdomain('');
    props.createStaticWithoutDomain(subdomain, awsCred.region, clone, props.habitat.id, domainName);
  }

  const handleRemove = (template) => {
    props.removeTemplate(template.id);
  }

  return (
    <div className="habitat-funcs-management-container">
      <div className="title-setup-w-new">
        <div className="title-setup-management-funcs">
          <div className="box-feature-name-icon-templates">
            <FontAwesomeIcon icon={faLayerGroup} />
          </div>
          <h5>Static Sites</h5>
        </div>
        <div className="new-function-btn-setup" onClick={handleShowNew}>
          <p> + New Site</p>
        </div>
      </div>

      {props.loadingTemplate && (
        <div className="spinner-container funcs-habitat-spinner">
          <Spinner animation="border" variant="primary" />
          <p>{props.loadingTemplateMessage}</p>
        </div>
      )}


      {props.errorTemplates && (
        <div className="error-display text-danger">
          <p><FontAwesomeIcon icon={faExclamationCircle} /> Error: {props.errorTemplates}</p>
        </div>
      )}



      <div className="setup-container-funcs">
        {props.templates &&
          props.templates.length > 0 &&
          props.templates.map((template) => (
            <div className="funcs-setup-box" key={template.id}>
              <div className="box-feature-name-funcs">
                <div className="box-feature-name-icon-templates">
                  <FontAwesomeIcon icon={faLayerGroup} />
                </div>
                <p>{template.name}</p>
              </div>

              <div className="text-feature-items">
                <div className="box-feature-item">
                  <p>
                    <b>Domain: </b> {template.domain_name}
                  </p>
                </div>
              </div>

              <div className="box-feature-item">
                <Button variant="outline-danger" size="sm" onClick={() => handleRemove(template)}>Remove</Button>
              </div>
            </div>
          ))}
      </div>

      <div className={showNewFunction + ' new-function-container'}>
        <p>New Site</p>

        <div className="inputs-new-aws-credential">
          <Form.Control
            as="select"
            defaultValue={'DEFAULT'}
            onChange={(e) => setRepositoryName(e.target.value)}
          >
            <option disabled value="DEFAULT">
              --Select a Repository--
            </option>
            {props.gitClones.map((clone) =>
              props.team.name
                ? clone.team_id && (
                  <option key={clone.id} value={clone.id}>
                    {clone.project_name}
                  </option>
                )
                : !clone.team_id && (
                  <option key={clone.id} value={clone.id}>
                    {clone.project_name}
                  </option>
                )
            )}
          </Form.Control>
          <Form.Control
            as="select"
            defaultValue={'DEFAULT'}
            onChange={(e) => setDomainName(e.target.value)}
            disabled={siteWithoutDomain}
          >
            <option disabled value="DEFAULT">
              --Select a Domain Name--
            </option>
            {props.domains.map(domain => (
              <option key={domain.name} value={domain.name.substring(0, domain.name.length - 1)}>{domain.name.substring(0, domain.name.length - 1)}</option>
            ))}
          </Form.Control>
          <Form.Control
            type="text"
            value={subdomain}
            aria-label="handler"
            placeholder="Subdomain"
            aria-describedby="handler"
            onChange={e => setSubdomain(e.target.value)}
            disabled={siteWithoutDomain}
          />
        </div>

        <div className="check-enable-no-domain">
          <Form.Check
            checked={siteWithoutDomain}
            type="checkbox"
            className="check-enable-build-1"
            onChange={() => setSiteWithoutDomain(!siteWithoutDomain)}
          />
          <p>Create static site without a domain</p>
        </div>

        <div className="button-container-create-site">
          {siteWithoutDomain ? (
            <Button
              size="sm"
              variant="primary"
              onClick={handleNewSiteWithoutDomain}
              className="launchTemplates"
            >
              Create Site
            </Button>
          ) : (
            <Button
              size="sm"
              variant="primary"
              onClick={handlenNewSite}
              className="launchTemplates"
            >
              Create Site
            </Button>
          )}

        </div>

        <div className="dom-reg-question">
          <p>AWS Domain Registration</p>
          <div className="question-mark-documentation-habitat">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip className="red-tooltip">
                  <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                </Tooltip>
              }

            >
              <a
                href="https://getkor.freshdesk.com/support/solutions/articles/47001163815-aws-domain-registration"
                rel="noopener noreferrer"
                className="help"
                target="_blank"
              >

                <FontAwesomeIcon icon={faQuestionCircle} />
              </a>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HabitatTemplates;
