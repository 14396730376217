import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Redirect } from 'react-router-dom'

const GitCloneModal = (props) => {

    const handleCloseModal = () => {
        props.setShowModal(!props.showModal)
    }

    return (
            <Modal
                size="md"
                show={props.showModal}
                aria-labelledby="newfunc-modal-title"
                onHide={handleCloseModal}
            >

                <Modal.Header closeButton>
                    <Modal.Title id="newfunc-modal-title">
                        Git Clone
                </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="git-clone-modal">
                        <Form>
                            <Form.Group>
                                <Form.Label>Function Name:</Form.Label>
                                <Form.Control
                                    type="text"
                                    aria-label="Title"
                                    aria-describedby="new-title"
                                    placeholder="Function name"
                                    ref={props.inputRepositoryName}
                                />
                                <hr />
                                <Form.Label>Repository Link:</Form.Label>
                                <Form.Control
                                    type="text"
                                    aria-label="Title"
                                    aria-describedby="new-title"
                                    placeholder="Repository Link"
                                    ref={props.inputRepository}
                                />
                                <hr />
                                <Form.Label>
                                    Git Credentials:
                        </Form.Label>
                                <Form.Control
                                    as="select"
                                    defaultValue={'DEFAULT'}
                                    onChange={e => props.setGitCredsClone(e.target.value)}
                                >
                                    <option disabled value="DEFAULT">
                                        Select Credentials
                                    </option>
                                    {props.gitCreds && props.gitCreds.map((gitCred) => (
                                        <option
                                            key={gitCred.id}
                                            value={gitCred.id}>
                                            {gitCred.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                <br />
                                <Form.Label>
                                    <p className="label-info">*KOR is using HTTPS for clone.<br />
                                You can clone a repository without git credentials if the repository is public.</p>
                                </Form.Label>
                                <br />
                                <div className="button-clone">
                                    <button
                                        className="button-clone-test"
                                        onClick={props.handleGetInputs}>
                                        Clone Now
                                </button>
                                    {props.stepAppTour == 3 && (
                                        <Redirect to={props.sendHabitat} />
                                    )}
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
    );
}

export default GitCloneModal;
