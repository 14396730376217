import React from 'react';
import Chart from 'react-apexcharts';
import { Row, Col, Card } from 'react-bootstrap';

const HabitatFrontChart = ({
  frontMetrics
}) => {

  return (
    <Row>
      <Col xs={12}>
        <div className="d-flex justify-content-center">
          <Card
            bg="dark"
            text='white'
            style={{ width: '18rem' }}
            className="mb-2 mt-5"
          >
            <Card.Body>
            <Card.Title className="text-center">Visits</Card.Title>
              <Card.Title className="text-center">{frontMetrics.visits}</Card.Title>
            </Card.Body>
          </Card>
        </div>
      </Col>
      <Col xs={12} md={6}>
        <div className="mt-5 p-3">
          {frontMetrics.bytesDownloaded && (
            <Chart
              options={frontMetrics.bytesDownloaded.options}
              series={frontMetrics.bytesDownloaded.series}
              type="line"
              width="100%"
            />
          )}
        </div>
      </Col>
      <Col xs={12} md={6}>
        <div className="mt-5 p-3">
          {frontMetrics.bytesUploaded && (
            <Chart
              options={frontMetrics.bytesUploaded.options}
              series={frontMetrics.bytesUploaded.series}
              type="line"
              width="100%"
            />
          )}
        </div>
      </Col>
      <Col xs={12} md={6}>
        <div className="mt-5 p-3">
          {frontMetrics.requests && (
            <Chart
              options={frontMetrics.requests.options}
              series={frontMetrics.requests.series}
              type="line"
              width="100%"
            />
          )}
        </div>
      </Col>
      <Col xs={12} md={6}>
        <div className="mt-5 p-3">
          {frontMetrics.errors && (
            <Chart
              options={frontMetrics.errors.options}
              series={frontMetrics.errors.series}
              type="pie"
              width="100%"
            />
          )}
        </div>
      </Col>
    </Row>
  );
}

export default HabitatFrontChart;