import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import styled from 'styled-components';
import theme from 'prism-react-renderer/themes/nightOwl';
import Spinner from 'react-bootstrap/Spinner';

const HabitatBuildLogs = (props) => {
    const language = 'javascript';
    const buildLogs = props.buildLogs;

    const Pre = styled.pre`
        text-align: left;
        margin: 0em 0;
        padding: 1em;
        overflow: scroll;
    `;

    const Line = styled.div`
        display: table-row;
    `;

    const LineNo = styled.span`
        display: table-cell;
        text-align: right;
        padding-right: 0em;
        user-select: none;
        opacity: 0.5;
    `;

    const LineContent = styled.span`
        display: table-cell;
    `;

    return (
        <div className="build-logs-container">
            
            {props.loading ? (
                <div className="spinner-container">
                    <Spinner animation="border" variant="primary" />
                    <p>Loading...</p>
                </div>
            ) : (
                    <Highlight {...defaultProps} theme={theme} code={buildLogs} language="jsx">
                        {({ className, style, tokens, getLineProps, getTokenProps }) => (
                            <Pre className={className} style={style}>
                                {tokens.map((line, i) => (
                                    <Line key={i} {...getLineProps({ line, key: i })}>
                                        <LineNo>{ }</LineNo>
                                        <LineContent>
                                            {line.map((token, key) => (
                                                <span key={key} {...getTokenProps({ token, key })} />
                                            ))}
                                        </LineContent>
                                    </Line>
                                ))}
                            </Pre>
                        )}
                    </Highlight>
                )}

        </div>

    )

}

export default HabitatBuildLogs;