import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../constants/routes';
import ChangePassword from '../components/account/ChangePassword';
import { newPassword } from '../actions/user.actions'; 

const ResetPasswordForm = withRouter(({history, newPassword}) => {

  const [password, setPassword]               = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleReset = () => {
    const params = new URLSearchParams(window.location.search);
    
    newPassword(params, password, confirmPassword);
    history.push(routes.SIGNIN);
  }

  return(
    <ChangePassword
      password={password}
      setPassword={setPassword}
      handleReset={handleReset}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword} 
    />
  )

});

const mapState = (state, props) => ({
  history: props.history
});

const mapDispatch = {
  newPassword
};

export default connect(
  mapState,
  mapDispatch
)(ResetPasswordForm);