import React from 'react';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';
import { Row, Col, Form } from 'react-bootstrap';
import '../../assets/styles/components/modals/CardSection.scss';



const CardSection = (props) => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <Form.Control 
            type="text" 
            placeholder="Name"
            onChange={(event) => props.setName(event.target.value)}
          />
        </Col>
        <Col xs={12}>
          <CardNumberElement />
        </Col>
        <Col xs={12} md={6}>
          <CardExpiryElement />
        </Col>
        <Col xs={12} md={6}>
          <CardCvcElement />
        </Col>
        <Col xs={12}>
          <Form.Control 
            type="text" 
            placeholder="Billing Address"
            onChange={(event) => props.setAddress(event.target.value)} 
          />
        </Col>
      </Row>
    </>
  );
};

export default CardSection;