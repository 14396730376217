import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../assets/styles/pages/Habitats.scss';
import HabitatTopMenu from '../components/Habitats/HabitatTopMenu';
import Moment from 'moment';
import { getAwsData, getDuration, getMemory, getAwsLog } from '../actions/aws-sdk.actions';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { listFunctions } from '../actions/funcs.actions';
import { retrieveHabitat } from '../actions/habitat.actions';
import { getStaticSites } from '../actions/templates.actions';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import HabitatMonitoringMenu from '../components/Habitats/HabitatMonitoringMenu';
import { getApiList, getApiMetrics, getFrontMetrics } from '../actions/aws-apigateway.actions';
import HabitatMonitor from '../components/Habitats/HabitatMonitor';
import HabitatDashboard from '../containers/HabitatDashboard';
import HabitatAPIGatewayMetrics from '../components/Habitats/HabitatAPIGatewayMetrics';
import HabitatCloudFrontMetrics from '../components/Habitats/HabitatCloudFrontMetrics';
import AppTour from '../components/AppTour';
import { nextStepTour, stopTour, changeOnboardingClassHabitat, changeOnboardingClassAWS } from '../actions/app-tour.actions';
import { changeStatusOnboarding } from '../actions/user.actions';
import { requestDeleteHabitat } from '../actions/habitat.actions';
import HabitatResourceReport from '../components/Habitats/HabitatResourceReport'

const HabitatMonitorPage = ({
    error,
    funcs,
    params,
    awsLog,
    loading,
    awsData,
    habitat,
    awsLogs,
    awsCreds,
    templates,
    awsMemory,
    getMemory,
    getAwsLog,
    getApiList,
    getAwsData,
    apiMetrics,
    awsDuration,
    getDuration,
    apiGateways,
    frontMetrics,
    getApiMetrics,
    listFunctions,
    getStaticSites,
    loadingMessage,
    retrieveHabitat,
    getFrontMetrics,
    stepAppTour,
    statusAppTour,
    changeStatusOnboarding,
    changeOnboardingClassHabitat,
    changeOnboardingClassAWS,
    nextStepTour,
    requestDeleteHabitat,
    resources
}) => {

    const [hours, setHours] = useState(3);
    const [funcName, setFuncName] = useState('');
    const [selectedFeature, setSelectedFeature] = useState('resources');
    const [onboardingClass, setOnboardingClass] = useState('onboardingClass');

    const endTime = Moment().format();
    let startTime = Moment().subtract(hours, 'hours').format();
    const resourcesArray = [];




    const selectLastHour = (varLastHour) => {
        setHours(varLastHour);
        startTime = Moment().subtract(varLastHour, 'hours').format();
        handleChart(funcName);
    };

    useEffect(() => {
        if (!habitat.id) {
            retrieveHabitat(params.habitatId);
            getStaticSites(params.habitatId);
            listFunctions(params.habitatId);
            getApiList(params.habitatId);
            requestDeleteHabitat(params.habitatId);
        } else {
            getStaticSites(habitat.id);
            listFunctions(habitat.id);
            getApiList(habitat.id);
            requestDeleteHabitat(params.habitatId);
        }
        if (funcs.length > 0) {
            handleChart(funcs[0].function_name);
            setFuncName(funcs[0].function_name);
        }
    }, []);

    const handleChart = (funcName) => {
        setFuncName(funcName);
        getDuration(habitat, startTime, endTime, funcName);
        getMemory(habitat, funcName);
    }

    let memoryChart = {
        series: [
            {
                name: 'Max Memory',
                data: awsMemory.memorySize
            },
            {
                name: 'Memory Used',
                data: awsMemory.memoryUsed
            }
        ],
        options: {
            chart: {
                id: 'ram-chart',
                stacked: false,
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: true
                }
            },
            title: {
                text: 'Memory Consumption (MB)',
                align: 'left'
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: false,
            },
            colors: [
                '#1d7aff',
                '#ff4f8b',
                '#ff875c',
                '#14ca88',
                '#81889d'
            ],
            stroke: {
                width: 1.5,
                curve: 'smooth',
            },
            markers: {
                size: 3.5,
                strokeWidth: 2.5,
                hover: {
                    size: undefined,
                }
            },
            yaxis: {
                tickAmount: 10,
                min: 0,
                max: 140,
            },
            xaxis: {
                type: 'datetime',
                categories: awsDuration.timestamps
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy HH:mm'
                }
            },
        },
    };

    return (
        <div>
            <AppTour
                stopTour={stopTour}
                stepAppTour={stepAppTour}
                nextStepTour={nextStepTour}
                statusAppTour={statusAppTour}
                onboardingClass={onboardingClass}
                setOnboardingClass={setOnboardingClass}
                changeStatusOnboarding={changeStatusOnboarding}
                changeOnboardingClassHabitat={changeOnboardingClassHabitat}
                changeOnboardingClassAWS={changeOnboardingClassAWS}
            />
            <div className="habitat-sub-menu">
                <div className="sub-menu-side-l">
                    <FontAwesomeIcon icon={faGlobe} />
                    <p>{habitat.name}</p>
                </div>
                <HabitatTopMenu
                    params={params}
                    habitat={habitat}
                    stepAppTour={stepAppTour}
                    changeStatusOnboarding={changeStatusOnboarding}
                    changeOnboardingClassHabitat={changeOnboardingClassHabitat}
                    changeOnboardingClassAWS={changeOnboardingClassAWS}
                    nextStepTour={nextStepTour}
                />
            </div>
            <div className="habitat-container">
                <div className="title-deployments title-funcs-dashboard">
                    <p>Monitoring</p>
                    <div className="question-mark-documentation-habitat">
                        <OverlayTrigger
                            placement="left"
                            overlay={
                                <Tooltip className="red-tooltip">
                                    <strong style={{ fontSize: '0.9em', padding: '2em 0' }}>Click here for documentation</strong>
                                </Tooltip>
                            }

                        >
                            <a
                                href="https://getkor.freshdesk.com/support/solutions/articles/47001164419-monitoring"
                                rel="noopener noreferrer"
                                className="help"
                                target="_blank"
                            >

                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </a>
                        </OverlayTrigger>
                    </div>
                </div>

                <HabitatMonitoringMenu
                    selectedFeature={selectedFeature}
                    setSelectedFeature={setSelectedFeature}
                />

                {selectedFeature === 'resources' && (
                    <HabitatDashboard
                        params={params}
                    />
                )}

                {selectedFeature === 'monitor' && (
                    <HabitatMonitor
                        handleChart={handleChart}
                        selectLastHour={selectLastHour}
                        memoryChart={memoryChart}
                        awsDuration={awsDuration}
                        funcs={funcs}
                        loading={loading}
                        hours={hours}
                    />
                )}

                {selectedFeature === 'apigateway' && (
                    <>
                        <HabitatAPIGatewayMetrics
                            error={error}
                            habitat={habitat}
                            apiMetrics={apiMetrics}
                            apiGateways={apiGateways}
                            getApiMetrics={getApiMetrics}
                        />
                    </>
                )}


                {selectedFeature === 'cloudfront' && (
                    <HabitatCloudFrontMetrics
                        error={error}
                        habitat={habitat}
                        templates={templates}
                        frontMetrics={frontMetrics}
                        getFrontMetrics={getFrontMetrics}
                    />
                )}

                {selectedFeature === 'resources-report' && (
                    <HabitatResourceReport
                        resourcesArray={resourcesArray}
                        resources={resources}
                    />
                )}
            </div>
        </div>
    )

}

const mapState = (state, props) => ({
    funcs: state.func.funcs,
    awsCreds: state.awsCreds,
    params: props.match.params,
    awsLogs: state.awsSdk.logs,
    error: state.apiGateway.error,
    loading: state.awsSdk.loading,
    habitat: state.habitat.habitat,
    awsLog: state.awsSdk.log.events,
    awsData: state.awsSdk.awsMetrics,
    awsMemory: state.awsSdk.awsMemory,
    templates: state.templates.templates,
    awsDuration: state.awsSdk.awsDuration,
    apiMetrics: state.apiGateway.apiMetrics,
    apiGateways: state.apiGateway.apiGateways,
    frontMetrics: state.apiGateway.frontMetrics,
    loadingMessage: state.awsSdk.loading_message,
    stepAppTour: state.appTour.stepAppTour,
    statusAppTour: state.user.user.onboarding,
    resources: state.habitat.resources_habitat
})

const mapDispatch = {
    getMemory,
    getAwsLog,
    getApiList,
    getAwsData,
    getDuration,
    getApiMetrics,
    listFunctions,
    getStaticSites,
    retrieveHabitat,
    getFrontMetrics,
    changeOnboardingClassHabitat,
    changeOnboardingClassAWS,
    nextStepTour,
    changeStatusOnboarding,
    requestDeleteHabitat
}

export default connect(
    mapState,
    mapDispatch
)(HabitatMonitorPage);