import React from 'react';
import '../../assets/styles/components/billing/billing-credit-card.scss';
import CardSetupForm from './CardSetupForm';

const CreditCard = ({team, setShow, setToast}) => {

    return(
        <CardSetupForm
            team={team} 
            setShow={setShow}
            setToast={setToast}
        />
    );
}

export default CreditCard;